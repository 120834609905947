import React from 'react';

import searchAgentBanner from '../../../src/images/entry_pages/search-agent-banner.svg';
import { DescriptionLine, handleListItemClick } from '../SharedComponents/ItemsListComponents';

import './GridViewSearchAgent.sass';


const GridViewSearchAgent = ({ advertismentData: { title, description },
                               mapIsOpen,
                               newSearchAgentPath,
                               siteLogoPath,
                               rootPath,
                               siteKey
                             }) => {

  const checkDk = siteKey === 'dk';

  return (
    <div className={`grid-item col-md-6 col-lg-${mapIsOpen ? 6 : 4} col-xl-${mapIsOpen ? 4 : 3}`}>
      <div className="advertisement search-agent">
        <div
          className="grid-item__wrapper"
          onClick={(e) => handleListItemClick(e, newSearchAgentPath, true)}
        >
          <div className="search-agent__image-wrapper">
            <img
              className="search-image lazyload"
              data-src={searchAgentBanner}
              data-sizes="auto"
              alt="Search Agent"
              width={168}
              height={132}
            />
          </div>

          <div className="grid-item__description-wrapper">
            <div className="adver-top">
              <p className="adver-content-title">{title}</p>
              <div className="adver-description">
                <DescriptionLine substring={description} lines={3} />
              </div>
              <div className="adver-description-md">
                <DescriptionLine substring={description} lines={4} />
              </div>
            </div>

            <div className="search-agent-bottom">
              <div className="search-agent-images">
                <a href={rootPath}>
                  <img
                    alt="provider logo"
                    className="lazyload"
                    data-expand="100"
                    data-sizes="auto"
                    data-src={`https://www.lokalebasen.dk${siteLogoPath}`}
                    height={60}
                  />
                </a>
              </div>
              <div className="grid-item__button-wrapper">
                <a href={newSearchAgentPath} target="_blank" className='grid-item__search-agent-button sa-button'>
                  <span>
                    { checkDk
                      ? I18n.t('search_agents.new.standard_search_agent')
                      : I18n.t('list-view.advertisement.search_agent_button_text')
                    }
                  </span>
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridViewSearchAgent;
