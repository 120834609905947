import React, { useContext, useState } from 'react';

import { SvgIco } from '../../SharedComponents/Icons';
import DescriptionTopMenu from './DescriptionTopMenu';
import { Context } from "../index";

import './Description.sass';


const Description = ({ activeTranslatedDescription, setTranslatedDescription }) => {

  const {
    isRequestFromPrerender,
    siteKey,
    tabs: { originalDescription, translatedDescriptions },
  } = useContext(Context);

  const [readMore, setReadMore] = useState(false);
  const [showOriginalDescription, setShowOriginalDescription] = useState(false);

  const textLength = activeTranslatedDescription.description ? activeTranslatedDescription.description.length : null;


  return (
    <div className="localized-desc">

      <DescriptionTopMenu
        activeTranslatedDescription={activeTranslatedDescription}
        setTranslatedDescription={setTranslatedDescription}
        siteKey={siteKey}
        translatedDescriptions={translatedDescriptions}
      />

      <div>
        { activeTranslatedDescription &&
          <div className="localized-desc__content">
            <p className="dv-info-section__title">
              { activeTranslatedDescription.title }
            </p>

            <div className="localized-desc__description-text"
                 dangerouslySetInnerHTML={{ __html:
                     showOriginalDescription
                       ? originalDescription
                       : (readMore || isRequestFromPrerender)
                         ? activeTranslatedDescription.description
                         : activeTranslatedDescription.description.slice(0, 500)
                 }}
            />
          </div>
        }

        { textLength > 500 &&
          <div className="localized-desc__original_desc_wrapper">
            { !readMore && <div className="opacity-block" /> }

            <div className="localized-desc__original_desc_read_more">
              <a onClick={() => setReadMore(prevState => !prevState)}>
                { readMore
                  ? I18n.t('apps.lb_showroom.detailed.description.hide_more_link')
                  : I18n.t('apps.lb_showroom.detailed.description.read_more_link')
                }
                <SvgIco name={readMore ? "expand-less" : "expand-more"} size={16} />
              </a>
            </div>
          </div>
        }
      </div>

    </div>
  );
}

export default Description;
