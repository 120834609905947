import React from 'react';

import './styles/virtual-tour.sass';

export const VirtualTourModal = (props) => {
  return (
    <div onClick={props.toggleModal}>
      { props.isOpen &&
        <div className='virtual-tour-modal'>
          <div className='virtual-tour-modal__iframe'>
            <iframe src={props.src} />
          </div>
        </div>
      }
    </div>
  )
};
