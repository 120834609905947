import React, { Component } from 'react';
import { isMobile, isTablet } from "react-device-detect";
import DetailMap from './DetailMap';

class DetailsMapWrapper extends Component {
  state = {
    isGalleryMobileComponent: false,
    isFullscreenMobile: false,
    orientationHorizontalMobile: false,
  };
  handleFullscreenMobile = () => this.setState({ isFullscreenMobile: !this.state.isFullscreenMobile });

  componentDidMount() {
    this.props.isGalleryComponent && isMobile && this.setState({ isGalleryMobileComponent: true });
  }

  render() {
    const { latitude, longitude , googleApiKey, orientationHorizontalMobile } = this.props;
    const { isFullscreenMobile, isGalleryMobileComponent } = this.state;
    const coordinatesObj = { lat: latitude, lng: longitude };
    const containerClass = `map-container${isFullscreenMobile ? ' fullscreen-mobile' : ''}`;


    return (
      <DetailMap
        googleApiKey={googleApiKey}
        containerClass={containerClass}
        coordinates={coordinatesObj}
        isGalleryMobileComponent={isGalleryMobileComponent}
        isMobile={isMobile && !isTablet}
        handleFullscreenMobile={this.handleFullscreenMobile}
        isFullscreenMobile={isFullscreenMobile}
        orientationHorizontalMobile={orientationHorizontalMobile}
      />
    );
  }
}

export default DetailsMapWrapper;
