import React, { Component } from 'react';
import Select from 'react-select';

import { SvgIco } from "./Icons";

import './DropdownWithSearch.sass';


class DropdownSelectWithSearch extends Component {

  state = {
    selectedOption: '',
    dropdownIsOpen: false,
  };

  componentDidMount() {
    const { options, initialValue, value } = this.props;
    const initialValueId = value || initialValue;
    const defaultValue = options.filter(elem => elem.value === initialValueId);

    defaultValue.length && this.setState({ selectedOption: defaultValue[0].label });
  }

  handleClickOutside = (e) => {
    const dropdownWithSearch = document.getElementById('dropdownWithSearch');

    !dropdownWithSearch.contains(e.target) && this.setState({ dropdownIsOpen: false });
  };

  handleSelect = selectedOption => {
    this.setState({ selectedOption: selectedOption.label }, () => this.toggleDropdown());
    this.props.setFieldValue(this.props.field, selectedOption.value);
  };

  toggleDropdown = () => {
    const { dropdownIsOpen } = this.state;
    this.setState({ dropdownIsOpen: !dropdownIsOpen }, () => {
      this.state.dropdownIsOpen ? document.addEventListener("click", this.handleClickOutside) : document.removeEventListener("click", this.handleClickOutside);
    });
  };

  componentWillUnmount() {
    this.state.dropdownIsOpen && document.removeEventListener("click", this.handleClickOutside);
  }

  render() {

    const { options, label } = this.props;
    const { selectedOption, dropdownIsOpen } = this.state;
    const inputFieldClass = `dropdown-with-search__input-field ${ selectedOption && 'active' }`;

    const customStyles = {
      indicatorsContainer: () => ({
        display: 'none',
      }),
      option: (provided, state) => ({
        ...provided,
        height: '40px',
        padding: '0px 16px',
        display: 'flex',
        alignItems: 'center',
        color: 'black',
        borderRadius: '2px',
        cursor: 'pointer'
      }),

      placeholder: () => ({
        display: 'none',
      }),
      control: () => ({
        minHeight: '32px',
        maxHeight: '32px',
        background: 'white',
        border: '1px solid #cccccc',
        borderRadius: '4px',
        margin: '16px 16px 10px'
      }),
      input: () => ({
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '5px',
        marginLeft: '-3px',
      }),
      menu: () => ({
        margin: 0,
        maxHeight: '208px',
        overflow: 'hidden',
        background: 'white',
        paddingTop: '0px',
        fontFamily: 'MuseoSans, Helvetica, Arial, sans-serif'
      }),
      menuList: (provided) => ({
        ...provided,
        maxHeight: '208px',
      }),
    };

    return (
      <div className="dropdown-with-search" id="dropdownWithSearch">
        <div className="dropdown-with-search__wrapper"
             onClick={ this.toggleDropdown }
             id="dropdownWithSearchWrapper">
          <div className={inputFieldClass}>
            <span className="dropdown-with-search__label">{ label }*</span>
            { selectedOption && <span className="dropdown-with-search__selected-option">{ selectedOption }</span> }
          </div>
          <div className="dropdown-with-search__input-toggle" >
            { dropdownIsOpen
              ? <SvgIco name="expand-less" size={20} />
              : <SvgIco name="expand-more" size={20} />
            }
          </div>
        </div>
        { dropdownIsOpen &&
          <div className="dropdown-with-search__select-wrapper">
            <span className="dropdown-with-search__search-icon" />
            <Select
              value={selectedOption || null}
              onChange={e => this.handleSelect(e)}
              options={options}
              menuIsOpen={true}
              closeMenuOnSelect={false}
              styles={customStyles}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#F6F6F6',
                  primary: '#F6F6F6',
                },
              })}
              isClearable
              isSearchable
            />
          </div>
        }
      </div>
    )
  }
}

export default DropdownSelectWithSearch;
