import React, { Component } from 'react';
import { apiProvider } from '../../api/core';
import { loadTawkTo } from '../TawkTo/TawkTo';
import RecoveryPasswordForm from './RecoveryPasswordForm';
import './RecoveryPassword.sass';


class ForgotPassword extends Component {
  state = {
    submitSuccess: false,
    errorMessage: false,
  };
  handleFormSubmit = (values) => {
    const { recoveryPasswordUrl } = this.props;
    const bodyFormData = new FormData();
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('reset_password_token');

    bodyFormData.append('password', values.password);
    bodyFormData.append('reset_password_token', token);

    apiProvider.post(recoveryPasswordUrl, bodyFormData, true)
      .then(response => {
        response.success && this.setState({
          submitSuccess: true,
          emailServerError: false,
        });
        response.error &&
        this.setState({
          errorMessage: true,
          submitSuccessMessage: false,
        });
      });
  };
  handleError = () => this.setState({ emailServerError: false, submitSuccess: false });

  componentDidMount() {
    loadTawkTo(this.props.siteKey);
  };

  render() {
    const {loginUrl} = this.props;
    const { submitSuccess, errorMessage } = this.state;

    return (
      <div className="recovery-password">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9 col-lg-9 col-xl-8">
              <h1>{I18n.t('views.sessions.new.login.recovery_password_title')}</h1>
              {submitSuccess &&
                <p>{I18n.t('views.sessions.new.login.recovery_password_success')} <a href={loginUrl}> {I18n.t('views.sessions.new.login.recovery_password_link_login')}</a>
                </p>
              }
              {errorMessage &&
              <p>{I18n.t('views.sessions.new.login.recovery_password_expire')} <a href='/providers/session/forgot_password'> {I18n.t('views.sessions.new.login.recovery_password_link_forgot_page')}</a>
              </p>}
            </div>
          </div>
          <RecoveryPasswordForm
            handleSubmit={this.handleFormSubmit}
            handleError={this.handleError}
          />
        </div>
      </div>
    )
  }
}

export default ForgotPassword;
