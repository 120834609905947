import React from 'react';
import Modal from 'react-modal';

import './index.sass';


const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    top: '0',
    overflow: 'auto',
    zIndex: '9999'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    pointerEvents: 'visible',
    background: 'white',
    borderRadius: '4px',
    position: 'absolute',
    width: '400px',
  },
};


const ModalWindow = ({ children, name, isOpen, onRequestClose }) => {

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={name}
      ariaHideApp={false}
      style={customStyles}
      closeTimeoutMS={100}
      className={name}>
      { children }
    </Modal>
  )
}

export default ModalWindow;

