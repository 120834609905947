import React, { Component, Fragment } from 'react';

import { SvgIco } from '../../SharedComponents/Icons';
import { hasSomeParentTheClass } from '../../../utils/helpers';
import { translateDescription } from '../../../utils/api';
import googleTranslate from '../../../src/images/icons/google-translate.svg';

import './Description.sass';


export class DescriptionTopMenu extends Component {
  constructor(props) {
    super(props);

    this.availableLocales = [
      {locale: 'da', country: 'dk'}, {locale: 'nl', country: 'nl'}, {locale: 'en', country: 'gb'}, {locale: 'fi', country: 'fi'},
      {locale: 'fr', country: 'fr'}, {locale: 'de', country: 'de'}, {locale: 'it', country: 'it'}, {locale: 'nb', country: 'no'},
      {locale: 'pl', country: 'pl'}, {locale: 'es', country: 'es'}, {locale: 'sv', country: 'se'}, {locale: 'tr', country: 'tr'},
      {locale: 'uk', country: 'ua'}
    ];

    this.switcherClassName = 'localized-desc__switcher';

    const originalLocale = this.getOriginalLocale();

    this.state = {
      showLocaleSwitcher: (this.props.siteKey === 'global'),
      showLocaleSwitcherItems: false,
      localesExceptActive: this.availableLocales.filter(item => item.locale !== originalLocale),
      activeLocale: originalLocale,
      originalLocale: originalLocale,
      showOriginalDescription: false,
      isShowingScrapedInfo: false,
    };
  }

  componentDidMount() {
    this.clickOutside();
    this.setActiveTranslatedDescription(this.state.activeLocale);
  };

  getOriginalLocale = () => {
    this.locales = this.props.translatedDescriptions.map(
      translated => translated.locale
    );
    return this.locales[0];
  };

  simplifyLocale = locale => locale.substring(0, 2);

  clickOutside = () => {
    document.addEventListener('click', e => {
      if (!hasSomeParentTheClass(e.target, this.switcherClassName)) {
        this.setState({
          showLocaleSwitcherItems: false,
        });
      }
    });
  };

  toggleLocaleSwitcherItems = () => {
    this.setState({
      showLocaleSwitcherItems: !this.state.showLocaleSwitcherItems,
    });
  };

  localesClickHandler = locale => {
    this.setState({ activeLocale: locale });
    this.setState({ localesExceptActive: this.availableLocales.filter(item => item.locale !== locale) });
    this.setState({ showLocaleSwitcherItems: false });
    this.setActiveTranslatedDescription(locale);
  };

  getTranslatedDescription = (locale) => {
    return this.props.translatedDescriptions.filter(
      translated => translated.locale === locale
    )[0];
  };

  setActiveTranslatedDescription = (locale) => {
    let desc = this.getTranslatedDescription(locale);

    if (desc !== undefined) {
      this.props.setTranslatedDescription(desc);
    }
    else {
      this.translateDescriptionWithGoogle(locale);
    }
  };

  translateDescriptionWithGoogle = (locale) => {
    let originalDesc = this.getTranslatedDescription(this.state.originalLocale);

    this.props.setTranslatedDescription({ description: 'Translating...', title: 'Translating...' });

    let localeFrom = this.simplifyLocale(this.state.originalLocale);

    translateDescription(originalDesc.description, originalDesc.title, localeFrom, locale)
      .then(res => {
        this.props.setTranslatedDescription(res.data);
        this.props.translatedDescriptions.push(res.data)
      })
      .catch(() =>
        this.props.setTranslatedDescription({ description: 'Translation error. Please, try again later', title: 'Translation error' })
      );
  };

  render() {
    const { activeLocale, showLocaleSwitcherItems } = this.state;
    const { activeTranslatedDescription } = this.props;


    return (
      <div>
        { this.state.showLocaleSwitcher &&
          <div className='localized-desc__top-menu'>
            <div className={ `localized-desc__top-menu-left` }>
              <div className={this.switcherClassName}>
                <a className="localized-desc__active-locale" onClick={this.toggleLocaleSwitcherItems}>
              <span className="localized-desc__active-locale-text">
                { I18n.t('generic.lang.' + activeLocale) }
              </span>
                  { showLocaleSwitcherItems
                    ? <SvgIco name="expand-less" size={20} />
                    : <SvgIco name="expand-more" size={20} />
                  }
                </a>

                { showLocaleSwitcherItems &&
                  <div className="localized-desc__locales_wrapper">
                    { this.availableLocales.map((locale, index) =>
                      <div className="localized-desc__locales-link-wrap" key={index}>
                        <div className="select-option-group">
                          <input
                            id={locale.locale}
                            type="radio"
                            value={locale.locale}
                            checked={this.state.activeLocale === locale.locale}
                            onChange={() => this.localesClickHandler(locale.locale)}
                            className="checkoption-input"
                          />
                          <label htmlFor={locale.locale} className="select-option"><span className="option-checkmark" />
                            { I18n.t('generic.lang.' + locale.locale) }
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                }
              </div>

              { activeTranslatedDescription.sourceLanguage &&
                <Fragment>
                  <div className="localized-desc__google-translated-text">
                    <div>Translated by Google Translate</div>
                  </div>
                  <div className="localized-desc__translated-by-google-icon">
                    <img data-src={googleTranslate}
                         alt="google-translate-icon"
                         className="lazyload"
                         data-sizes="auto"
                         height={16}
                         width={16}
                    />
                  </div>
                </Fragment>
              }
            </div>
          </div>
        }
      </div>
    );
  }
}


export default DescriptionTopMenu;
