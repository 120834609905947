import React, { Component } from 'react';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: '9999',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    pointerEvents: 'visible',
  },
};


class InfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingModal: this.props.isShowingModal,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(prevState => ({ isShowingModal: nextProps.isShowingModal }));
  }

  render() {

    const privacyPolicyPath = 'https://' + window.location.host + '/' + I18n.t('routes.privacy_url');

    return (
      <div>
        <Modal
          isOpen={this.state.isShowingModal}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.props.handleClose}
          contentLabel="Error Modal"
          ariaHideApp={false}
          style={customStyles}
          closeTimeoutMS={500}
        >
          <div className="modal-header">
            <h2 ref={subtitle => (this.subtitle = subtitle)}>
              {I18n.t('apps.lb_showroom.detailed.order_form.data_protection')}
            </h2>
          </div>
          <div className="modal-body">
            <div className="modal-body__content" dangerouslySetInnerHTML={{ __html: I18n.t('apps.lb_showroom.detailed.order_form.modal.data_protection_body') }}/>
            <div className="modal-body__policy-link">
              <p>{I18n.t('apps.lb_showroom.detailed.order_form.modal.policy_link_preline')}
                <a href={privacyPolicyPath}>{I18n.t('apps.lb_showroom.detailed.order_form.modal.privacy_policy')}</a>.
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <button className="close-modal-button" onClick={this.props.handleClose}>
              {I18n.t('generic.close')}
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default InfoModal;
