import React from 'react';

import './AtomLoader.sass';


const AtomLoader = () => {
  return (
    <div className="atom-loader">
      <div className="atom-loader__wrapper">
        <div className="atom-loader__inner one"></div>
        <div className="atom-loader__inner two"></div>
        <div className="atom-loader__inner three"></div>
      </div>
    </div>
  )
};

export default AtomLoader;
