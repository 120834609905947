import React from 'react';
import Markdown from 'markdown-to-jsx';


const FooterLinks = ({ footerBoxes }) => {
  return (
    <div className="global-footer__links">
      <div className="container">
        <div className="row">
          { footerBoxes.map(box =>
            <div className={`global-footer__links-row col-md-${ 12 / footerBoxes.length }` } key={box.footer_box.id}>
              <Markdown>{ box.footer_box.content }</Markdown>
            </div>
          ) }
        </div>
      </div>
    </div>
  )
};

export default FooterLinks;
