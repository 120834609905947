import React, { Component } from 'react';
import { SvgIco } from './Icons';

import './InfoNotice.sass';

class InfoNotice extends Component {

  state = {
    isShowingInfo: false,
    content: {
      anonymous: {
        title: I18n.t('generic.anonymous_location.detailed.notice'),
        modalTitle: I18n.t('generic.anonymous_location.detailed.notice'),
        modalContent: I18n.t('generic.anonymous_location.detailed.notice_title'),
      },
      parsed: {
        title: I18n.t('generic.found_automatically'),
        modalTitle: I18n.t('generic.title_info_text'),
        modalContent: I18n.t('generic.parsed_info_text'),
      }
    },
  };

  toggleInfoModal = () => {
    const { isShowingInfo } = this.state;
    !isShowingInfo
      ? this.setState({ isShowingInfo: true }, () => {
        window.addEventListener('mousedown', this.handleClickOutsideInfoModal);
        const windowHeight = window.innerHeight;
        const modalBottomPos = this.infoModal.getBoundingClientRect().bottom;
        windowHeight < modalBottomPos && window.scroll(0, window.pageYOffset + (modalBottomPos - windowHeight) + 20);
      })
      : this.setState({ isShowingInfo: false }, () => window.removeEventListener('mousedown', this.handleClickOutsideInfoModal));
  };

  handleClickOutsideInfoModal = (e) => {
    const target = e.target;
    const noticeWrapperContainsTarget = this.noticeWrapper.contains(target);
    const infoLinkWrapperContainsTarget = this.infoLinkWrapper.contains(target);
    const closeInfoBtnContainsTarget = this.closeButton.contains(target);

    if (!infoLinkWrapperContainsTarget && (!noticeWrapperContainsTarget || closeInfoBtnContainsTarget)) {
      this.toggleInfoModal();
    }
  };

  render() {
    const { noticeKind, root, id, missingImagesUrl, showCopyright } = this.props;
    const { isShowingInfo } = this.state;
    const { title, modalTitle, modalContent } = this.state.content[noticeKind];
    const wrapperId = id ? `noticeWrapper-${id}`: null;

    return (
      <div className={`info-notice ${root}-view`} ref={ wrapper => this.noticeWrapper = wrapper } id={wrapperId}>
        <div className="info-notice__wrapper">
          <div ref={link => this.infoLinkWrapper = link} onClick={this.toggleInfoModal} className="info-notice__title">
            <span> { title } </span>
            <div className={`info-notice__info-icon ${ isShowingInfo && 'active' }`} id="info-notice-icon">
              <SvgIco name="info_outline" size={18} />
            </div>
          </div>
          { isShowingInfo && (
            <div className="info-notice__info-modal" ref={modal => this.infoModal = modal}>
              <div role="button" ref={ btn => this.closeButton = btn }  className="info-notice__close-modal-btn">
                <SvgIco name="close" size={24} />
              </div>
              <p className='info-notice__modal-title'>{ modalTitle }</p>

              { showCopyright &&
                <p className='info-notice__modal-content copyright' role="button">
                  { I18n.t('generic.parsed_copyright') }
                </p>
              }

              <p className='info-notice__modal-content' role="button">
                { modalContent }&nbsp;
                { noticeKind === 'parsed' &&
                  <a href={missingImagesUrl}
                     target="_blank"
                     className="read-more"
                     id={`infoNoticeLink-${id}`}>
                    { I18n.t('generic.read_more') }
                  </a>
                }
              </p>
            </div>
          ) }
        </div>
      </div>
    );
  }
}

export default InfoNotice;
