import React, { useContext, useEffect } from 'react';

import DropdownSelector from "../DropdownSelector";
import { Context } from "../index";

import './index.sass';


const EnergyRatingFilter = ({ isMobileMenu }) => {

  const {
    energyRatings,
    handleEnergyRatingSelect,
    requestParams,
    selectedEnergyRatings,
    setRequestParams,
    setSelectedEnergyRatings,
  } = useContext(Context);

  const { energy_rating } = requestParams;

  const selectedData = selectedEnergyRatings.map(option => ({ id: option, name: option }));
  const energyRatingsOptions = energyRatings && energyRatings.map(option =>
    ({ id: option, name: option === 'Exempted' ? I18n.t('views.locations.show.exempted_from_energy_rating_short') : option })
  );

  useEffect(() => {
    energy_rating.length && setSelectedEnergyRatings(energy_rating);
  }, []);


  useEffect(() => {
    setSelectedEnergyRatings(requestParams.energy_rating);
  }, [requestParams.energy_rating]);


  const handleSelect = (e, option) => {
    const updatedRatings = [ ...selectedEnergyRatings ];
    const optionSelectedIndex = updatedRatings.indexOf(option.id);

    optionSelectedIndex >= 0 ? updatedRatings.splice(optionSelectedIndex, 1) : updatedRatings.push(option.id);
    !isMobileMenu && handleEnergyRatingSelect(updatedRatings);
    isMobileMenu && setRequestParams(prevParams => ({ ...prevParams, energy_rating: updatedRatings }));
    setSelectedEnergyRatings(updatedRatings);
  };


  return (
    <div className="energy-rating-filter">
      { !!energyRatingsOptions.length &&
        <div className="lv-filter lv-filter__energy-rating energy-rating-filter__wrapper">
          <DropdownSelector
            dynamicData={energyRatingsOptions}
            isMobileMenu={isMobileMenu}
            placeholder={I18n.t('activerecord.attributes.property.energy_rating')}
            selectAction={handleSelect}
            selectedData={selectedData}
            selectedCounterPhrase={I18n.t('activerecord.attributes.property.energy_rating')}
            showOnlyCountPlaceholder={true}
          />
        </div>
      }
    </div>
  );
}


export default EnergyRatingFilter;
