import React, { useContext } from 'react';

import { SvgIco } from "../../SharedComponents/Icons";
import { Context } from "../index";

import './index.sass';


const ClosedLocationBanner = () => {

  const { nearbyLocationsLink, similarLocationsLink } = useContext(Context);


  return (
    <div className="closed-locations-banner">
      <SvgIco name="locators" width={306} height={143} />

      { similarLocationsLink &&
        <a className="closed-locations-banner__button similar-locations" href={similarLocationsLink} target="_blank">
          { I18n.t('search_agents.invalid_order.similar_locations') }
        </a>
      }

      { nearbyLocationsLink &&
        <a className="closed-locations-banner__button nearby-locations" href={nearbyLocationsLink} target="_blank">
          { I18n.t('apps.lb_showroom.nearby_locations') }
        </a>
      }
    </div>
  )
};

export default ClosedLocationBanner;
