import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

import mapPin from '../../src/images/details-page-pin.svg';
import { SvgIco } from '../SharedComponents/Icons';
import mapStyles from "../../src/stylesheets/map_styles.json";

import './DetailsMap.sass';


function DetailMap({ containerClass,
                     googleApiKey,
                     orientationHorizontalMobile,
                     coordinates,
                     isFullscreenMobile,
                     isGalleryMobileComponent,
                     handleFullscreenMobile,
                     isMobile,
                     markers}) {

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: googleApiKey,
  });

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const renderMap = () => {

    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={coordinates}
        zoom={15}
        defaultCenter={coordinates}
        onLoad={map => {}}
        mapContainerClassName={containerClass}
        options={{
          gestureHandling: 'cooperative',
          fullscreenControl: !isFullscreenMobile && !isGalleryMobileComponent,
          mapTypeControl: !isFullscreenMobile || !isGalleryMobileComponent,
          streetViewControl: !isFullscreenMobile && !isMobile,
          styles: mapStyles,
        }}
      >
        <div className={`dv-gallery-map-fullscreen-header${isFullscreenMobile && !orientationHorizontalMobile ? ' active' : ''}`}>
            <span>
              { I18n.t('lb_showroom.detailed.asset_box.new_map_button') }
            </span>
          { isGalleryMobileComponent &&
            <div className="map-mobile-fullscreen-btn" onClick={handleFullscreenMobile}>
              { isFullscreenMobile
                ? <SvgIco name="close" size={24} />
                : <SvgIco name="crop_free" size={24} />
              }
            </div>
          }
        </div>
        <Marker icon={{ url: mapPin, scaledSize: { width: 56, height: 56} }} position={coordinates} />
        { markers }
      </GoogleMap>
    )
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : <span>Loading..</span>
}

export default DetailMap;


