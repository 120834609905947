import React from 'react';

import BenefitsMobile from './BenefitsMobile';

import './index.sass';


const BenefitsSection = ({ siteKey, virtualTourUrl }) => {

  const benefits = (index) => ({
    iconName: index+1,
    headline: I18n.t(`signup.teaser.ceo.block_${index+1}.header`),
    body: I18n.t(`signup.teaser.ceo.block_${index+1}.text`),
  });


  return (
    <div className="benefits container">

      <h2 className="benefits__headline">
        { I18n.t('signup.teaser.ceo.heading') }
      </h2>

      <BenefitsMobile
        benefits={benefits}
        siteKey={siteKey}
        virtualTourUrl={virtualTourUrl}
      />

      <div className="row benefits__wrapper">
        { Array.from(Array(12)).map((elm, index) =>
          <div className="benefits__item col-md-6 col-lg-4" key={index}>
            <h3 className="benefits__item-headline">
              { siteKey !== 'dk' && index === 6
                ?
                  <img
                    alt={benefits(index).iconName}
                    className="lazyload"
                    data-sizes="auto"
                    data-src={require(`../../../src/images/list_office/Instagram-icon.svg`)}
                    height={20}
                  />
                :
                  <img
                    alt={benefits(index).iconName}
                    className="lazyload"
                    data-sizes="auto"
                    data-src={require(`../../../src/images/list_office/${benefits(index).iconName}.svg`)}
                    height={20}
                  />
              }
              { benefits(index).headline }
            </h3>

            <p className="benefits__item-body">{ benefits(index).body }
              { siteKey === 'dk' && index === 6 &&
                <span dangerouslySetInnerHTML={{ __html: virtualTourUrl }}/>
              }
            </p>
          </div>
        )}
      </div>
    </div>
  )
};

export default React.memo(BenefitsSection);
