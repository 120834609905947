import React from 'react';
import classNames from 'classnames';

import { handleListItemClick, DescriptionLine } from '../SharedComponents/ItemsListComponents';

import './ListViewAdvertisment.sass';


const ListViewAdvertisment = ({ advertismentData: {
                                  search_agent,
                                  image_text,
                                  image_text_bottom,
                                  title,
                                  description,
                                  text_on_button,
                                  text_above_button,
                                },
                                rootPath,
                                signupTeaserPath,
                                siteLogoPath,
                              }) => {

  const advertismentClass = classNames(
    'col-xl-3 col-lg-3 col-md-4 col-3 search-agent-column-no-padding-left',
  );

  const mainWrap = classNames('advertisement-wrap', {
    'search-agent-wrap': search_agent,
  });


  return (
    <div className="container list-item max-width">
      <div
        className={mainWrap}
        onClick={(e) => handleListItemClick(e, signupTeaserPath, true)}
      >
        <div className="list-item__wrapper row">
          <div className={advertismentClass}>
            <div className="advertisement">
              <p className="adver-title" data-bind="text: image_text">
                {image_text}
              </p>
              <span
                className="adver-title bottom-adver"
                data-bind="text: image_text_bottom"
              >
                {image_text_bottom}
              </span>
            </div>
          </div>

          <div className="list-item__description-wrapper col-xl-6 col-lg-6 col-md-4 col-sm-9 col-12">
            <div className="adver-description-container">
              <div className="list-item__description-title">
                <p>{title}</p>
              </div>

              <div className="description-line">
                <div className="adver-description-tablet">
                  <DescriptionLine substring={description} lines={3} />
                </div>
                <div className="adver-description-desktop">
                  <DescriptionLine substring={description} lines={2} />
                </div>
              </div>

              <div className="list-item__bottom">
                <div className="advert-images">
                  <a href={rootPath}>
                    <img
                      alt="provider logo"
                      className="lazyload"
                      data-expand="100"
                      data-sizes="auto"
                      data-src={`https://www.lokalebasen.dk${siteLogoPath}`}
                      height={44}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="list-item__metrics-wrapper col-xl-3 col-lg-3 col-md-4">
            <div className="adver-metrics-container">
              <div className="wrap-advert-text">{text_above_button}</div>
              <div className="b-button list-item__get-info-button">
                { text_on_button }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListViewAdvertisment;
