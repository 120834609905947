import React, { useContext } from 'react';

import { toUnderscore } from '../../../utils/helpers';
import { DetailedViewSet } from "../../SharedComponents/Icons";
import { Context } from "../index";

import './GallerySwitcher.sass';


const Label = ({ name, count, isActive, ...props }) => {
  return (
    <div className={`switcher-pill ${ isActive ? 'active' : '' }`} {...props}>
      { DetailedViewSet[name] }
      <span>
      { I18n.t(`lb_showroom.detailed.asset_box.new_${ name }_button`) }
    </span>
    </div>
  );
};


const GallerySwitcher = ({ counters }) => {

  const {
    activeGallerySection,
    switchGallerySection,
  } = useContext(Context);

  const renderPills = () => {
    // sorted tabs under the photo exactly in this way
    const sorted = {};
    sorted['photos'] = counters.photos;
    sorted['floorPlans'] = counters.floorPlans;
    sorted['map'] = counters.map;
    sorted['videos'] = counters.videos;
    sorted['virtualTour'] = counters.virtualTour;
    Object.keys(sorted).forEach(key => sorted[key] === undefined ? delete sorted[key] : '');

    const pills = Object.keys(sorted).map(item => {
      let properties = {
        isActive: activeGallerySection === item,
        name: toUnderscore(item),
        key: item,
        onClick: () => switchGallerySection(item),
      };

      item !== 'virtualTour' && (properties.count = sorted[item]);
      item === 'floorPlans' && (properties.name = 'floor-plans');

      return <Label {...properties} key={item} />;
    });

    return <div className="switcher-row">{ pills }</div>;
  };

  return (
    <div className="switcher-container">{ renderPills() }</div>
  )
};


export default GallerySwitcher;
