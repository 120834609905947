import React, { Component } from 'react';
import classNames from 'classnames';

import { SvgIco } from './Icons';

import'./DropDownContainer.sass';


class DropDownContainer extends Component {
  constructor(props){
    super(props);

    this.state = {
      isOpen: false
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) =>
    this.wrapperRef && !this.wrapperRef.contains(event.target) && this.setState({ isOpen: false });

  setWrapperRef = (node) => this.wrapperRef = node;

  handleClick = () => this.setState(prevState => ({isOpen: !prevState.isOpen}));


  render() {
    const { name, text, children, selectedTooltip, isMobileMenu } = this.props;
    const isOpen = !isMobileMenu || this.state.isOpen;
    const placeholderClasses = classNames('dropdown-selector__placeholder', { 'active': isOpen });
    const actionIconClasses = classNames('dropdown-selector__action-icon', { 'open': isOpen });
    const bodyClasses = classNames('dropdown-selector__body', { 'dropdown-selector__body--open': isOpen });
    const triggerClasses = classNames('dropdown-selector__trigger', { 'dropdown-selector__trigger--active': isOpen });


    return (
      <div className="filter-toggle" ref={this.setWrapperRef}>
        <div className={triggerClasses} onClick={this.handleClick}>
          <div className="filter-toggle__icon-wrapper">
            <SvgIco name={name} size={22}/>
          </div>
          <div className={placeholderClasses}>
            { text }
          </div>
          <div className="dropdown-selector__trigger-right">
            { selectedTooltip.length > 0 &&
              <div className="dropdown-selector__selected-tooltip">
                { selectedTooltip }
              </div>
            }
            <div className={actionIconClasses}>
              { !isOpen ? <SvgIco name="expand-more" size={20} /> : <SvgIco name="expand-less" size={20} /> }
            </div>
          </div>
        </div>

        { (isOpen || isMobileMenu) &&
          <div className={bodyClasses}>
            { children }
          </div>
        }
      </div>
    );
  }
}

export default DropDownContainer;
