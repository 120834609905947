import React, { useContext, useEffect, useRef, useState, Fragment, Suspense } from 'react';
import { debounce } from 'throttle-debounce';
import classNames from 'classnames';

const CombinedLeases = React.lazy(() => import('../CombinedLeases'));
const SameAddressLocations = React.lazy(() => import('../SameAddressLocations'));
const BaseSlider = React.lazy(() => import('../../SharedComponents/BaseSlider'));
const LocationTemplate = React.lazy(() => import('../../SharedComponents/LocationTemplate'));
const SimilarLocations = React.lazy(() => import('../../SimilarLocations'));

import EconomySection from '../EconomySection';
import { apiProvider } from '../../../api/core';
import SearchAgentBanner from '../../SharedComponents/SearchAgentBanner';
import FAQSection from '../../SharedComponents/FAQSection';
import MapSection from '../MapSection';
import FixedNavigationBar from '../FixedNavigationBar';
import FactsAndFacilities from '../FactsAndFacilities';
import DescriptionSection from '../DescriptionSection';
import { Context } from "../index";

import './index.sass';


const InfoSections = () => {

  const {
    anonymous,
    comparableLocations,
    combinedLocations,
    tabs: {
      economy,
      isRentIncludeOperationalCost,
    },
    favouriteLocationsIds,
    isRequestFromPrerender,
    kind,
    locale,
    location,
    locationClosed,
    locationId,
    newSearchAgentPath,
    rentedOut,
    richSnippetQuestions,
    richSnippetQuestionsTitle,
    scrollToForm,
    section,
    sectionType,
    setComparableLocations,
    setFavouriteLocationsIds,
    setShowSimilarLocations,
    showSimilarLocations,
    siteKey,
    similarLocationsEndpoint,
    similarLocationsHeadline,
    temporarilyUnavailable,
    viewedLocations,
    visitorToken,
    updateViewedLocations,
  } = useContext(Context);

  const [activeSection, setActiveSection] = useState(null);
  const [locationsWithSameAddress, setLocationsWithSameAddress] = useState([]);
  const [showNavigationMenuState, setShowNavigationMenuState] = useState(false);

  const scrollTargetsRefs = {
    descriptionRef: useRef(),
    factsRef: useRef(),
    economyRef: useRef(),
    mapRef: useRef(),
    similarLocationsRef: useRef(),
    faqRef: useRef(),
  };

  const { descriptionRef, economyRef, factsRef, faqRef, mapRef, similarLocationsRef } = scrollTargetsRefs;


  useEffect(() => {
    const { addressLine1, postalDistrictId } = location;
    const retrievedFavouriteLocations = localStorage && localStorage.getItem(`my.favourite_${sectionType}_${siteKey}`);
    const retrievedComparableLocations = localStorage && localStorage.getItem(`my.comparable_${sectionType}_${siteKey}`);
    const favouriteLocationsIds = JSON.parse(retrievedFavouriteLocations) || [];
    const comparableLocations = JSON.parse(retrievedComparableLocations) || [];

    favouriteLocationsIds && setFavouriteLocationsIds(favouriteLocationsIds);
    comparableLocations && setComparableLocations(comparableLocations);

    window.innerWidth >= 992 && updateViewedLocations();

    showNavigationMenu();
    window.addEventListener('scroll', showNavigationMenu);

    apiProvider
      .getAll(
        `/api/locations/${locationId}/get_by_address?postal_district_id=${postalDistrictId}&address_line_1=${addressLine1}&locale=${locale}`
      )
      .then(
        (response) =>
          response &&
          response.length &&
          setLocationsWithSameAddress(response)
      );

    return () => {
      window.removeEventListener('scroll', showNavigationMenu);
    };
  }, []);


  const showNavigationMenu = () => {
    const topOffset = document.getElementById('top-menu').offsetHeight + 48;
    const showNavigation = descriptionRef && descriptionRef.current && descriptionRef.current.getBoundingClientRect().top - 100 <= topOffset;

    if (showNavigation) {
      debounce(10, handleActiveAreaSection);
      handleActiveAreaSection();
    }

    setShowNavigationMenuState(showNavigation);
  };


  const handleActiveAreaSection = () => {
    const topOffset = document.getElementById('top-menu').offsetHeight + 48;
    const sectionsList = document.querySelectorAll('.info-sections__block-wrapper');
    const viewPortBottomBorder = (window.innerHeight - topOffset) / 2;

    if (descriptionRef && descriptionRef.current && descriptionRef.current.getBoundingClientRect().top <= topOffset) {
      Object.keys(sectionsList).forEach((elem) => {
        const node = sectionsList[elem];
        const nodeName = node && node.firstChild && node.firstChild.dataset.name;

        if (nodeName && activeSection !== nodeName) {
          const nodeTop = node.getBoundingClientRect().top;
          const nodeBottom = node.getBoundingClientRect().bottom;

          // If node is partially in area viewPort
          if (nodeTop < viewPortBottomBorder && nodeBottom > topOffset) {
            // If node is 100% in area viewPort
            if ((nodeTop < topOffset && nodeBottom > viewPortBottomBorder) || (nodeTop > topOffset && nodeBottom < viewPortBottomBorder)) {
              setActiveSection(nodeName);
            } else {
              const viewPortNodeTop = nodeTop < topOffset ? topOffset : nodeTop;
              const viewPortNodeBottom =
                nodeBottom > viewPortBottomBorder
                  ? viewPortBottomBorder
                  : nodeBottom;

              // If node takes at least 50% of area viewPort
              if (
                viewPortNodeBottom - viewPortNodeTop >=
                (viewPortBottomBorder - topOffset) / 2
              ) {
                setActiveSection(nodeName);
              }
            }
          }
        }
      });
    } else if (activeSection !== 'description') {
      setActiveSection('description');
    }
  };


  const scrollToTarget = (target) => {
    const targetCoords = Math.floor(scrollTargetsRefs[`${target}Ref`].current.getBoundingClientRect().top + window.pageYOffset - (document.getElementById('top-menu').offsetHeight + 48));

    window.removeEventListener('scroll', showNavigationMenu);
    window.scroll(0, targetCoords);
    window.addEventListener('scroll', debounce(20, () => endOfScroll(targetCoords)));
  };


  const endOfScroll = (targetCoords) => {
    if (window.pageYOffset === targetCoords) {
      window.addEventListener('scroll', showNavigationMenu);
      window.removeEventListener('scroll', debounce(20, () => endOfScroll(targetCoords)));
    }
  };


  const disposalPrice = economy.disposalPrice;
  const richSnippetsAvailable = !!richSnippetQuestions && richSnippetQuestions.length > 0;
  const filteredViewedLocations = viewedLocations && viewedLocations.filter((viewed) => viewed.id !== location.id);
  const showViewedLocations = filteredViewedLocations && filteredViewedLocations.length > 0;
  const showMap = !anonymous && !!location.latitude && !!location.longitude;
  const isDisposalRender = parseInt(disposalPrice) > 0 && (siteKey === 'dk' || siteKey === 'global');
  const pricePerM2Present = economy.pricesPerM2 && Object.keys(economy.pricesPerM2).length > 0;
  const pricesPerYearPresent = economy.pricesPerYear && Object.keys(economy.pricesPerYear).length > 0;
  const checkEconomySectionPresent = (pricePerM2Present || pricesPerYearPresent || isDisposalRender || kind === 'meeting_room') && !locationClosed;
  const viewedLocationsWrapperClasses = classNames('viewed-locations-wrapper', { 'top-indentation': !showSimilarLocations });
  const showOrderFormButton = !!(!rentedOut && !temporarilyUnavailable && !locationClosed);


  return (
    <Fragment>
      <div className="container">
        <div className="info-sections-row row">
          { combinedLocations && combinedLocations.length > 0 &&
            <Suspense fallback={''}>
              <div className="col-12">
                <div className="info-sections__block-wrapper">
                  <CombinedLeases
                    combinedLocations={combinedLocations}
                    isRentIncludeOperationalCost={isRentIncludeOperationalCost}
                  />
                </div>
              </div>
            </Suspense>
          }

          <div className="col-lg-8 col-md-12 col-12">
            <div className="info-sections">
              { showNavigationMenuState &&
                <FixedNavigationBar
                  activeSection={activeSection}
                  checkEconomySectionPresent={checkEconomySectionPresent}
                  scrollToForm={scrollToForm}
                  scrollToTarget={scrollToTarget}
                  showMap={showMap}
                  similarLocationsHeadline={similarLocationsHeadline}
                  showNavigationMenu={showNavigationMenuState}
                  showOrderFormButton={showOrderFormButton}
                  showRichSnippets={richSnippetsAvailable}
                  showSimilarLocations={showSimilarLocations}
                />
              }

              <div className="info-sections__block-wrapper">
                <div
                  className="sectionScrollTarget description"
                  data-name="description"
                  ref={descriptionRef}
                />
                <DescriptionSection />
              </div>
            </div>
          </div>

          <div className="full-width-background-wrapper">
            <div className="full-width-background" />
            <div className="col-lg-8 col-md-12 col-12">
              <div className="info-sections__block-wrapper">
                <div
                  className="sectionScrollTarget"
                  data-name="facts"
                  ref={factsRef}
                />
                <FactsAndFacilities />
              </div>
            </div>
          </div>

          { checkEconomySectionPresent &&
            <div className="col-lg-8 col-md-12 col-12">
              <div className="info-sections__block-wrapper">
                <div
                  className="sectionScrollTarget"
                  data-name="economy"
                  id="economy-section"
                  ref={economyRef}
                />
                <EconomySection />
              </div>
            </div>
          }

          { showMap &&
            <div className="col-lg-8 col-md-12 col-md-8 col-12">
              <div className="info-sections__block-wrapper">
                <div className="info-sections__block-wrapper">
                  <div
                    className="sectionScrollTarget"
                    data-name="map"
                    ref={mapRef}
                  />
                  { !anonymous && <MapSection /> }
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      { !!locationsWithSameAddress.length &&
        <Suspense fallback={''}>
          <SameAddressLocations
            locationsWithSameAddress={locationsWithSameAddress}
          />
        </Suspense>
      }

      { !anonymous &&
        <Suspense fallback={''}>
          <div className="info-sections__block-wrapper with-favourite">
            <div
              className="sectionScrollTarget"
              data-name="similarLocations"
              id="similar-locations-section"
              ref={similarLocationsRef}
            />
              <SimilarLocations
                comparableLocations={comparableLocations}
                favouriteLocationsIds={favouriteLocationsIds}
                handleInit={() => setShowSimilarLocations(true)}
                similarLocationsEndpoint={similarLocationsEndpoint}
                similarLocationsHeadline={similarLocationsHeadline}
                section={section}
                siteKey={siteKey}
                updateComparableLocations={setComparableLocations}
                updateFavouriteLocations={(ids) => setFavouriteLocationsIds(ids)}
              />
          </div>
        </Suspense>
      }

      { showViewedLocations &&
        <Suspense fallback={''}>
          <div className={viewedLocationsWrapperClasses}>
            <div className="viewed-locations container">
              <h2 className="viewed-locations__headline container">
                { siteKey === 'dk' && section !== 'lease'
                  ? I18n.t('apps.lb_showroom.viewed_sales')
                  : I18n.t('apps.lb_showroom.viewed_leases')}
              </h2>
              <BaseSlider infinite withDots>
                { filteredViewedLocations.map(item =>
                  item.id !== location.id &&
                  <LocationTemplate
                    comparableLocations={comparableLocations}
                    component="viewed"
                    data={item}
                    key={item.uuid}
                    section={section}
                    siteKey={siteKey}
                    updateComparableLocations={setComparableLocations}
                    updateFavouriteLocations={(ids) => setFavouriteLocationsIds(ids)}
                  />,
                )}
              </BaseSlider>
            </div>
          </div>
        </Suspense>
      }

      <SearchAgentBanner
        newSearchAgentPath={newSearchAgentPath}
        page="detail-view"
        richSnippetsAvailable={richSnippetsAvailable}
        searchAgentHeadline={I18n.t('lb_showroom.detailed.search_agent.headline')}
        searchAgentText={I18n.t('lb_showroom.detailed.search_agent.description')}
        siteKey={siteKey}
        visitorToken={visitorToken}
      />

      { richSnippetsAvailable &&
        <div className="info-sections__block-wrapper">
          <div
            className="sectionScrollTarget"
            data-name="faq"
            ref={faqRef}
          />
          <FAQSection
            isRequestFromPrerender={isRequestFromPrerender}
            richSnippetQuestions={richSnippetQuestions}
            title={richSnippetQuestionsTitle['title']}
          />
        </div>
      }
    </Fragment>
  );
};


export default InfoSections;
