import React, { Component } from 'react';
import Flag from 'react-flags';

import './LanguageSwitcher.sass'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: '999',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    pointerEvents: 'visible',
    height: '80%',
  },
};


class LanguageSwitcher extends Component {

  state = {
    isOpened: false
  };

  switchState = (e) => {
    if(this.node) {
      if(this.node.contains(e.target)) {
        this.setState({
          isOpened: !this.state.isOpened
        });

        return;
      }
    }

    this.setState({ isOpened: false });
  };

  componentDidMount() {
    document.addEventListener('click', this.switchState, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.switchState, false)
  }

  render() {
    const { availableLocalesLinks, currentLocaleCountry } = this.props;

    if (availableLocalesLinks && availableLocalesLinks.length > 0) {
      return (
        <div className='language-switcher'>
          <div ref={node => this.node = node} className='language-switcher__language-selected'>
            <Flag
              basePath="/images/flags"
              name={currentLocaleCountry}
              format="png"
              pngSize={24}
              alt={currentLocaleCountry} />
            <span className='language-switcher__choose-language'>{ I18n.t('generic.choose_your_language') }</span>
          </div>
          { this.state.isOpened && (
            <div className='language-switcher__option-list'>
              { availableLocalesLinks.map(link => (
                <a className='language-switcher__option' key={ link.countryCode } href={ link.href.split('?')[0] + window.location.search }>
                  <Flag
                    basePath="/images/flags"
                    name={link.countryCode}
                    format="png"
                    pngSize={24}
                    alt={link.countryCode} />
                  <span className='language-switcher__locale-name'>{link.localeName}</span>
                </a>
              ))}
            </div>
          )}
        </div>
      );
    } else {
      return (null);
    }
  }
}

export default React.memo(LanguageSwitcher);
