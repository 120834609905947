import React, { Fragment } from 'react';
import { SvgIco } from '../../SharedComponents/Icons';

const pricesTableSignUpModal = [
  {
    key: '',
    category: "Lejemåls m²",
    basic: "Basic",
    basicPlus: "Basic Plus",
    premium: "Premium",
    premiumPlus: "Premium +",
  },
  {
    key: '0..249',
    category: "0 - 249 m²",
    basic: "756 kr.",
    basicPlus: "984 kr.",
    premium: "1.984 kr.",
    premiumPlus: "5.250 kr.",
  },
  {
    key: '250..499',
    category: "250 - 499 m²",
    basic: "1.259 kr.",
    basicPlus: "1.756 kr.",
    premium: "2.756 kr.",
    premiumPlus: "6.222 kr.",
  },
  {
    key: '500..999',
    category: "500 - 999 m²",
    basic: "2.336 kr.",
    basicPlus: "3.015 kr.",
    premium: "4.015 kr.",
    premiumPlus: "7.681 kr.",
  },
  {
    key: '1000..1999',
    category: "1.000 - 1.999 m²",
    basic: "3.642 kr.",
    basicPlus: "4.558 kr.",
    premium: "5.558 kr.",
    premiumPlus: "9.424 kr.",
  },
  {
    key: '2000..9999',
    category: "2.000 - 9.999 m²",
    basic: "5.522 kr.",
    basicPlus: "6.584 kr.",
    premium: "7.584 kr.",
    premiumPlus: "11.650 kr.",
  },
  {
    key: 'default',
    category: "Over 9.999 m²",
    basic: "12.339 kr.",
    basicPlus: "13.395 kr.",
    premium: "14.395 kr.",
    premiumPlus: "18.661 kr.",
  },
  {
    key: '',
    category: "Det får du",
    basic: "",
    basicPlus: "",
    premium: "",
    premiumPlus: "",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_1'),
    basic: "<span class='check-icon' />",
    basicPlus: "<span class='check-icon' />",
    premium: "<span class='check-icon' />",
    premiumPlus: "<span class='check-icon' />",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_2'),
    basic: "<span class='check-icon' />",
    basicPlus: "<span class='check-icon' />",
    premium: "<span class='check-icon' />",
    premiumPlus: "<span class='check-icon' />",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_3'),
    basic: "<span class='check-icon' />",
    basicPlus: "<span class='check-icon' />",
    premium: "<span class='check-icon' />",
    premiumPlus: "<span class='check-icon' />",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_4_new'),
    basic: "<span class='check-icon' />",
    basicPlus: "<span class='check-icon' />",
    premium: "<span class='check-icon' />",
    premiumPlus: "<span class='check-icon' />",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_6'),
    basic: "<span class='check-icon' />",
    basicPlus: "<span class='check-icon' />",
    premium: "<span class='check-icon' />",
    premiumPlus: "<span class='check-icon' />",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_14'),
    basic: "<span class='check-icon' />",
    basicPlus: "<span class='check-icon' />",
    premium: "<span class='check-icon' />",
    premiumPlus: "<span class='check-icon' />",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_7'),
    basic: "",
    basicPlus: "<span class='check-icon' />",
    premium: "<span class='check-icon' />",
    premiumPlus: "<span class='check-icon' />",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_8'),
    basic: "",
    basicPlus: "<span class='check-icon' />",
    premium: "<span class='check-icon' />",
    premiumPlus: "<span class='check-icon' />",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_9'),
    basic: "",
    basicPlus: "<span class='check-icon' />",
    premium: "<span class='check-icon' />",
    premiumPlus: "<span class='check-icon' />",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_10'),
    basic: "",
    basicPlus: "<span class='check-icon' />",
    premium: "<span class='check-icon' />",
    premiumPlus: "<span class='check-icon' />",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_11_new'),
    basic: "",
    basicPlus: "",
    premium: "<span class='check-icon' />",
    premiumPlus: "<span class='check-icon' />",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_12_new'),
    basic: "",
    basicPlus: "",
    premium: "<span class='check-icon' />",
    premiumPlus: "<span class='check-icon' />",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_13'),
    basic: "",
    basicPlus: "",
    premium: "<span class='check-icon' />",
    premiumPlus: "<span class='check-icon' />",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_15'),
    basic: "",
    basicPlus: "",
    premium: "",
    premiumPlus: "<span class='check-icon' />",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_16'),
    basic: "",
    basicPlus: "",
    premium: "",
    premiumPlus: "<span class='check-icon' />",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_17'),
    basic: "",
    basicPlus: "",
    premium: "",
    premiumPlus: "<span class='check-icon' />",
  },
  {
    key: '',
    category: I18n.t('signup.teaser.tabs.tab.block.linklist_18'),
    basic: "",
    basicPlus: "",
    premium: "",
    premiumPlus: "<span class='check-icon' />",
  },
];


const BasicPriceContent = ({ advertisementStrategiesPrices }) => {

  function generateTableRows() {
    return fillPrices(advertisementStrategiesPrices).map((item, index) => (
      <tr key={index} className={`${index === 0 || index === 7 ? 'sign-up-modal__table-header' : ''}`}>
        <td dangerouslySetInnerHTML={{ __html: item.category }} />
        <td className={`${index === 0 ? 'sign-up-modal__table-td' : ''}`} dangerouslySetInnerHTML={{ __html: item.basic }} />
        <td className={`${index === 0 ? 'sign-up-modal__table-td' : ''}`} dangerouslySetInnerHTML={{ __html: item.basicPlus }} />
        <td className={`${index === 0 ? 'sign-up-modal__table-td' : ''}`} dangerouslySetInnerHTML={{ __html: item.premium }} />
        <td className={`${index === 0 ? 'sign-up-modal__table-td' : ''}`} dangerouslySetInnerHTML={{ __html: item.premiumPlus }} />
      </tr>
    ));
  }

  function fillPrices(prices) {
    let strategy_prices = prices['prices'];

    pricesTableSignUpModal.forEach((obj) => {
        if(obj.key != '') {
            obj.basic = strategy_prices['basic'][obj.key] + ' kr.';
            obj.basicPlus = strategy_prices['basicPlus'][obj.key] + ' kr.';
            obj.premium = strategy_prices['premium'][obj.key] + ' kr.';
            obj.premiumPlus = strategy_prices['premiumPlus'][obj.key] + ' kr.';
        }
    });

    return pricesTableSignUpModal;
  }

  return (
    <Fragment>
      <div className="sign-up-modal__header">
        <h2 className="sign-up-modal__header-title">
          { I18n.t('signup.signup_form.basic.infobox.title') }
        </h2>
        <div className="sign-up-modal__close-btn" id="modalCloseBtn">
          <SvgIco name="close" size={32}/>
        </div>
      </div>

      <div className="sign-up-modal__body highlight-basic">
        <p className="sign-up-modal__pricing-description" dangerouslySetInnerHTML={{__html: I18n.t('signup.signup_form.basic.infobox.body')}} />
        <div className="sign-up-modal__pricing-table-wrapper">
          <h3>Afregningsmodel (Betal pr. annonce)</h3>

          <table className="sign-up-modal__table">
            <tbody>
              { generateTableRows() }
            </tbody>
          </table>

        </div>
      </div>
    </Fragment>
  )
};

export default BasicPriceContent;
