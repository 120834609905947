import React, { Suspense, useContext, useEffect, useState } from 'react';
import { isMobile, isTablet } from "react-device-detect";

import ImageGalleryWrapper from './ImageGalleryWrapper';
import { Context } from "../index";

const VirtualTour = React.lazy(() => import('../VirtualTour'));
const DetailsMapWrapper = React.lazy(() => import('../../NewMainMap/DetailsMapWrapper'));


const AssetBlock = () => {

  const {
    activeGallerySection,
    assets: { photos, floorPlans, videos },
    googleApiKey,
    location: { latitude, longitude },
  } = useContext(Context);

  const [orientationHorizontalMobile, setOrientationHorizontalMobile] = useState(null);


  useEffect(() => {
    if (isMobile && !isTablet) {
      window.innerHeight < window.innerWidth && setOrientationHorizontalMobile(true);
      window.addEventListener('orientationchange', handleOrientationChange);
    }

    return () => {
      isMobile && !isTablet && window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);


  const handleOrientationChange = () => {
    setOrientationHorizontalMobile(prevState => !prevState);
  };


  switch (activeGallerySection) {
    case 'photos':
      return (
        <ImageGalleryWrapper
          isDefault={true}
          photos={photos}
        />
      );
    case 'videos':
      return (
        <div className="dv-frame-wrapper">
          <iframe
            src={videos[0]}
            webkitallowfullscreen="webkitallowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            allowFullScreen
            frameBorder={0}
            id="dv-video"
          />
        </div>
      );

    case 'floorPlans':
      return (
        <ImageGalleryWrapper photos={floorPlans} />
      );

    case 'virtualTour':
      return (
        <Suspense fallback={<div className="skeleton-box" />}>
          <VirtualTour />
        </Suspense>
      );

    case 'map':
      return (
        <Suspense fallback={<div className="skeleton-box" />}>
          <DetailsMapWrapper
            googleApiKey={googleApiKey}
            isGalleryComponent={true}
            latitude={latitude}
            longitude={longitude}
            orientationHorizontalMobile={orientationHorizontalMobile}
          />
        </Suspense>
      );

    default:
      return (
        <ImageGalleryWrapper photos={photos} />
      );
  }
}

export default AssetBlock;

