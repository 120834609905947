import React, { Fragment, Suspense, useContext } from 'react';

import { NearbyLocationsBanner } from "../SharedComponents/ItemsListComponents";
import { Context } from "../index";
import GridViewItem from './GridViewItem.jsx';

const GridViewSearchAgent = React.lazy(() => import('./GridViewSearchAgent'));
const GridViewAdvertisment = React.lazy(() => import('./GridViewAdvertisment'));

import '../SharedComponents/ItemsListComponents.sass';
import './GridViewItem.sass';


const GridViewItemList = ({ hoveredLocationId, setHoveredLocationId }) => {

  const {
    comparableLocations,
    currency,
    favouriteLocationsIds,
    keywords,
    locationsByPostalDistricts,
    mapOptions,
    missingImagesUrl,
    orderedLocations,
    section,
    setComparableLocations,
    setFavouriteLocationsIds,
    setOrderedLocations,
    siteKey,
    userCountryPhoneCode,
  } = useContext(Context);

  const { countryAlpha2, newSearchAgentPath, rootPath, signupTeaserPath, siteLogoPath } = keywords;
  const mapIsOpen = mapOptions.open;


  return (
    <div className="grid-list container max-width">
      <div className="row">
        { locationsByPostalDistricts.map(location => {

          const { advertisement, id } = location;

          if (id === 'nearByBanner') {
            return (
              <NearbyLocationsBanner
                grid={true}
                key={id + 'nearby'}
                mapIsOpen={mapIsOpen}
                siteKey={siteKey}
              />
            )
          } else if (advertisement) {

            const { search_agent } = location;

            return (
              <Fragment>
                { search_agent
                  ?
                    <Suspense fallback={''}>
                      <GridViewSearchAgent
                        advertismentData={location}
                        key={id}
                        mapIsOpen={mapIsOpen}
                        newSearchAgentPath={newSearchAgentPath}
                        rootPath={rootPath}
                        signupTeaserPath={signupTeaserPath}
                        siteKey={siteKey}
                        siteLogoPath={siteLogoPath}
                      />
                    </Suspense>
                  :
                    <Suspense fallback={''}>
                      <GridViewAdvertisment
                        advertismentData={location}
                        key={id}
                        mapIsOpen={mapIsOpen}
                        rootPath={rootPath}
                        signupTeaserPath={signupTeaserPath}
                        siteLogoPath={siteLogoPath}
                      />
                    </Suspense>
                }
              </Fragment>
            )
          } else {
            return (
              <GridViewItem
                comparableLocations={comparableLocations}
                countryAlpha2={countryAlpha2}
                currency={currency}
                favouriteLocationsIds={favouriteLocationsIds}
                hoveredLocationId={hoveredLocationId}
                key={location.id + 'grid-item'}
                locationData={location}
                mapIsOpen={mapIsOpen}
                missingImagesUrl={missingImagesUrl}
                orderedLocations={orderedLocations}
                section={section}
                setHoveredLocationId={setHoveredLocationId}
                setOrderedLocations={setOrderedLocations}
                siteKey={siteKey}
                updateComparableLocations={setComparableLocations}
                updateFavouriteLocations={setFavouriteLocationsIds}
                userCountryPhoneCode={userCountryPhoneCode}
              />
            )
          }
        })}
      </div>
    </div>
  )
};


export default GridViewItemList;
