import React, { Component, Fragment } from 'react';
import axios from 'axios';

import placeHolderImage from '../../../assets/images/photo_on_its_way.png';
import GoogleReviewsWidget from "../SharedComponents/GoogleReviewsWidget";
import { DetailedViewSet, SvgIco } from '../SharedComponents/Icons';
import { loadTawkTo } from '../TawkTo/TawkTo';
import { VirtualTourModal } from './VirtualTourModal.jsx';
import SearchOfferModal from "../SharedComponents/SearchOptionsModal";

import './styles/style.sass';


class OrderConfirmationContainer extends Component {

  state = {
    virtualTourIsOpen: false,
    downloadPdfUrl: "",
    statusPdfFile: "",
    loadingProspectus: false,
    serverError: false,
    generateButton: true,
  };


  componentDidMount() {
    loadTawkTo(this.props.siteKey);
  };


  toggleVirtualTour = () =>
    this.setState({ virtualTourIsOpen: !this.state.virtualTourIsOpen });


  generateProspectus = () => {
    const { createLocationPatternedPropsectus } = this.props;
    let checkPdf = this.props.locationProspectus || this.state.downloadPdfUrl.length > 0;
    this.setState({generateButton: false})

    if (!checkPdf) {
      this.setState({loadingProspectus: true, serverError: false});
      axios({method: "post", url: createLocationPatternedPropsectus})
        .then(response => {
          let pdfUrl = response.data.pdf_url;
          let statusUrl = response.data.status_url;

          let pdfTimer = () => axios({method: "get", url: statusUrl})
            .then(response => {
              if (response.data.status === "finished") {
                this.setState({statusPdfFile: response.data.status, downloadPdfUrl: pdfUrl, loadingProspectus: false})
              } else {
                setTimeout(pdfTimer, 7000);
              }
            });
          setTimeout(pdfTimer, 100);

        })
        .catch(error => {
          console.log(error)
          this.setState({loadingProspectus: false, serverError: true, generateButton: true});
        });
    }
  };


  render() {

    const { userEmail,
            currency,
            ourEmail,
            googleApiKey,
            locationImage,
            locationAddress,
            locationProspectus,
            virtualTour,
            districtName,
            locationKind,
            locationType,
            linkToListView,
            siteName,
            linkFromEmail,
            siteKey,
            isInternal,
            newSearchAgentPath,
            orderId,
            apiCreateOrderDetailsPath } = this.props;

    const { statusPdfFile, downloadPdfUrl, loadingProspectus, serverError, generateButton } = this.state;

    let pageTitle;
    let pageTextHint;
    let pageText;
    let prospectusTitle;
    let pageNotFound = false;

    switch(this.props.page) {
      case 'order_confirmation':
        pageTitle = `${I18n.t('order_confirmations.show.header')}`;
        pageText = `${I18n.t('order_confirmations.show.status', {'email': userEmail, 'our_email_address': ourEmail})}`;
        prospectusTitle = `${I18n.t('order_confirmations.show.summary')}`;
        break;
      case 'internal_provider_order':
        pageTitle = `${I18n.t('order_confirmations.show.header')}`;
        pageText = `${I18n.t('order_confirmations.show.status', {'email': userEmail, 'our_email_address': ourEmail})}`;
        prospectusTitle = `${I18n.t('order_confirmations.show.summary')}`;
        break;
      case 'provider_messages':
        pageTitle = `${I18n.t('provider_messages.thank_you.header')}`;
        pageText = `${I18n.t('provider_messages.thank_you.status')}`;
        prospectusTitle = `${I18n.t('provider_messages.thank_you.summary')}`;
        break;
      case 'showcase_orders':
        pageTitle = `${I18n.t('showcase_orders.thank_you.header')}`;
        pageTextHint = `${I18n.t('showcase_orders.thank_you.status_warning')}`;
        pageText = `${I18n.t('showcase_orders.thank_you.status', {'email': userEmail})}`;
        prospectusTitle = `${I18n.t('showcase_orders.thank_you.summary')}`;
        break;
      default:
        pageTitle = `${I18n.t('error_pages.404_page.header')}`;
        pageText = `${I18n.t('controllers.location_adverts.flash.location_deactivated')}`;
        pageNotFound = true;
    }

    const pageTitleFromEmail = I18n.t("order_confirmations.show.header-from-email");
    const generateProspectusTitle = I18n.t("order_confirmations.show.generate-prospectus-location");
    const checkSiteKey = siteKey === "dk" || siteKey === "global";


    const spinner =
      <div className="spinner-wrapper">
        <div className="smoothie-spinner" />
        <p>{I18n.t("order_confirmations.show.please-wait-few-seconds")} ...</p>
      </div>;


    return (
      <div className='order-conf__page-wrapper container'>

        <SearchOfferModal
          apiCreateOrderDetailsPath={apiCreateOrderDetailsPath}
          currency={currency}
          locationKind={locationType}
          orderId={orderId}
          siteKey={siteKey}
        />

        <div className='order-conf__body'>
          <div className="row">
            <h1 className='order-conf__header col-xl-12'>{ linkFromEmail ? pageTitleFromEmail : pageTitle }</h1>
            { pageTextHint && <p className='order-conf__main-text-hint col-md-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2'>{ pageTextHint }</p> }
            {!linkFromEmail && <p className='tac order-conf__main-text col-md-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2'>{ pageText }</p> }


            { !pageNotFound && (
              <div className='order-conf__cols-wrapper order-conf__propectus-wrapper col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2'>
                <div className='order-conf__col hidden-xs'>
                  <div className='order-conf__propectus-image' style={{'backgroundImage': `url(${locationImage || placeHolderImage})`}} />
                </div>
                <div className='order-conf__col'>
                  <div className='order-conf__propectus-info'>
                    <div className='order-conf__propectus-address'>
                      <p>{ linkFromEmail ? generateProspectusTitle : prospectusTitle }</p>
                      <b>{ locationAddress }</b>
                    </div>
                    <div className='order-conf__propectus-actions'>
                      {(checkSiteKey && !isInternal) &&
                        <Fragment>

                          {(generateButton && !locationProspectus) &&
                          <div className="order-conf__prospectus-actions-button">
                            <a onClick={this.generateProspectus}>
                              { I18n.t("order_confirmations.show.generate-prospectus") }
                            </a>
                          </div>}

                          {serverError && (
                            <p>Some server error try later</p>
                          )}
                        </Fragment>
                      }

                      { loadingProspectus
                        ? spinner
                        : <Fragment>
                          {!isInternal && (locationProspectus || statusPdfFile === "finished") && (
                            <div className="order-conf__prospectus-actions-button">
                              <a href={locationProspectus ? locationProspectus : downloadPdfUrl} target='_blank'>
                                <SvgIco name="get_app" size={24} />
                                <span>{ I18n.t('order_confirmations.show.download_prospectus') }</span>
                              </a>
                            </div>
                          )}
                        </Fragment>
                      }

                      { siteKey !== 'dk' && siteKey !== 'global' && !virtualTour && !locationProspectus && (
                        <div className="order-conf__other-materials">
                          <b>{ I18n.t('order_confirmations.show.other_materials') }</b>
                          <p>{ I18n.t('order_confirmations.show.hint_instead_of_prospectus') }</p>
                        </div>
                      )}


                      { virtualTour && (
                        <a className='virtual-tour-icon' onClick={this.toggleVirtualTour}>
                          { DetailedViewSet['virtual_tour'] }
                          <span>{ I18n.t('order_confirmations.show.see_virtual_tour') }</span>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>)}

            <div className='col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2'>
              <GoogleReviewsWidget
                apiKey={googleApiKey}
                siteKey={siteKey} />
            </div>
          </div>

          <div className='order-conf__more-blocks-wrapper row'>
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className='order-conf__more-block top'>
                <h2>{ I18n.t('order_confirmations.show.browse_more_header') }</h2>
                <p className='flex-main'>
                  { I18n.t('order_confirmations.show.browse_more_in_district', {'district_name': districtName, 'location_kind': locationKind }) }
                </p>
                <div>
                  <a href={linkToListView} className='b-button b-button--huge'>
                    { I18n.t('order_confirmations.show.browse_more_cta') }
                    <SvgIco name="keyboard-arrow-right-rounded" size={34} />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className='order-conf__more-block'>
                <h2>{ I18n.t('order_confirmations.show.search_agent_header')} </h2>
                <p className='flex-main'>
                  { I18n.t('order_confirmations.show.search_agent_benefits', {'site_name': siteName }) }
                </p>
                <div>
                  <a href={newSearchAgentPath} className='b-button b-button--huge bg-green'>
                    { I18n.t('order_confirmations.show.search_agent_cta') }
                    <SvgIco name="keyboard-arrow-right-rounded" size={34} />
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
        <VirtualTourModal src={virtualTour} isOpen={this.state.virtualTourIsOpen} toggleModal={this.toggleVirtualTour} />
      </div>
    )
  }
}

export default OrderConfirmationContainer;
