import React, { useContext, useState, Fragment, Suspense } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";
import { isMobileOnly } from "react-device-detect";

const InfoNotice = React.lazy(() => import('../../SharedComponents/InfoNotice'));
const CompareIcon = React.lazy(() => import('../../SharedComponents/CompareIcon'));
const SpaceCalculator = React.lazy(() => import('../../SharedComponents/SpaceCalculator'));
import { SvgIco } from '../../SharedComponents/Icons';
import FavouriteIcon from '../../SharedComponents/FavouriteIcon';
import { Context } from "../index";

import './index.sass';


const Header = () => {

  const {
    allKinds,
    anonymous,
    areaUnit,
    comparableLocations,
    facts,
    favouriteLocationsIds,
    isScraped,
    kind,
    locationClosed,
    locationId,
    locationRented,
    missingImagesUrl,
    section,
    setFavouriteLocationsIds,
    siteKey,
    suitableFor,
    tabs,
    title,
    updateComparableLocations,
  } = useContext(Context);


  const [showAllKinds, setShowAllKinds] = useState(false);

  const { price, area, persons } = facts;
  const { economy } = tabs;
  const disposalPrice = economy.disposalPrice || 0;
  const showEmployees = persons;
  const visibleKinds = showAllKinds || allKinds.length <= 2 ? allKinds : allKinds.slice(0, 2);
  const reducedKinds = allKinds.length > 2 ? `+${allKinds.length - 2}` : false;
  const disposal = parseInt(disposalPrice) > 0 && (siteKey === 'dk' || siteKey === 'global');
  const sectionTitle = section !== "lease" ? I18n.t('apps.lb_showroom.detailed.economy.prices_for_sale') : I18n.t('apps.lb_showroom.detailed.economy.annual_prices_' + areaUnit);
  const badgesWrapperClasses = classNames('dv-header__cafe-disposal-block', {'indentation-mobile': suitableFor.length });
  const bottomBorderClasses = classNames('dv-header__bottom-border', {'indentation-mobile': suitableFor.length });
  const verifiedLabel = section === 'lease' ? I18n.t('apps.lb_showroom.verified_lease') : 'Verificeret salgsejendom';
  const pricePerM2Present = economy.pricesPerM2 && Object.keys(economy.pricesPerM2).length > 0;
  const pricesPerYearPresent = economy.pricesPerYear && Object.keys(economy.pricesPerYear).length > 0;
  const checkEconomySectionPresent = pricePerM2Present || pricesPerYearPresent || disposal || kind === 'meeting_room';
  const pricePerYearM2 = economy.pricesPerM2 && economy.pricesPerM2.pricePerYear;


  const scrollToEconomySection = () =>
    window.scroll(0, document.getElementById('economy-section').getBoundingClientRect().top + window.pageYOffset - (document.getElementById('top-menu').offsetHeight + 48));


  const InfoCell = ({ data, showUnit, icon, isPersons, isArea }) => !!data.value &&
    <div className="dv-header__location-details-cell">
      <div className="dv-header__cell-value">
        <SvgIco name={icon || data.icon} size={22}/>
        <span>{ data.value } {showUnit && data.unit}</span>
        { isPersons && data.tooltip &&
          <span className="dv-header__info-tooltip"
                data-for={'persons-tooltip'}
                data-tip={data.tooltip}>
            <SvgIco name="info_outline" size={16} />
            <ReactTooltip
              effect="solid"
              id={'persons-tooltip'}
              place="right"
              className="info-tooltip"
            />
          </span>
        }
      </div>

      <div className="dv-header__cell-label">
        { data.label }
      </div>

      { isArea && kind === 'office' && siteKey === 'dk' &&
        <Suspense fallback="">
          <SpaceCalculator />
        </Suspense>
      }
    </div>;


  return (
    <div className="dv-header">

      { anonymous &&
        <Suspense fallback="">
          <InfoNotice
            missingImagesUrl={missingImagesUrl}
            noticeKind={'anonymous'}
            root="detail"
          />
        </Suspense>
      }

      { isScraped &&
        <div className="dv-header__scraped-info-wrapper">
          <div className="dv-header__scraped-info-block">
            <p className="dv-header__scraped-headline" data-nosnippet={true}>
              <SvgIco name="info_outline" size={22} />
              { I18n.t('generic.title_info_text') }
            </p>
            <p className="dv-header__scraped-content" data-nosnippet={true}>
              { I18n.t('generic.parsed_info_text') }&nbsp;
              <a href={missingImagesUrl} target="_blank" className="read-more">
                { I18n.t('generic.read_more') }
              </a>
            </p>
          </div>
        </div>
      }

      <div className="dv-header__top-wrapper">
        <div className="section dv-header__location-kinds">
          { visibleKinds.map((kind, index) =>
            <span key={index}>
              { I18n.t(`generic.${kind}`) }
            </span>
          )}
          { (reducedKinds && !showAllKinds) &&
            <span className={!showAllKinds ? 'dv-header__show-all-kinds-link' : ''}
                  onClick={ !showAllKinds ? setShowAllKinds(true) : () => null }>
              { reducedKinds }
            </span>
          }
        </div>

        { !locationRented &&
          <div className="dv-header__action-icons-wrapper">
            { section === 'lease' && !isMobileOnly &&
              <Suspense fallback="">
                <CompareIcon
                  comparableLocations={comparableLocations}
                  kind={kind}
                  locationId={locationId}
                  siteKey={siteKey}
                  tooltipPosition={'left'}
                  updateComparableLocations={updateComparableLocations}
                  withLabel={true}
                />
              </Suspense>
            }

            <FavouriteIcon
              favoriteLocations={favouriteLocationsIds}
              isDetailView={true}
              locationId={locationId}
              section={section}
              siteKey={siteKey}
              tooltipPosition={'left'}
              updateFavouriteLocations={(ids) => setFavouriteLocationsIds(ids)} />
          </div>
        }

      </div>

      <div className="dv-header__title-wrapper">
        <h1 className={`dv-header__location-title${ isScraped ? ' extra-indentation' : ''}`}>
          { title }
        </h1>
        { !isScraped &&
          <div className="dv-header__verified-lease">
            <SvgIco name="verified" size={22}/>
            <span>{ verifiedLabel }</span>
          </div>
        }
      </div>

      <div className="row">
        { kind !== 'virtual_office' &&
          <div className="dv-header__location-details-row area col-12">
            { kind === 'meeting_room' && showEmployees
              ? <InfoCell data={persons} isPersons={true} />
              : !showEmployees
              ? <InfoCell data={area} showUnit={area.icon !== 'desks'} isArea={true} />
              : kind !== 'parking' && kind !== 'virtual_office' &&
                <Fragment>
                  <InfoCell data={area} showUnit={true} isArea={true} />
                  <InfoCell data={persons} isPersons={true} />
                </Fragment>
            }

          </div>
        }
      </div>

      { kind !== 'virtual_office' && !locationClosed &&
        <div className="dv-header__separator-wrapper">
          <div className="dv-header__location-details-separator" />
        </div>
      }

      { !locationClosed &&
        <div className="dv-header__location-details-wrapper">
          <div className="dv-header__location-details-row">
            { pricePerYearM2 &&
              <div className="dv-header__location-details-cell">
                <div className="dv-header__cell-value">
                  <SvgIco name="price" size={22} />
                  <span>{ pricePerYearM2 }</span>
                </div>
                <div className="dv-header__cell-label">
                  { sectionTitle }
                </div>
                { checkEconomySectionPresent &&
                  <div className="dv-header__economy-section-link" onClick={scrollToEconomySection}>
                    { I18n.t('apps.lb_showroom.detailed.see_economy_link') }
                  </div>
                }
              </div>
            }

            <InfoCell data={{ ...price, icon: 'calendar_month' }} />

            { disposal &&
              <InfoCell
                data={{
                  icon: 'real_estate_agent',
                  label: I18n.t('apps.lb_showroom.detailed.economy.disposal_price'),
                  value: disposalPrice
                }}
                showUnit={false}
              />
            }
          </div>
        </div>
      }

      { suitableFor.length > 0 &&
        <div className={badgesWrapperClasses}>
          { (!locationClosed || (locationClosed && suitableFor.length > 0)) && <div className="separator" /> }

          { suitableFor.length > 0 && suitableFor.map((elem, index) =>
            <div className='dv-header__suitable-for' key={index}>
              <SvgIco name={elem.icon} width={20} height={20} className="dv-header__suitable-for-icon" />
              <span className="dv-header__suitable-for-label">
                { elem.label }
              </span>
            </div>
          )}
        </div>
      }

      <div className={bottomBorderClasses}>
        <div className="dv-header__location-details-separator" />
      </div>

    </div>
  )
};

export default Header;


Header.propTypes = {
  economy: PropTypes.object,
}

Header.defaultProps = {
  economy: {},
};
