import React, { Fragment, useEffect, useMemo, useState} from 'react';
import Modal from "react-modal";
import DateTimePicker from "react-datetime-picker";

import { placeOrderDetails } from '../../../utils/api';
import BasicDropdown from "../../SharedComponents/Forms/BasicDropdown";
import Input from "../../SharedComponents/Forms/Input";
import { SvgIco } from "../../SharedComponents/Icons";
import { loadTawkTo } from "../../TawkTo/TawkTo";
import { EllipsisLoader } from "../../Provider/Loaders";

import './index.sass';


const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    top: '0',
    overflow: 'auto',
    zIndex: '20000000000',
    backdropFilter: 'blur(5px)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'visible',
    background: 'white',
    borderRadius: '16px 16px 0px 0px',
    position: 'absolute',
  },
};

const kindsOptions = [
  { value: 'open_office', label: I18n.t('order_confirmations.modal.kind.open_office') },
  { value: 'closed_office', label: I18n.t('order_confirmations.modal.kind.closed_office') },
  { value: 'hot_desk', label: I18n.t('order_confirmations.modal.kind.hot_desk') },
  { value: 'assigned_workspace', label: I18n.t('order_confirmations.modal.kind.assigned_workspace') },
];


const SearchOfferModal = ({ currency, siteKey, locationKind, orderId, apiCreateOrderDetailsPath }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [values, setValues] = useState({ currency_id: currency.id, order_id: orderId });
  const [timeRangeOptional, setTimeRangeOptional] = useState(null);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);


  useEffect(() => {
    const showSearchOptionsModal = (locationKind === 'business_center' || locationKind === 'coworking') && siteKey !== 'dk' && orderId && apiCreateOrderDetailsPath && locationKind;

    if (showSearchOptionsModal) {
      // Show modal once a day
      const searchOfferModalShown = localStorage && localStorage.getItem('my.search_offer_modal_shown');
      const newDate = new Date();

      newDate.setHours(23,59,59,59);

      const timestamp = `${newDate.getUTCDate()}_${newDate.getUTCMonth() + 1}_${newDate.getUTCFullYear()}`;

      if (!searchOfferModalShown || searchOfferModalShown !== timestamp) {
        setModalIsOpen(true);
        localStorage && localStorage.setItem('my.search_offer_modal_shown', timestamp);
      }
    }
  }, []);


  const requiredValuesFilled = useMemo(() => {
    const { employee_amount, workplace_type, monthly_budget_min, monthly_budget_max, occupied_at_type, occupied_at_date } = values;

    return !!(employee_amount && workplace_type && (monthly_budget_min || monthly_budget_max) && (occupied_at_type || occupied_at_date));
  },[values]);


  const handleSubmit = () => {

    const path = '/lease/api/frontend/order_details';

    setLoading(true);

    placeOrderDetails(path, values)
      .then((response) => {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => setModalIsOpen(false), 1000);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };


  const handleChange = (newValues) =>
    setValues((prevValues) => ({ ...prevValues, ...newValues }));


  const setTimeRange = (value, isDateOptional) => {
    setTimeRangeOptional(isDateOptional ? value : null);
    handleChange({
      occupied_at_type: isDateOptional ? 'custom_date' : value,
      occupied_at_date: isDateOptional ? value.toLocaleDateString('en-GB').split('/').join('.') : '',
    });
  };


  const customFormattedDate = timeRangeOptional && timeRangeOptional.toLocaleDateString('en-GB').split('/').join('.');


  return (
    <Modal
      ariaHideApp={false}
      className={`search-options-modal ${success ? 'success' : ''}`}
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      style={customStyles}
    >
      <div className="search-options-modal__wrapper">

        { success &&
          <div className="search-options-modal__success-icon-wrapper">
            <div className="search-options-modal__success-icon">
              <SvgIco name="check" />
            </div>
          </div>
        }

        { !success &&
          <Fragment>
            <div className="search-options-modal__close-icon" onClick={() => setModalIsOpen(false)}>
              <SvgIco name="close" size={24} />
            </div>

            <div className="search-options-modal__info">
              <h2>{ I18n.t('order_confirmations.modal.header') }</h2>
              <p>{ I18n.t('order_confirmations.modal.header_info') }</p>
            </div>

            <div className="search-options-modal__form">

              <div className="form-group kinds filled">
                <div className="floating-label">
                  { I18n.t('order_confirmations.modal.kind_label') }
                </div>
                <BasicDropdown
                  handleChange={(value) => handleChange({ workplace_type: value })}
                  name="workplace_type"
                  options={kindsOptions}
                  placeholder={I18n.t('apps.lb_showroom.main.kinds_placeholder')}
                  value={values.workplace_type || null}
                />
              </div>

              <Input
                handleChange={(e) => (/^\d+$/.test(e.target.value) || !e.target.value) && handleChange({ employee_amount: e.target.value }) }
                inputMode="numeric"
                label={I18n.t('activemodel.attributes.service_request.employee_count')}
                name="employee_amount"
                pattern="[0-9]*"
                placeholder={I18n.t('generic.enter_amount')}
                type="number"
                value={values.employee_amount || ''}
              />

              <div className="search-options-modal__price-range">
                <p>{ I18n.t('order_confirmations.modal.price_range_label') }</p>

                <div className="search-options-modal__price-inputs">
                  <div className="search-options-modal__price-input">

                    <div className="floating-label">
                      { I18n.t('generic.minimum') }
                    </div>
                    <div className="search-options-modal__price-input-bottom">
                      <div className={`search-options-modal__price-currency ${values.monthly_budget_min ? 'active' : ''}`}>
                        { currency.formatted }
                      </div>
                      <Input
                        handleBlur={() => values.monthly_budget_max && values.monthly_budget_min && Number(values.monthly_budget_max) < Number(values.monthly_budget_min) && handleChange({ monthly_budget_min: values.monthly_budget_max })}
                        handleChange={(e) => (/^\d+$/.test(e.target.value) || !e.target.value) && handleChange({ monthly_budget_min: e.target.value }) }
                        inputMode="numeric"
                        name="monthly_budget_min"
                        pattern="[0-9]*"
                        placeholder="0"
                        type="number"
                        value={values.monthly_budget_min || ''}
                      />
                    </div>
                  </div>

                  <div className="search-options-modal__price-inputs-separator" />

                  <div className="search-options-modal__price-input">
                    <div className="floating-label">
                      { I18n.t('generic.maximum') }
                    </div>
                    <div className="search-options-modal__price-input-bottom">
                      <div className={`search-options-modal__price-currency ${values.monthly_budget_max ? 'active' : ''}`}>
                        { currency.formatted }
                      </div>
                      <Input
                        handleBlur={() => values.monthly_budget_max && values.monthly_budget_min && Number(values.monthly_budget_max) < Number(values.monthly_budget_min) && handleChange({ monthly_budget_max: values.monthly_budget_min })}
                        handleChange={(e) => (/^\d+$/.test(e.target.value) || !e.target.value) && handleChange({ monthly_budget_max: e.target.value })}
                        inputMode="numeric"
                        name="monthly_budget_max"
                        pattern="[0-9]*"
                        placeholder="0"
                        type="number"
                        value={values.monthly_budget_max || ''}
                      />
                    </div>
                  </div>
                </div>
              </div>


              <div className="search-options-modal__time-range">

                <p>{ I18n.t('order_confirmations.modal.occupied_at_label') }</p>

                <div className="search-options-modal__time-range-row">
                  <div className={`search-options-modal__time-range-trigger ${values.occupied_at_type === 'soonest' ? 'active' : ''}`}
                       onClick={() => setTimeRange('soonest')}>
                    { I18n.t('order_confirmations.modal.occupied_at_type.soonest') }
                  </div>

                  <div className={`search-options-modal__time-range-trigger ${values.occupied_at_type === 'next_few_weeks' ? 'active' : ''}`}
                       onClick={() => setTimeRange('next_few_weeks')}>
                    { I18n.t('order_confirmations.modal.occupied_at_type.next_few_weeks') }
                  </div>
                </div>

                <div className="search-options-modal__time-range-row">
                  <div className={`search-options-modal__time-range-trigger ${values.occupied_at_type === 'next_2_3_monthes' ? 'active' : ''}`}
                       onClick={() => setTimeRange('next_2_3_monthes')}>
                    { I18n.t('order_confirmations.modal.occupied_at_type.next_2_3_monthes') }
                  </div>

                  <div className={`search-options-modal__time-range-trigger search-options-modal__time-range-trigger--calendar ${timeRangeOptional ? 'active' : ''}`}>
                    <div className="search-options-modal__time-range-label">
                      { customFormattedDate || I18n.t('order_confirmations.modal.occupied_at_type.custom_date') }
                    </div>
                    <DateTimePicker
                      className={calendarIsOpen ? 'calendar-open' : ''}
                      disableClock={true}
                      format="dd-MM-yyyy"
                      minDate={new Date}
                      onCalendarClose={() => setCalendarIsOpen(false)}
                      onCalendarOpen={() => setCalendarIsOpen(true)}
                      onChange={(date) => setTimeRange(date, true)}
                      value={timeRangeOptional} />
                  </div>

                </div>
              </div>
            </div>

            <div className="search-options-modal__buttons-wrapper">
              <div className="search-options-modal__close-button" onClick={() => setModalIsOpen(false)}>
                { I18n.t('generic.skip') }
              </div>

              <div className={`search-options-modal__submit-button ${!requiredValuesFilled ? 'inactive' : '' } ${loading ? 'loading' : ''}`} onClick={handleSubmit}>
                { loading
                  ? <EllipsisLoader />
                  : I18n.t('generic.submit')
                }
              </div>
            </div>
          </Fragment>
        }

      </div>
    </Modal>
  )
}

export default SearchOfferModal;
