import React, { Component } from 'react';
import { fetchSimilarLocations } from '../../utils/api';
import NumberFormatter from '../../utils/NumberFormatter';
import BaseSlider from '../SharedComponents/BaseSlider';
import LocationTemplate from '../SharedComponents/LocationTemplate';

import './index.sass';


class SimilarLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    const { similarLocationsEndpoint, similarLocationsList } = this.props;

    similarLocationsList && similarLocationsList.length > 0
      ? this.setState({ isLoaded: true, items: this.handleResult(similarLocationsList) })
      : similarLocationsEndpoint && this.renderSimilarLocations(similarLocationsEndpoint);
  }

  renderSimilarLocations(endPoint) {
    return fetchSimilarLocations(endPoint)
      .then(response => response.data)
      .then(responseJSON => {
        this.setState({
          isLoaded: true,
          items: this.handleResult(responseJSON),
        });
      })
      .catch(error => {
        this.setState({
          isLoaded: true,
          error,
        });
      });
  }

  handleResult(items) {
    for (let i = 0; i < items.length; i++) {
      items[i].first_spotlight_value_formatted = this.numberFormatter().numberWithDelimiter(
        items[i].first_spotlight_value
      );
      if (items[i].raw_kind === 'parking') {
        items[i].second_spotlight_value_formatted = items[i].second_spotlight_value
      } else {
        items[i].second_spotlight_value_formatted = this.numberFormatter().numberWithDelimiter(
          items[i].second_spotlight_value
        );
      }
    }
    items.length && this.props.handleInit && this.props.handleInit();
    return items;
  }

  numberFormatter() {
    return new NumberFormatter('.', ',');
  }

  render() {
    const { isLoaded, items } = this.state;

    const {
      comparableLocations,
      favouriteLocationsIds,
      locationAvailable,
      section,
      similarLocationsHeadline,
      similarLocationsParagraph,
      siteKey,
      updateComparableLocations,
      updateFavouriteLocations,
    } = this.props;

    const wrapperMargin = { marginBottom: locationAvailable ? '23px' : 0 };


    if (isLoaded && items.length > 0) {
      return (
        <div className="similar-locations dv-info-section-wrapper" style={ wrapperMargin } id="similarLocations">
          <h2 className="similar-locations__heading container">{ similarLocationsHeadline }</h2>
          { similarLocationsParagraph && <p className="similar-locations__subheading container">{ similarLocationsParagraph }</p> }
          <div className="similar-locations__slider-wrapper container">
            <BaseSlider infinite withDots>
              { items.map(item => (
                <LocationTemplate
                  component="similar"
                  comparableLocations={comparableLocations}
                  data={item}
                  favoriteLocations={favouriteLocationsIds}
                  key={item.uuid}
                  section={section}
                  siteKey={siteKey}
                  updateFavouriteLocations={updateFavouriteLocations}
                  updateComparableLocations={updateComparableLocations}
                />
              )) }
            </BaseSlider>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default SimilarLocations;
