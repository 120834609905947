import React from 'react';

import landscapeImage from '../../../src/images/landscape.png';

import './Landscape.sass';


const Landscape = ({ latitude, longitude }) => {
  const handleLandscapeClick = () => {
    window.open('https://skraafoto.dataforsyningen.dk?project=Denmark&lon=' + longitude + '&lat=' + latitude);
  };


  return (
    <div className='image-gallery-landscape-background lazyload' data-bg={landscapeImage} data-sizes="auto" id="landscape-banner">
      <div className="image-gallery-landscape-wrapper">
        <div className='image-gallery-landscape-text'>
          <h3 className='image-gallery-landscape-text-heading' onClick={handleLandscapeClick}>
            {I18n.t('detailed.location.gallery.landscape.heading')}
          </h3>
          <p className="image-gallery-landscape-text-middle" onClick={handleLandscapeClick}>
            {I18n.t('detailed.location.gallery.landscape.paragraph_1')}
            {I18n.t('detailed.location.gallery.landscape.paragraph_2')}
            {I18n.t('detailed.location.gallery.landscape.paragraph_3')}
          </p>
          <div className="image-gallery-landscape__button order-form__button" onClick={handleLandscapeClick}>
            {I18n.t('detailed.location.gallery.landscape.cta')}
          </div>
          <p className="image-gallery-landscape-text-buttom" onClick={handleLandscapeClick}>
            {I18n.t('detailed.location.gallery.landscape.paragraph_buttom')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Landscape;
