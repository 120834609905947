import React from 'react';

import { SvgIco } from "../../SharedComponents/Icons";

import './BenefitsMobile.sass'


class BenefitsMobile extends React.PureComponent {

  state = {
    activeTab: null,
  };

  toggleDropdownTrigger = (wrapperName) => {
    const { activeTab } = this.state;

    this.setState({ activeTab: wrapperName }, () => {

      !activeTab && this[wrapperName].classList.add('open');

      if (activeTab) {

        if (activeTab === wrapperName) {
          this[activeTab].classList.toggle('open');
          this[activeTab].classList.toggle('close');
        } else {
          this[activeTab].classList.remove('open');
          this[activeTab].classList.add('close');
          this[wrapperName].classList.remove('close');
          this[wrapperName].classList.add('open');
        }
      }
    });
  };

  render() {
    const { benefits, virtualTourUrl, siteKey } = this.props;


    return (
      <div className="benefits-mobile">
        { Array.from(Array(12)).map((benefit, index) =>
          <div key={index} className="benefits-mobile__item" ref={el => this[`benefitTrigger${index}`] = el}>

            <h3 onClick={() => this.toggleDropdownTrigger(`benefitTrigger${index}`)}>
              <img
                alt={benefits(index).iconName}
                className="lazyload"
                data-sizes="auto"
                data-src={require(`../../../src/images/list_office/${benefits(index).iconName}.svg`)}
                height={20}
              />
              { benefits(index).headline }
              <SvgIco name="expand-more" size={24} />
            </h3>

            <p className="benefits-mobile__body">{ benefits(index).body }
              { siteKey === 'dk' && index === 6 &&
                <span dangerouslySetInnerHTML={{ __html: virtualTourUrl }} />
              }
            </p>

          </div>
        )}
      </div>
    )
  }
}

export default BenefitsMobile;
