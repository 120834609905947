import React, { Component } from "react";

import './index.sass';

class Modal extends Component {

  componentDidMount() {
    const modalClassList = document.getElementById(this.props.modalId).classList;
    const initialBodyWidth = document.body.clientWidth;

    document.addEventListener('click', this.handleClickOutside);
    document.body.classList.add('modal-active');

    if(initialBodyWidth < document.body.clientWidth) {
      document.body.style.marginRight = document.body.clientWidth - initialBodyWidth + 'px';
    }

    modalClassList.add('in');
    setTimeout(() => modalClassList.add('active'), 50);
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    document.body.classList.remove('modal-active');
    document.body.style.marginRight = 'unset';
  }

  handleClickOutside = (e) => {
    const { modalId } = this.props;

    if (!this.modalWrapper.contains(e.target) || e.target.id === 'modalCloseBtn' || e.target.id === 'modalFooterCloseBtn') {
      document.getElementById(modalId).classList.remove('active', 'in');
      setTimeout(() => this.props.toggleModal(modalId), 100);
    }
  };

  render() {

    const { modalId, content } = this.props;
    const padding = modalId !== 'termOfService' && { paddingTop: `${document.getElementById('top-menu').clientHeight}px` };

    return (
      <div className={`${ modalId === 'termOfService' ? 'terms-of-service ' : '' }sign-up-modal fade `}
           id={ modalId }
           style={ padding || null }
      >
        { modalId !== 'termOfService'
          ? <div className="sign-up-modal__wrapper" ref={ elem => this.modalWrapper = elem }>
              { content }
            </div>
          : <div className="sign-up-modal__wrapper container">
              <div className="row">
                <div className="col-12 col-md-12 offset-lg-2 col-lg-8 offset-xl-2 col-xl-8">
                  <div className="terms-of-service__content" ref={ elem => this.modalWrapper = elem }>
                    { content }
                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    )
  }
}

export default Modal;
