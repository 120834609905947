import React from 'react';
import crawledFallbackImage from '../../src/images/parsed-background.svg';

const CrawledLocationImg = () => {

  const styles = {
    backgroundSize: 'cover',
    backgroundPosition: 'top left',
  };

  return (
    <div style={styles}
         className='image-gallery-image crawled lazyload noFullScreen'
         data-bg={crawledFallbackImage}
         data-sizes="auto"
    >
      <div className='image-gallery-crawled__text-wrapper'>
      </div>
    </div>
  );
};

export default CrawledLocationImg;
