import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import classNames from 'classnames';

import { SvgIco } from "../../SharedComponents/Icons";

import './InteriorDesignBanner.sass';


const InteriorDesignBanner = ({ isFullScreen, orientationHorizontalMobile }) => {

  const isFullScreenMobile = isFullScreen && isMobileOnly;
  const bannerClasses = classNames('interior-design-banner', {
    'interior-design-banner--fullscreen': isFullScreen,
    'interior-design-banner--orientation-horizontal-mobile': orientationHorizontalMobile,
  });


  return (
    <div className={bannerClasses}>
      <div className="interior-design-banner__wrapper">
        <div className="interior-design-banner__title">
          { I18n.t('detailed.location.gallery.interior_design.title') }
        </div>

        <div className="interior-design-banner__content-wrapper">
          { !isFullScreenMobile &&
            <img className="interior-design-banner__img"
                 src={require('../../../src/images/plan-photo.svg')}
                 alt="Floor plan"
                 width={141}
                 height={120} />
          }
          <p className="interior-design-banner__paragraph">
            { isFullScreenMobile
              ? I18n.t('detailed.location.gallery.interior_design.paragraph_mobile')
              : I18n.t('detailed.location.gallery.interior_design.paragraph')
            }
          </p>
        </div>

        <div className="interior-design-banner__subtitle">
          { I18n.t('detailed.location.gallery.interior_design.subtitle') }
        </div>

        <div className="interior-design-banner__subparagraph">
          { I18n.t('detailed.location.gallery.interior_design.subparagraph') }
        </div>

        <a className="interior-design-banner__link-btn"
           href="https://www.lokalebasen.dk/indretningsforslag-af-kontor-for-lejer"
           target="_blank">
          <span>{ I18n.t('detailed.location.gallery.interior_design.button') }</span>
          <SvgIco name="keyboard-arrow-right-rounded-ios" size={20} />
        </a>
      </div>
    </div>
  )
}

export default InteriorDesignBanner;
