import React, { useContext, useMemo, useState, Suspense  } from 'react';
import PropTypes from "prop-types";
import { isMobileOnly } from "react-device-detect";

import { mappedMonths } from "../../helpers/danishMonths";

const InfoBadges = React.lazy(() => import('../../SharedComponents/InfoBadges'));
const FullscreenGallery = React.lazy(() => import('./FullscreenGallery'));
const MobileGallery = React.lazy(() => import('./MobileGallery'));
import { Context } from "../index";

import './ImageGalleryWrapper.sass';


const ImageGalleryWrapper = ({ isDefault, photos }) => {

  const {
    activeGallerySection,
    isScraped,
    location,
    locationClosed,
    locationRented,
    missingImagesUrl,
    section,
  } = useContext(Context);

  const [activeSlide, setActiveSlide] = useState(0);
  const [fullscreenIsOpen, setFullscreenIsOpen] = useState(false);

  const photosCount = useMemo(
    () => {
      let count = 0;
      photos.map(photo => photo.itemType === 'image' && (count = count + 1), 0)

      return count;
    },
    [photos],
  );


  const {
    anonymous,
    closedMonth,
    latitude,
    longitude,
    showNewBanner,
    specialOffer
  } = location;

  const closedMonthLiteral = closedMonth && mappedMonths(closedMonth);
  const showBadges = locationRented || isScraped || anonymous || showNewBanner || specialOffer;
  const isSaleLocation = section !== 'lease';
  const enableFullscreen = photos[0].itemType !== 'placeholder_image' && !locationRented;


  const handleOpenFullscreen = (isOpen, slideIndex) => {
    if (enableFullscreen) {
      isOpen
        ? document.body.classList.add('modal-open')
        : document.body.classList.remove('modal-open');

      setActiveSlide(isOpen && slideIndex ? slideIndex : 0)
      setFullscreenIsOpen(isOpen);
    };
  };


  const infoBadges =
    <Suspense fallback={''}>
      <InfoBadges
        anonymous={anonymous}
        closedMonth={closedMonthLiteral}
        crawledBottomText={I18n.t('generic.parsed_copyright')}
        isCrawled={isScraped}
        isRentedOut={locationRented || locationClosed}
        isNew={showNewBanner}
        missingImagesUrl={missingImagesUrl}
        rentedOutLabel={locationClosed ? I18n.t('lb_showroom.detailed.asset_box.location_not_available') : null}
        sale={isSaleLocation}
        specialOffer={specialOffer}
        wrapperClass="detail-view"
      />
    </Suspense>;


  const GalleryImage = ({ data, index, withBadges }) =>
    <div className="image-gallery__img-wrapper">
      <img
         alt={data.alt}
         className={`${locationRented ? 'rented-out' : ''} ${photos[0].itemType === 'placeholder_image' ? 'no-fullscreen' : ''}`}
         onClick={() => handleOpenFullscreen(true, index)}
         src={data.original}
      />

      { withBadges && showBadges && infoBadges }
    </div>;


  const gallery = React.useMemo(
    () =>
      !isMobileOnly
        ?
          <div className="row">
            <div className={`image-gallery__main-img-column col-md-${photosCount > 1 &&  photos[1].itemType === 'image' ? '6' : '12'}`}>
              <GalleryImage data={photos[0]} index={0} withBadges={true} />
            </div>

            { photosCount > 1 && !(photosCount === 2 && photos[1].itemType !== 'image') &&
              <div className="col-md-6">
                { photosCount > 2
                  ?
                    <div className="row image-gallery__right-images-row">
                      { photos.map((photo, index) => {
                        const columnsCount = photosCount === 3 || (photosCount === 4 && index === 3) ? 12 : 6;

                        if (index !== 0 && index <= 4 && photo.itemType === 'image') {
                          return (
                            <div className={`image-gallery__right-images-col col-md-${columnsCount}`} key={`image-slide-right-${index}`}>
                              <GalleryImage data={photo} index={index} />
                              { index === 4 && photosCount > 5 && !locationRented &&
                                <div className="image-gallery__more-photos-wrapper">
                                  + { photosCount - 5 } { I18n.t('lb_showroom.detailed.asset_box.new_photos_button') }
                                </div>
                              }
                            </div>
                          )
                        }
                      })}
                    </div>
                  :
                    <GalleryImage data={photos[1]} index={1} />
                }
              </div>
            }
          </div>
        :
          <div className={`image-gallery__main-img-column ${locationRented ? 'rented-out' : anonymous ? 'anonymous' : isScraped ? 'crawled' : ''}`}>
            <div className="image-gallery__mobile-fallback">
              <GalleryImage data={photos[0]} index={0} withBadges={true} />
            </div>

            { enableFullscreen &&
              <Suspense fallback={''}>
                <MobileGallery
                  activeSection={activeGallerySection}
                  data={photos}
                  isDefault={isDefault}
                  latitude={latitude}
                  longitude={longitude}
                  onClick={handleOpenFullscreen}
                />
              </Suspense>
            }

            { showBadges && infoBadges }
          </div>,
    [photos],
  );


  return (
    <div className="image-gallery">

      { fullscreenIsOpen &&
        <Suspense fallback={''}>
          <FullscreenGallery
            activeSlide={activeSlide}
            handleClose={() => handleOpenFullscreen(false)}
            latitude={latitude}
            longitude={longitude}
            photos={photos}
          />
        </Suspense>
      }

      { gallery }

    </div>
  )
};


export default ImageGalleryWrapper;


ImageGalleryWrapper.propTypes = {
  isDefault: PropTypes.bool,
  locationRented: PropTypes.bool,
  isScraped: PropTypes.bool,
  location: PropTypes.shape({
    anonymous: PropTypes.bool,
    showNewBanner: PropTypes.bool,
    specialOffer: PropTypes.bool,
  }),
  missingImagesUrl: PropTypes.string,
  orientationHorizontalMobile: PropTypes.bool,
  photos: PropTypes.object,
};


ImageGalleryWrapper.defaultProps = {
  isDefault: false,
  locationRented: false,
  isScraped: false,
  location: {
    anonymous: false,
    showNewBanner: false,
    specialOffer: false,
  },
  missingImagesUrl: '/',
  orientationHorizontalMobile: false,
  photos: [],
};
