import { gql } from "@apollo/client";

export const LOCATION_INFO_FIELDS = gql`
  fragment locationInfoFields on Location {
    address
    areaFrom
    areaTo
    currencyId
    enquiries
    enquiriesCountInteger
    explainLocationIncomplete
    floorPlans { thumbnailW600 }
    floorPlansCount
    infoMessage
    initialState
    isComplete
    latitude
    locationShownDatapoints
    locationUrl
    longitude
    monthlyRentPerSpaceWithCentsFrom
    monthlyRentPerSpaceWithCentsTo
    photos { thumbnailW600 }
    photosCount
    prospectusCount
    scoreInPercents
    scoreItemsList
    section
    sectionTranslated
    state
    stateInfo
    stateTranslated
    vimeoId
    virtualTour
    yearlyRentPerM2WithCentsFrom
    yearlyRentPerM2WithCentsTo
  }
`;
