import React, { Component, Fragment } from 'react';

import httpService from '../../api/httpService';
import { handleFavoriteLocationsCounter } from '../../utils/topBarHelper';
import { checkOrderedLocation } from '../helpers/checkOrderedLocation';
import { loadTawkTo } from "../TawkTo/TawkTo";
import { sendTrovitLead } from "../helpers/trovit_analitics";
import { SvgIco } from "../SharedComponents/Icons";
import SearchAgentBanner from "../SharedComponents/SearchAgentBanner";
import GetInfoFormModal from "../SharedComponents/GetInfoFormModal";
import FavoritesList from './FavoritesList';
import EmptyPage from './EmptyPage';
import StickyBar from "./StickyBar";

import './index.sass';


class FavoritesPage extends Component {
  state = {
    activeSection: 'lease',
    isLoading: true,
    selectButtonsOpen: false,
    deleteButtonsOpen: false,
    isActiveInfoForm: false,
    showServerErrorMessage: false,
    isFormLoading: false,
    submitSuccess: false,
    leaseLocationsIds: [],
    saleLocationsIds: [],
    leaseLocations: [],
    saleLocations: [],
    orderedLocations: [],
    leaseSelected: [],
    saleSelected: [],
  };


  constructor(props) {
    super(props);
    localStorage && localStorage.setItem('locale', props.locale);
  };


  static timestamp() {
    return { timestamp: new Date().toISOString() };
  };

  componentDidMount() {
    const { siteKey } = this.props;

    const leaseLocationsIds =
      localStorage && localStorage.getItem(`my.favourite_lease_${siteKey}`)
        ? JSON.parse(localStorage.getItem(`my.favourite_lease_${siteKey}`))
        : [];

    const saleLocationsIds =
      localStorage && localStorage.getItem(`my.favourite_sale_${siteKey}`)
        ? JSON.parse(localStorage.getItem(`my.favourite_sale_${siteKey}`))
        :[];

    (leaseLocationsIds.length || saleLocationsIds.length)
      ? this.setState({ activeSection: leaseLocationsIds.length ? 'lease' : 'sale', leaseLocationsIds, saleLocationsIds }, () => this.requestData())
      : this.setState({ isLoading: false });

    if(localStorage) {
      localStorage.getItem('my.ordered_locations')
        ? this.setState({ orderedLocations: checkOrderedLocation() })
        : localStorage.setItem('my.ordered_locations', JSON.stringify({}));
    }

    loadTawkTo(siteKey);
  };


  requestData = () => {
    const { activeSection } = this.state;
    const currentLocationsIds = this.state[`${activeSection}LocationsIds`];

    currentLocationsIds.length &&
      this.setState({ isLoading: true }, () =>
        httpService.getLocationsByIds(currentLocationsIds)
          .then(response =>
            this.setState({
              isLoading: false,
              [`${activeSection}Locations`]: response || [],
            }, () => {
              if (response.length !== currentLocationsIds.length) {
                const { activeSection } = this.state;
                const { siteKey } = this.props;
                const newFavouriteIds = response.map(location => `${location.id}`);

                localStorage && localStorage.setItem(`my.favourite_${activeSection}_${siteKey}`, JSON.stringify(newFavouriteIds));
                handleFavoriteLocationsCounter(siteKey);
                this.setState({ [`${activeSection}LocationsIds`]: newFavouriteIds },
                  () => {
                    if (!newFavouriteIds.length) {
                      const availableSection = activeSection === 'sale' ? 'lease' : 'sale';

                      this.state[`${availableSection}LocationsIds`].length && this.handleActiveSection(availableSection);
                    }
                  }
                );
              }
            })
          ));
  };


  handleActiveSection = (section) =>
    this.setState({ activeSection: section }, () => !this.state[`${section}Locations`].length && this.requestData());


  removeFromFavorite = () => {
    const { activeSection } = this.state;
    const { siteKey } = this.props;
    const retrievedFaves = localStorage && localStorage.getItem(`my.favourite_${activeSection}_${siteKey}`);
    const favouriteLocations = JSON.parse(retrievedFaves) || [];
    const selectedGroup = this.state[`${activeSection}Selected`];
    const updatedList = this.state[`${activeSection}Locations`].filter(location => !selectedGroup.includes(`${location.id}`));
    const updatedStorageList = favouriteLocations.length && favouriteLocations.filter(id => !selectedGroup.includes(id));

    localStorage && localStorage.setItem(`my.favourite_${activeSection}_${siteKey}`, JSON.stringify(updatedStorageList));
    handleFavoriteLocationsCounter(siteKey);

    this.setState({
      [`${activeSection}Locations`]: updatedList,
      [`${activeSection}LocationsIds`]: updatedStorageList,
      [`${activeSection}Selected`]: [],
    }, () => {
      if (!this.state[`${activeSection}LocationsIds`].length) {
        const availableSection = activeSection === 'sale' ? 'lease' : 'sale';

        this.state[`${availableSection}LocationsIds`].length && this.handleActiveSection(availableSection);
      }
    });
  };


  cleanAllFavoriteLocations = () => {
    const { activeSection } = this.state;
    const { siteKey } = this.props;
    const availableSection = activeSection === 'sale' ? 'lease' : 'sale';

    this.setState({
      [`${activeSection}Locations`]: [],
      [`${activeSection}LocationsIds`]: [],
    });

    localStorage && localStorage.setItem(`my.favourite_${activeSection}_${siteKey}`, JSON.stringify([]));
    this.handleActiveSection(availableSection);
    handleFavoriteLocationsCounter(siteKey);
  };


  setOrderedLocations = (orderedLocations) => this.setState({ orderedLocations: orderedLocations });


  handleSelect = (id) => {
    const { activeSection } = this.state;
    const updatedSelectedLocation = [ ...this.state[`${activeSection}Selected`] ];
    const selectedIndex = updatedSelectedLocation.indexOf(`${id}`);

    selectedIndex >= 0
      ? updatedSelectedLocation.splice(selectedIndex, 1)
      : updatedSelectedLocation.push(`${id}`);

    this.setState({ [`${activeSection}Selected`]: updatedSelectedLocation });
  };


  selectAll = () => {
    const { activeSection } = this.state;

    this.setState({ [`${activeSection}Selected`]: this.state[`${activeSection}LocationsIds`] });
  };


  deselectAll = () => this.setState({ [`${this.state.activeSection}Selected`]: [] });


  isSelected = (id) => this.state[`${this.state.activeSection}Selected`].includes(`${id}`);


  handleOpenButtonsDropdown = (buttonsGroup) => {
    const isOpen = this.state[`${buttonsGroup}ButtonsOpen`];

    this.setState({ [`${buttonsGroup}ButtonsOpen`]: !isOpen }, () => {
      this.state[`${buttonsGroup}ButtonsOpen`]
        ? window.addEventListener('click', (e) => this.handleClickOutside(e, buttonsGroup))
        : window.removeEventListener('click', (e) => this.handleClickOutside(e, buttonsGroup));
    });
  };


  handleClickOutside = (e, buttonsGroup) => {
    const buttonsWrapper = document.getElementById(`${buttonsGroup}Buttons`);

    buttonsWrapper && !buttonsWrapper.contains(e.target) && this.setState({ [`${buttonsGroup}ButtonsOpen`]: false });
  };


  multipleOrderSubmit = (params, orderUrl) => {
    const uuids = this.locationsAvailableForOrder().map(location => location.uuid);

    this.setState({ isFormLoading: true });

    httpService.orderInfoListViewForm(orderUrl, params, uuids)
      .then(response => {
        if (response) {
          const orderedLocations = JSON.parse(localStorage.getItem('my.ordered_locations'));

          uuids.map(uuid => orderedLocations[uuid] = [ this.constructor.timestamp() ]);

          const updateOrderedLocation = JSON.stringify(orderedLocations);

          localStorage.setItem('my.ordered_locations', updateOrderedLocation);

          sendTrovitLead(this.props.siteKey);

          this.setState({
            submitSuccess: true,
            showServerErrorMessage: false,
            isLoading: false,
          });

          this.setOrderedLocations(Object.keys(orderedLocations));
        } else {
          this.setState({
            submitSuccess: false,
            showServerErrorMessage: true,
            isLoading: false,
          });
        }
      });
  };


  handleOpenInfoForm = () =>
    this.setState({ isActiveInfoForm: true });


  handleCloseInfoForm = () =>
    this.setState({
      isActiveInfoForm: false,
      showServerErrorMessage: false,
      submitSuccess: false,
    });


  // showJeudanMessage = () =>
  //   !!this.locationsAvailableForOrder().find(location => location.isJeudan);


  locationsAvailableForOrder = () => {
    const { activeSection, orderedLocations } = this.state;
    const selectedLocationsIds = this.state[`${activeSection}Selected`];
    const favouriteLocationsList = this.state[`${activeSection}Locations`];
    const selectedLocationsList = [];

    favouriteLocationsList.map(location => {

      const { address, id, provider_name, shown_as_rented_out, temporarily_unavailable, url, uuid } = location;

      selectedLocationsIds.includes(`${location.id}`)
        && !orderedLocations.includes(uuid)
        && !shown_as_rented_out
        && !temporarily_unavailable
        && selectedLocationsList.push({
          address,
          id,
          url,
          uuid,
          // isJeudan: provider_name && provider_name.toLowerCase() === 'jeudan',
      });
    });

    return selectedLocationsList;
  };


  render() {
    const { currency, siteKey, newSearchAgentPath } = this.props;

    const {
      activeSection,
      deleteButtonsOpen,
      isActiveInfoForm,
      isFormLoading,
      isLoading,
      leaseLocationsIds,
      orderedLocations,
      saleLocationsIds,
      selectButtonsOpen,
      showServerErrorMessage,
      submitSuccess
    } = this.state;

    const locationsAvailableForOrder = this.locationsAvailableForOrder();
    const selectedLocationsCount = locationsAvailableForOrder.length;
    const locationsCount = leaseLocationsIds.length + saleLocationsIds.length;
    const showLocationsList = !!(leaseLocationsIds.length || saleLocationsIds.length);
    const favoritesList = this.state[`${activeSection}Locations`];
    const showSwitchers = !!(leaseLocationsIds.length && saleLocationsIds.length);


    return (
      <div className="favorites">

        <div className="favorites__header container">
          <div className="favorites__header-headline">
            <h1>{ I18n.t('generic.favorites') }</h1>
            { showLocationsList &&
              <p className="favorites-list__subtitle">
                { I18n.t('favorite_locations.subtitle', { locations_count: locationsCount }) }
              </p>
            }
          </div>

          { showLocationsList && <img alt="Favourites" src={require('../../src/images/icons/favorite_empty_page.svg')} /> }
        </div>

        { showLocationsList
          ?
            <Fragment>
              <StickyBar>
                <div className="favorites__action-bar container">
                  <div className={`favorites__get-info-button ${!selectedLocationsCount ? 'inactive' : ''}`} onClick={ this.handleOpenInfoForm }>
                    { I18n.t("apps.lb_showroom.detailed.order_form.get_information") + ` (${selectedLocationsCount})` }
                  </div>
                  <div className="favorites__action-bar-right">
                    <div id="selectButtons"
                         className="favorites__select-group"
                         onClick={() => this.handleOpenButtonsDropdown('select')}>
                      <SvgIco name="select-all" size={24} />
                      <div className={`favorites__action-buttons-wrapper favorites__action-buttons-wrapper--orange ${ selectButtonsOpen ? 'open' : '' }`}>
                        <div className="favorites__select-all-btn" onClick={this.selectAll}>
                          { I18n.t('apps.lb_showroom.postal_districts.select_all') }
                        </div>
                        <div className="favorites__deselect-all-btn" onClick={this.deselectAll}>
                          { I18n.t('apps.lb_showroom.postal_districts.deselect_all') }
                        </div>
                      </div>
                    </div>
                    <div id="deleteButtons"
                         className="favorites__delete-group"
                         onClick={() => this.handleOpenButtonsDropdown('delete')}>
                      <SvgIco name="trash" size={20} />
                      <div className={`favorites__action-buttons-wrapper favorites__action-buttons-wrapper--red ${ deleteButtonsOpen ? 'open' : '' }`}>
                        <div className="favorites__delete-selected-btn" onClick={this.removeFromFavorite}>
                          { I18n.t('generic.delete_selected') }
                        </div>
                        <div className="favorites__delete-all-btn" onClick={this.cleanAllFavoriteLocations}>
                          { I18n.t('generic.delete_all') }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </StickyBar>

              <FavoritesList
                activeSection={activeSection}
                currency={currency}
                favoritesList={favoritesList}
                isLoading={isLoading}
                orderedLocations={orderedLocations}
                showSwitchers={showSwitchers}
                siteKey={siteKey}
                handleActiveSection={this.handleActiveSection}
                handleSelect={this.handleSelect}
                isSelected={this.isSelected}
                removeFromFavorite={this.removeFromFavorite}
                setOrderedLocations={this.setOrderedLocations} />

              <div className="favorites__search-agent-wrapper">
                <SearchAgentBanner
                  newSearchAgentPath={newSearchAgentPath}
                  searchAgentText={I18n.t('lb_showroom.detailed.search_agent.description')}
                  searchAgentHeadline={I18n.t('lb_showroom.detailed.search_agent.headline')}
                  siteKey={siteKey}
                />
              </div>

              <div id="getInfoFormFavoriteWrapperMulti">
                { isActiveInfoForm &&
                  <GetInfoFormModal
                    gtmClass="getInfoFormFavorite"
                    handleClose={this.handleCloseInfoForm}
                    // isJeudan={this.showJeudanMessage()}
                    isLoading={isFormLoading}
                    isMultiple={true}
                    locations={locationsAvailableForOrder}
                    orderUrl={'/lease/api/orders'}
                    parkingCheckbox={false}
                    showServerErrorMessage={showServerErrorMessage}
                    siteKey={siteKey}
                    submitHandler={this.multipleOrderSubmit}
                    submitSuccess={submitSuccess}
                  />
                }
              </div>
            </Fragment>
          :
            !isLoading && <EmptyPage />
        }
      </div>
    )
  }
}

export default FavoritesPage;
