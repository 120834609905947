import React, { Suspense, useState, useContext } from 'react';

const Contacts = React.lazy(() => import('../Contacts'));
import { Context } from "../index";
import Description from './Description';

import './index.sass';


const DescriptionSection = () => {

  const { locationContactDetails, tabs: { originalDescription } } = useContext(Context);

  const [translatedDescription, setTranslatedDescription] = useState('');

  const showContactsSection = locationContactDetails.available || (!locationContactDetails.available && locationContactDetails.provider.partner);


  return (
    <div className="row">
      <div className="col-12 col-md-7 col-lg-12">
        <div className="dv-info-section-wrapper dv-info-section-wrapper--description" id="description">
          <div className="dv-info-section__container">

            { originalDescription &&
              <div className="section">
                <Description
                  activeTranslatedDescription={translatedDescription}
                  setTranslatedDescription={setTranslatedDescription}
                />
              </div>
            }

            { showContactsSection &&
              <Suspense fallback={''}>
                <Contacts />
              </Suspense>
            }
          </div>
        </div>
      </div>
    </div>
  );
};


export default React.memo(DescriptionSection);
