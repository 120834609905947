import React, { Fragment, useEffect, Suspense } from 'react';

import { Portal } from "../../SharedComponents/hooks";
import { SvgIco } from "../../SharedComponents/Icons";

const Landscape = React.lazy(() => import('./Landscape'));
const InteriorDesignBanner = React.lazy(() => import('./InteriorDesignBanner'));

import './FullscreenGallery.sass';


const FullscreenGallery = ({ activeSlide, handleClose, latitude, longitude, photos }) => {

  useEffect(() => {
    setTimeout(() => {
      if (activeSlide !== 0) {
        const target = document.getElementById('fullscreen-scroll-target');
        const wrapper = document.getElementById('fullscreen-wrapper');

        if (target) {
          const targetTop = target.getBoundingClientRect().top;
          const targetCoords = Math.floor(targetTop + window.scrollY - 80);

          wrapper.scroll(0, targetCoords);
        };
      }
    }, 600);
  }, []);


  return (
    <Portal>
      <div className="fullscreen-gallery">
        <div className="fullscreen-gallery__wrapper" id="fullscreen-wrapper">
          <div className="fullscreen-gallery__container">
            <div className="fullscreen-gallery__close-btn" onClick={handleClose}>
              <SvgIco name="close" size={32} />
            </div>

            { photos.map((photo, index) =>

              <div className="fullscreen-gallery__img-wrapper">
                { photo.itemType === 'image'
                  ?
                    <Fragment>
                      { index === activeSlide && <div id="fullscreen-scroll-target" /> }
                      <img
                        src={photo.originalFull}
                        alt={photo.alt}
                        className="lazyload"
                        data-sizes="auto"
                      />
                    </Fragment>

                  :
                    photo.itemType === 'interior-design-banner'
                  ?
                    <Suspense fallback={<div className="skeleton-box" />}>
                      <InteriorDesignBanner isFullScreen={true} orientationHorizontalMobile={false} />
                    </Suspense>
                  :
                    photo.itemType === 'page'
                  ?
                    <Suspense fallback={<div className="skeleton-box" />}>
                      <Landscape latitude={latitude} longitude={longitude}/>
                    </Suspense>
                  : null
                }
              </div>

            )}
          </div>
        </div>
      </div>
    </Portal>
  )
}

export default FullscreenGallery;
