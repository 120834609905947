import React, { useEffect, useRef, useState } from 'react';
import { Marker } from '@react-google-maps/api';

import pin from "../../../src/images/list_view_pin.svg";
import pinHover from "../../../src/images/list_view_pin_hover.svg";


const MapMarker = ({ activePlaceId,
                     clusterer,
                     handleActiveMarker,
                     hoveredLocationId,
                     location,
                     noClustererRedraw
                  }) => {

  const [hovered, setHovered] = useState(null);
  const markerRef = useRef();

  const { id, lat, lng }= location;
  const isActive = activePlaceId && activePlaceId === id;


  useEffect(() => {
    if (hoveredLocationId && hoveredLocationId === id) {
      !hovered && setHovered(true);
    } else {
      hovered && setHovered(false);
    }
  }, [hoveredLocationId]);


  return (
    <Marker
      clusterer={clusterer}
      key={id}
      noClustererRedraw={noClustererRedraw}
      // noClustererRedraw={true}
      onClick={() => handleActiveMarker(id, { lat, lng }, location.ids)}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      options={{ zIndex: hovered || isActive ? 1000 : null, metadata: { id }}}
      position={location}
      ref={markerRef}
      icon={
        { url: hovered || isActive ? pinHover : pin,
          scaledSize: { width: 50, height: 71 },
          anchor: { x: 20, y: 45 },
        }
      }
    />
  )
};

export default MapMarker;
