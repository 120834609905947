import React, { useEffect } from 'react';


export const InteriorDesignForm = () => {

  useEffect(() => {
    (function() {
      let js, q,
          d = document,
          gi = d.getElementById,
          ce = d.createElement,
          gt = d.getElementsByTagName,
          id = "type_form_share",
          b = "https://embed.typeform.com/";

      if (!gi.call(d, id)) {
        js = ce.call(d, "script");
        js.id = id;
        js.src = b+ "embed.js";
        q = gt.call(d, "script")[0];
        q.parentNode.insertBefore(js,q);
      }
    })();
  }, []);


  return (
    <div className="">
      <a className="input-banner__buy-button typeform-share button" href="https://form.typeform.com/to/DwdDoFML?typeform-medium=embed-snippet"
         data-mode="popup"
         data-size="70" target="_blank">
        { I18n.t('generic.buy_now') }
      </a>
    </div>
  )
}

export default InteriorDesignForm;
