import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Marker, InfoWindow } from 'react-google-maps';
import { isMobileOnly } from 'react-device-detect';

import pin from '../../src/images/listview-pin-png.png';
import hoverPin from '../../src/images/hover-listview-pin-png.png';


class MarkerWrapper extends Component {

  getPixelCoords = (lat, lng) => {
    const { map } = this.props;
    const projection = map.getProjection(),
          topRight = projection.fromLatLngToPoint(map.getBounds().getNorthEast()),
          bottomLeft = projection.fromLatLngToPoint(map.getBounds().getSouthWest()),
          scale = Math.pow(2, map.getZoom());

    // Create our point.
    const point = projection.fromLatLngToPoint(new google.maps.LatLng(lat,lng));

    // Get the x/y based on the scale.
    const x = Math.floor((point.x - bottomLeft.x) * scale);
    const y = Math.floor((point.y - topRight.y) * scale);

    return { x, y };
  }

  setMarkerOffset = () => {
    const { lat, lng, map } = this.props;

    const windowWidth = Math.floor(window.innerWidth),
          windowHeight = Math.floor(window.innerHeight) - 70,
          topMapOffset = 70,  // the distances are different as we have an optional map controls on the top and right parts
          rightMapOffset = 70,
          bottomMapOffset = 30,
          leftMapOffset = 30,
          infoWindowHeight = 342,
          infoWindowWidth = 270,
          topInfoWindowOffset = 23,
          centerPoint = this.getPixelCoords(map.getCenter().lat(), map.getCenter().lng()),
          markerPoint = this.getPixelCoords(lat, lng);

    // these are the offsets from central point of the marker to InfoWindow
    // including inner distance inside the image (and 8px padding from img for top and bottom)
    const offsetCoords = {
      top: markerPoint.y - 15 - 8,
      right: markerPoint.x + 20,
      bottom: markerPoint.y + 25,
      left: markerPoint.x - 20 - 8,
    };

    // TODO: make it count dynamically
    const infoWindowCenterOffsets = {
      offsetTop: { x: 0, y: 2 },
      offsetRight: { x: 155, y: 201 },
      offsetBottom: { x: 0, y: 400 },
      offsetLeft: { x: -155, y: 201 },
    };

    const { top, right, bottom, left } = offsetCoords;
    const { offsetTop, offsetRight, offsetBottom, offsetLeft } = infoWindowCenterOffsets;

    const fitTop = (offsetHeight) => top - topMapOffset - offsetHeight > 0,
          fitBottom = (offsetHeight) => bottom + offsetHeight < windowHeight - bottomMapOffset,
          fitLeft = (offsetWidth) => left - leftMapOffset - offsetWidth > 0,
          fitRight = (offsetWidth) => right + offsetWidth < windowWidth - rightMapOffset,
          fitsLeftRight = (offsetWidth) => fitRight(offsetWidth) && fitLeft(offsetWidth),
          fitsTopBottom = (offsetHeight) => fitTop(offsetHeight) && fitBottom(offsetHeight),
          fitCenterTop = () => fitTop(infoWindowHeight) && fitsLeftRight(infoWindowWidth / 2),
          fitCenterBottom = () => fitBottom(infoWindowHeight) && fitsLeftRight(infoWindowWidth / 2),
          fitCenterLeft = () => fitLeft(infoWindowWidth) && fitsTopBottom(infoWindowHeight / 2),
          fitCenterRight = () => fitRight(infoWindowWidth) && fitsTopBottom(infoWindowHeight / 2),
          setInfoWindowOffset = (offset) => new google.maps.Size(offset.x, offset.y);

    // In case we dont fit the center position of the InfoWindow against marker pin
    const recalculateOffset = (quadrant) => {
      const topDistance = markerPoint.y - topMapOffset,
            bottomDistance = windowHeight - bottomMapOffset - markerPoint.y;

      switch (quadrant) {
        case "tr":
          return { x: offsetLeft.x, y: infoWindowHeight - topDistance + topInfoWindowOffset };
        case "tl":
          return { x: offsetRight.x, y: infoWindowHeight - topDistance + topInfoWindowOffset };
        case 'br':
          return { x: offsetLeft.x, y: bottomDistance };
        case 'bl':
          return { x: offsetRight.x, y: bottomDistance };
      }
    }

    // The quadrant in witch the we have marker
    let quadrant = "";

    quadrant += (markerPoint.y > centerPoint.y) ? "b" : "t";
    quadrant += (markerPoint.x < centerPoint.x) ? "l" : "r";

    const offset = () => {
      let offsetCoords;

      switch (quadrant) {
        case 'tr':
          offsetCoords = fitCenterTop() ? offsetTop
                       : fitCenterBottom() ? offsetBottom
                       : fitCenterLeft() ? offsetLeft
                       : recalculateOffset('tr');
          break;
        case 'tl':
          offsetCoords = fitCenterTop() ? offsetTop
                       : fitCenterBottom() ? offsetBottom
                       : fitCenterRight() ? offsetRight
                       : recalculateOffset('tl');
          break;
        case "br":
          offsetCoords = fitCenterTop() ? offsetTop
                       : fitCenterBottom() ? offsetBottom
                       : fitCenterLeft() ? offsetLeft
                       : recalculateOffset('br');
          break;
        case "bl":
          offsetCoords = fitCenterTop() ? offsetTop
                       : fitCenterBottom() ? offsetBottom
                       : fitCenterRight() ? offsetRight
                       : recalculateOffset('bl');
      }

      return setInfoWindowOffset(offsetCoords);
    }

    return offset();
  }

  render() {

    const { isActive, lat, lng, children, onInfoToggle, locationId } = this.props;
    const pixelOffset = !isMobileOnly && isActive && this.setMarkerOffset();

    const getInfo = () => isActive
                          ? <InfoWindow options={ {disableAutoPan: true, pixelOffset } }>
                              <div>{ children }</div>
                            </InfoWindow>
                          : null;

    const toggleInfo = () => onInfoToggle(locationId, { lat: lat, lng: lng });

    return (
      <Marker
        ref={(marker) => { this.marker = marker; }}
        noRedraw={true}
        position={{ lat, lng }}
        onClick={toggleInfo}
        icon={ isActive
               ? {url: hoverPin, scaledSize: { width: 28, height: 40}, anchor: { x: 15, y: 15 }}
               : {url: pin, scaledSize: { width: 28, height: 40}, anchor: { x: 15, y: 15 }} }
      >
        { getInfo() }
      </Marker>
    )
  }
}

MarkerWrapper.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  children: PropTypes.node,
  isActive: PropTypes.bool,
  locationId: PropTypes.number,
  colsY: PropTypes.number,
  rowX: PropTypes.number,
  onInfoToggle: PropTypes.func,
};

MarkerWrapper.defaultProps = {
  lat: 0,
  lng: 0,
  children: null,
  isActive: false,
  locationId: 0,
  colsY: 0,
  rowX: 0,
  onInfoToggle: () => true,
};


export default MarkerWrapper;
