import React, { Fragment, useContext, useState } from "react";
import ReactTooltip from "react-tooltip";
import Truncate from 'react-truncate';
import classNames from 'classnames';
import PropTypes from "prop-types";

import { toUnderscore } from "../../../utils/helpers";
import { SvgIco } from '../../SharedComponents/Icons';
import SmartTextTooltip from "../SmartTextTooltip";
import { Context } from "../index";
import SectionRow from './SectionRow';

import './index.sass';


const EconomySection = () => {

  const {
    areaUnit,
    contactOrdered,
    facts,
    isRequestFromPrerender,
    kind,
    linkToDisposalPriceInfo,
    locationClosed,
    rentedOut,
    scrollToForm,
    section,
    setModalFormOpen,
    siteKey,
    switchSelectedOrderForm,
    tabs,
    temporarilyUnavailable,
  } = useContext(Context);

  const [economyText, setEconomyText] = useState(false);
  const [openAnnualPrice, setOpenAnnualPrice] = useState(true);
  const [openPricePerSquareMeter, setOpenPricePerSquareMeter] = useState(true);
  const [openPricePerHour, setOpenPricePerHour] = useState(true);

  const { economy, isRentIncludeOperationalCost, smartText } = tabs;
  const { disposalPrice, pricePerHour, pricesPerM2, pricesPerYear } = economy;
  const { price } = facts;
  const smartTextEconomy = smartText.economy;
  const pricePerM2Present = pricesPerM2 && Object.keys(pricesPerM2).length > 0;
  const pricesPerYearPresent = pricesPerYear && Object.keys(pricesPerYear).length > 0;
  const textLines = isMobile ? 4 : 2;
  const isMobile = window.innerWidth <= 574;
  const isDisposalRender = parseInt(disposalPrice) > 0 && (siteKey === 'dk' || siteKey === 'global');
  const showPricePerHour = kind === 'meeting_room' && !pricePerM2Present && !pricesPerYearPresent;
  const i18nPricePerHour = I18n.t(`providers.locations.form.rent_price_per_hour`);
  const i18nAnnualPrices = I18n.t('apps.lb_showroom.detailed.economy.annual_prices');
  const rightColumnClasses = 'dv-info-section__year-price-table col-lg-6 col-md-6 order-lg-3 order-md-3 order-3';
  const leftColumnClasses = classNames('dv-info-section__year-price-table col-lg-6 col-md-6',
    { 'disposal-true': isDisposalRender,
      'order-lg-1 order-md-2 order-2': isMobile,
    });
  const leftColumnTitle = section !== "lease"
    ? I18n.t('apps.lb_showroom.detailed.economy.prices_for_sale')
    : kind === 'parking'
      ? I18n.t('parking.parking_lots.parking_interval_row.monthly_rent_per_space')
      : I18n.t('apps.lb_showroom.detailed.economy.annual_prices_' + areaUnit);


  const askProvider = () => {
    if (isMobile && !rentedOut && !temporarilyUnavailable && !locationClosed) {
      setModalFormOpen(true);
    } else {
      const formWrapper = document.getElementById('order-form-wrapper');

      switchSelectedOrderForm('provider_message');
      scrollToForm('order-form-target');
      formWrapper.classList.remove('highlight');
      setTimeout(() => formWrapper.classList.add('highlight'), 500);
    }
  };


  const askProviderLink =
    <div className="dv-info-section__ask-provider-link" onClick={!contactOrdered ? askProvider : null}>
      { contactOrdered
        ? I18n.t('apps.lb_showroom.detailed.order_form.ask_question_sent_economy')
        : I18n.t('generic.not_provided_new')
      }
    </div>;


  const rightColumnContent = pricesPerYearPresent &&
    Object.keys(pricesPerYear).map((item, index) =>
      <div key={index} className="dv-info-section__table-row dv-info-section__label dv-info-section__label--full-width">
        <div className="dv-info-section__table-col dv-info-section__table-col--left">
          { item === 'annualRent' && isRentIncludeOperationalCost
            ? I18n.t('apps.lb_showroom.detailed.economy.annual_prices_m2_including_operational')
            : I18n.t(`apps.lb_showroom.detailed.economy.${ toUnderscore(item) }`)
          }
        </div>
        <div className="dv-info-section__table-col dv-info-section__table-col--right">
          { pricesPerYear[item] || (!rentedOut && !temporarilyUnavailable && !locationClosed && askProviderLink) || null }
        </div>
      </div>
    );


  const leftColumnContent = pricePerM2Present ?
    Object.keys(pricesPerM2).map((item, index) =>
      <div className="dv-info-section__label dv-info-section__label--full-width" key={index}>
        {kind === "parking"
          ?
            <Fragment>
              <div>{ I18n.t('parking.parking_lots.parking_interval_row.monthly_rent_per_space') }</div>
              <div>{price.value}</div>
            </Fragment>
          :
            <Fragment>
              <div>
                { item === 'pricePerYear' && isRentIncludeOperationalCost
                  ? I18n.t('apps.lb_showroom.detailed.economy.annual_prices_m2_including_operational')
                  : I18n.t(`apps.lb_showroom.detailed.economy.${ toUnderscore(item) }_new`)
                }
              </div>
              { pricesPerM2[item] ? <div>{ pricesPerM2[item] }</div> : askProviderLink }
            </Fragment>
        }
      </div>
    ) :
      <div className="dv-info-section__label dv-info-section__label--full-width">
        <div>{ I18n.t('parking.parking_lots.parking_interval_row.monthly_rent_per_space') }</div>
        <div>{ price.value }</div>
      </div>;


  const pricePerHourContent = showPricePerHour &&
    <div className="dv-info-section__label dv-info-section__label--full-width">
      <div>{ i18nPricePerHour }</div>
      { pricePerHour ? <div>{ pricePerHour }</div> : askProviderLink }
    </div>;


  return (
    <div className="dv-info-section-wrapper dv-info-section-wrapper--economy" id="economy">
      <h2 className="dv-info-section__title">
        { I18n.t('apps.lb_showroom.detailed.tabs.economy') }
      </h2>

      { smartTextEconomy && <SmartTextTooltip id="economy" /> }

      { economyText || isRequestFromPrerender ?
          <Fragment>
            <p className="smart-text-extended">{ smartTextEconomy }</p>
            <div className="dv-info-section__read-more-smart-text">
              <a onClick={() => setEconomyText(prevState => !prevState)}>
                { I18n.t('apps.lb_showroom.detailed.description.hide_more_link') }
                <SvgIco name="expand-less" size={15} />
              </a>
            </div>
          </Fragment>
        :
          <Fragment>
            { smartTextEconomy &&
              <div className="smart-text">
                <Truncate
                  lines={ textLines }
                  ellipsis={ <Fragment><span>{textLines !== 0 && '... '}</span>
                    <div className="dv-info-section__read-more-smart-text">
                    <a onClick={() => setEconomyText(prevState => !prevState)}>
                      { I18n.t('generic.read_more') }
                      <SvgIco name="expand-more" size={15} />
                    </a>
                  </div></Fragment> }
                  children={ smartTextEconomy }
                />
              </div>
            }
          </Fragment>
      }

      <div className="dv-info-section__content row">

        { showPricePerHour &&
          <SectionRow
            activeKey="0"
            columnWrapperClass="left-column-wrapper"
            content={pricePerHourContent}
            handleClick={() => setOpenPricePerHour(prevState => !prevState)}
            isOpen={openPricePerHour}
            sectionClasses={leftColumnClasses}
            sectionHeadline={i18nPricePerHour}
          />
        }

        { (pricePerM2Present || kind === "parking") &&
          <SectionRow
            activeKey="1"
            columnWrapperClass="left-column-wrapper"
            content={leftColumnContent}
            handleClick={() => setOpenPricePerSquareMeter(prevState => !prevState)}
            isOpen={openPricePerSquareMeter}
            sectionClasses={leftColumnClasses}
            sectionHeadline={leftColumnTitle}
          />
        }

        { isDisposalRender &&
          <div className="dv-info-section__section-wrapper dv-info-section__section-wrapper--disposal col-lg-6 col-md-6 order-lg-2 order-md-1 order-1">
            <h3 className="dv-info-section__header disposal-header">
              <div className="dv-info-section__info-wrapper">
                <SvgIco name="disposal" size={22} />
                { I18n.t('apps.lb_showroom.detailed.economy.disposal') }
              </div>
              <a className="dv-info-section__info"
                 href={linkToDisposalPriceInfo}
                 data-tip={I18n.t('apps.lb_showroom.detailed.economy.info_tooltip')}
                 data-class="dv-info-section__info-tooltip"
                 target="_blank">
                 <SvgIco name="info_outline" size={22} />
              </a>
              <ReactTooltip place='bottom' effect='solid' />
            </h3>
            <div className="disposal-column-wrapper">
              <div className="dv-info-section__label dv-info-section__label--full-width">
                <div>{ I18n.t('apps.lb_showroom.detailed.economy.consideration_received') }</div>
                <div>{ disposalPrice }</div>
              </div>
            </div>
          </div>
        }

        { pricesPerYearPresent && section === "lease" && kind !== "parking" &&
          <SectionRow
            activeKey="0"
            columnWrapperClass="right-column-wrapper"
            content={rightColumnContent}
            handleClick={() => setOpenAnnualPrice(prevState => !prevState)}
            isOpen={openAnnualPrice}
            sectionClasses={rightColumnClasses}
            sectionHeadline={i18nAnnualPrices}
          />
        }
      </div>
    </div>
  )
};


export default EconomySection;


EconomySection.propTypes = {
  price: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  area: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  location: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  tabs: PropTypes.shape({
    facility: PropTypes.shape({
      facilities: PropTypes.array,
    }),
    economy: PropTypes.shape({
      disposalPrice: PropTypes.string,
    }),
    smartText: PropTypes.shape({
      factAndFacilities: PropTypes.string,
    }),
    transport: PropTypes.shape({
      distances: PropTypes.array,
      postalDistrict: PropTypes.shape({
        postalName: PropTypes.string,
      }),
    }),
  }),
  facts: PropTypes.shape({
    area: PropTypes.shape({
      icon: PropTypes.string,
    }),
    price: PropTypes.object,
  }),
};


EconomySection.defaultProps = {
  tabs: {
    facility: {
      facilities: [],
    },
    economy: {
      disposalPrice: '0 kr.',
    },
    smartText: {
      factAndFacilities: '',
    },
    transport: {
      distances: [],
      postalDistrict: {
        postalName: '',
      },
    },
  },
  facts: {
    area: {
      icon: '',
      label: '',
      value: '',
      unit: '',
    },
    price: {
      icon: '',
      label: '',
      value: '',
      unit: null,
    },
    economy: {},
  },
};
