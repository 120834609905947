import basic from '../../src/images/list_office/basic.svg'
import basic_plus from '../../src/images/list_office/basic_plus.svg'
import premium from '../../src/images/list_office/premium.svg'
import premiumPlus from '../../src/images/list_office/premium_plus.svg'

const dkFullLinksList = [
  'signup.teaser.tabs.tab.block.linklist_1',
  'signup.teaser.tabs.tab.block.linklist_2',
  'signup.teaser.tabs.tab.block.linklist_3',
  'signup.teaser.tabs.tab.block.linklist_4_new',
  'signup.teaser.tabs.tab.block.linklist_6',
  'signup.teaser.tabs.tab.block.linklist_14',
  'signup.teaser.tabs.tab.block.linklist_7',
  'signup.teaser.tabs.tab.block.linklist_8',
  'signup.teaser.tabs.tab.block.linklist_9',
  'signup.teaser.tabs.tab.block.linklist_10',
  'signup.teaser.tabs.tab.block.linklist_11_new',
  { icon: 'facebook', text: 'signup.teaser.tabs.tab.block.linklist_19'},
  'signup.teaser.tabs.tab.block.linklist_12_new',
  'signup.teaser.tabs.tab.block.linklist_13',
  'signup.teaser.tabs.tab.block.linklist_15',
  'signup.teaser.tabs.tab.block.linklist_16',
  'signup.teaser.tabs.tab.block.linklist_17',
  'signup.teaser.tabs.tab.block.linklist_18'
];


// DK
export const tabData = {

  basicNoCure: {
    bottomText: 'signup.teaser.prices.block.footer_1',
    headerImage: basic,
    headerText: {
      subhead: 'signup.teaser.tabs.tab.main-header-basic-no-cure-no-pay',
      subhead_text: "signup.teaser.tabs.tab.right",
      subhead_text_dk_annouce: "signup.teaser.tabs.tab.left"
    },
    headerTextLease: {
      subhead: 'signup.teaser.tabs.tab.main-header-no-cure-no-pay',
      subhead_text: "signup.teaser.tabs.tab.right",
      subhead_text_dk_annouce: "signup.teaser.tabs.tab.left"
    },
    linksLease: [
      'signup.teaser.tabs.tab.block.great',
      'signup.teaser.tabs.tab.block.general_2',
      'signup.teaser.tabs.tab.block.no_cure_no_pay.list_1',
      'signup.teaser.tabs.tab.block.no_cure_no_pay.list_2',
    ],
    linksLeaseDk: dkFullLinksList.slice(0, 6),
    linksPayPerShow: dkFullLinksList.slice(0, 6),
  },

  basicNoCurePlus: {
    bottomText: 'signup.teaser.prices.block.footer_1',
    headerImage: basic_plus,
    headerText: {
      head: 'signup.teaser.tabs.tab.main-header-no-cure-no-pay',
      subhead: 'signup.teaser.tabs.tab.basic-plus-header',
      subhead_text: "signup.teaser.tabs.tab.right",
      subhead_text_dk_annouce: "signup.teaser.tabs.tab.left"
    },
    linksPayPerShow: dkFullLinksList.slice(0, 10),
  },

  noCurePremium: {
    bottomText: 'signup.teaser.prices.block.min_subscription_duration',
    headerImage: premium,
    headerText: {
      head: 'signup.teaser.tabs.tab.main-header-no-cure-no-pay',
      subhead: 'signup.teaser.tabs.tab.premium',
      subhead_text: "signup.teaser.tabs.tab.right",
      subhead_text_dk_annouce: "signup.teaser.tabs.tab.left"
    },
    linksPayPerShow: dkFullLinksList.slice(0, 13),
  },

  premiumPlus: {
    bottomText: 'signup.teaser.prices.block.min_subscription_duration',
    headerImage: premiumPlus,
    headerText: {
      head: 'signup.teaser.tabs.tab.main-header-no-cure-no-pay',
      subhead: 'signup.teaser.tabs.tab.premium-plus',
      subhead_text: "signup.teaser.tabs.tab.right",
      subhead_text_dk_annouce: "signup.teaser.tabs.tab.left"
    },
    linksPayPerShow: dkFullLinksList,
  },

  noCureEnterprice: {
    bottomText: null,
    headerText: {
      subhead: 'signup.teaser.tabs.tab.enterprice',
      subhead_text: "signup.teaser.tabs.tab.right",
      subhead_text_dk_annouce: "signup.teaser.tabs.tab.left"
    },
    linksLease: [
      'signup.teaser.tabs.tab.block.contact_us.list_1',
      'signup.teaser.tabs.tab.block.contact_us.list_2',
    ],
    linksLeaseDk: [
      'signup.teaser.tabs.tab.block.linklist_enterprice_1',
      'signup.teaser.tabs.tab.block.linklist_enterprice_2',
      'signup.teaser.tabs.tab.block.linklist_enterprice_3',
      'signup.teaser.tabs.tab.block.linklist_enterprice_4',
      'signup.teaser.tabs.tab.block.linklist_14',
    ],
    linksPayPerShow: [
      'signup.teaser.tabs.tab.block.linklist_enterprice_1',
      'signup.teaser.tabs.tab.block.linklist_enterprice_2',
      'signup.teaser.tabs.tab.block.linklist_enterprice_3',
      'signup.teaser.tabs.tab.block.linklist_enterprice_4',
      'signup.teaser.tabs.tab.block.linklist_14',
    ],
  }
};


// Other sites
export const tabDataBot = {
  basic: {
    bottomText: 'signup.teaser.prices.block.footer_1',
    headerText: {
      subhead: 'signup.teaser.tabs.tab.basic-header',
      subhead_text: "signup.teaser.tabs.tab.block.free_price",
    },
    linksLease: [
      'signup.teaser.tabs.tab.block.greater',
      'signup.teaser.tabs.tab.block.general_2',
      'signup.teaser.tabs.tab.block.basic.list_1',
      'signup.teaser.prices.block.list_1',
      'signup.teaser.tabs.tab.block.basic_plus.list_2',
      'signup.teaser.tabs.tab.block.basic_plus.list_3',
      'signup.teaser.tabs.tab.block.general_website-link',
      'signup.teaser.tabs.tab.block.general_contact-person-photo',
    ],
    linksLeaseDk: [
      'signup.teaser.tabs.tab.block.greater',
      'signup.teaser.tabs.tab.block.general_2',
      'signup.teaser.tabs.tab.block.basic.list_1',
      'signup.teaser.prices.block.list_1',
      'signup.teaser.tabs.tab.block.basic_plus.list_2',
      'signup.teaser.tabs.tab.block.basic_plus.list_3',
      'signup.teaser.tabs.tab.block.general_website-link',
      'signup.teaser.tabs.tab.block.general_contact-person-photo',
      'signup.teaser.tabs.tab.block.linklist_14',
    ],
  },

  premium: {
    bottomText: 'signup.teaser.prices.block.footer_4',
    headerText: {
      subhead: 'signup.teaser.tabs.tab.premium',
      subhead_text: "signup.new_form.plans.block1.meeting_pricer.title",
    },
    linksLease: [
      'signup.teaser.tabs.tab.block.greatest',
      'signup.teaser.tabs.tab.block.general_2',
      'signup.teaser.tabs.tab.block.basic.list_1',
      'signup.teaser.prices.block.list_1',
      'signup.teaser.tabs.tab.block.basic_plus.list_2',
      'signup.teaser.tabs.tab.block.basic_plus.list_3',
      'signup.teaser.tabs.tab.block.general_website-link',
      'signup.teaser.tabs.tab.block.general_contact-person-photo',
      'signup.teaser.tabs.tab.block.premium.list_4',
      'signup.teaser.tabs.tab.block.premium.list_5',
      'signup.teaser.prices.block.list_2_3_new'
    ],
    linksLeaseDk: [
      'signup.teaser.tabs.tab.block.greatest',
      'signup.teaser.tabs.tab.block.general_2',
      'signup.teaser.tabs.tab.block.basic.list_1',
      'signup.teaser.prices.block.list_1',
      'signup.teaser.tabs.tab.block.no_cure_no_pay_plus.list_3',
      'signup.teaser.tabs.tab.block.basic_plus.list_3',
      'signup.teaser.tabs.tab.block.general_website-link',
      'signup.teaser.tabs.tab.block.general_contact-person-photo',
      'signup.teaser.tabs.tab.block.linklist_14',
      'signup.teaser.tabs.tab.block.premium.list_5',
      'signup.teaser.prices.block.list_2_3_new'
    ],
  },

  parkering: {
    bottomText: 'signup.teaser.prices.block.footer_1',
    headerText: {
      subhead: 'signup.signup_form.parking_basic_parkering',
      subhead_text: 'signup.teaser.tabs.tab.left',
    },
    linksLease: [
      'signup.teaser.tabs.tab.block.greater',
      'signup.teaser.tabs.tab.block.general_2',
      'signup.teaser.tabs.tab.block.basic.list_1',
      'signup.teaser.tabs.tab.block.parkering_basic_list_4',
      'signup.teaser.tabs.tab.block.basic_plus.list_2',
      'signup.teaser.tabs.tab.block.basic_plus.list_3',
      'signup.teaser.tabs.tab.block.general_website-link',
      'signup.teaser.tabs.tab.block.general_contact-person-photo',
      'signup.teaser.tabs.tab.block.linklist_14',
    ],
  }
};

export const danishInfoFullPriceList = [
    {
      'key': '',
      'area': 'Lejemål | m'
    },
    {
      'key': '0..249',
      'area': '0 - 249 m'
    },
    {
      'key': '250..499',
      'area': '250 - 499 m'
    },
    {
      'key': '500..999',
      'area': '500 - 999 m'
    },
    {
      'key': '1000..1999',
      'area': '1.000 - 1.999 m'
    },
    {
      'key': '2000..9999',
      'area': '2.000 - 9.999 m'
    },
    {
      'key': 'default',
      'area': 'Over 9.999 m'
    }
];
