import React, { Fragment } from 'react';

import './TermOfServiceContent.sass';
import {SvgIco} from "../../SharedComponents/Icons";

const TermsOfServiceContent = ({ activeContract }) => {
  return (
    <Fragment>
      <div className="terms-of-service__close-btn">
        <div className="sign-up-modal__close-btn" id="modalCloseBtn">
          <SvgIco name="close" size={22}/>
        </div>
      </div>
      <div className="terms-of-service__header">
        <h2 className="terms-of-service__header-title">
          { activeContract.title }
        </h2>
      </div>
      <div className="terms-of-service__body" dangerouslySetInnerHTML={{__html: activeContract.body}} />
    </Fragment>
  )
};

export default TermsOfServiceContent;
