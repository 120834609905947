import { postLocationShown } from './api';

class Storage {
  constructor() {
    this.locationViewedKey = 'my.viewed_locations';
    this.locationOrderedKey = 'my.ordered_locations';
    this.locationShowcaseKey = 'my.ordered_showcase';
    this.locationContactKey = 'my.contact_locations';
  }

  static timestamp() {
    return { timestamp: new Date().toISOString() };
  }

  populateStorageItems(key) {
    if (!!localStorage) {
      if (!!localStorage.getItem(key)) {
        this[`${key}Obj`] = JSON.parse(localStorage.getItem(key));
      } else {
        this[`${key}Obj`] = {};
        localStorage.setItem(key, '{}');
      }
    }
  }

  isLocationViewed(itemId) {
    // --> TODO: cahnge this to be functional
    this.itemId = itemId;
    this.viewedLocations = localStorage && localStorage.getItem(this.locationViewedKey);

    //if (this.viewedLocations != undefined && JSON.parse(this.viewedLocations)[this.itemId] == undefined) {
      postLocationShown(this.itemId)
    //}

    this.populateStorageItems(this.locationViewedKey);
    this.updateViewedItem();
    return (
      Object.keys(this[`${this.locationViewedKey}Obj`]).indexOf(this.itemId) !== -1
    );
  }

  isLocationOrdered(itemId) {
    // --> TODO: cahnge this to be functional
    let orderLocation;
    let orderedDate;
    let todayDate = new Date();
    let oneWeekAgoDate = new Date(todayDate.getTime() - (60*60*24*7*1000)); // one week ago
    this.itemId = itemId;
    this.populateStorageItems(this.locationOrderedKey);
    orderLocation = localStorage && JSON.parse(localStorage.getItem("my.ordered_locations"))[this.itemId];

    if (orderLocation) {
      orderedDate = new Date(orderLocation[0].timestamp);

      return oneWeekAgoDate < orderedDate;
    } else {
      return false   // location not yet ordered
    }
  }

  isShowcaseOrdered(itemId) {
     // --> TODO: cahnge this to be functional
     this.itemId = itemId;
     this.populateStorageItems(this.locationShowcaseKey);
     return (
       Object.keys(this[`${this.locationShowcaseKey}Obj`]).indexOf(
         this.itemId
       ) !== -1
     );
  }

  isContactOrdered(itemId) {
    // --> TODO: cahnge this to be functional
    const locationObj = this[`${this.locationContactKey}Obj`] || null;

    this.itemId = itemId;
    this.populateStorageItems(this.locationContactKey);

    if (locationObj) {
      return (
        Object.keys(this[`${this.locationContactKey}Obj`]).indexOf(
          this.itemId
        ) !== -1
      );
    }
  };

  updateViewedItem() {
    this[`${this.locationViewedKey}Obj`][this.itemId] = [
      this.constructor.timestamp(),
    ];
    const viewedLocationStr = JSON.stringify(
      this[`${this.locationViewedKey}Obj`]
    );
    localStorage.setItem(this.locationViewedKey, viewedLocationStr);
  }

  updateOrderedItem() {
    // --> TODO: cahnge this to be functional
    this[`${this.locationOrderedKey}Obj`][this.itemId] = [
      this.constructor.timestamp(),
    ];
    const orderedLocationStr = JSON.stringify(
      this[`${this.locationOrderedKey}Obj`]
    );
    localStorage.setItem(this.locationOrderedKey, orderedLocationStr);
  }

  updateContactItem() {
    // --> TODO: cahnge this to be functional
    this[`${this.locationContactKey}Obj`][this.itemId] = [
      this.constructor.timestamp(),
    ];
    const orderedLocationStr = JSON.stringify(
      this[`${this.locationContactKey}Obj`]
    );
    localStorage.setItem(this.locationContactKey, orderedLocationStr);
  }

  updateShowcaseItem() {
    // --> TODO: cahnge this to be functional
    this[`${this.locationShowcaseKey}Obj`][this.itemId] = [
      this.constructor.timestamp(),
    ];
    const orderedLocationStr = JSON.stringify(
      this[`${this.locationShowcaseKey}Obj`]
    );
    localStorage.setItem(this.locationShowcaseKey, orderedLocationStr);
  }
}

export default new Storage();
