import React from 'react';

import './PulsingLoader.sass';


const PulsingLoader = () => {

  return (
    <div className="pulsing-loader-wrapper">
      <div className="pulsing-loader" />
    </div>
  )
};

export default PulsingLoader;

