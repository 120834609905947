import React, { Component } from 'react';
import ErrorModalDialog from './ErrorModalDialog';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      didCatchError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
      didCatchError: true,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <ErrorModalDialog
          errorName={this.state.error.toString()}
          errorInfo={this.state.errorInfo.componentStack}
          didCatchError={this.state.didCatchError}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
