// this parking only on DK, that why I don't create some keys in phraseapp
import React, { Fragment } from 'react';

import { localizedNumberFormatter } from "../../../utils/helpers";
import { SvgIco } from "../../SharedComponents/Icons";


const BasicParkeringContent = ({ price }) => {

  return (
    <Fragment>
      <div className="sign-up-modal__header">
        <h2 className="sign-up-modal__header-title">
          { I18n.t('generic.parking') }
        </h2>
        <div className="sign-up-modal__close-btn" id="modalCloseBtn">
          <SvgIco name="close" size={32}/>
        </div>
      </div>
      <div className="sign-up-modal__body">
        <p className="sign-up-modal__pricing-description" dangerouslySetInnerHTML={{__html: I18n.t('signup.signup_form.parking_price_description')}} />
        <div className="sign-up-modal__pricing-table-wrapper">
          <h3>{ I18n.t('signup.signup_form.parking_price_table_title') }</h3>

          <table className="sign-up-modal__table sign-up-modal__parkering">
            <tbody>

            <tr className="sign-up-modal__table-header">
              <td>{ I18n.t('signup.signup_form.on_deal.infobox.prices') }</td>
              <td className="sign-up-modal__table-td">{I18n.t('signup.signup_form.parking_basic_parkering')}</td>
            </tr>

            <tr>
              <td>{ I18n.t('signup.signup_form.parking_tabs_block_one') }</td>
              <td>{localizedNumberFormatter(Number(price))} kr.</td>
            </tr>

            <tr>
              <td>{ I18n.t('signup.signup_form.meeting_room.infobox.billing_period.period') }</td>
              <td>{ I18n.t('signup.signup_form.coworking.infobox.billing_period.monthly') }</td>
            </tr>

            <tr>
              <td>{ I18n.t('signup.signup_form.meeting_room.infobox.term.term') }</td>
              <td>{ I18n.t('signup.signup_form.meeting.infobox.term.months_basic') }</td>
            </tr>

            <tr className="sign-up-modal__table-header">
              <td>{ I18n.t('signup.signup_form.on_deal.infobox.you_get') }</td>
              <td/>
            </tr>

            <tr>
              <td>{ I18n.t('signup.teaser.tabs.tab.block.greater') }</td>
              <td><span className="check-icon" /></td>
            </tr>

            <tr>
              <td>{ I18n.t('signup.teaser.tabs.tab.block.general_2') }</td>
              <td><span className="check-icon" /></td>
            </tr>

            <tr>
              <td>{ I18n.t('signup.teaser.tabs.tab.block.basic.list_1') }</td>
              <td><span className="check-icon" /></td>
            </tr>

            <tr>
              <td>Har du flere parkeringspladser på samme adresse, betaler du kun for én annonce.</td>
              <td><span className="check-icon" /></td>
            </tr>

            <tr>
              <td>Visning af kontaktoplysninger</td>
              <td><span className="check-icon" /></td>
            </tr>

            <tr>
              <td>{ I18n.t('signup.teaser.tabs.tab.block.no_cure_no_pay_plus.list_4') }</td>
              <td><span className="check-icon" /></td>
            </tr>

            <tr>
              <td>{ I18n.t('signup.teaser.tabs.tab.block.general_website-link') }</td>
              <td><span className="check-icon" /></td>
            </tr>


            <tr>
              <td>{ I18n.t('signup.teaser.tabs.tab.block.general_contact-person-photo') }</td>
              <td><span className="check-icon" /></td>
            </tr>

            <tr>
              <td>{ I18n.t('signup.teaser.tabs.tab.block.linklist_14') }</td>
              <td><span className="check-icon" /></td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  )
};

export default BasicParkeringContent;
