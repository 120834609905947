import React, { Component } from 'react';
import { getParkingLotsNear } from '../../utils/api';

import BaseMap from './BaseMap';
import ParkingMarker from './ParkingMarker';

function loadingElement() {
  return <div style={{ height: `100%` }} />;
}

class ParkingMarkerMap extends Component {
  updateMarkerOpenedId = (marker_id) => {
      this.setState({ markerOpenedId: marker_id });
  };
  markers = () => {
      return this.state.parkingLots.map(marker => (
          <ParkingMarker marker={marker} key={marker.id} updateMarkerOpenedId={this.updateMarkerOpenedId} markerOpenedId={this.state.markerOpenedId} />
      ));
  };

  constructor(props) {
    super(props);
    this.state = {
      parkingLots: [],
      markerOpenedId: null
    };
  }

  componentDidMount() {
    const { coordinates: { latitude, longitude }, clickHandler } = this.props;

    getParkingLotsNear(latitude, longitude)
      .then(res => {
        const parkingLots = res.data.parking_lots;
        this.setState({ parkingLots });
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    const { coordinates: { latitude, longitude }, clickHandler, googleApiKey } = this.props;
    const coordinatesObj = { lat: latitude, lng: longitude };

    return (
      <BaseMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`}
        loadingElement={loadingElement()}
        containerElement={<div className="bottom-map-container" />}
        mapElement={<div id="location-react-map" className="bottom-location-map" />}
        coordinates={coordinatesObj}
        markers={this.markers()}
      />
    );
  }
}

export default ParkingMarkerMap;
