import React from "react";
import { SvgIco } from "../SharedComponents/Icons";
import LogoDk from '../../src/images/logos/lokalebasen_dk.svg';
import LogoMO from '../../src/images/logos/matchoffice_generic.svg';


export const MainInfoColumns = (data, siteKey, selectedLocationsIds, handleSelect, orderedLocations) => React.useMemo(
  () => [
    {
      Header: <img src={siteKey === 'dk' ? LogoDk : LogoMO} className="comparison-table__logo-header" />,
      accessor: 'photo',
      Cell: (cell) => {
        const { id, is_internal, photo, shown_as_rented_out, temporarily_unavailable, url, uuid } = cell.row.original;
        const locationOrdered = orderedLocations.includes(uuid);

        return (
          <div className="comparison-table__img-cell">
            <div className="comparison-table__check-wrapper" onClick={() => handleSelect(id)}>
              { selectedLocationsIds.includes(id) &&
                <SvgIco name="check-bold" height={16} width={16} />
              }
            </div>
            <div className="comparison-table__img-wrapper">
              <img src={photo} alt="First photo" className={shown_as_rented_out || temporarily_unavailable ? 'rented-out' : ''} />
              { (shown_as_rented_out || temporarily_unavailable) &&
                <div className="comparison-table__rented-out-badge">
                  { shown_as_rented_out
                    ? I18n.t('activerecord.attributes.location.states.rented_out', {count: 0})
                    : I18n.t('activerecord.attributes.location.states.temporarily_unavailable_short')
                  }
                </div>
              }
              { is_internal &&
                <div className="comparison-table__crawled-badge">
                  { I18n.t('generic.parsed_location') }
                </div>
              }
              { locationOrdered &&
                <div className="comparison-table__ordered-banner">
                  { I18n.t('apps.lb_showroom.detailed.order_form.cta_fixed_info_sent') }
                </div>
              }
            </div>
            <a href={url} target="_blank">
              { I18n.t('compare.table.more_photos_link') }
            </a>
          </div>
        )
      }
    },

    {
      Header: I18n.t('generic.address') + ':',
      accessor: 'address',
      Cell: (cell) =>
        <div className="comparison-table__address-cell">
          { cell.row.original.address }
        </div>
    },

    {
      Header: I18n.t('providers.statistics.index.kind') + ':',
      accessor: 'kind',
    },

    {
      Header: I18n.t('activerecord.attributes.location.secondary_kinds') + ':',
      accessor: 'secondary_kinds',
    },

    {
      Header: I18n.t('search_agent.area') + ':',
      accessor: 'area',
    },

    {
      Header: I18n.t('providers.locations.form.energy_rating') + ':',
      accessor: 'energy_rating',
      Cell: (cell) => {
        const { energy_rating } = cell.row.original;
        const energyRatingMark = energy_rating ? `${energy_rating}`.toLowerCase() : null;
        const energyRatingLabel = energy_rating === 'Exempted'
          ? I18n.t('simple_form.options.location.energy_rating.X')
          : I18n.t('activerecord.attributes.property.energy_rating');

        return (
          <div className="comparison-table__energy-rating-cell">
            <SvgIco
              name={`energy-rating-${energyRatingMark}`}
              height={18}
              width={18}
            />
            { energyRatingLabel }
          </div>
        )
      }
    },
  ],
  [data]
);


export const EconomyColumns = (data) => React.useMemo(
  () => [
    {
      Header: I18n.t('apps.lb_showroom.detailed.facts.monthly_price') + ':',
      accessor: 'prices.monthly_price',
      Cell: (cell) => cell.row.original.prices.monthly_price,
    },
    {
      Header: I18n.t('providers.locations.form.rent_price_per_hour') + ':',
      accessor: 'prices.price_per_hour',
      Cell: (cell) => cell.row.original.prices.price_per_hour,
    },
    {
      Header: I18n.t('activerecord.attributes.location.yearly_rent_per_m2') + ':',
      accessor: 'prices.prices_per_m2.price_per_year',
      Cell: cell => cell.row.original.prices.prices_per_m2.price_per_year,
    },
    {
      Header: I18n.t('apps.lb_showroom.detailed.economy.electricity_per_m2_per_year_new') + ':',
      accessor: 'prices.prices_per_m2.electricity_per_m2_per_year',
      Cell: cell => cell.row.original.prices.prices_per_m2.electricity_per_m2_per_year,
    },
    {
      Header: I18n.t('apps.lb_showroom.detailed.economy.heating_per_m2_per_year'),
      accessor: 'prices.prices_per_m2.heating_per_m2_per_year',
      Cell: cell => cell.row.original.prices.prices_per_m2.heating_per_m2_per_year,
    },
    {
      Header: I18n.t('activerecord.attributes.location.yearly_operational_cost_per_m2') + ':',
      accessor: 'prices.prices_per_m2.operational_per_m2_per_year',
      Cell: cell => cell.row.original.prices.prices_per_m2.operational_per_m2_per_year,
    },
    {
      Header: I18n.t('apps.lb_showroom.detailed.economy.water_per_m2_per_year') + ':',
      accessor: 'prices.prices_per_m2.water_per_m2_per_year',
      Cell: cell => cell.row.original.prices.prices_per_m2.water_per_m2_per_year,
    },
    {
      Header: I18n.t('providers.locations.form.annual_electricity_cost') + ':',
      accessor: 'prices.prices_per_year.annual_electricity_cost',
      Cell: cell => cell.row.original.prices.prices_per_year.annual_electricity_cost,
    },
    {
      Header: I18n.t('providers.locations.form.annual_heating_cost') + ':',
      accessor: 'prices.prices_per_year.annual_heating_cost',
      Cell: cell => cell.row.original.prices.prices_per_year.annual_heating_cost,
    },
    {
      Header: I18n.t('apps.lb_showroom.detailed.economy.annual_operational_cost') + ':',
      accessor: 'prices.prices_per_year.annual_operational_cost',
      Cell: cell => cell.row.original.prices.prices_per_year.annual_operational_cost,
    },
    {
      Header: I18n.t('apps.lb_showroom.detailed.economy.annual_rent') + ':',
      accessor: 'prices.prices_per_year.annual_rent',
      Cell: cell => cell.row.original.prices.prices_per_year.annual_rent,
    },
    {
      Header: I18n.t('activerecord.attributes.location.building_energy_cost') + ':',
      accessor: 'prices.prices_per_year.annual_total_cost',
      Cell: cell => cell.row.original.prices.prices_per_year.annual_total_cost,
    },
    {
      Header: I18n.t('providers.locations.form.annual_water_cost') + ':',
      accessor: 'prices.prices_per_year.annual_water_cost',
      Cell: cell => cell.row.original.prices.prices_per_year.annual_water_cost,
    },
  ],
  [data]
);

export const FacilitiesColumns = (data) => React.useMemo(
  () => [
    {
      Header: '',
      accessor: 'facilities',
      Cell: (cell) => {

        const facilities = cell.row.original.facilities;
        const sortedFacilities = facilities.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        const facilitiesLength = facilities && facilities.length;

        return (
          <div className={`comparison-table__facilities-cell ${facilitiesLength === 1 ? 'single' : ''}`}>
            { !!facilitiesLength && sortedFacilities.map(facility =>
              <div className="comparison-table__facilities-list-item" key={facility.key}>
                <div className="comparison-table__facilities-list-item-icon lazyload"
                     data-bg={facility.icon || require(`../../src/images/icons/facts_and_facilities/default.svg`)}
                     data-sizes="auto"
                />
                <span>{ facility.name }</span>
              </div>
            )}
          </div>
        )
      }
    },
  ],
  [data]
);
