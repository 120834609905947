import React from 'react';
import classNames from 'classnames';

import FavoritesItem from './FavoritesItem';

import './FavoritesList.sass';


const FavoritesList = ({ activeSection,
                         currency,
                         favoritesList,
                         handleActiveSection,
                         handleSelect,
                         isLoading,
                         isSelected,
                         orderedLocations,
                         removeFromFavorite,
                         setOrderedLocations,
                         showSwitchers,
                         siteKey }) => {

  const leasesTabClasses = classNames('favorites-list__tab', { 'active': activeSection === 'lease' });
  const salesTabClasses = classNames('favorites-list__tab', { 'active': activeSection === 'sale' });
  const spinner =
    <div className="spinner-wrapper">
      <div className="smoothie-spinner" />
    </div>;


  return (
    <div className="favorites-list">

      { showSwitchers &&
        <div className="favorites-list__tabs container">
          <div className={leasesTabClasses} onClick={() => handleActiveSection('lease')}>
            Til leje
          </div>
          <div className={salesTabClasses} onClick={() => handleActiveSection('sale')}>
            Til salg
          </div>
        </div>
      }

      <div className="favorites-list__items-wrapper">
        <div className="container">
          { isLoading
            ? spinner
            : <div className="row">
                { favoritesList.map(item => (
                  <FavoritesItem
                    address={item.address}
                    addressLine1={item.address_line_1}
                    anonymous={item.anonymous}
                    closedMonth={item.closed_month}
                    currency={currency}
                    facts={item.price_and_area_facts}
                    handleSelect={handleSelect}
                    id={item.id}
                    isInternal={item.is_internal}
                    isSelected={isSelected(item.id)}
                    key={item.uuid}
                    nearbyParkingLot={item.nearby_parking_lot}
                    orderedLocations={orderedLocations}
                    orderPath={item.order_path}
                    photoAlt={item.photo_alt}
                    photoUrl={item.photo_url}
                    postalCodeAndName={item.postal_code_and_name}
                    providerName={item.provider_name}
                    rawKind={item.raw_kind}
                    removeFromFavorite={removeFromFavorite}
                    secondaryKinds={item.secondary_kinds}
                    section={activeSection}
                    setOrderedLocations={setOrderedLocations}
                    showNewBadge={item.show_new_badge}
                    shownAsRentedOut={item.shown_as_rented_out}
                    siteKey={siteKey}
                    specialOffer={item.special_offer}
                    temporarilyUnavailable={item.temporarily_unavailable}
                    url={item.url}
                    uuid={item.uuid}
                  />
                )) }
              </div>
          }
        </div>
      </div>
    </div>
  )
};

export default FavoritesList;
