import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { InfoWindow } from "@react-google-maps/api";

import { apiProvider } from '../../../api/core';
import MarkerContent from './MarkerContent';
import MarkersList from "./MarkersList";


const InfoWrapper = forwardRef((props, ref) => {

  const { activePlaceId, setActivePlaceId } = props;

  const [markerFullInfo, setMarkerFullInfo] = useState(null);
  const [windowPosition, setWindowPosition] = useState(null);
  const [markersInfo, setMarkersInfo] = useState(null);


  const show = (id, pos, ids) => {
    let locationsParamsIds = '';
    const idsList = ids || [id];
    const sameMarker =
      (markerFullInfo && id === markerFullInfo.id)
      ||
      (markersInfo && ids && markersInfo.every(marker => ids.includes(marker.id)));

    idsList.map(id => locationsParamsIds = locationsParamsIds + `ids[]=${id}&`);
    setMarkerFullInfo(null);
    setWindowPosition(pos);
    setMarkersInfo(null);
    setActivePlaceId(null);

    !sameMarker &&
      apiProvider.getAll(`/lease/api/frontend/locations/map_markers_info?${locationsParamsIds}`)
        .then(response => {
          if (response) {
            ids
              ? setMarkersInfo(response.map(marker => JSON.parse(marker)))
              : setMarkerFullInfo(JSON.parse(response[0]));

            setActivePlaceId(id);
          }
        });
  };


  const close = () => {
    setMarkersInfo(null);
    setMarkerFullInfo(null);
  };


  const onInfoToggle = () => {
    setMarkerFullInfo(null);
    setMarkersInfo(null);
    setActivePlaceId(null);
  };


  useImperativeHandle(ref, () => ({
    show, close,
  }), [show, close]);


  return (
    <div>
      { markerFullInfo || markersInfo
        ?
          <InfoWindow position={windowPosition} onUnmount={() => onInfoToggle(activePlaceId)}>
            { markersInfo
              ? <MarkersList markers={markersInfo} handleClose={onInfoToggle} />
              : <MarkerContent markerData={markerFullInfo} onInfoToggle={onInfoToggle} />
            }
          </InfoWindow>
        : null
      }
    </div>
  );
});


export default InfoWrapper;
