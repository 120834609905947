import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import Truncate from 'react-truncate';

import nearbyLocationsBanner from '../../../src/images/icons/nearby-location-banner.svg';
import LokalebasenLogo from '../../../src/images/logos/lokalebasen_dk.svg';
import MatchofficeLogo from '../../../src/images/logos/matchoffice_generic.svg';
import partnerBadge from '../../../src/images/provider/partner-badge-listview.png'
import { SvgIco } from '../../SharedComponents/Icons';

import './ItemsListComponents.sass';

export const ProviderLogo = ({ providerLogo, providerName }) => (
  <div className='provider-logo'>
    <img
      alt={providerName}
      className="lazyload"
      data-sizes="auto"
      data-expand="100"
      data-src={providerLogo}
    />
  </div>
);

export const ProviderPartner = () =>
  <div className="provider-partner-icon" ><SvgIco name="partner_badge" size={40} /></div>;

export const DescriptionLine = ({ substring, lines }) => (
  <Truncate
    lines={lines}
    ellipsis={
      <span>
        {lines !== 0 && '... '}
        <span className="read-more">{I18n.t('generic.read_more')}</span>
      </span>
    }
    children={substring}
  />
);

export const MapIcon = ({ id, toggleMap, withLabel }) => (
  <Fragment>
    <span
      onClick={toggleMap}
      role="button"
      data-tip={I18n.t('tooltips.showroom.view_on_map')}
      data-class="list-item-tooltip"
      data-for={'map' + id}
      className="map-icon"
      id="map-pin-icon"
    >
      { withLabel && <div className="map-icon__label">{ I18n.t('tooltips.showroom.view_on_map') }</div> }
      <SvgIco name="map_detail" size={20} />
    </span>
    <ReactTooltip place="top" effect="solid" id={'map' + id} />
  </Fragment>
);

export const handleListItemClick = (e, href, id) => {
  if (!e.ctrlKey && !e.metaKey) {
    e.preventDefault();

    const parent = e.target.parentNode;
    const noticeWrapper = document.getElementById('noticeWrapper-' + id);

    if (e.target.id === `infoNoticeLink-${id}`) {
      window.open(e.target.getAttribute('href'), '_blank');
    } else if (
      parent.getAttribute('role') !== 'button' &&
      e.target.getAttribute('role') !== 'button' &&
      !parent.classList.contains('embla__buttons') &&
      parent.parentElement.id !== 'map-pin-icon' &&
      (!noticeWrapper || !noticeWrapper.contains(e.target))
    ) {
      sessionStorage.setItem('location_items.scroll_value', window.pageYOffset);
      window.open(href, '_blank');
    }
  }
};

export const NearbyLocationsBanner = ({ grid, mapIsOpen, siteKey }) => (
  <div className={`${grid ? `grid-item col-md-6 col-lg-${mapIsOpen ? 6 : 4} col-xl-${mapIsOpen ? 4 : 3}` : 'container max-width'}`}>
    <div className={`nearby-locations-banner${grid ? ' grid' : ' row'}`}>
      <div
        className={`nearby-locations-banner__background${
          !grid ? ' col-xl-12' : ''
        }`}
      ></div>
      <div className={`nearby-locations-banner__img${ !grid ? ' col-xl-3 col-lg-3 col-md-4 col-12' : ''}`}>
        <img
          alt="Nearby Locations"
          className="lazyload"
          data-expand="100"
          data-sizes="auto"
          data-src={nearbyLocationsBanner}
          height={149}
          width={183}
        />
      </div>
      <div
        className={`nearby-locations-banner__description-content${
          !grid ? ' col-xl-6 col-lg-6 col-md-4 col-sm-9 col-12' : ''
        }`}
      >
        <div className="nearby-locations-banner__content-wrapper">
          <div className="nearby-locations-banner__headline">
            {I18n.t('apps.lb_showroom.nearby_locations')}
          </div>
          <div className="nearby-locations-banner__description">
            {I18n.t('apps.lb_showroom.nearby_locations_description')}
          </div>
        </div>
      </div>
      <div
        className={`nearby-locations-banner__logo ${
          !grid ? ' col-xl-3 col-lg-3 col-md-4 col-12' : ''
        }`}
      >
        <img
          alt="Site Logo"
          className="lazyload"
          height="auto"
          width={191}
          data-sizes="auto"
          data-src={siteKey === 'dk' ? LokalebasenLogo : MatchofficeLogo}
          data-expand="100"
        />
      </div>
    </div>
  </div>
);
