import React, { Component } from "react";
import queryString from 'query-string';

import { localizedNumberFormatter } from "../../utils/helpers";
import { SvgIco } from "../SharedComponents/Icons";

import './PaymentModel.sass'


class PaymentModel extends Component {

  state = {
    paymentModel: '',
    activePolicyGroup: '',
    activePolicyPlan: '',
  };

  componentDidMount() {
    const { invoicingStrategy, name } = this.props.activeContract.contract;
    const policyGroup = invoicingStrategy.includes('coworking') ? 'coworking-policy-group' : 'invoicing-policy-group';
    const policyPlan = name.replace(/_(\w)/g, (m => m[1].toUpperCase()));
    const parkeringCheck = queryString.parse(window.location.search).contract_name === 'parking' ? 'parkering-policy-group' : null;
    const activePolicyGroup = parkeringCheck ? parkeringCheck : policyGroup;
    const activePolicyPlan = parkeringCheck ? 'basic' : policyPlan;

    this.setState({ activePolicyGroup, activePolicyPlan }, () => {
      const policyGroup = document.getElementById(activePolicyGroup);
      const policyPlan = document.getElementById(activePolicyPlan);

      policyGroup && policyGroup.classList.add('active');
      policyPlan && policyPlan.classList.add('active');
      this.props.handleContractSelect(activePolicyPlan, activePolicyGroup);
    });
  }

  handleMainGroupRadioSelect = (targetGroup) => {
    const { activePolicyGroup, activePolicyPlan } = this.state;
    const targetGroupRadio = document.getElementById(targetGroup);
    const targetPlan = targetGroupRadio.querySelector('.payment-model__sublist').children[1].id;

    document.getElementById(targetGroup) && activePolicyGroup !== targetGroup && this.setState({
      activePolicyGroup: targetGroup,
      activePolicyPlan: targetPlan,
    }, () => {
      document.getElementById(activePolicyPlan).classList.remove('active');
      document.getElementById(activePolicyGroup).classList.remove('active');
      document.getElementById(targetPlan).classList.add('active');
      this.props.handleContractSelect(targetPlan, activePolicyGroup);
      setTimeout(() => targetGroupRadio.classList.add('active'), 10);
    });
  };

  handlePlanRadioSelect = (targetPlan) => {
    const { activePolicyGroup, activePolicyPlan } = this.state;

    document.getElementById(targetPlan) && activePolicyPlan !== targetPlan && this.setState({
      activePolicyPlan: targetPlan,
    }, () => {
      document.getElementById(activePolicyPlan).classList.remove('active');
      document.getElementById(targetPlan).classList.add('active');
      this.props.handleContractSelect(targetPlan, activePolicyGroup);
    })
  };

  renderOptionList = (optionListData) => {
    const { activePolicyPlan } = this.state;

    return optionListData.map(elem => {
      const activeClass = activePolicyPlan === elem.dataName ? 'active' : '';
        return (
          <li className={`payment-model__sublist-radio-wrapper ${ activeClass }`}
              id={elem.dataName}
              key={elem.dataName}
              data-radio={true}
              onClick={() => this.handlePlanRadioSelect(elem.dataName)}>
            <span className="payment-model__sublist-radio"/>
            <p className="payment-model__sublist-radio-label">
              { elem.label }
              { elem.promotion && <span className="payment-model__sublist-radio-promo">{ elem.promotion }</span> }
            </p>
          </li>
        )
      }
    )
  };


  render() {

    const { contactInfoEmail, contactInfoPhone, currentCurrency, siteKey, toggleModal, advertisementStrategiesPrices } = this.props;
    const margin = { marginBottom: 0 };
    const isDk = siteKey === 'dk';
    const phoneNumber = isDk ? '(+45) 39 45 85 57' : contactInfoPhone;
    const advertisementPrices = advertisementStrategiesPrices['prices'];

    const providerDkContractOption =
      isDk
      ? [
          { dataName: 'basic', label: 'Basic - fra ' + advertisementPrices['basic']['0..249'] + ' kr.' },
          { dataName: 'basicPlus', label: 'Basic Plus - fra ' + advertisementPrices['basicPlus']['0..249'] + ' kr.' },
          { dataName: 'premium', label: 'Premium - fra ' + advertisementPrices['premium']['0..249'] + ' kr.' },
          { dataName: 'premiumPlus', label: 'Premium + - fra ' + advertisementPrices['premiumPlus']['0..249'] + ' kr.' }
        ]
      : [];

    const providerContractOption = [
      { dataName: 'noCureBasic', label: I18n.t('signup.new_form.plans.block1.nocure_pricer.basic') },
    ];

    const providerContractOptionMeetingRoom = [
      { dataName: 'annualRemoval',
        label: I18n.t('signup.new_form.plans.block1.meeting_room_pricer.annual_settlement_new', { price: localizedNumberFormatter(Number(advertisementPrices.meetingRoom.default)) }),
        promotion: I18n.t('signup.meeting_rooms.free_untill')
      },
      { dataName: 'annualRemovalPremium',
        label: I18n.t('signup.new_form.plans.block1.meeting_room_pricer.premium_new', { price: localizedNumberFormatter(Number(advertisementPrices.meetingRoomPremium.default)) }),
      },
    ];

    const providerContractOptionParkering =
      isDk
      ? [{ dataName: 'basic', label: `${localizedNumberFormatter(Number(advertisementPrices.parking.default))} kr.` }]
      : [];


    return (
      <div className="payment-model">
        <ul className="payment-model__main-list">

          <li className="payment-model__main-list-item" id="invoicing-policy-group">
            <div className="payment-model__main-list-radio-wrapper"
                 data-radio={true}
                 onClick={ () => this.handleMainGroupRadioSelect('invoicing-policy-group') }>
              <span className="payment-model__main-list-radio" />
              <p className="payment-model__main-list-radio-label">
                { I18n.t('signup.new_form.plans.block1.title_new') }
              </p>
            </div>

            <div className="payment-model__collapsible-wrapper">
              <p className="payment-model__subheader">{ I18n.t('signup.payment_model_top_options_description') }</p>
              { isDk &&
                <ul className="payment-model__sublist" id="provider-contract-plan-dk">
                  <p className="payment-model__sublist-header">
                    { I18n.t('signup.new_form.plans.block1.basic_pricer.title') }
                    <SvgIco name="help_outline" onClick={() => toggleModal('basicPriceInfo')} size={22} />
                  </p>
                  { this.renderOptionList(providerDkContractOption) }
                </ul>
              }
              <ul className="payment-model__sublist" id="provider-contract-plan">
                <div>

                  <p className="payment-model__sublist-header">
                    { I18n.t('signup.new_form.plans.block1.nocure_pricer.title') }
                    <SvgIco name="help_outline" onClick={() => toggleModal('noCureInfo')} size={22} />
                  </p>

                </div>
                { this.renderOptionList(providerContractOption)}
              </ul>
            </div>
          </li>

          <li className="payment-model__main-list-item" id="coworking-policy-group">
            <div className="payment-model__main-list-radio-wrapper"
                 data-radio={true}
                 onClick={ () => this.handleMainGroupRadioSelect('coworking-policy-group') }>
              <span className="payment-model__main-list-radio" />
              <p className="payment-model__main-list-radio-label">
                { I18n.t('apps.entry_page.entry_pages.show.lease.meeting_rooms') }
              </p>
            </div>

            <div className="payment-model__collapsible-wrapper">
              <ul className="payment-model__sublist" id="provider-contract-plan-coworking">
                <p className="payment-model__sublist-header">
                  { I18n.t('signup.new_form.plans.block1.meeting_pricer.title') }
                  <SvgIco name="help_outline" onClick={() => toggleModal('coworkingInfo')} size={22} />
                </p>
                { this.renderOptionList(providerContractOptionMeetingRoom) }
              </ul>
            </div>
          </li>

          { isDk && (
            <li className="payment-model__main-list-item" id="parkering-policy-group">
              <div className="payment-model__main-list-radio-wrapper"
                   data-radio={true}
                   onClick={ () => this.handleMainGroupRadioSelect('parkering-policy-group') }>
                <span className="payment-model__main-list-radio" />
                <p className="payment-model__main-list-radio-label">
                  { I18n.t('generic.parking') }
                </p>
              </div>

              <div className="payment-model__collapsible-wrapper">
                <ul className="payment-model__sublist" id="provider-contract-plan-parkering" style={margin}>
                  <p className="payment-model__sublist-header">
                    { I18n.t('signup.teaser.tabs.tab.left') }
                    <SvgIco name="help_outline" onClick={() => toggleModal('basicParking')} size={22} />
                  </p>
                  { this.renderOptionList(providerContractOptionParkering) }
                </ul>
              </div>
            </li>
          )}

        </ul>
        <span className="payment-model__notice">
          { I18n.t('signup.new_form.plans.notes') }
        </span>

        { isDk &&
          <div className="payment-model__info-block">
            <div className="payment-model__info-title">
              { I18n.t('signup.teaser.contacts.heading') }
            </div>

            <div className="payment-model__info-links">
              { phoneNumber &&
                <div className="payment-model__info-phone">
                  <img className="lazyload"
                       data-src={require(`../../src/images/list_office/Phone_icon.svg`)}
                       alt="Phone"
                       data-sizes="auto"
                       height={21}
                       width={21} />
                  <a href={`tel://${phoneNumber}`}>{ phoneNumber }</a>
                </div>
              }
              <div className="payment-model__info-email">
                <img className="lazyload"
                     data-src={require(`../../src/images/list_office/Mail_icon.svg`)}
                     alt="Phone"
                     data-sizes="auto"
                     height={17}
                     width={21} />
                <a href={`mailto:${contactInfoEmail}`}>{ contactInfoEmail }</a>
              </div>
            </div>

            <div className="payment-model__working-hours-wrapper">
              <div className="payment-model__info-title-hours">
                { I18n.t('simple_form.labels.parking_lot.opening_hours') + ':' }
              </div>
              <p className="payment-model__working-hours">
                { I18n.t('views.sessions.new.opening_hours_weekdays') } { isDk ? '09:00-16:30' : '09:00-17:00' }
              </p>
            </div>
          </div>
        }

      </div>
    )
  }
}

export default PaymentModel;
