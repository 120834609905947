import React from 'react';
import InformationUnavailable from '../SharedComponents/InformationUnavailable';
import { loadTawkTo } from '../TawkTo/TawkTo';

class InformationUnavailablePage extends Component {

  componentDidMount() {
    loadTawkTo(this.props.siteKey);
  }

  render() {
    const { similarLocationsHeadline, similarLocationsList, siteKey } = this.props;
    const headline = I18n.t('search_agent.information_unavailable.headline');
    const content = I18n.t('search_agent.information_unavailable.content');


    return (
      <InformationUnavailable
        similarLocationsList={similarLocationsList}
        similarLocationsHeadline={similarLocationsHeadline}
        headline={headline}
        content={content}
        siteKey={siteKey}
        section="lease"
      />
    )
  }
}

export default InformationUnavailablePage;
