import React, { Component } from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

class ErrorModalDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingModal: false,
      errorName: null,
      errorInfo: null,
    };
  }
  componentDidMount() {
    this.setState({
      isShowingModal: this.props.didCatchError,
      errorName: this.props.errorName,
      errorInfo: this.props.errorInfo,
    });
  }
  handleClose = () => {
    this.setState({ isShowingModal: false });
    window.location.href += '/404';
  };
  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.isShowingModal}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.handleClose}
          contentLabel="Error Modal"
          ariaHideApp={false}
          style={customStyles}
        >
          <h2 ref={subtitle => (this.subtitle = subtitle)}>
            {this.state.errorName}
          </h2>
          <p> {this.state.errorInfo} </p>
          <button className="" onClick={this.handleClose}>
            Close
          </button>
        </Modal>
      </div>
    );
  }
}

export default ErrorModalDialog;
