import React, { Component } from "react";
import Yup from 'yup';

import { withFormik } from 'formik';
import { validationPatterns } from '../../../utils/validationPatterns';
import Client from '../../../utils/Client';
import Loader from '../../SharedComponents/Loader';


class RequestFormWrapper extends Component {


  render() {
    const {
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            submitError,
            pendingRequest,
          } = this.props;


    return (
      <form name="request-call-form form-inline"
            className="call-modal__form"
            id="call_request"
            onSubmit={handleSubmit}
            noValidate>
        <div className="form-group">
          <label className="call-modal__form-label" htmlFor="email">
            <input
              id="phone"
              type="tel"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`form-control call-modal__input
                    ${values.phone !== '' ? 'not-empty' : 'empty'}
                    ${touched.phone ? (errors.phone ? 'error' : 'is-valid') : null}`}
            />
            <span className={`call-modal__form-floating-label ${values.phone && 'active'}`}>
              { I18n.t('activemodel.attributes.provider_service_request.phone_number_order_form') }*
            </span>
          </label>
          <div className="call-modal__form-input-tooltip-error">
            { touched.phone ? errors.phone : null }
          </div>

          { submitError &&  (
            <div className="call-modal__servers-error">
              { submitError }
            </div>
          )}
        </div>

        <div className="form-group">
          <input
            type="text"
            name="fifth_element"
            className="invisible"
            value={values.fifth_element}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>

        <div className="form-group form-group--not-required call-modal__form-btn-submit">
          <button type="submit" className="b-button" disabled={pendingRequest}>
            <div className="button-content">
              { pendingRequest && <Loader size={18} left={-16} top={0}/> }
              { I18n.t('signup.teaser.modal_close.button') }
            </div>
          </button>
        </div>
      </form>
    )
  }
}

const { fifth_element, phone } = Client.getClientInfo();
const { phoneI18n } = Client.getI18nAttrNames();
const i18nRequiredKey = 'apps.lb_showroom.detailed.order_form.validation.field_required';
const i18nMinNumberKey = 'apps.lb_showroom.detailed.order_form.validation.min_number';
const i18nSymbolsLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 254 });
const i18nAtLeast8Digits = 'apps.lb_showroom.detailed.order_form.validation.at_least_8_digits';

export const RequestCallForm = withFormik({
  mapPropsToValues: () => ({ fifth_element, phone }),

  validationSchema: Yup.object().shape({
    fifth_element: Yup.string(),
    phone: Yup.string()
              .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
              .min(8, I18n.t(i18nMinNumberKey, { field: phoneI18n }))
              .matches(validationPatterns.phone, I18n.t('apps.lb_showroom.misc.scoped_form.phone.invalid'))
              .test('minDigitsValidation', I18n.t(i18nAtLeast8Digits), function(value) {
                const { path, createError } = this;
                const phoneIsValid = value && value.replace(/[+\-\s()]/g, '').length >= 8;
                return phoneIsValid || !value || createError({ path, message: I18n.t(i18nAtLeast8Digits) });
              })
              .required(I18n.t(i18nRequiredKey, { field: phoneI18n })),
  }),

  handleSubmit: (values, bag) => {
    bag.props.submitHandler(values);
  },

  displayName: 'RequestCallForm',
})(RequestFormWrapper);


export default RequestCallForm;
