import React, { Component } from 'react';
import Sticky from 'react-stickynode';

import './index.sass';


class StickyBar extends Component {

  state ={
    previousScrollY: null,
    showStickyBar: false,
  };

  handleStickyBarRender = () => {
    const { previousScrollY } = this.state;
    const currentScrollY = window.pageYOffset;

    // We need this additional check for Safari and Firefox
    // to prevent appearance of Sticky-bar at the end of the page caused by overscroll
    window.pageYOffset + window.innerHeight < document.body.clientHeight &&
      this.setState({
        showStickyBar: previousScrollY > currentScrollY,
        previousScrollY: currentScrollY,
      });
  };

  componentDidMount() {
    this.setState({ previousScrollY: window.pageYOffset })
    window.addEventListener('scroll', this.handleStickyBarRender);
  }

  render() {
    return (
      <Sticky
        top="#top-menu"
        bottomBoundary=".nothing"
        innerZ={998}
        enabled={ this.state.showStickyBar }>
        { this.props.children }
      </Sticky>
    );
  }
}

export default StickyBar;
