import React, { Fragment, useContext } from 'react';

import partnerBadge from "../../../src/images/premium-partner-badge.png";
import PersonPlaceholder from "../../../src/images/placeholder_person.png";
import { postLocationProviderAdvertsShown } from "../../../utils/api";
import SmallContactBlock from "./SmallContactBlock";
import { Context } from "../index";

import './index.sass';


const Contacts = () => {

  const {
    location: { addressLine1 },
    locationClosed,
    locationContactDetails,
    locationId,
    partnerBadgeUrl,
    siteKey,
    streetName,
  } = useContext(Context);

  const { available, contact, owner, provider } = locationContactDetails;


  const ProviderBadgeSection = () => (
    <div className="contact-info-content">
      <div className="contact-info-content__logo-wrapper">
        <img
          data-src={partnerBadge}
          alt={'Premium partner logo'}
          className="lazyload"
          data-sizes="auto"
          data-expand="100"
          height={56}
          width={56}
        />
      </div>
      <div>
        <div className="contact-info-content__title">
          { I18n.t('partner_badge.title') }
        </div>
        <div className="contact-info-content__name">
          { I18n.t('partner_badge.name') }
        </div>
        <a href={partnerBadgeUrl} className="contact-info-content__partner-link">
          { I18n.t('generic.read_more') }
        </a>
      </div>
    </div>
  );


  return (
    <Fragment>

      { available && (
        <div className="section contact-info-block">
          <p>{ I18n.t('generic.contact_details') }</p>

          <div className="dv-info-section__contact-info-wrapper">

            { !locationClosed &&
              <div className="contact-info-content">
                <div className="contact-info-content__photo-wrapper">
                  <img
                    data-src={contact.photo || PersonPlaceholder}
                    className="lazyload"
                    data-sizes="auto"
                    alt="Person"
                    height={56}
                    width={56}
                  />
                </div>
                <div>
                  <div className="contact-info-content__title">
                    { I18n.t('generic.contact_person') }
                  </div>
                  <div className="contact-info-content__name">
                    { contact.name }
                  </div>
                  <SmallContactBlock
                    contact={contact}
                    locationId={locationId}
                    siteKey={siteKey}
                    streetName={streetName}
                    addressLine1={addressLine1}
                  />
                </div>
              </div>
            }

            <div className="contact-info-content">
              { provider.logo &&
                <div className="contact-info-content__logo-wrapper">
                  <img
                    data-src={provider.logo}
                    alt={provider.name}
                    className="lazyload"
                    data-sizes="auto"
                    width={56}
                  />
                </div>
              }
              <div className={`contact-info-content__provider-info ${!provider.logo ? 'with-indentation' : ''}`}>
                <div className="contact-info-content__title">
                  { provider.title }
                </div>

                <div className="contact-info-content__name">
                  { provider.name }
                </div>

                { provider.info &&
                  <a onClick={postLocationProviderAdvertsShown(locationId)} href={provider.info}>
                    { I18n.t('generic.read_more') }
                  </a>
                }

                { !locationClosed &&
                  <div
                    onClick={postLocationProviderAdvertsShown(locationId)}
                    dangerouslySetInnerHTML={{ __html: provider.advertLink }}
                  />
                }
              </div>
            </div>

            { owner && (owner.name || owner.logo) &&
              <div className="contact-info-content">
                { owner.logo &&
                  <div className="contact-info-content__logo-wrapper">
                    <img
                      data-src={owner.logo}
                      alt={owner.name}
                      className="lazyload"
                      data-sizes="auto"
                    />
                  </div>
                }
                <div>
                  <div className="contact-info-content__title">
                    { I18n.t('locations.owner_box.heading') }
                  </div>
                  <div className="contact-info-content__name">
                    { owner.name }
                  </div>
                </div>
              </div>
            }

            { provider.partner && <ProviderBadgeSection /> }

          </div>
        </div>
      )}

      <div className="contact-info-block contact-info-block--provider-badge-only">
        { !available && provider.partner && <ProviderBadgeSection /> }
      </div>
    </Fragment>
  )
}

export default Contacts;
