import React, { Component, Fragment } from 'react';

import httpService from '../../api/httpService';
import { mappedMonths } from '../helpers/danishMonths';
import GetInfoFormModal from '../SharedComponents/GetInfoFormModal';
import { SvgIco } from '../SharedComponents/Icons';
import CompareIcon from "../SharedComponents/CompareIcon";
import SearchOfferModal from "../SharedComponents/SearchOptionsModal";
import { sendTrovitLead } from "../helpers/trovit_analitics";

import './FavoritesItem.sass';


class FavoritesItem extends Component {

  state = {
    isActiveInfoForm: false,
    showServerErrorMessage: false,
    isLoading: false,
    orderId: null,
  };


  static timestamp() {
    return { timestamp: new Date().toISOString() };
  };


  componentDidMount() {
    window.history.scrollRestoration = "manual";
  };


  handleOpenInfoForm = () =>
    this.setState({ isActiveInfoForm: true });


  handleCloseInfoForm = () =>
    this.setState({ isActiveInfoForm: false });


  infoHandlerSubmit = (params, orderUrl) => {
    const { siteKey, uuid } = this.props;

    this.setState({ isLoading: true });

    httpService.orderInfoListViewForm(orderUrl, params, uuid)
      .then(response => {
       if (response) {
         const orderedLocations = JSON.parse(localStorage.getItem('my.ordered_locations'));
         const { order_uuid } = response;

         orderedLocations[uuid] = [ this.constructor.timestamp() ];

         const updateOrderedLocation = JSON.stringify(orderedLocations);

         localStorage.setItem('my.ordered_locations', updateOrderedLocation);

         sendTrovitLead(siteKey);

         this.setState({
           submitSuccess: true,
           showServerErrorMessage: false,
           isLoading: false,
           orderId: order_uuid,
         });

         this.props.setOrderedLocations(Object.keys(orderedLocations));
       }
       else {
         this.setState({
           submitSuccess: false,
           showServerErrorMessage: true,
           isLoading: false,
         });
       }
      });
  };


  handleItemClick = (e, url) => {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();

      const parent = e.target.parentNode;

      if (parent.getAttribute('role') !== 'button' && e.target.getAttribute('role') !== 'button') {
        sessionStorage.setItem('location_items.scroll_value', window.pageYOffset);
        window.open(url, "_blank")
      }
    }
  };


  render() {
    const { isActiveInfoForm, submitSuccess, showServerErrorMessage, isLoading, orderId } = this.state;
    const {
      address,
      addressLine1,
      anonymous,
      closedMonth,
      currency,
      facts,
      handleSelect,
      hidePrice,
      id,
      isInternal,
      isSelected,
      nearbyParkingLot,
      orderedLocations,
      orderPath,
      photoAlt,
      photoUrl,
      postalCodeAndName,
      providerName,
      rawKind,
      secondaryKinds,
      section,
      siteKey,
      shownAsRentedOut,
      showNewBadge,
      specialOffer,
      temporarilyUnavailable,
      url,
      uuid } = this.props;

    // const isJeudan = providerName && providerName.toLowerCase() === 'jeudan';
    const { price, area } = facts;
    const imgStyle = { filter: shownAsRentedOut || temporarilyUnavailable ? 'grayscale(100%)' : 'none' };
    const rentedOutMonth = mappedMonths(closedMonth);
    const showSearchOfferModal = submitSuccess && orderId && !isActiveInfoForm;


    return (
      <div className="favorite-item col-12 col-md-6 col-lg-4 col-xl-3">
        <a className="favorite-item__link" href={url} target="_blank" onClick={e => this.handleItemClick(e, url, id)}>

          <div className={`favorite-item__check-box ${ isSelected ? 'checked' : '' }`}
               onClick={() => handleSelect(id)}
               role="button">
            { isSelected && <SvgIco name="check-bold" size={16} /> }
          </div>

          <div className="favorite-item__image-wrapper" role="img">
            <img data-src={photoUrl}
                 alt={photoAlt}
                 data-sizes="auto"
                 className="favorite-item__image lazyload"
                 style={imgStyle} />

            { section === 'lease' &&
              <CompareIcon
                kind={rawKind}
                locationId={id}
                siteKey={siteKey}
                tooltipPosition={'left'} />
            }

            { (showNewBadge || specialOffer) &&
              <div className="favorite-item__badges-wrapper">

                { showNewBadge &&
                  <div className="favorite-item__badge favorite-item__badge--new">
                    <span>{ I18n.t("apps.lb_showroom.showroom.list.new_item_label") }</span>
                  </div>
                }

                { specialOffer &&
                  <div className="favorite-item__badge favorite-item__badge--special">
                    <span>{ I18n.t('generic.special_offer') }</span>
                  </div>
                }
              </div>
            }

            <div className="favorite-item__labels-wrapper">
              { (shownAsRentedOut || temporarilyUnavailable) &&
                <div className="favorite-item__label favorite-item__label--rented-out">
                  <span>
                    { shownAsRentedOut
                      ?
                        <Fragment>
                          { I18n.t('activerecord.attributes.location.states.rented_out', {count: 0}) }&nbsp;{ (siteKey === 'dk' && rentedOutMonth) && `fra ${rentedOutMonth}` }
                        </Fragment>
                      :
                        I18n.t('activerecord.attributes.location.states.temporarily_unavailable')
                    }

                  </span>
                </div>
              }

              { anonymous &&
                <div className="favorite-item__label favorite-item__label--anonymous">
                  <span>{ I18n.t('generic.anonymous_location.detailed.label') }</span>
                </div>
              }

              { isInternal && !shownAsRentedOut && !temporarilyUnavailable &&
                <Fragment>
                  <div className="favorite-item__label favorite-item__label--parsed-top">
                    <span>{ I18n.t('generic.parsed_location') }</span>
                  </div>
                </Fragment>
              }
            </div>
          </div>

          <div className="favorite-item__info-block-wrapper">
            <div className="favorite-item__location-kinds">

              <span className="favorite-item__location-kind">
                { I18n.t(`generic.${rawKind}`) }
              </span>

              { !!secondaryKinds.length && <span>+{ secondaryKinds.length }</span> }
            </div>

            <div className="favorite-item__location-address">
              <p>{ addressLine1 }</p>
              <p>{ postalCodeAndName }</p>
            </div>

            { rawKind !== 'parking' && rawKind !== 'virtual_office' && area.value && (
              <div className="favorite-item__info-wrapper favorite-item__info-row">
                <p className="favorite-item__info-label">
                  <img data-src={require(`../../src/images/icons/${area.icon}.svg`)}
                       alt="area"
                       className="lazyload"
                       data-sizes="auto"
                       width="22px"
                       height="22px"
                       data-expand="100" />
                  { area.icon === 'desks' ? I18n.t('generic.desks') : area.label }
                </p>
                <p className="favorite-item__info-value">
                  { area.value } { area.icon !== 'desks' && area.unit }
                </p>
              </div>
            )}

            { !hidePrice &&
              <div className="favorite-item__info-wrapper">
                <div className="favorite-item__info-row favorite-item__info-row--price">
                  <p className="favorite-item__info-label">
                    { price.label }
                  </p>
                  <p className="favorite-item__info-value">
                    { price.value } { price.unit }
                  </p>
                </div>
              </div>
            }

            <div className="favorite-item__bottom">
              { !shownAsRentedOut && !temporarilyUnavailable &&
                <div className="favorite-item__button-wrapper" role="button">
                  { orderedLocations.includes(uuid)
                    ?
                      <button className="b-button favorite-item__info-button sent-info">
                        <SvgIco name="check" size={24} />
                        { I18n.t('apps.lb_showroom.detailed.order_form.cta_fixed_info_sent') }
                      </button>
                    :
                      <div className="b-button favorite-item__info-button" onClick={ this.handleOpenInfoForm }>
                        { I18n.t("apps.lb_showroom.detailed.order_form.get_information") }
                      </div>
                  }
                </div>
              }
            </div>
          </div>
        </a>

        <div id={'getInfoFormFavoriteWrapper' + id}>
          { isActiveInfoForm &&
            <GetInfoFormModal
              address={address}
              gtmClass="getInfoFormFavorite"
              handleClose={this.handleCloseInfoForm}
              id={id}
              isLoading={isLoading}
              // isJeudan={isJeudan}
              orderUrl={orderPath}
              parkingCheckbox={nearbyParkingLot}
              showServerErrorMessage={showServerErrorMessage}
              siteKey={siteKey}
              submitHandler={this.infoHandlerSubmit}
              submitSuccess={submitSuccess}
            />
          }
        </div>

        { showSearchOfferModal &&
          <SearchOfferModal
            apiCreateOrderDetailsPath={null}
            currency={currency}
            locationKind={rawKind}
            orderId={orderId}
            siteKey={siteKey}
          />
        }
      </div>
    );
  }
}



export default FavoritesItem;
