import React from 'react';

import './index.sass';


const CombinedLeases = ({ combinedLocations, isRentIncludeOperationalCost }) =>
  <div className="combined-leases">

    <p className="combined-leases__title">
      { I18n.t('apps.lb_showroom.detailed.combined_lease.title') }
    </p>

    { combinedLocations.map(lease =>
      <div className="combined-leases__row row">

        <div className="combined-leases__col combined-leases__kind col-12 col-md-2 col-lg-2 col-xl-2">
          { lease.kind }
        </div>

        <div className="combined-leases__col col-6 col-md-2 col-lg-3 col-xl-2">
          { lease.area ? lease.area + ' m²' : I18n.t('locations.index.not_available') }
          <div className="combined-leases__label">
            { I18n.t('apps.lb_showroom.detailed.area.area_label') }
          </div>
        </div>

        <div className="combined-leases__col col-6 col-md-3 col-lg-3 col-xl-2">
          { lease.yearlyPricePerM2 ? lease.yearlyPricePerM2 + ' kr.' : I18n.t('locations.index.not_available') }
          <div className="combined-leases__label">
            { I18n.t(isRentIncludeOperationalCost
              ? 'activerecord.attributes.location.yearly_rent_per_m2_include_operational_cost'
              : 'activerecord.attributes.location.yearly_rent_per_m2_from')
            }
          </div>
        </div>

        <div className="col-12 col-md-7 col-lg-8 col-xl-12">
          <div className="combined-leases__border" />
        </div>
      </div>
    )}

  </div>;


export default CombinedLeases;
