import React, { useEffect, useState, Suspense } from 'react';

import { SvgIco } from "../Icons";

const SpaceCalculatorModal = React.lazy(() => import('./SpaceCalculatorModal'));

import './index.sass';


const SpaceCalculator = ({ wrapperClass }) => {

  const [isOpen, setIsOpen] = useState(false);


  useEffect(() => {
    isOpen
      ? document.body.classList.add('modal-active')
      : document.body.classList.remove('modal-active');
  }, [isOpen]);


  return (
    <div className={`space-calculator ${wrapperClass || ''}`}>

      <div className="space-calculator__toggle" onClick={() => setIsOpen(true)}>
        <SvgIco name="calculate" size={24} />
        <div className="space-calculator__toggle-label">
          { I18n.t('space_calculator.toggle_label') }
        </div>
      </div>

      { isOpen &&
        <Suspense fallback={''}>
          <SpaceCalculatorModal
            isOpen={isOpen}
            handleClose={() => setIsOpen(false)}
          />
        </Suspense>
      }
    </div>
  )
};

export default SpaceCalculator;
