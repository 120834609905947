import React, { Fragment, Component } from 'react';
import classNames from 'classnames';
import Yup from 'yup';
import { withFormik } from 'formik';
import Modal from 'react-modal';

import Client from '../../../../utils/Client';
import alertErrorIcon from '../../../../src/images/icons/alert.svg';
import { validationPatterns } from '../../../../utils/validationPatterns';
import { SvgIco } from '../../../SharedComponents/Icons';

import './Contactform.sass';


const customStylesMobile = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: '9990',
    padding: '0'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    pointerEvents: 'visible',
    height: '100%',
    border: 'none',
    borderRadius: '0px'
  },
};


const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    overflow: 'auto',
    zIndex: '9990'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    pointerEvents: 'visible',
    background: 'white',
    borderRadius: '4px',
    position: 'absolute',
  },
};


const ContactFormWrapper = ({ errors,
                              handleBlur,
                              handleChange,
                              handleClose,
                              handleSubmit,
                              isAdviceForm,
                              openContactModal,
                              privacyPolicyPath,
                              setFieldValue,
                              showServerErrorMessage,
                              submitSuccess,
                              touched,
                              values,
                            }) => {

  const inputClassBuilder = (fieldName) =>
    classNames({
      'error': touched[fieldName] && errors[fieldName],
      'filled': values[fieldName],
    });

  const mobileOrTablet = window.innerWidth <= 1024;


  return (
    <Modal
      ariaHideApp={false}
      closeTimeoutMS={500}
      isOpen={openContactModal}
      onRequestClose={handleClose}
      style={ mobileOrTablet ? customStylesMobile : customStyles}
    >
      <div className="get-contact" id="get-contact-form">
        { mobileOrTablet
          ?
            <div className="get-contact__title-section">
              <div className='modal-close' onClick={handleClose}>
                <SvgIco name="arrow-back" size={24}/>
              </div>

              <div className="get-contact__title">
                <p>{ I18n.t('signup.teaser.tabs.tab.block.contact_us.link') }</p>
              </div>

              <div className='modal-close' onClick={handleClose}>
                <SvgIco name="close" size={24}/>
              </div>
            </div>
          :
            <div className="get-contact__header">
              <div>
                <div className={`server-error-message ${!showServerErrorMessage ? 'not-showing' : ''}`}>
                  <img
                    alt="alertErrorIcon"
                    className="lazyload"
                    data-sizes="auto"
                    data-src={alertErrorIcon}
                  />
                  <p>{ I18n.t('generic.server-error-listview-page')}&nbsp;
                    <a href={window.location.href}>{ I18n.t('general.refresh-page') }</a>
                  </p>
                </div>
              </div>
              <h2>
                { isAdviceForm ? I18n.t('signup.teaser.advice.modal_headline') : I18n.t('signup.teaser.agent.modal_headline') }
              </h2>
            </div>
        }

        <SvgIco
          className="get-contact__close"
          name="close"
          onClick={handleClose}
          size={22}
        />

        <div className="get-contact__body">
          { mobileOrTablet &&
            <>
              <div>
                <div className={`server-error-message ${!showServerErrorMessage ? 'not-showing' : ''}`}>
                  <img
                    alt="alertErrorIcon"
                    className="lazyload"
                    data-sizes="auto"
                    data-src={alertErrorIcon}
                  />
                  <p>
                    { I18n.t('generic.server-error-listview-page')}&nbsp;<a href={window.location.href}>{ I18n.t('general.refresh-page') }</a>
                  </p>
                </div>
              </div>
              <h2 className="get-contact__mobile-header">
                { isAdviceForm ? I18n.t('signup.teaser.advice.modal_headline') : I18n.t('signup.teaser.agent.modal_headline') }
              </h2>
            </>
          }

          <p className="get-contact__description">
            { isAdviceForm ? I18n.t('signup.teaser.advice.modal_text') : I18n.t('signup.teaser.agent.modal_text')}
          </p>

          <form
            className="get-contact__form"
            name="getContactModalForm"
            noValidate
            onSubmit={handleSubmit}>

            <input
              className="invisible"
              id="fifth_element"
              name="fifth_element"
              onChange={handleChange}
              onPaste={handleChange}
              type="text"
              value={values.fifth_element}
            />

            <div>
              <div className="form-group">
                <input
                  className={inputClassBuilder('company')}
                  id="company"
                  onChange={(e) => { setFieldValue('company', e.target.value.trimStart().replace(/\s\s+/g, ' ')) }}
                  onBlur={handleBlur}
                  type="text"
                  value={values.company}
                />
                <span className="floating-label">
                  { I18n.t('generic.company_name') }*
                </span>
                { touched.company && errors.company && <div className="error-tooltip">{ errors.company }</div> }
              </div>

              <div className="form-group">
                <input
                  className={inputClassBuilder('email')}
                  id="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                />
                <span className={`floating-label ${values.email && 'active'}`}>
                  { I18n.t('generic.email') }*
                </span>
                { touched.email && errors.email && <div className="error-tooltip">{ errors.email }</div> }
              </div>

              <div className="form-group">
                <input
                  className={inputClassBuilder('phone')}
                  id="phone"
                  name="phone"
                  type="tel"
                  onBlur={handleBlur}
                  onChange={(e) => { setFieldValue('phone', e.target.value.trimStart().replace(/\s\s+/g, ' ')) }}
                  value={values.phone}
                />
                <span className={`floating-label ${values.phone && 'active'}`}>
                  { I18n.t('generic.phone') }*
                </span>
                { touched.phone && errors.phone && <div className="error-tooltip">{ errors.phone }</div> }
              </div>
            </div>

            <div>
              <div className="form-group">
                { isAdviceForm
                  ?
                    <Fragment>
                      <input
                        className={inputClassBuilder('address')}
                        id="address"
                        name="address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.address}
                      />
                      <span className={`floating-label ${values.address && 'active'}`}>
                        { I18n.t('signup.teaser.advice.modal_address') }*
                      </span>
                      { touched.address && errors.address && <div className="error-tooltip">{ errors.address }</div> }
                    </Fragment>
                  :
                    <Fragment>
                      <input
                        className={inputClassBuilder('quantity_of_leases')}
                        id="quantity_of_leases"
                        name="quantity_of_leases"
                        onBlur={handleBlur}
                        onChange={(e) => (/^\d+$/.test(e.target.value) || !e.target.value) && setFieldValue('quantity_of_leases', e.target.value)}
                        value={values.quantity_of_leases || ''}
                      />
                      <span className={`floating-label ${values.quantity_of_leases && 'active'}`}>
                        { I18n.t('signup.teaser.agent.modal_leases_quantity') }*
                      </span>
                      { touched.quantity_of_leases && errors.quantity_of_leases && <div className="error-tooltip">{ errors.quantity_of_leases }</div> }
                    </Fragment>
                }
              </div>

              <div className="form-group">
                <textarea
                  className={values.comment ? 'filled' : ''}
                  id="comment"
                  resize="none"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.comment}
                />
                <span className="floating-label">
                  { isAdviceForm ? I18n.t('signup.teaser.advice.modal_comment') : I18n.t('signup.teaser.agent.modal_comment') }
                </span>
              </div>

              <div className="form-submit-section">
                <div className="order-button">
                  <button
                    className={`form-set-info-submit b-button ${ (!submitSuccess) && 'loading'} ${(submitSuccess) && 'sent-info'}`}
                    type="submit">
                    { submitSuccess
                      ? I18n.t('apps.lb_showroom.detailed.order_form.information_was_sent')
                      : (isAdviceForm ? I18n.t('signup.teaser.advice.button') : I18n.t('signup.teaser.tabs.tab.block.contact_us.text') )
                    }
                  </button>
                </div>

                <p>
                  { I18n.t('apps.lb_showroom.detailed.order_form.modal.policy_link_preline') }&nbsp;
                  <a href={privacyPolicyPath} className="private-policy-link">
                    { I18n.t('apps.lb_showroom.detailed.order_form.modal.privacy_policy') }
                  </a>
                </p>

              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

const { address, quantity_of_leases, fifth_element, phone, email, company } = Client.getClientInfo();
const { emailI18n, phoneI18n, companyI18n } = Client.getI18nAttrNames();
const i18nRequiredKey = 'apps.lb_showroom.detailed.order_form.validation.field_required';
const i18nMinNumberKey = 'apps.lb_showroom.detailed.order_form.validation.min_number';
const i18nSymbolsLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 254 });
const i18nSymbolsEmailLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 100 });
const i18nInvalidAddressInput = 'apps.lb_showroom.misc.scoped_form.address.invalid';
const i18nTooManySimilarSymbols = 'apps.lb_showroom.detailed.order_form.validation.too_many_similar_symbols';
const i18nAtLeastOneLetterIsRequired = 'apps.lb_showroom.detailed.order_form.validation.at_least_one_letter';
const i18nInvalidCompanyInput = 'apps.lb_showroom.misc.scoped_form.company.invalid';
const i18nAtLeast8Digits = 'apps.lb_showroom.detailed.order_form.validation.at_least_8_digits';


const ContactForm = withFormik({
  mapPropsToValues: (bag) => ({ address, quantity_of_leases, email, phone, fifth_element, company, isAdviceForm: bag.isAdviceForm }),
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .matches(validationPatterns.symbolsEmailLimit, i18nSymbolsEmailLimitErrorMessage)
      .email(I18n.t('apps.lb_showroom.detailed.order_form.validation.wrong_email'))
      .required(I18n.t(i18nRequiredKey, { field: emailI18n })),
    phone: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .min(8, I18n.t(i18nMinNumberKey, { field: phoneI18n }))
      .matches(validationPatterns.phone, I18n.t('apps.lb_showroom.misc.scoped_form.phone.invalid'))
      .test('minDigitsValidation', I18n.t(i18nAtLeast8Digits), function(value) {
        const { path, createError } = this;
        const phoneIsValid = value && value.replace(/[+\-\s()]/g, '').length >= 8;
        return phoneIsValid || !value || createError({ path, message: I18n.t(i18nAtLeast8Digits) });
      })
      .required(I18n.t(i18nRequiredKey, { field: phoneI18n })),
    company: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .matches(validationPatterns.company, I18n.t(i18nInvalidCompanyInput))
      .matches(validationPatterns.specialCharacktersNotMoreThan, I18n.t(i18nTooManySimilarSymbols))
      .matches(validationPatterns.atLeastOneLetter, I18n.t(i18nAtLeastOneLetterIsRequired))
      .required(I18n.t(i18nRequiredKey, { field: companyI18n })),
    isAdviceForm: Yup.boolean(),
    address: Yup.string()
      .when("isAdviceForm", {
        is: true,
        then: Yup.string()
          .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
          .matches(validationPatterns.company, I18n.t(i18nInvalidAddressInput))
          .matches(validationPatterns.specialCharacktersNotMoreThan, I18n.t(i18nTooManySimilarSymbols))
          .matches(validationPatterns.atLeastOneLetter, I18n.t(i18nAtLeastOneLetterIsRequired))
          .required(I18n.t(i18nRequiredKey, { field: I18n.t('signup.teaser.advice.modal_address') })),
      }),
    quantity_of_leases: Yup.string()
      .when("isAdviceForm", {
        is: false,
        then: Yup.string()
          .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
          .matches(validationPatterns.company, I18n.t(i18nInvalidAddressInput))
          .matches(validationPatterns.specialCharacktersNotMoreThan, I18n.t(i18nTooManySimilarSymbols))
          .matches(validationPatterns.atLeastOneLetter, I18n.t(i18nAtLeastOneLetterIsRequired))
          .required(I18n.t(i18nRequiredKey, { field: I18n.t('signup.teaser.agent.modal_leases_quantity') })),
      }),
    comment: Yup.string()
  }),

  handleSubmit: (values, bag) => {
    const currentValues = { ...values };
    const { isAdviceForm } = values;

    delete currentValues[isAdviceForm ? 'quantity_of_leases' : 'address'];
    delete currentValues.isAdviceForm;

    bag.props.submitHandler(values);
  },

  displayName: 'ContactForm',
})(ContactFormWrapper);

export default ContactForm;
