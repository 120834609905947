import React from 'react';

const DistancesListItem = ({ item, lazyload }) => {

  // If icon with facility doesn't exist yet we will show default checked icon
  const iconPathHandler = (iconName) => {
    try {
      return require(`../../../src/images/icons/facts_and_facilities/${iconName}.svg`);
    } catch (error) {
      return require(`../../../src/images/icons/facts_and_facilities/default.svg`);
    }
  };

  const backgroundImage = { backgroundImage: `url(${iconPathHandler(item.tag)})` };

  return (
    <div className="facts-and-facilities__list-item distances col-lg-6 col-md-6 col-12">
      <div className="facts-and-facilities__distance-item-type">
        <div className="facts-and-facilities__icon-wrapper">
          <div className={`facts-and-facilities__list-item-icon ${ lazyload ? 'lazyload' : '' }`}
               data-bg={iconPathHandler(item.tag)}
               data-sizes="auto"
               style={backgroundImage}
          />
        </div>
        <span>{ item.name }</span>
      </div>
      <div className="facts-and-facilities__transport-distance">{ item.distance } { item.unit }</div>
    </div>
  )
};

export default React.memo(DistancesListItem);
