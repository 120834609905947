import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { SvgIco } from "../../SharedComponents/Icons";
import { Context } from "../index";

import './index.sass';


const FacilitiesFilter = ({ clearFacilities, isMobileMenu, toggleClearFacilitiesFilter }) => {

  const {
    applyFacilitiesFilters,
    facilitiesList,
    selectedFacilities,
    setRequestParams,
    setSelectedFacilities,
  } = useContext(Context);

  const [facilitiesFilterOpen, setFacilitiesFilterOpen] = useState(false);
  const [currentSelectedFacilities, setCurrentSelectedFacilities] = useState(selectedFacilities || []);
  const [showMoreIsOpen, setShowMoreIsOpen] = useState(false);

  const filterWrapper = useRef();


  useEffect(() => {
    setCurrentSelectedFacilities(selectedFacilities);
  }, [selectedFacilities]);


  useEffect(() => {
    if (clearFacilities) {
      handleApplyFilters([]);
      toggleClearFacilitiesFilter();
    }
  }, [clearFacilities]);


  const toggleFacilitiesFilter = (currentState) => {
    const isOpen = !currentState;

    setFacilitiesFilterOpen(isOpen);
    isOpen
      ? window.addEventListener('click', handleFacilitiesOpenClick)
      : window.removeEventListener('click', handleFacilitiesOpenClick)
  };


  const handleFacilitiesOpenClick = (e) => {
    !filterWrapper && !filterWrapper.current && window.removeEventListener('click', handleFacilitiesOpenClick);

    const applyAllFiltersButtonMobile = document.getElementById('applyFiltersButtonMobile');

    filterWrapper
    && filterWrapper.current
    && !filterWrapper.current.contains(e.target)
    && (!applyAllFiltersButtonMobile || !applyAllFiltersButtonMobile.contains(e.target))
    && toggleFacilitiesFilter(true);
  };


  const handleFacilitiesCheckboxClick = (e) => {
    const value = e.target.value;
    const updatedList = [ ...currentSelectedFacilities ];
    const valueIndex = updatedList.indexOf(value);

    valueIndex >= 0 ? updatedList.splice(valueIndex, 1) : updatedList.push(value);
    handleApplyFilters(updatedList);
  };


  const handleSelectAllFacilities = () =>
    handleApplyFilters(facilitiesList.map(item => item.key))


  const handleApplyFilters = (newList) => {
    setCurrentSelectedFacilities(newList);
    newList && setRequestParams(prevParams => ({ ...prevParams, facilities: newList }));
    newList && setSelectedFacilities(newList);
    !isMobileMenu && applyFacilitiesFilters(newList);
  };

  const handleShowMore = () => {
    const showMore = !showMoreIsOpen;

    setShowMoreIsOpen(showMore);
    showMore && filterWrapper.current.scrollIntoView();
  };


  const filterIsOpen = !isMobileMenu || facilitiesFilterOpen;
  const selectedFacilitiesCount = currentSelectedFacilities.length;

  let selectedFacilitiesNames = [];
  currentSelectedFacilities.length && facilitiesList.map(listItem => currentSelectedFacilities.includes(listItem.key) && selectedFacilitiesNames.push(listItem.label));

  const dynamicList = facilitiesList && facilitiesList.length > 0 && facilitiesList.slice(0, 9);
  const currentFacilities = isMobileMenu || showMoreIsOpen ? facilitiesList : dynamicList;
  const showShowMoreLink = !isMobileMenu && facilitiesList.length > 9;


  if (currentFacilities && currentFacilities.length > 0) {
    return (
      <div className="facilities-filter" ref={filterWrapper}>
        <div className={`facilities-filter__dropdown${ filterIsOpen ? ' open' : '' }`} onClick={() => toggleFacilitiesFilter(facilitiesFilterOpen)}>
          <span>{ I18n.t('apps.lb_showroom.detailed.tabs.facilities') }<span>{ !!selectedFacilitiesCount && ` (${selectedFacilitiesCount})` }</span></span>
          <div className="dropdown-selector__trigger-right">
            { selectedFacilitiesNames.length > 0 &&
              <div className="dropdown-selector__selected-tooltip">
                { selectedFacilitiesNames.join(', ') }
              </div>
            }
            <div className="dropdown-selector__action-icon">
              { filterIsOpen
                ? <SvgIco name="expand-less" size={20} />
                : <SvgIco name="expand-more" size={20} />
              }
            </div>
          </div>
          <div className="facilities-filter__select-links">
            <div className="facilities-filter__select-all-link" onClick={handleSelectAllFacilities}>
              { I18n.t('apps.lb_showroom.postal_districts.select_all') }
            </div>
            <div className="facilities-filter__deselect-all-link" onClick={() => handleApplyFilters([])}>
              { I18n.t('apps.lb_showroom.postal_districts.deselect_all') }
            </div>
          </div>
        </div>

        { (filterIsOpen || isMobileMenu) &&
          <div className={`facilities-filter__list${ filterIsOpen ? ' open' : '' }`}>
            <div className="facilities-filter__top-wrapper">
              <div className="facilities-filter__select-links">
                <div className="facilities-filter__select-all-link" onClick={handleSelectAllFacilities}>
                  { I18n.t('apps.lb_showroom.postal_districts.select_all') }
                </div>
                <div className="facilities-filter__deselect-all-link" onClick={() => handleApplyFilters([])}>
                  { I18n.t('apps.lb_showroom.postal_districts.deselect_all') }
                </div>
              </div>
            </div>

            <div className={`facilities-filter__main-body${ filterIsOpen ? ' open' : '' }`}>
              <div className="facilities-filter__facilities-block">
                <div className="facilities-filter__facilities-list">
                  { currentFacilities.map(item =>
                    <div className="facilities-filter__list-item" key={item.key}>
                      <label className={`${currentSelectedFacilities.includes(item.key) ? 'active' : ''}`}>
                        <SvgIco name="check" size={18} />
                        <input
                          checked={currentSelectedFacilities.includes(item.key)}
                          onChange={(e) => handleFacilitiesCheckboxClick(e)}
                          type="checkbox"
                          value={item.key} />
                        { item.label }
                      </label>
                    </div>
                  )}
                </div>
              </div>
              { showShowMoreLink &&
                <div className="facilities-filter__show-more-link" onClick={handleShowMore}>
                  { showMoreIsOpen
                    ? I18n.t('views.locations.show.facilities.show_less_link')
                    : I18n.t('views.locations.show.facilities.show_more_link')
                  }
                </div>
              }
            </div>

            <div className="facilities-filter__select-links facilities-filter__select-links--bottom ">
              <div className="facilities-filter__select-all-link" onClick={handleSelectAllFacilities}>
                { I18n.t('apps.lb_showroom.postal_districts.select_all') }
              </div>
              <div className="facilities-filter__deselect-all-link" onClick={() => handleApplyFilters([])}>
                { I18n.t('apps.lb_showroom.postal_districts.deselect_all') }
              </div>
            </div>
            <div className="dropdown-selector__separator" />
          </div>
        }
      </div>
    )
  } else {
    return null;
  }
};


FacilitiesFilter.propTypes = {
  selectedFacilities: PropTypes.arrayOf(PropTypes.string),
  facilitiesList: PropTypes.arrayOf(PropTypes.object),
};

FacilitiesFilter.defaultProps = {
  selectedFacilities: [],
  facilitiesList: [],
};


export default FacilitiesFilter;
