import React from 'react';
import Slider from 'react-slick';

import { SvgIco } from "../../SharedComponents/Icons";
import TableContent from '../ContractsTop/TableContent';
import { tabDataBot } from '../praiceLinks';

import './index.sass';


const ContractsBottom = ({ advertisementStrategiesPrices,
                           contractDetails,
                           contractLink,
                           signupFormPath,
                           siteKey,
                         }) => {

  const settings = {
    adaptiveHeight: true,
    arrows: true,
    dots: true,
    infinite: true,
    nextArrow: <div className="testimonials__next-btn"><SvgIco name="keyboard-arrow-right-rounded-ios" size={32} /></div>,
    prevArrow: <div className="testimonials__prev-btn"><SvgIco name="keyboard-arrow-left-rounded-ios" size={32} /></div>,
    slidesToShow: 1,
    slidesToScroll: 1,
  };


  return (
    <div className="signup-tabs-wrapper gray-bg-contracts bottom-contracts" id="coworking">
      <div className="container">
        <div className="bottom-tabs-wrapper">
          <div className="meeting-room">

            <div className="signup-tabs-heading">
              <h2>{ I18n.t("apps.entry_page.entry_pages.show.lease.meeting_rooms") }</h2>
            </div>

            <div className="signup-tabs-subheading">
              <p>{ I18n.t('signup.teaser.prices.heading_sub') }</p>
              <a href={`${signupFormPath}?contract_id=${contractLink.annualRemoval.id}`}>
                { I18n.t("signup.teaser.meeting_rooms_free_treal_link") }
              </a>
            </div>

            <div className="tab-content">

              <Slider {...settings}>
                <TableContent
                  annual={true}
                  id={contractDetails.meetingRoomContracts.annualRemoval.id}
                  price={advertisementStrategiesPrices.prices.meetingRoom.default}
                  rate={'basic'}
                  siteKey={siteKey}
                  signupFormPath={signupFormPath}
                  tabInfo = {tabDataBot.basic}
                />

                <TableContent
                  annual={true}
                  id={contractDetails.meetingRoomContracts.annualRemovalPremium.id}
                  price={advertisementStrategiesPrices.prices.meetingRoomPremium.default}
                  rate={'premium premium-bottom'}
                  siteKey={siteKey}
                  signupFormPath={signupFormPath}
                  tabInfo = {tabDataBot.premium}
                />
              </Slider>

              <div className="tabs__wrapper desktops">
                <TableContent
                  annual={true}
                  id={contractDetails.meetingRoomContracts.annualRemoval.id}
                  price={advertisementStrategiesPrices.prices.meetingRoom.default}
                  rate={'basic'}
                  siteKey={siteKey}
                  signupFormPath={signupFormPath}
                  tabInfo = { tabDataBot.basic }
                />

                <TableContent
                  annual={true}
                  id={contractDetails.meetingRoomContracts.annualRemovalPremium.id}
                  price={advertisementStrategiesPrices.prices.meetingRoomPremium.default}
                  rate={'premium premium-bottom'}
                  siteKey={siteKey}
                  signupFormPath={signupFormPath}
                  tabInfo = {tabDataBot.premium}
                />
              </div>

              <div className="signup-tabs-bottom with-two-columns">
                <p dangerouslySetInnerHTML={{__html: I18n.t('signup.teaser.prices.footer')}} />
              </div>
            </div>
          </div>

          { siteKey === "dk" &&
            <div className="parking">
              <div className="signup-tabs-heading">
                <h2>{ I18n.t('generic.parking') }</h2>
              </div>

              <div className="signup-tabs-subheading">
                <p>{ I18n.t('signup.teaser.tabs.tab.block.parkering_subtitle_list_office_page_whole_phrase')}</p>
              </div>

              <div className="tab-content">
                <div className="tabs__wrapper">
                  <TableContent
                    annual={true}
                    contractName="parkering"
                    id="9"
                    price={advertisementStrategiesPrices.prices.parking.default}
                    rate={'parkering'}
                    siteKey={siteKey}
                    signupFormPath={signupFormPath}
                    tabInfo={tabDataBot.parkering}
                  />
                </div>
              </div>

              <div className="signup-tabs-bottom with-two-columns mobiles">
                <p dangerouslySetInnerHTML={{__html: I18n.t('signup.teaser.prices.footer')}} />
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ContractsBottom;
