import React, { Component } from 'react';
import Yup from 'yup';
import { withFormik } from 'formik';
import classNames from 'classnames';

import Client from '../../../utils/Client';
import { validationPatterns } from '../../../utils/validationPatterns';
import alertErrorIcon from '../../../src/images/icons/alert.svg';
import Loader from '../../SharedComponents/Loader';
import { SvgIco } from "../../SharedComponents/Icons";

import './index.sass';


class GetInfoFormModalWrapper extends Component {

  state = {
    dataProtectionIsOpen: false,
    // jeudanReadMore: false,
    // showJeudanValidationMessage: false,
    locationsListOpen: false,
  };


  componentDidMount() {
    document.getElementById('top-menu').classList.add('hidden');
    this.infoForm.addEventListener('click', this.handleClickOutside)
  };


  componentWillUnmount() {
    document.getElementById('top-menu').classList.remove('hidden');
    this.infoForm.removeEventListener('click', this.handleClickOutside)
  };


  handleClickOutside = (e) => {
    !this.infoFormWrapper.contains(e.target) && this.props.handleClose();
  };


  toggleDataProtection = () => {
    this.setState({ dataProtectionIsOpen: !this.state.dataProtectionIsOpen }, () => {
      this.state.dataProtectionIsOpen && setTimeout(() => {
        const targetTopCoords = document.getElementById('dataScrollTarget').getBoundingClientRect().top;
        const contentTopCoords = Math.abs(document.getElementById('infoFormContent').getBoundingClientRect().top);
        const targetCoords = Math.floor(targetTopCoords + contentTopCoords);
        this.infoForm.scroll(0, targetCoords);
      }, 300)
    });
  };


  // openReadMoreJeudan = () =>
  //   this.setState({ jeudanReadMore: true });


  // showJeudanValidationMessage = () =>
  //   this.setState({ showJeudanValidationMessage: true });


  handleSubmit = (e) => {
    e.preventDefault();
    this.props.errors && Object.keys(this.props.errors).length && this.infoForm.scroll(0, 0);
    // this.props.isJeudan && !this.props.values.jeudan_policy && this.showJeudanValidationMessage();
    this.props.handleSubmit();
  };


  openLocationsList = () => {
    const { locationsListOpen } = this.state;

    this.setState({ locationsListOpen: !locationsListOpen }, () => {
      this.state.locationsListOpen
        ? window.addEventListener('click', this.handleLocationsListState)
        : window.removeEventListener('click', this.handleLocationsListState);
    });
  };


  handleLocationsListState = (e) => {
    const locationsListWrapper = document.getElementById('locationsListWrapper');

    !locationsListWrapper.contains(e.target) && this.setState({ locationsListOpen: false });
  };


  render() {
    const {
      values,
      handleChange,
      touched,
      handleBlur,
      errors,
      parkingCheckbox,
      submitSuccess,
      // isJeudan,
      handleClose,
      address,
      gtmClass,
      showServerErrorMessage,
      isLoading,
      isMultiple,
      locations,
      setFieldValue,
    } = this.props;

    const {
      dataProtectionIsOpen,
      // jeudanReadMore,
      // showJeudanValidationMessage,
      locationsListOpen,
    } = this.state;

    const privacyPolicyPath = 'https://' + window.location.host + '/' + I18n.t('routes.privacy_url');
    const dataProtectionBodyClasses = classNames('data-protection__body', { 'open': dataProtectionIsOpen });
    const getInfoClasses = classNames(`get-info ${gtmClass}`, {
      'success': submitSuccess,
      'flex-start': dataProtectionIsOpen,
    });

    // const jeudanValidationMessageClasses = classNames('jeudan-policy-checkbox__validation-message', { 'visible': showJeudanValidationMessage });
    // const checkboxClasses = classNames('jeudan-policy-checkbox__wrapper', {
    //   'checked': values.jeudan_policy,
    //   'error': touched.jeudan_policy && errors.jeudan_policy,
    // });


    const inputClassBuilder = (fieldName) =>
      classNames({
        'error': touched[fieldName] && errors[fieldName],
        'filled': values[fieldName],
      });

    const locationsList = isMultiple && locations &&
      <div className="get-info__multiple-locations-address">
        <div className="get-info__address-line">
          <div className={`get-info__address-line-link-wrapper ${ locationsListOpen ? 'open' : '' }`}
               id="locationsListWrapper">
              <div className="get-info__address-line-link" onClick={this.openLocationsList}>
                { I18n.t('apps.lb_showroom.detailed.order_form.selected_locations_list') } ({ locations.length })
              </div>
            <div className="get-info__locations-list">
              { locations.map(location => <a href={location.url} target="_blank">{ location.address }</a>) }
            </div>
          </div>
        </div>
      </div>;


    return (
      <div ref={el => this.infoForm = el} className={getInfoClasses}>
        <div className="get-info__wrapper" ref={el => this.infoFormWrapper = el}>
          { submitSuccess &&
            <div className="get-info__success-wrapper">
              <SvgIco name="close" size={24} onClick={handleClose} className="get-info__close get-info__close--success" />
              { !isMultiple &&
                <div className="get-info__success-address-line">
                  <SvgIco name="check" size={22} />
                  <span className="get-info__address-line">{ address }</span>
                </div>
              }
              <h2 className="get-info__success-header">
                { I18n.t('signup.teaser.modal.thank_you.thanks') }
              </h2>
              <p>
                { I18n.t('apps.lb_showroom.detailed.order_form.success_content', { email: values.email }) }
              </p>
            </div>
          }
          { !submitSuccess &&
            <div>

              <div className="get-info__mobile-header">
                <SvgIco name="arrow-back" size={24} onClick={handleClose} />
                <h3>{ I18n.t('apps.lb_showroom.detailed.order_form.modal_main_top_title') }</h3>
                <SvgIco name="close" size={24} onClick={handleClose} />
              </div>

              <div className="get-info__content" id="infoFormContent">
                <div>
                  <div className={`server-error-message ${!showServerErrorMessage ? 'not-showing' : ''}`}>
                    <img src={alertErrorIcon} alt="alertErrorIcon"/>
                    <p>{ I18n.t('generic.server-error-listview-page') }&nbsp;<a href={window.location.href}>{ I18n.t('general.refresh-page') }</a></p>
                  </div>
                </div>

                <SvgIco name="close" size={24} onClick={handleClose} className="get-info__close mobile-hidden" />

                { isMultiple
                  ? locationsList
                  : <span className="get-info__address-line">{ address }</span>
                }

                <h2 className="get-info__title">{ I18n.t('apps.lb_showroom.detailed.order_form.headline') }</h2>
                <p className="get-info__description">{ I18n.t('apps.lb_showroom.detailed.order_form.description') }</p>

                <form
                  name="getInfoListViewForm"
                  className="get-info__form"
                  onSubmit={this.handleSubmit}
                  noValidate>

                  <input
                    id="fifth_element"
                    type="text"
                    name="fifth_element"
                    className="invisible"
                    value={values.fifth_element}
                    onPaste={handleChange}
                    onChange={handleChange}
                  />

                  <div>
                    <div className="form-group">
                      <input
                        id="name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={inputClassBuilder('name')}
                      />
                      <span className="floating-label">
                        { I18n.t('generic.name') }*
                      </span>
                      <div className="error-tooltip">{ errors.name }</div>
                    </div>

                    <div className="form-group">
                      <input
                        id="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={inputClassBuilder('email')}
                      />
                      <span className={`floating-label ${values.email && 'active'}`}>
                        { I18n.t('generic.email') }*
                      </span>
                      { touched.email && errors.email && <div className="error-tooltip">{ errors.email }</div> }
                    </div>

                    <div className="form-group">
                      <input
                        name="phone"
                        type="tel"
                        value={values.phone}
                        id="phone"
                        onChange={handleChange}
                        onChange={(e) => {
                          setFieldValue('phone', e.target.value.trimStart().replace(/\s\s+/g, ' '))
                        }}
                        onBlur={handleBlur}
                        className={inputClassBuilder('phone')}
                      />
                      <span className={`floating-label ${values.phone && 'active'}`}>
                        { I18n.t('generic.phone') }*
                      </span>
                      { touched.phone && errors.phone && <div className="error-tooltip">{ errors.phone }</div> }
                    </div>
                  </div>

                  <div>
                    <div className="form-group">
                      <input
                        id="company"
                        type="text"
                        value={values.company}
                        onChange={(e) => {
                          setFieldValue('company', e.target.value.trimStart().replace(/\s\s+/g, ' '))
                        }}
                        onBlur={handleBlur}
                        className={inputClassBuilder('company')}
                      />
                      <span className={`floating-label ${values.company && 'active'}`}>
                        { I18n.t('generic.company_name') }*
                      </span>
                      { touched.company && errors.company && <div className="error-tooltip">{ errors.company }</div> }
                    </div>

                    <div className="form-group">
                      <textarea
                        id="comment"
                        type="text"
                        resize="none"
                        value={values.comment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={values.comment ? 'filled' : ''}
                      />
                      <span className="floating-label">
                        { I18n.t('activemodel.attributes.provider_service_request.your_question')} ({I18n.t('activemodel.attributes.provider_service_request.your_option')})
                      </span>
                    </div>
                    <div className="form-submit-section">

                      {/* TEMPORARY SOLUTION */}

                      {/*{ parkingCheckbox &&*/}
                      {/*  <div className="parking-section">*/}
                      {/*    <div className="material-icons parking-icon">*/}
                      {/*      local_parking*/}
                      {/*    </div>*/}
                      {/*    <label className="parking-label" htmlFor="parking">{I18n.t('apps.lb_showroom.detailed.order_form.send_near_parking_lots')}*/}
                      {/*      <input*/}
                      {/*        id="option_parking_lots"*/}
                      {/*        type="checkbox"*/}
                      {/*        value={values.option_parking_lots}*/}
                      {/*        checked={values.option_parking_lots === true}*/}
                      {/*        onChange={handleChange}*/}
                      {/*        onBlur={handleBlur}*/}
                      {/*      />*/}
                      {/*    </label>*/}
                      {/*  </div>*/}
                      {/*}*/}

                      {/*{ isJeudan && isMultiple &&*/}
                      {/*  <div className="jeudan-policy-checkbox__multi-tooltip">*/}
                      {/*    { I18n.t('activemodel.attributes.provider_service_request.jeudan_privacy_policy_notice') }*/}
                      {/*  </div>*/}
                      {/*}*/}

                      {/*{ isJeudan &&*/}
                      {/*  <div className="form-group jeudan-policy-checkbox">*/}
                      {/*    <div className={checkboxClasses}>*/}
                      {/*      <SvgIco name="check" size={14} />*/}
                      {/*      <input*/}
                      {/*        name="jeudan_policy"*/}
                      {/*        type="checkbox"*/}
                      {/*        value={values.jeudan_policy}*/}
                      {/*        id="jeudan_policy"*/}
                      {/*        onChange={handleChange}*/}
                      {/*        onBlur={handleBlur}*/}
                      {/*      />*/}

                      {/*      <div className={`jeudan-policy-checkbox__label ${isMultiple ? 'bold' : ''}`}>*/}
                      {/*        { jeudanReadMore*/}
                      {/*          ? <div dangerouslySetInnerHTML={{ __html: I18n.t('activemodel.attributes.provider_service_request.jeudan_privacy_policy_full')}}*/}
                      {/*                 className="jeudan-policy-checkbox__label-content" />*/}
                      {/*          : <div>*/}
                      {/*              { I18n.t('activemodel.attributes.provider_service_request.jeudan_privacy_policy_short') }*/}
                      {/*              <span onClick={this.openReadMoreJeudan} className="jeudan-policy-checkbox__label-read-more-link">*/}
                      {/*                { I18n.t('generic.read_more') }.*/}
                      {/*              </span>*/}
                      {/*            </div>*/}
                      {/*        }*/}
                      {/*        { touched.jeudan_policy && errors.jeudan_policy &&*/}
                      {/*          <div className={jeudanValidationMessageClasses}>*/}
                      {/*            { touched.jeudan_policy && errors.jeudan_policy ? errors.jeudan_policy : null }*/}
                      {/*          </div>*/}
                      {/*        }*/}
                      {/*      </div>*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*}*/}

                      <div className="order-button">
                        <button
                          disabled={isLoading}
                          className={`form-set-info-submit b-button ${ isLoading ? 'loading' : ''}`}
                          type="submit">
                          <div className="form-set-info-submit__wrapper">
                            { isLoading && <Loader size={18} left={-26} top={3}/> }
                            <span>{ I18n.t('apps.lb_showroom.detailed.order_form.send_information') }</span>
                          </div>
                        </button>
                      </div>

                      <div onClick={this.openInfoModal} className="data-protection">
                        <div className="data-protection__toggle" onClick={this.toggleDataProtection}>
                          <SvgIco name="lock" size={22} className="data-protection__info-icon" />
                          <span>{ I18n.t('apps.lb_showroom.detailed.order_form.data_protection') }</span>
                          { dataProtectionIsOpen
                            ? <SvgIco name="expand-less" size={16} />
                            : <SvgIco name="expand-more" size={16} />
                          }
                        </div>

                        <div className="data-protection__wrapper">
                          <div id="dataScrollTarget" />
                          <div className={dataProtectionBodyClasses}>
                            <div className="data-protection__headline">{ I18n.t('apps.lb_showroom.detailed.order_form.data_protection') }</div>
                            <div className="data-protection__content" dangerouslySetInnerHTML={{ __html: I18n.t('apps.lb_showroom.detailed.order_form.modal.data_protection_body') }} />
                            <div className="data-protection__policy-link">
                              <p>{ I18n.t('apps.lb_showroom.detailed.order_form.modal.policy_link_preline') }
                                &nbsp;
                                <a href={privacyPolicyPath}>{ I18n.t('apps.lb_showroom.detailed.order_form.modal.privacy_policy') }</a>.
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </form>

                <div className="data-protection__wrapper mobile-hidden">
                  <div id="dataScrollTarget" />
                  <div className={dataProtectionBodyClasses}>
                    <div className="data-protection__headline">{ I18n.t('apps.lb_showroom.detailed.order_form.data_protection') }</div>
                    <div className="data-protection__content" dangerouslySetInnerHTML={{ __html: I18n.t('apps.lb_showroom.detailed.order_form.modal.data_protection_body') }} />
                    <div className="data-protection__policy-link">
                      <p>{ I18n.t('apps.lb_showroom.detailed.order_form.modal.policy_link_preline') }
                        &nbsp;
                        <a href={privacyPolicyPath}>{ I18n.t('apps.lb_showroom.detailed.order_form.modal.privacy_policy') }</a>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}


const { fifth_element, name, phone, email, company, option_parking_lots } = Client.getClientInfo();
const { nameI18n, emailI18n, companyI18n, phoneI18n } = Client.getI18nAttrNames();
const i18nRequiredKey = 'apps.lb_showroom.detailed.order_form.validation.field_required';
const i18nMinNumberKey = 'apps.lb_showroom.detailed.order_form.validation.min_number';
const i18nSymbolsLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 254 });
const i18nSymbolsEmailLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 100 });
const i18nAtLeast8Digits = 'apps.lb_showroom.detailed.order_form.validation.at_least_8_digits';

const GetInfoFormModal = withFormik({
  mapPropsToValues: (bag) => ({
    name,
    email,
    company,
    phone: phone ? phone : bag.userCountryPhoneCode,
    option_parking_lots,
    fifth_element,
    // jeudan_policy: false,
    // isJeudan: bag.isJeudan
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .matches(validationPatterns.name, I18n.t("apps.lb_showroom.misc.scoped_form.name.invalid"))
      .required(I18n.t(i18nRequiredKey, { field: nameI18n })),
    email: Yup.string()
      .matches(validationPatterns.symbolsEmailLimit, i18nSymbolsEmailLimitErrorMessage)
      .email(I18n.t('apps.lb_showroom.detailed.order_form.validation.wrong_email'))
      .required(I18n.t(i18nRequiredKey, { field: emailI18n })),
    company: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .matches(validationPatterns.company, I18n.t("apps.lb_showroom.misc.scoped_form.company.invalid"))
      .required(I18n.t(i18nRequiredKey, { field: companyI18n })),
    phone: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .min(8, I18n.t(i18nMinNumberKey, { field: phoneI18n }))
      .matches(validationPatterns.phone, I18n.t('apps.lb_showroom.misc.scoped_form.phone.invalid'))
      .test('minDigitsValidation', I18n.t(i18nAtLeast8Digits), function(value) {
        const { path, createError } = this;
        const phoneIsValid = value && value.replace(/[+\-\s()]/g, '').length >= 8;
        return phoneIsValid || !value || createError({ path, message: I18n.t(i18nAtLeast8Digits) });
      })
      .required(I18n.t(i18nRequiredKey, { field: phoneI18n })),
    parking: Yup.boolean(),
    // isJeudan: Yup.boolean(),
    // jeudan_policy: Yup.boolean()
    //   .when("isJeudan", {
    //     is: true,
    //     then: Yup.boolean()
    //              .required(I18n.t('activemodel.attributes.provider_service_request.jeudan_privacy_policy_error_message'))
    //              .oneOf([true], I18n.t('activemodel.attributes.provider_service_request.jeudan_privacy_policy_error_message')),
    //   })
  }),

  handleSubmit: (values, bag) => {
    Client.updateClientInfo(values);
    bag.props.submitHandler(values, bag.props.orderUrl);
  },

  displayName: 'GetInfoForm',
})(GetInfoFormModalWrapper);


export default GetInfoFormModal;
