import React, { Component } from 'react';
import { withFormik } from 'formik';
import Yup from 'yup';

import SignUp from '../../utils/SignUp';
import { validationPatterns } from '../../utils/validationPatterns';

import './ForgotPassword.sass';


class ForgotPasswordFormWrapper extends Component {

  errorValidator = (field) => {
    const { errors, touched, emailServerError } = this.props;
    const fieldError = errors[field] && touched[field] && 'forgot-password-form__form-group-error';

    return (emailServerError && 'forgot-password-form__form-group-error') || fieldError;
  };

  render() {

    const { values, handleChange, handleBlur, errors, handleSubmit, emailServerError, submitSuccessMessage, handleError } = this.props;


    return (
      <div className="row">
        <div className="col-12 col-md-6 col-lg-5 col-xl-4">
          <div className="forgot-password-form">
            <form name="forgotPasswordForm" className="forgot-password-form__form" onSubmit={ handleSubmit } noValidate>
              <input
                id="fifth_element"
                type="text"
                name="fifth_element"
                className="invisible"
                value={values.fifth_element}
                onPaste={handleChange}
                onChange={handleChange}
              />

              { submitSuccessMessage &&
                <div className="forgot-password-form__input-tooltip-success">
                  { submitSuccessMessage }
                </div>
              }

              <div className={`form-group ${ this.errorValidator('email')}`}>
                <label className="forgot-password-form__label" htmlFor="email">
                  <input
                    name="email"
                    type="email"
                    value={values.email}
                    id="email"
                    onChange={e => {
                      handleChange(e);
                      (emailServerError || submitSuccessMessage) && handleError();
                    }}
                    onBlur={handleBlur}
                    data-tip={errors.email}
                    className={`forgot-password-form__input ${values.email  && 'active'}`}
                  />
                  <span className={`floating-label ${values.email && 'active'}`}>
                { I18n.t('views.sessions.new.login.email') }*
              </span>
                </label>
                <div className="forgot-password-form__input-tooltip-error">
                  { errors.email }
                </div>
                { emailServerError &&
                  <div className="forgot-password-form__input-tooltip-error">
                    { emailServerError }
                  </div>
                }
              </div>

              <div className="form-group form-group--submit">
                <button type="submit" className="b-button">
                  { I18n.t('providers.sessions.forgot_password.send') }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const { email, fifth_element } = SignUp.getClientInfo();
const { emailI18n } = SignUp.getI18nAttrNames();

const i18nRequiredKey = 'apps.lb_showroom.detailed.order_form.validation.field_required';
const i18nInvalidEmailInput = 'apps.lb_showroom.detailed.order_form.validation.wrong_email';
const i18nSymbolsEmailLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 100 });

const ForgotPasswordForm = withFormik({
  mapPropsToValues: () => { return { email, fifth_element }},

  validationSchema: Yup.object().shape({
    email: Yup.string()
              .matches(validationPatterns.symbolsEmailLimit, i18nSymbolsEmailLimitErrorMessage)
              .email(I18n.t(i18nInvalidEmailInput))
              .required(I18n.t(i18nRequiredKey, { field: emailI18n })),
  }),

  handleSubmit: (values, bag) => {
    bag.props.handleSubmit(values);
  },

  displayName: 'LoginForm',
})(ForgotPasswordFormWrapper);

export default ForgotPasswordForm;
