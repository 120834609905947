import React from 'react';

import './UnsupportedBrowserMessage.sass'

const UnsupportedBrowserMessage = ({ browserName, handleClose }) => {
  return (
    <div className="unsupported-browser">
      <div className="unsupported-browser__content container">
        <div className="unsupported-browser__message">
          <p className="unsupported-browser__title">
            { `${ I18n.t('apps.lokalebasen_header.browser_message.title') } ${ browserName }`}
          </p>
          <p className="unsupported-browser__message">
            { I18n.t('apps.lokalebasen_header.browser_message.message') }
          </p>
        </div>
        <div className="unsupported-browser__close-btn" onClick={ handleClose } />
      </div>
    </div>
  )
};

export default UnsupportedBrowserMessage;