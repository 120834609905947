import React from 'react';
import { Pie, PieChart, Cell } from "recharts";

import './PercentagePieChart.sass';


const PercentagePieChart = ({ filledPercents }) => {

  const data = [
    { name: 'filled', value: filledPercents },
    { name: 'empty', value: 100 - filledPercents },
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (props) => {
    const {cx, cy, midAngle, innerRadius, outerRadius, payload} = props;

    if (payload && payload.name !== 'empty') {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text x={x} y={y}
              fill="#77bc1f"
              textAnchor={x > cx ? 'start' : 'end'}
              dominantBaseline="central"
              fontWeight={900}
              fontSize={34}>
          { filledPercents }%
        </text>
      );
    }

    return null;
  };

  return (
    <div className="pie-chart">
      <PieChart width={328} height={328}>
        <Pie
          data={data}
          dataKey="value"
          cx={159}
          cy={159}
          startAngle={90}
          endAngle={-360}
          outerRadius={155}
          fill="rgba(119, 188, 31, 0.1)"
          paddingAngle={0}
          label={renderCustomizedLabel}
          stroke={'none'}
        >
          { data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={index === 0 ? 'rgba(119, 188, 31, 0.1)' : 'white'} />
          ))}
        </Pie>
        <Pie
          data={data}
          dataKey="value"
          cx={159}
          cy={159}
          innerRadius={155}
          outerRadius={164}
          startAngle={90}
          endAngle={-360}
          fill="#82ca9d"
          stroke={'none'}>
          { data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={index === 0 ? '#77bc1f' : '#E0E2E2'} />
          ))}
        </Pie>
      </PieChart>
    </div>
  )
}

export default PercentagePieChart;
