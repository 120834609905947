import React from 'react';

import './TrustLogos.sass';

const TrustLogos = ({ trustedLogosUrl, trustedLogos }) => {

  return (
    <div className="trusted-logos">
      <div className="container">
        <div className="trusted-logos__wrapper">
          <div className="trusted-logos__header">
            { I18n.t('apps.lb_showroom.detailed.order_form.logos-membership') }
          </div>
          <ul className="trusted-logos__list">
            { trustedLogos.map(logo =>
              <li className="trusted-logos__logo-item" key={logo.image}>
                <a href={trustedLogosUrl} target="_blank" className="trusted-logos__logo-link">
                  <img
                    data-src={require(`../../src/images/logos/${logo.image}`)}
                    alt={logo.image}
                    className="trusted-logos__logo-img lazyload"
                    height={40}
                    data-sizes="auto"
                    data-expand="100"
                  />
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
};

export default TrustLogos;
