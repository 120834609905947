import React, { useContext, useEffect, useRef, useState } from 'react';
import { MarkerClusterer } from '@react-google-maps/api';

import { Context } from "../index";
import InfoWrapper from './InfoWrapper'
import MapMarker from "./MapMarker";


const MapWrapper = ({ boundsTrigger,
                      closeInfoWindow,
                      markersArray,
                    }) => {

  const { hoveredLocationId } = useContext(Context);

  const [activeCluster, setActiveCluster] = useState(null);
  const [activePlaceId, setActivePlaceId] = useState(null);

  const infoWindow = useRef();
  const clusterWrapper = useRef();


  useEffect(() => {
    if (hoveredLocationId) {
      highlightCluster();
    } else {
      activeCluster && activeCluster.clusterIcon.div && activeCluster.clusterIcon.div.classList.remove('active')
      setActiveCluster(null);
    }
  }, [hoveredLocationId]);


  useEffect(() => {
    closeInfoWindow && infoWindow && infoWindow.current.close();
  }, [closeInfoWindow]);


  useEffect(() => {
    infoWindow && infoWindow.current.close();
  }, [markersArray]);


  const highlightCluster = () => {
    const clusters = clusterWrapper && clusterWrapper.current && clusterWrapper.current.state && clusterWrapper.current.state.markerClusterer.getClusters();
    let currentCluster;

    clusters && clusters.length && clusters.map(cluster => {
      const markers = cluster.getMarkers();
      const hoveredMarker = markers.find(marker => marker.metadata.id === hoveredLocationId);

      if (hoveredMarker) {
        activeCluster && activeCluster.clusterIcon.div && activeCluster.clusterIcon.div.classList.remove('active');
        cluster.clusterIcon.div.classList.add('active');
        currentCluster = cluster;
      }
    });

    !currentCluster && activeCluster && activeCluster.clusterIcon.div && activeCluster.clusterIcon.div.classList.remove('active');
    setActiveCluster(currentCluster || null);
  };


  const handleActiveMarker = (id, pos, ids) =>
    infoWindow.current.show(id, pos, ids);


  const filteredArray = (markersArray && markersArray.filter(e => (e.location.lat && e.location.lon))) || [];
  const markersData = filteredArray.map(({id, location}) => {
    const marker = { lng: location.lon, lat: location.lat, id: location.id ? location.id : id };

    location.ids && (marker.ids = location.ids);

    return marker;
  });


  return (
    <div>
      { !boundsTrigger && markersData.length &&
        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          maxZoom={15}
          gridSize={60}
          clusterClass='cluster'
          ref={clusterWrapper}
        >
          { clusterer => (
            <div>
              { markersData.map((location, index) => {

                const noClustererRedraw = index !== (markersData.length - 1);

                return(
                  <MapMarker
                    activePlaceId={activePlaceId}
                    clusterer={clusterer}
                    handleActiveMarker={handleActiveMarker}
                    hoveredLocationId={hoveredLocationId}
                    key={`marker-${location.id}-${index}`}
                    location={location}
                    noClustererRedraw={noClustererRedraw}
                  />
                )
              })
              }
            </div>
          )}
        </MarkerClusterer>
      }

      <InfoWrapper
        activePlaceId={activePlaceId}
        ref={infoWindow}
        setActivePlaceId={setActivePlaceId}
      />

    </div>
  );
}

export default MapWrapper;
