import React, { Fragment } from 'react';
import { isMobile, isTablet, isIPad13 } from 'react-device-detect';
import Modal from 'react-modal';

import { apiProvider } from '../../../api/core';
import ThankYouIcon from '../../../src/images/list_office/thank_you.svg';
import RequestCallForm from './RequestCallForm';

import './index.sass';
import {SvgIco} from "../../SharedComponents/Icons";


const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    top: '0',
    overflow: 'auto',
    zIndex: '9999'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    pointerEvents: 'visible',
    background: 'white',
    borderRadius: '4px',
    position: 'absolute',
  },
};


class RequestCallModal extends React.PureComponent {
  state = {
    modalWasShown: true,
    modalIsOpen: false,
    mobileModalIsOpen: false,
    requestSuccess: false,
    submitError: false,
    pendingRequest: false,
  };

  hideModal = () =>
    this.setState({
      modalWasShown: true,
      modalIsOpen: false,
      mobileModalIsOpen: false
    }, () => {
      document.removeEventListener('unload', this.showModal);
      document.body.removeEventListener('mouseleave', this.showModal);
    });

  showModal = () => {
    const getContactFormOpen = document.getElementById('get-contact-form');
    !this.props.hideModal && !this.state.modalWasShown && !getContactFormOpen && this.setState({ modalIsOpen: true });
  };

  showMobileModal = () => {
    const getContactFormOpen = document.getElementById('get-contact-form');
    this.props.hideModal
      ? setTimeout(this.showMobileModal, 30000)
      : !getContactFormOpen && this.setState({ mobileModalIsOpen: true });
  };

  componentDidMount() {
    setTimeout(this.showMobileModal, 30000);
    setTimeout(() => this.setState({ modalWasShown: false }), 5000);
    document.addEventListener('unload', this.showModal);
    document.body.addEventListener('mouseleave', this.showModal);
  };

  handleSubmit = (data) => {

    this.setState({ pendingRequest: true });

    const url = '/call_request';
    const params = new FormData();

    params.append('call_request[phone_number]', data.phone);
    params.append('call_request[ohtrryvhfhruk]', data.fifth_element);
    params.append('button', '');

    apiProvider.post(url, params, true)
               .then(response =>
                 response.status === 'success'
                  ? this.setState({ requestSuccess: true, pendingRequest: false }, () => setTimeout(this.hideModal, 3000))
                  : this.setState({ pendingRequest: false })
               );
  }

  render() {

    const { modalWasShown, modalIsOpen, mobileModalIsOpen, requestSuccess, submitError, pendingRequest } = this.state;
    const isMobileDevice = isMobile || isTablet || isIPad13;
    const showModal = isMobileDevice
                      ? !modalWasShown && mobileModalIsOpen
                      : !modalWasShown && modalIsOpen;

    return (
      <Modal
        isOpen={showModal}
        onRequestClose={this.hideModal}
        contentLabel="Request a call modal"
        ariaHideApp={false}
        style={customStyles}
        closeTimeoutMS={500}
        className="call-modal"
      >
        <div className={`call-modal__wrapper${requestSuccess ? ' call-modal__wrapper--success' : ''}`}>
          <div className="call-modal__close-btn" onClick={this.hideModal}>
            <SvgIco name="close" size={24} />
          </div>
            { requestSuccess
              ? <Fragment>
                  <div>
                    <h2>{ I18n.t('signup.teaser.modal_close.thank-header') }</h2>
                    <p className="call-modal__thanks-content">{ I18n.t('signup.teaser.modal_close.thank-text') }</p>
                    <button className="call-modal__thank-close-button" onClick={this.hideModal}>{I18n.t('generic.close')}</button>
                  </div>
                  <div className="call-modal__icon-wrapper">
                    <img src={ThankYouIcon} alt="thanks icon" />
                  </div>
                </Fragment>
              : <Fragment>
                  <div className="call-modal__img" />
                  <div className="call-modal__body">
                    <h2>{ I18n.t('signup.teaser.modal_close.header') }</h2>
                    <p>{ I18n.t('signup.teaser.modal_close.text') }</p>
                    <RequestCallForm submitHandler={this.handleSubmit} submitError={submitError} pendingRequest={pendingRequest}/>
                  </div>
                </Fragment>
            }
          </div>
      </Modal>
    )
  }
}

export default RequestCallModal;
