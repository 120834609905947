import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';

import { apiProvider } from '../../../api/core';
import { SvgIco } from '../../SharedComponents/Icons';
import GetContactModalForm from './GetContactModalForm';
import FormSentModalThankyou from './FormSentModalThankyou';
import ContactBlock from "./ContactBlock";

import './TableContent.sass';

class TableContent extends Component {

  state = {
    openCollapseMenu: false,
    openContactModal: false,
    openThankModal: false,
    formSent: false,
    showServerErrorMessage: false,
  };


  openEnterpriceModal = () => {
    this.setState({openContactModal: true, openThankModal: false, formSent: false})
  };

  closeModalForm = () => {
    this.setState({openContactModal: false, openThankModal: false, showServerErrorMessage: false})
  };

  openCollapseMenu = () => {
    this.setState({openCollapseMenu: !this.state.openCollapseMenu});
    this.props.updateSliderHeight()
  };

  infoHandlerSubmit = (params) => {
    const bodyFormData = new FormData();
    delete params.fifth_element;

    for (let key in params) {
      bodyFormData.append(key, params[key]);
    }

    // we need to add back end for it (now it is just mock up request)
    apiProvider.post(this.props.contactUsFormPath, bodyFormData, true)
               .then(response =>
                 response.error
                   ? this.setState({ showServerErrorMessage: true })
                   : this.setState({
                       formSent: true,
                       openThankModal: true,
                       openContactModal: false,
                       showServerErrorMessage: false,
                     })
               );
  };

  textTopGreenLine = (rate) => {
    switch (rate) {
      case 'plus':
        return <p>{ I18n.t('signup.teaser.tabs.tab.plus-rate-green-wrapper') }</p>;
      case 'premium premium-bottom':
        return <p>{ I18n.t('signup.teaser.tabs.tab.plus-rate-green-wrapper') }</p>;
      case 'premium':
        return <p>{ I18n.t('signup.teaser.tabs.tab.premium-rate-green-wrapper') }</p>;
      case 'premium-plus':
        return <p>{ I18n.t('signup.teaser.advice.headline') }</p>;
    }
  };

  render() {
    const {
      activeTab,
      annual,
      contactUsFormPath,
      contractName,
      danishInfoFullPriceList,
      dropDownPrices,
      id,
      payPerShow,
      price,
      priceSection,
      privacyPolicyPath,
      rate,
      signupFormPath,
      siteKey,
      tabInfo,
    } = this.props;

    const checkRate = (rate === 'plus' || rate === 'premium');
    const greenTopLine = rate !== 'basic' && rate !== 'enterprise';
    const enterprise = rate === 'enterprise';
    const { headerImage, headerText, headerTextLease, linksLease, linksLeaseDk, linksPayPerShow } = tabInfo;
    const selectLinks = activeTab ? linksPayPerShow : siteKey === 'dk' && linksLeaseDk || linksLease;
    const headertext = activeTab ? headerText : siteKey === 'dk' && headerTextLease || headerText;

    if (siteKey !== 'dk' && checkRate) {
      linksLease.shift();
      linksPayPerShow.shift();
    };


    return (
      <div className={`tab__block ${rate}`}>
        { greenTopLine && this.textTopGreenLine(rate) &&
          <div className="green-block-wrapper">
            { this.textTopGreenLine(rate) }
          </div>
        }

        { enterprise &&
          <div className="get-contact-form">
            <GetContactModalForm
              openContactModal={this.state.openContactModal}
              handleClose={this.closeModalForm}
              submitHandler={this.infoHandlerSubmit}
              orderUrl={signupFormPath}
              showServerErrorMessage={this.state.showServerErrorMessage}
              contactUsFormPath={contactUsFormPath}
              privacyPolicyPath={privacyPolicyPath}
              formSent={this.state.formSent}
            />
          </div>
        }

        <div className="tab__block__head">
          <div className="heading-text">
            { headertext.subhead &&
              <span>
                { I18n.t(headertext.subhead) }
              </span>
            }

            { !dropDownPrices && headertext.head &&
              <span className="subhead">
                { I18n.t(headertext.head) }
              </span>
            }
          </div>

          <div className="subheading-text">
            { payPerShow
              ? I18n.t(headertext.subhead_text_dk_annouce)
              : I18n.t(headertext.subhead_text)
            }
          </div>

        </div>

        <div className="tab__block__body">
          { headerImage && siteKey === 'dk' &&
            <img
              alt="basic-plan"
              className="plan-image lazyload"
              data-sizes="auto"
              data-src={headerImage}
            />
          }

          { enterprise
            ?
              <ContactBlock siteKey={siteKey} />
            :
              <div className="body-price">
                 { annual &&
                  <>
                    <strong>
                      { price || 0 }
                    </strong>
                    <span>
                      <span className="price-description-text">
                        { I18n.t('signup.teaser.prices.per_item') }
                      </span>
                    </span>
                  </>
                }

                { !annual &&
                  <>
                    <strong>
                      <span>
                        { danishInfoFullPriceList && danishInfoFullPriceList.length
                          ? `${ I18n.t('generic.from') } ${danishInfoFullPriceList[1].price}`
                          : priceSection.heading
                        }
                      </span>
                    </strong>

                    <span className="price-description-text">
                      { siteKey === 'dk' && activeTab
                        ? I18n.t('signup.teaser.prices.per_item')
                        : I18n.t('signup.teaser.tabs.tab.block.per_rent')
                      }
                    </span>

                    { priceSection.onDealBasicPrice &&
                      <>
                        <strong>
                          <span>{priceSection.onDealBasicPrice}</span>
                        </strong>
                        <span>
                          <span className="price-description-text">
                            { I18n.t('signup.teaser.prices.per_item') }
                          </span>
                        </span>
                      </>
                    }
                  </>
                }

                { dropDownPrices && siteKey === 'dk' &&
                  <div className="body__collapse">
                    <div aria-controls="collapse-block"
                         aria-expanded={this.state.openCollapseMenu}
                         onClick={this.openCollapseMenu} className="collapse__heading">

                      { this.state.openCollapseMenu
                        ? <span>{ I18n.t('signup.teaser.tabs.tab.block.collapse.heading_closed') }</span>
                        : <span>{ I18n.t("signup.teaser.tabs.tab.block.collapse.heading") }</span>
                      }

                      { this.state.openCollapseMenu
                        ? <SvgIco name="expand-less" size={18} />
                        : <SvgIco name="expand-more" size={18} />
                      }
                    </div>

                    <Collapse in={this.state.openCollapseMenu}>
                      <div className="collapse-block">

                        { danishInfoFullPriceList.map((elem, index) =>
                          <div className="collapse-block__item" key={index}>
                            <div className="meters-info">
                              { elem.area } <sup>2</sup>
                            </div>
                            <div className="price-info">
                              { elem.price }
                            </div>
                          </div>
                        )}
                      </div>
                    </Collapse>
                  </div>
                }
              </div>
          }

          <div className="body-footer">
            { enterprise
              ? <div onClick={this.openEnterpriceModal} className="signup-cta-button main-button">
                 { I18n.t('signup.teaser.tabs.tab.block.contact_us.link') }
               </div>
              : <a href={`${signupFormPath}?contract_id=${id ? id : priceSection.id}${contractName ? '&contract_name=parking' : ''}`}
                   className="signup-cta-button main-button">
                 { I18n.t('signup.teaser.tabs.tab.block.link') }
                </a>
            }
          </div>

          <div className="body-list">
            <ul>
              { selectLinks.map((elem, index) =>
                <li key={index}>
                  { typeof elem === "object"
                    ?
                      <div className="body-list__item-content">
                        <SvgIco name={elem.icon} size={32} />
                        <span dangerouslySetInnerHTML={{__html:  I18n.t(elem.text)}} />
                      </div>
                    : <span dangerouslySetInnerHTML={{__html:  I18n.t(elem)}} />
                  }
                </li>
              )}
            </ul>
          </div>
        </div>

        { this.state.formSent && (
          <div>
            <FormSentModalThankyou
              openThankModal={this.state.openThankModal}
              handleClose={this.closeModalForm}
            />
          </div>
        )}
      </div>
    );
  }
}

TableContent.defaultProps = {
  danishInfoFullPriceList: [],
};

TableContent.propTypes = {
  danishInfoFullPriceList: PropTypes.array,
};

export default TableContent;
