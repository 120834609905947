import React from 'react';

import PulsingLoader from './PulsingLoader';

import './ModalLoader.sass';


const ModalLoader = () => {

  return (
    <div className="modal-loader">
      <PulsingLoader />
    </div>
  )
};

export default ModalLoader;
