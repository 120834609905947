import React from 'react';
import Slider from "react-slick";

import { SvgIco } from "../../SharedComponents/Icons";
import { testimonials } from './data';
import TestimonialBlock from './TestimonialBlock';

import './index.sass';


const Testimonials = () => {

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      { ...props }
      className={
        "slick-prev slick-arrow testimonials__prev-btn" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0}
      type="button"
    >
      <SvgIco name="keyboard-arrow-left-rounded-ios" size={32} />
    </button>
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      { ...props }
      className={
        "slick-next slick-arrow testimonials__next-btn" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1}
      type="button"
    >
      <SvgIco name="keyboard-arrow-right-rounded-ios" size={32} />
    </button>
  );

  const settings = {
    adaptiveHeight: false,
    arrows: true,
    dots: true,
    infinite: true,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          adaptiveHeight: false,
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  };


  return (
    <div className="testimonials container">
      <Slider {...settings}>
        { testimonials.map((testimonial, index) =>
          <TestimonialBlock
            key={index}
            testimonial={testimonial}
          />
        )}
      </Slider>
      <div className="row testimonials__desktop">
        { testimonials.map((testimonial, index) =>
          <TestimonialBlock
            additionalClass="col-lg-4"
            key={index}
            testimonial={testimonial}
          />
        )}
      </div>
    </div>
  )
};

export default Testimonials;
