import React, { Fragment, Suspense, useContext, useEffect } from 'react';
import PropTypes from "prop-types";

import ListViewItems from '../ListViewItems/ListViewItems';
import { NearbyLocationsBanner } from "../SharedComponents/ItemsListComponents";
import { Context } from "../index";

const ListViewSearchAgent = React.lazy(() => import('./ListViewSearchAgent'));
const ListViewAdvertisment = React.lazy(() => import('./ListViewAdvertisment'));

import '../SharedComponents/ItemsListComponents.sass';
import './ListViewItems.sass';


const ListViewItemsList = () => {

  const {
    comparableLocations,
    currency,
    dynRemarketingId,
    favouriteLocationsIds,
    keywords,
    locationsByPostalDistricts,
    missingImagesUrl,
    orderedLocations,
    section,
    setComparableLocations,
    setFavouriteLocationsIds,
    setOrderedLocations,
    siteKey,
    userCountryPhoneCode,
  } = useContext(Context);

  const { countryAlpha2, newSearchAgentPath, rootPath, signupTeaserPath, siteLogoPath } = keywords;


  useEffect(() => {
    locationsByPostalDistricts.length > 0
      && !!dynRemarketingId
      && trackDynamicRemarketing(locationsByPostalDistricts.map(location => location.id));
  }, []);


  const trackDynamicRemarketing = (locationsIds) => {
    const dynRemData = {
      send_to: dynRemarketingId,
      listing_id: locationsIds,
      listing_pagetype: 'list_view_show',
    };

    window.dataLayer = window.dataLayer || [];

    function gtag(...args) {
      dataLayer.push(args);
    }
    gtag('js', new Date());
    gtag('config', dynRemarketingId);
    gtag('event', 'view_item', dynRemData);
  };


  return (
    <div className="list-view__list-as-list">
      { locationsByPostalDistricts.map((location, index) => {

        const { advertisement, id } = location;

        if (id === 'nearByBanner') {

          return <NearbyLocationsBanner siteKey={siteKey} key={`${id}-${index}`}/>

        } else if (advertisement) {

          const { search_agent } = location;

          return (
            <Fragment key={`${id}-${index}`}>
              { search_agent
                ?
                  <Suspense fallback={''}>
                    <ListViewSearchAgent
                      advertismentData={location}
                      key={`${id}-${index}`}
                      newSearchAgentPath={newSearchAgentPath}
                      rootPath={rootPath}
                      signupTeaserPath={signupTeaserPath}
                      siteKey={siteKey}
                      siteLogoPath={siteLogoPath}
                    />
                  </Suspense>
                :
                  <Suspense fallback={''}>
                    <ListViewAdvertisment
                      advertismentData={location}
                      key={`${id}-${index}`}
                      rootPath={rootPath}
                      signupTeaserPath={signupTeaserPath}
                      siteLogoPath={siteLogoPath}
                    />
                  </Suspense>
              }
            </Fragment>
          )
        } else {

          return (
            <ListViewItems
              comparableLocations={comparableLocations}
              countryAlpha2={countryAlpha2}
              currency={currency}
              favouriteLocationsIds={favouriteLocationsIds}
              key={`${id}-${index}`}
              locationData={location}
              missingImagesUrl={missingImagesUrl}
              orderedLocations={orderedLocations}
              section={section}
              setOrderedLocations={setOrderedLocations}
              siteKey={siteKey}
              updateFavouriteLocations={setFavouriteLocationsIds}
              updateComparableLocations={setComparableLocations}
              userCountryPhoneCode={userCountryPhoneCode}
            />
          )
        }
      })}
    </div>
  )
}


export default ListViewItemsList;


ListViewItemsList.propTypes = {
  dynRemarketingId: PropTypes.array,
};

ListViewItemsList.defaultProps = {
  dynRemarketingId: [],
};
