import React from 'react';
import { isIOS, isMobile, isTablet } from 'react-device-detect';
import "core-js/stable";
import "regenerator-runtime/runtime";
// import '@babel/polyfill';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import { debounce } from "throttle-debounce";
import classNames from 'classnames';

import { SvgIco } from '../SharedComponents/Icons';
import Landscape from './Landscape';
import CrawledLocationImg from './CrawledLocationImg';
import InteriorDesignBanner from './InteriorDesignBanner';

import './index.sass';


const defaultGalleryOptions = {
  lazyLoad: true,
  showPlayButton: false,
  showBullets: false,
  showThumbnails: false,
};

class Gallery extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFullscreen: false,
      images: [],
      noFullScreen: false,
    };

    this.hidePhotoesIndex = debounce(5000, this.hideIndex);
  }

  componentDidMount() {
    this.props.photos.length && this.setImages();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.photos !== prevProps.photos || this.props.section !== prevProps.section) {

      const assetsContainer = document.getElementById('asset-container');

      assetsContainer.classList.contains('asset-container--resized') && assetsContainer.classList.remove('asset-container--resized');
      this.setImages();
    }
  }

  setImages = () =>
    this.setState({ images:
      this.props.photos.map(photo =>
        Object.assign(photo, {
          thumbnailAlt: photo.alt,
          thumbnail: photo.original,
          thumbnailClass: photo.itemType === 'page' || photo.itemType === 'interior-design-banner' ? 'hidden' : '',
          originalAlt: photo.alt,
          originalClass: this.props.addClass,
        })
      )
    });

  getOptions() {
    return { ...defaultGalleryOptions, ...this.props.options };
  }

  renderFullscreenButton = (onClick) => {
    const { isFullscreen } = this.state;
    return (
      <div className={`full-screen-btn ${ isFullscreen && ' full-screen-in' }`} onClick={ onClick } ref={el => this.fullscreenBtn = el}>
        { isFullscreen
          ? <SvgIco name="close" size={24} />
          : <SvgIco name="zoom-out-map" size={24} />
        }
      </div>
    );
  };

  renderRightNav = (onClick, disabled) => {
    return (
      <div
        className='image-gallery-custom-right-nav'
        onClick={onClick} >
        <SvgIco name="keyboard-arrow-right-rounded" size={30} />
      </div>
    )
  }

  renderLeftNav = (onClick, disabled) => {
    return (
      <div
        className='image-gallery-custom-left-nav'
        onClick={onClick} >
        <SvgIco name="keyboard-arrow-left-rounded" size={30} />
      </div>
    )
  };

  _renderItem(item) {
    const { isRentedOut, anonymous, addClass, orientationHorizontalMobile } = this.props;
    const { isFullscreen, noFullScreen } = this.state
    const notAvailableFullScreen = isRentedOut || isFullscreen || noFullScreen || anonymous;

    switch(item.itemType) {
      case 'image':

      case 'placeholder_image':
        if (item.itemType === 'placeholder_image') {
          this.setState({noFullScreen: true})
        }
        return (
          <div className={`image-gallery-image ${notAvailableFullScreen ? 'noFullScreen' : ''}`} id={`wrapper${item.original}`}>
            {/*I create thi span for centering image in div on full screen and it will be*/}
            {/*good looking in all browsers*/}
            <span className="helper-fullscreen" />
            <img src={isMobile ? item.original : item.originalFull}
                 alt={item.alt}
                 className={`${!isMobile ? "lazyload" : ''}`}
                 data-sizes="auto"
                 onLoad={addClass === 'floor-plans-gallery' ? () => this.handleImageOrientation(`wrapper${item.original}`) : () => null}
            />
          </div>
        );

      case 'crawled_location_block':
          return <CrawledLocationImg />;

      case 'interior-design-banner':
          return <InteriorDesignBanner isFullScreen={isFullscreen} orientationHorizontalMobile={orientationHorizontalMobile} />;

      default:
        return <Landscape latitude={this.props.latitude} longitude={this.props.longitude} />;
    }
  }

  handleShowIndex = () => {
    document.querySelector('.image-gallery-index').classList.add('active');
    this.hidePhotoesIndex();
  };

  hideIndex = () => {
    if (document.querySelector('.image-gallery-index')) {
      document.querySelector('.image-gallery-index').classList.remove('active');
    }
  };

  _renderCustomControls() {
    if(this.state.isFullscreen)
    return (
      <div className="dv-gallery-fullscreen-header">
        <span>
          { I18n.t(`activerecord.models.${this.props.activeSection}`).other }
        </span>
      </div>
    )
  }

  handleFullScreen = fullScreenOpened => {
    // FOR IOS DEVICES
    if (isIOS && isMobile) {
      if (Boolean(fullScreenOpened)) {
        if(isTablet) {
          setTimeout(() => {
            document.querySelector('.dv-gallery-container').classList.add('ios-fullscreen');
            document.body.classList.add('safari-fullscreen');
          }, 5);
        } else {
          document.querySelector('.dv-gallery-container').classList.add('ios-fullscreen');
          document.body.classList.add('safari-fullscreen');
        }
      } else {
        document.querySelector('.dv-gallery-container').classList.remove('ios-fullscreen');
        document.body.classList.remove('safari-fullscreen');
      }
    }

    this.setState({ isFullscreen: Boolean(fullScreenOpened) });
  };

  handleShowFullScreen = () => {
    const { showInteriorDesignBanner } = this.props;
    const isLastImage = this.gallery.getCurrentIndex() === this.state.images.length - 1;
    const landscape = document.getElementById('landscape-banner');
    const isBanner = (landscape || showInteriorDesignBanner) && isLastImage;

    !isBanner && this.gallery.fullScreen();
  };

  handleOnSlide = (index) => {

    const { showInteriorDesignBanner } = this.props;

    if (showInteriorDesignBanner) {
      const imagesLength = this.state.images.length;
      const assetsContainer = document.getElementById('asset-container');

      index === imagesLength -1
      ? assetsContainer.classList.add('asset-container--resized')
      : assetsContainer.classList.contains('asset-container--resized') && assetsContainer.classList.remove('asset-container--resized');
    }

    this.handleShowIndex();
  };

  handleImageOrientation = (wrapperId) => {
    const imageWrapper = document.getElementById(wrapperId);
    const activeImage = imageWrapper.getElementsByTagName('IMG')[0];

    activeImage.height > activeImage.width && imageWrapper.classList.add('vertical-align');
  };


  render() {
    const { actionIcons, addClass, isRentedOut, abTestFixedCta, orientationHorizontalMobile, anonymous } = this.props;
    const { isFullscreen, images, noFullScreen } = this.state;
    const showFullScreenBtn = !isRentedOut;
    const notAvailableFullScreen = isRentedOut || isFullscreen || noFullScreen || anonymous;
    const galleryWrapperClasses = classNames('dv-gallery-container', { 'fixed-cta-ab-test-styles': abTestFixedCta });
    const galleryClasses = classNames({
      [addClass]: addClass,
      'container fullscreen': isFullscreen,
      'horizontal-mobile': (isFullscreen && orientationHorizontalMobile),
    });

    return (
      <div className={galleryWrapperClasses}>
          <ImageGallery
            renderFullscreenButton={(showFullScreenBtn && !noFullScreen) ? this.renderFullscreenButton : () => null }
            renderItem={this._renderItem.bind(this)}
            renderCustomControls={ this._renderCustomControls.bind(this) }
            items={images}
            { ...this.getOptions() }
            additionalClass={galleryClasses}
            renderRightNav={this.renderRightNav}
            renderLeftNav={this.renderLeftNav}
            showIndex={true}
            showThumbnails={isFullscreen}
            onClick={ notAvailableFullScreen ? () => null : this.handleShowFullScreen }
            onScreenChange={this.handleFullScreen}
            ref={el => this.gallery = el}
            onBeforeSlide={this.handleOnSlide}
            disableKeyDown={this.props.disableArrow}
          />
        { actionIcons }
      </div>
    );
  }
}

Gallery.defaultProps = {
  photos: [],
  options: {},
};

Gallery.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      original: PropTypes.string.isRequired,
      alt: PropTypes.string,
    })
  ),
  options: PropTypes.object,
};


export default Gallery;
