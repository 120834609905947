import React from 'react';
import { handleListItemClick, DescriptionLine } from '../SharedComponents/ItemsListComponents';
import { SvgIco } from "../../SharedComponents/Icons";

import './GridViewAdvertisment.sass';


const GridViewAdvertisment = ({ advertismentData: {
                                  image_text,
                                  image_text_bottom,
                                  title,
                                  description,
                                  text_on_button,
                                },
                                mapIsOpen,
                                rootPath,
                                signupTeaserPath,
                                siteLogoPath,
                              }) => {

  return (
    <div className={`grid-item col-md-6 col-lg-${mapIsOpen ? 6 : 4} col-xl-${mapIsOpen ? 4 : 3}`}>
      <div className="advertisement">
        <div
          className="grid-item__wrapper"
          onClick={(e) => handleListItemClick(e, signupTeaserPath, true)}
        >
          <div className="grid-item-image">
            <p className="adver-title" data-bind="text: image_text">
              {image_text}
            </p>
            <span
              className="adver-title bottom-adver"
              data-bind="text: image_text_bottom"
            >
              { image_text_bottom }
            </span>
          </div>

          <div className="grid-item__description-wrapper">
            <div className="adver-top">
              <p className="adver-content-title">{title}</p>
              <div className="adver-description">
                <DescriptionLine substring={description} lines={3} />
              </div>
              <div className="adver-description-md">
                <DescriptionLine substring={description} lines={4} />
              </div>
            </div>

            <div className="search-agent-bottom">
              <div className="search-agent-images">
                <a href={rootPath}>
                  <img
                    alt="provider logo"
                    className="lazyload"
                    data-expand="100"
                    data-sizes="auto"
                    data-src={`https://www.lokalebasen.dk${siteLogoPath}`}
                    height={60}
                  />
                </a>
              </div>
              <div className="grid-item__button-wrapper">
                <button className="b-button grid-item__info-button">
                  { text_on_button }
                  <SvgIco name="keyboard-arrow-right-rounded-ios" size={20} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridViewAdvertisment;
