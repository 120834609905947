import React, { useState } from 'react';
import CryptoJS from "crypto-js";

import { postLocationContactDetailsShown } from '../../../utils/api';
import { SvgIco } from '../../SharedComponents/Icons';


const SmallContactBlock = ({ addressLine1,
                             contact,
                             locationId,
                             siteKey,
                             streetName,
                           }) => {

  const [isContactsShown, setIsContactsShown] = useState(false);
  const [contactEmail, setContactEmail] = useState(null);

  const address = streetName || addressLine1;


  const handleEmail = () => {
    const encodedEmail = CryptoJS.enc.Base64.parse(contact.tag);
    const key = CryptoJS.enc.Utf8.parse('75smg2w68qmy2ori75smg2w68qmy2ori');
    const iv = CryptoJS.enc.Utf8.parse('javatzwkbnkmih8t');
    const decryptEmail  = CryptoJS.AES.decrypt({ ciphertext: encodedEmail }, key, { iv: iv, mode: CryptoJS.mode.CBC });
    const originalEmail = decryptEmail.toString(CryptoJS.enc.Utf8);

    return originalEmail;
  };

  const handleClick = () => {
    setIsContactsShown(true);
    setContactEmail(handleEmail());
    postLocationContactDetailsShown(locationId)
      .catch(error => console.log('Error: ', error));
  };


  return (
    <div onClick={handleClick}>
      <div className="contact-info-content__info-links">
        <SvgIco name="phone" size={22}/>
        { isContactsShown
          ? <a className="contact-info-content__info-phone-link" href={`tel://${contact.phone}`}>{ contact.phone }</a>
          : I18n.t('generic.show_phone_number')
        }
      </div>
      <div className="contact-info-content__info-links">
        <SvgIco name="mail" size={22}/>
        { isContactsShown
          ? (siteKey === 'dk'
              ?
                <a href={`mailto:${contactEmail}?subject=Vedr.%20${address}%20på%20Lokalebasen.dk&body=%0D%0AHej%20${contact.name}%0D%0A%0D%0A%0D%0AJeg%20er%20interesseret%20I%20at%20høre%20mere%20om%20lejemålet%20på%20https://www.lokalebasen.dk/%20:%0D%0A%0D%0A${window.location.href}`}>
                  { contactEmail }
                </a>
              :
                <a href={`mailto:${contactEmail}?subject=Questions%20regarding%20${address}%20on%20Matchoffice.com&body=%0D%0AHi%20${contact.name}%0D%0A%0D%0A%0D%0AI%20would%20like%20to%20know%20more%20about%20commercial%20lease%20found%20on%20https://matchoffice.com%20:%0D%0A%0D%0A${window.location.href}`}>
                  { contactEmail }
                </a>
            )
          : I18n.t('generic.show_email')
        }
      </div>
    </div>
  )
};


export default SmallContactBlock;
