import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';

import { Context } from "../index";
import GallerySwitcher from './GallerySwitcher';
import AssetBlock from './AssetBlock';

import './index.sass';


const GallerySection = () => {

  const {
    assets,
    location,
    locationRented,
    virtualTourOpen,
  } = useContext(Context);


  const [assetsData, setAssetsData] = useState(assets);


  useEffect(() => {
    const interiorDesign = { itemType: 'interior-design-banner', original: '' };
    const updatedAssets = { ...assetsData };

    assetsData.showInteriorDesignForm && updatedAssets.floorPlans.push(interiorDesign);
    setAssetsData(updatedAssets);
  }, []);


  const switcherCounters = () => {
    const assetsObj = {};

    Object.keys(assetsData).map((item) => {
      if (assetsData[item] && assetsData[item].length) {
        item === 'photos'
          ? (assetsObj[item] = assetsData[item].filter(
              (image) => image.itemType === 'image',
            ).length)
          : (assetsObj[item] = assetsData[item].length);
      }
    });

    if (!location.anonymous && location.latitude && location.longitude) {
      assetsObj.map = [];
    }
    return assetsObj;
  };


  const assetContainerClasses = classNames('asset-container container', {
    'rented-out': locationRented,
    'virtual-tour-box': virtualTourOpen,
  });


  return (
    <div className="container dv-gallery">
      <div className={assetContainerClasses} id="asset-container">
        <AssetBlock />
      </div>

      <GallerySwitcher counters={switcherCounters()} />
    </div>
  );
};


export default GallerySection;
