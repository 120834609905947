import React, { Component, Suspense } from 'react';
import classNames from 'classnames';
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { isMobileOnly } from "react-device-detect";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import httpService from '../../../api/httpService';
import { handleListItemClick } from '../SharedComponents/ItemsListComponents';
import FavouriteIcon from '../../SharedComponents/FavouriteIcon';
import { SvgIco } from '../../SharedComponents/Icons';
import EmblaCarousel from "../../SharedComponents/EmblaCarousel";
import { mappedMonths } from '../../helpers/danishMonths';
import { sendTrovitLead } from "../../helpers/trovit_analitics";

const CompareIcon = React.lazy(() => import('../../SharedComponents/CompareIcon'));
const InfoBadges = React.lazy(() => import('../../SharedComponents/InfoBadges'));
const InfoNotice = React.lazy(() => import('../../SharedComponents/InfoNotice'));
const GetInfoFormModal = React.lazy(() => import('../../SharedComponents/GetInfoFormModal'));

import './GridViewItem.sass';


class GridViewItem extends Component {
  state = {
    isActiveInfoForm: false,
    orderedLocations: [],
    showServerErrorMessage: false,
    showMorePhotos: false,
    isLoading: false,
    showEnergyRatingTooltip: false,
    showPersonsTooltip: false,
    orderId: null,
  };

  static timestamp() {
    return { timestamp: new Date().toISOString() };
  }

  componentDidMount() {
    window.history.scrollRestoration = 'manual';
  }

  handleOpenInfoForm = () => this.setState({ isActiveInfoForm: true });

  handleCloseInfoForm = () => {
    this.setState({ isActiveInfoForm: false });
    window.removeEventListener('mousedown', this.handleClickOutside);
  };

  infoHandlerSubmit = (params, orderUrl, abTestParam) => {
    const { uuid } = this.props.locationData;

    // params.ab_code = abTestParam;

    this.setState({ isLoading: true });

    httpService
      .orderInfoListViewForm(orderUrl, params, uuid)
      .then((response) => {
        if (response) {
          const orderedLocations = JSON.parse(localStorage.getItem('my.ordered_locations'));
          const { order_uuid } = response;

          orderedLocations[uuid] = [this.constructor.timestamp()];

          const updateOrderedLocation = JSON.stringify(orderedLocations);

          localStorage.setItem('my.ordered_locations', updateOrderedLocation);

          sendTrovitLead(this.props.siteKey);

          this.setState({
            submitSuccess: true,
            showServerErrorMessage: false,
            isLoading: false,
            orderId: order_uuid,
          });
          this.props.setOrderedLocations(Object.keys(orderedLocations));
        } else {
          this.setState({
            submitSuccess: false,
            showServerErrorMessage: true,
            isLoading: false,
          });
        }
      });
  };

  handleSlideChange = () =>
    !this.state.showMorePhotos && this.setState({ showMorePhotos: true });

  promotedLInk = (e) => {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();
      e.stopPropagation();

      const href =
        'https://www.lokalebasen.dk/serviceydelser-til-udlejere/fremhevet/annoncering';
      window.open(href, '_blank');
    }
  };


  handleTooltip = (key, tooltipRef) => {
    const tooltipShowState = this.state[key];

    this.setState({ [key]: !tooltipShowState }, () =>
      tooltipShowState ? ReactTooltip.hide(this[tooltipRef]) : ReactTooltip.show(this[tooltipRef])
    );
  };

  afterHide = (key) =>
    this.setState({ [key]: false });


  handleMouseLeave = () => {
    const { hoveredLocationId, locationData, setHoveredLocationId } = this.props;

    hoveredLocationId && hoveredLocationId === locationData.id && setHoveredLocationId(null);
  };


  render() {
    const {
      isActiveInfoForm,
      isLoading,
      infoSent,
      showMorePhotos,
      showServerErrorMessage,
      submitSuccess,
    } = this.state;

    const {
      comparableLocations,
      countryAlpha2,
      favouriteLocationsIds,
      locationData,
      mapIsOpen,
      missingImagesUrl,
      orderedLocations,
      section,
      setHoveredLocationId,
      siteKey,
      updateFavouriteLocations,
      updateComparableLocations,
      userCountryPhoneCode,
    } = this.props;


    const {
      address_line1,
      address_title,
      anonymous,
      closed_month,
      energy_rating,
      exempted_energy,
      href,
      id,
      is_increased_in_listview,
      is_internal,
      kind,
      nearby_parking_lot,
      order_path,
      postal_code,
      postal_name,
      presenter,
      price_decrease_percentage,
      provider_logo,
      secondary_kinds,
      show_full_address,
      show_new_badge,
      show_price_decrease_label,
      shown_as_rented_out,
      special_offer,
      street_name,
      suitable_for_kinds,
      temporarily_unavailable,
      uuid,
      monthly_rent_price,
    }  = locationData;


    // TODO remove after deploy and locations props update
    const all_photos = locationData.all_photos || locationData.first_3_photos || [];
    const premium = is_increased_in_listview;
    const premiumOnlyDK = is_increased_in_listview && countryAlpha2 === 'dk';
    const suitableFor = suitable_for_kinds || [];
    const locationKinds = [kind, ...secondary_kinds];
    // const isJeudan = provider_name && provider_name.toLowerCase() === 'jeudan';
    const suitableForItem = suitableFor.length ? suitableFor[0] : null;
    const locationKindsLength =locationKinds.length > 1 && locationKinds.length - 1;
    const reducedKinds = (locationKindsLength && locationKinds.slice(0, 1)) || locationKinds;
    const closedMonth = mappedMonths(closed_month);
    const energyRatingMark = energy_rating ? `${energy_rating}`.toLowerCase() : exempted_energy ? 'exempted' : null;
    const energyRatingLabel = energy_rating ? I18n.t('activerecord.attributes.property.energy_rating') : I18n.t('simple_form.options.location.energy_rating.X');
    const { persons, persons_label, persons_tooltip } = presenter;
    const { formatted_monthly_rent_price, monthly_rent_label } = monthly_rent_price || {};
    const showInfoBadges = anonymous || show_new_badge || shown_as_rented_out || temporarily_unavailable || is_internal || special_offer;

    const getInfoFormGrid = classNames('gridInfo-form-wrapper', { 'active-infoform-grid': isActiveInfoForm });

    const sliderClasses = classNames('grid-item__image-slider', {
      disabled: all_photos.length === 1 || shown_as_rented_out || temporarily_unavailable,
      anonymous,
      crawled: is_internal,
      'rented-out': shown_as_rented_out || temporarily_unavailable,
    });

    const styles = {
      itemStyle: shown_as_rented_out || temporarily_unavailable ? { background: '#F4F5F5' } : null,
      gallery: shown_as_rented_out || temporarily_unavailable ? { filter: 'grayscale(100%)' } : null,
      iconsBlockStyle: !is_internal
        ? {
            justifyContent: `${
              provider_logo && !anonymous
                ? 'space-between'
                : provider_logo && anonymous
                ? 'flex-start'
                : ''
            }`,
          }
        : null,
    };

    const SLIDES = all_photos.map((elem, index) => (
      <div key={elem} className="grid-item__image-wrapper">
        <img
          className={
            index <= 1 ||
            index === all_photos.length - 1 ||
            showMorePhotos
              ? 'lazyload blur-up'
              : ''
          }
          data-sizes="auto"
          alt={elem.alt}
          data-src={elem.url}
          data-expand="500"
          style={styles.carouselStyle}
          height="172"
          width="100%"
        />
      </div>
    ));

    const OPTIONS = { loop: true };
    const disableScroll = shown_as_rented_out || temporarily_unavailable;


    return (
      <div className={`grid-item col-md-6 col-lg-${mapIsOpen ? 6 : 4} col-xl-${mapIsOpen ? 4 : 3}`}>
        <a
          href={href}
          className={`grid-item__wrapper${premium ? ' premium' : ''}`}
          onClick={(e) => handleListItemClick(e, href, id)}
          onMouseEnter={() => setHoveredLocationId && setHoveredLocationId(id)}
          onMouseLeave={this.handleMouseLeave}
          style={styles.itemStyle}
        >
          <div className={sliderClasses}>

            { showInfoBadges &&
              <Suspense fallback={''}>
                <InfoBadges
                  anonymous={anonymous}
                  closedMonth={siteKey === 'dk' && !isMobileOnly ? closedMonth : null}
                  isCrawled={is_internal}
                  isRentedOut={shown_as_rented_out || temporarily_unavailable}
                  isNew={show_new_badge}
                  hideCrawledBottom={true}
                  missingImagesUrl={missingImagesUrl}
                  rentedOutLabel={temporarily_unavailable ? I18n.t('activerecord.attributes.location.states.temporarily_unavailable') : null}
                  sale={section !== 'lease'}
                  specialOffer={special_offer}
                  wrapperClass="list-view"
                />
              </Suspense>
            }

            <EmblaCarousel
              carouselName="grid-item-slider"
              disableScroll={disableScroll}
              handleChange={this.handleSlideChange}
              slides={SLIDES}
              options={OPTIONS}
              withDots
            />

            { section === 'lease' && !isMobileOnly &&
              <Suspense fallback={''}>
                <CompareIcon
                  comparableLocations={comparableLocations}
                  kind={kind}
                  locationId={id}
                  siteKey={siteKey}
                  tooltipPosition={'left'}
                  updateComparableLocations={updateComparableLocations}
                />
              </Suspense>
            }

            <FavouriteIcon
              favoriteLocations={favouriteLocationsIds}
              locationId={id}
              section={section}
              siteKey={siteKey}
              tooltipPosition={'left'}
              updateFavouriteLocations={updateFavouriteLocations}
            />

          </div>

          <div className={`grid-item__description-wrapper${ shown_as_rented_out || temporarily_unavailable ? ' grid-item__description-wrapper--bordered' : '' }`}>

            <div className="grid-item__top-row">
              <div className="grid-item__kinds">
                { reducedKinds.map((elem, index) => <span className="grid-item__kind" key={index}>{ I18n.t(`generic.${elem}`) } </span> )}
                { locationKindsLength
                  ? <span className="grid-item__kind">
                      +{ locationKindsLength }
                    </span>
                  : null
                }
              </div>

              { energyRatingMark &&
                <div className="list-item__energy-rating-wrapper" role="button" aria-labelledby="energy-rating">
                  <div className={`list-item__energy-rating ${energyRatingMark}`}
                       data-class="list-item-tooltip"
                       data-for={'energy-rating' + id}
                       data-tip={energyRatingLabel}
                       onClick={() => this.handleTooltip('showEnergyRatingTooltip', 'EnergyRatingRef')}
                       ref={ref => this.EnergyRatingRef = ref}
                       role="button">
                    <SvgIco
                      name={`energy-rating-${energyRatingMark}`}
                      width={18}
                      height={18}
                    />
                  </div>
                  <ReactTooltip
                    afterHide={() => this.afterHide('showEnergyRatingTooltip')}
                    effect="solid"
                    id={'energy-rating' + id}
                    place="left"
                    className="energy-rating-tooltip"
                  />
                </div>
              }
            </div>

            <div className={`${show_price_decrease_label ? 'grid-item__sale-badge' : ''} grid-item__description-title`}>
              <h4 style={{ color: shown_as_rented_out || temporarily_unavailable ? '#F4F5F5' : '#ffffff'}}>{ address_line1 }, { show_full_address && postal_code } { postal_name }</h4>

              <div className="text-title">
                { !anonymous && !suitableForItem && !is_internal
                  ?
                    <span>
                      <p>{street_name || address_line1}</p>
                      <p style={{ marginTop: '6px' }}>
                        { show_full_address && postal_code } { postal_name }
                      </p>
                    </span>
                  :
                    <p>
                      { street_name || address_line1 }{', '}
                      { show_full_address && postal_code } { postal_name }
                    </p>
                }
              </div>

              { !is_internal && !!suitableFor.length &&
                <div className="grid-item__suitable-for-wrapper">
                  { suitableForItem &&
                    <div className="suitable-for">
                      <div className="suitable-for__item">
                        <SvgIco name={suitableForItem} width={14} height={14} className="suitable-for__icon" />
                        <span className="suitable-for__label">
                          { I18n.t(`lb_showroom.showroom.suitable.for.${suitableForItem}`) }
                        </span>
                        { suitableFor.length > 1 && <div className="suitable-for__more">+{ suitableFor.length - 1 }</div> }
                      </div>
                    </div>
                   }
                </div>
              }

              { is_internal &&
                <Suspense fallback={''}>
                  <div role="button">
                    <InfoNotice noticeKind={'parsed'} root="grid" id={id} missingImagesUrl={missingImagesUrl}/>
                  </div>
                </Suspense>
              }

            </div>

            <div className="grid-item__metrics-wrapper">
              <div className="grid-item__area">
                { presenter.area && <p>{ persons ? persons_label : presenter.area_label }:</p> }

                <p>
                  { persons || presenter.area }
                  { persons && persons_tooltip &&
                    <span className="list-view__info-tooltip"
                          role="button"
                          data-for={'persons-list-tooltip-' + id}
                          data-tip={persons_tooltip}
                          onClick={() => this.handleTooltip('showPersonsTooltip', 'PersonsTooltipRef')}
                          ref={ref => this.PersonsTooltipRef = ref}>
                      <SvgIco name="info_outline" size={14} />
                      <ReactTooltip
                        afterHide={() => this.afterHide('showPersonsTooltip')}
                        effect="solid"
                        id={'persons-list-tooltip-' + id}
                        place="top"
                        className="persons-tooltip"
                      />
                    </span>
                  }
                </p>
              </div>

              <div className={`grid-item__price-block ${premium ? 'premium' : ''}`}>
                { formatted_monthly_rent_price &&
                  <div className="grid-item__price">
                    <p>{ monthly_rent_label }:</p>
                    <p style={{ display: 'flex' }}>
                      { formatted_monthly_rent_price }
                      { show_price_decrease_label &&
                        <div className="sale-image-badge">
                          <span className="percentage">-{price_decrease_percentage}%</span>
                        </div>
                      }
                    </p>
                  </div>
                }

                <div className="grid-item__price">
                  <p>{ presenter.label }:</p>
                  <p style={{ display: 'flex' }}>
                    { presenter.price }
                    { !formatted_monthly_rent_price && show_price_decrease_label &&
                      <div className="sale-image-badge">
                        <span className="percentage">-{price_decrease_percentage}%</span>
                      </div>
                    }
                  </p>
                </div>
              </div>
            </div>


            <div className="grid-item__bottom">
              { !shown_as_rented_out && !temporarily_unavailable &&
                <div className="grid-item__button-wrapper" role="button">
                  { orderedLocations.includes(uuid)
                    ?
                      <button className="b-button grid-item__info-button sent-info">
                        <SvgIco name="check" size={24} />
                        { I18n.t('apps.lb_showroom.detailed.order_form.cta_fixed_info_sent') }
                      </button>
                    :
                      <div className="b-button grid-item__info-button" onClick={ this.handleOpenInfoForm }>
                        { I18n.t("apps.lb_showroom.detailed.order_form.get_information") }
                      </div>
                  }
                </div>
              }
            </div>

            { isMobileOnly && premiumOnlyDK &&
              <div role="button" className="grid-item__premium-link" onClick={(e) => this.promotedLInk(e)}>
                { I18n.t('generic.promoted_location_link') }
              </div>
            }
          </div>
        </a>

        <div className={getInfoFormGrid} id={'gridItemFormWrapper' + id}>
          { isActiveInfoForm &&
            <Suspense fallback={
              <div className="spinner-wrapper order-form-spinner">
                <div className="smoothie-spinner" />
              </div>}>
              <GetInfoFormModal
                submitHandler={this.infoHandlerSubmit}
                parkingCheckbox={nearby_parking_lot}
                orderUrl={order_path}
                id={id}
                infoSent={infoSent}
                submitSuccess={submitSuccess}
                // isJeudan={isJeudan}
                handleClose={this.handleCloseInfoForm}
                gtmClass="getInfoFormGrid"
                address={address_title}
                showServerErrorMessage={showServerErrorMessage}
                isLoading={isLoading}
                userCountryPhoneCode={userCountryPhoneCode}
              />
            </Suspense>
          }
        </div>
      </div>
    );
  }
}

GridViewItem.propTypes = {
  address_title: PropTypes.string,
  address_line1: PropTypes.string,
  closed_month: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
  id: PropTypes.string,
  kind: PropTypes.string,
  locationData: PropTypes.object,
  missingImagesUrl: PropTypes.string,
  postal_code: PropTypes.string,
  postal_name: PropTypes.string,
  presenter: PropTypes.object,
  provider_logo: PropTypes.string,
  provider_name: PropTypes.string,
  provider_url: PropTypes.string,
  secondary_kinds: PropTypes.array,
  show_new_badge: PropTypes.bool,
  shown_as_rented_out: PropTypes.bool,
  uuid: PropTypes.string,
  show_full_address: PropTypes.bool,
  temporarily_unavailable: PropTypes.bool,
};

GridViewItem.defaultProps = {
  address_title: '',
  address_line1: '',
  closed_month: '',
  description: '',
  href: '',
  id: '',
  kind: '',
  locationData: {},
  missingImagesUrl: '',
  postal_code: '',
  postal_name: '',
  presenter: {},
  provider_logo: '',
  provider_name: '',
  provider_url: '',
  secondary_kinds: [],
  show_new_badge: false,
  shown_as_rented_out: false,
  uuid: '',
  show_full_address: false,
  temporarily_unavailable: false,
};

export default React.memo(GridViewItem);
