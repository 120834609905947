import React, { Component } from 'react';
import { withFormik } from 'formik';
import Modal from 'react-modal';
import Yup from 'yup';
import { isIPad13, isMobile } from "react-device-detect";
import postalCodes from "postal-codes-js";

import { addressValidation, cityValidation, eanValidation, passwordValidation, vatIdValidation } from '../../SharedComponents/Forms/ValidationSchemaPattern';
import { postalCodeError, requiredFieldError } from "../../SharedComponents/Forms/ErrorMessages";
import { SvgIco } from "../../SharedComponents/Icons";
import DropdownSelectWithSearch from "../../SharedComponents/DropdownSelectWithSearch";
import SignUp from '../../../utils/SignUp';

import './ContinueRegistrationModal.sass';


const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    top: '0',
    overflow: 'auto',
    zIndex: '9999',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'visible',
    background: 'white',
    borderRadius: '4px',
    position: 'absolute',
  },
};


class ContinueRegistrationModal extends Component {

  state = {
    passwordVisibility: false,
    tooltipVatIsOpen: false,
  };

  handleTooltipClick = (e) => {
    e.preventDefault();
    this.setState({tooltipVatIsOpen: !this.state.tooltipVatIsOpen}, () =>
      this.state.tooltipVatIsOpen
        ? window.addEventListener('click', this.handleClickOutside)
        : window.removeEventListener('click', this.handleClickOutside)
    );
  };

  handleClickOutside = (e) => {
    e.preventDefault();
    (e.target === this.tooltipContent || !this.tooltipWrapper.contains(e.target)) && this.setState({ tooltipVatIsOpen: false });
  };

  togglePasswordVisibility = () => {
    this.setState({
      passwordVisibility: !this.state.passwordVisibility,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if(Object.keys(this.props.errors).length) {
      this.setState({ errorsOnSubmit: true }, () => {
        const target = document.querySelector('.sign-up-form__form-group-error');
        const targetCoords = Math.floor(target.getBoundingClientRect().top + window.pageYOffset - (document.getElementById('top-menu').offsetHeight + 48));

        window.scroll(0, targetCoords);
      });
    } else {
      this.props.handleSubmit();
    }
  };

  errorValidator = (field) => {
    const { errors, touched } = this.props;
    const { errorsOnSubmit } = this.state;
    const fieldError = errors[field] && (touched[field] || errorsOnSubmit) && 'sign-up-form__form-group-error';

    return field === 'email'
      ? (this.props.emailAlreadyExist && 'sign-up-form__form-group-error') || fieldError
      : fieldError;
  };


  render() {
    const {
      countriesSelectOptions,
      currentCountryCode,
      errors,
      handleBlur,
      handleChange,
      handleClose,
      internalError,
      isOpen,
      setFieldValue,
      siteKey,
      toggleTermsModal,
      values,
    } = this.props;

    const { passwordVisibility, tooltipVatIsOpen } = this.state;

    const countriesOptions = siteKey === 'global' && countriesSelectOptions.map(item => {
      return { value: item[1], label: item[0] }
    });


    return (

      <Modal
        ariaHideApp={false}
        className="register-modal"
        contentLabel="register-modal"
        isOpen={isOpen}
        onRequestClose={handleClose}
        style={customStyles}
      >
        <div className="register-modal__wrapper">

          <div className="sign-up-form__contact-data-header">
            <h2>{ I18n.t('signup.new_form.register.user_info') }</h2>
          </div>

          { internalError &&
            <div className="sign-up-form__input-tooltip-error" style={{ display: 'block', marginBottom: '8px', marginTop: '-8px' }}>
              { I18n.t('apps.lb_showroom.detailed.order_form.error.message') }
            </div>
          }

          <form name="continueRegistrationForm" className="continue-registration-form" onSubmit={this.handleSubmit}>

            <input
              id="fifth_element"
              type="text"
              name="fifth_element"
              className="invisible"
              value={values.fifth_element}
              onPaste={handleChange}
              onChange={handleChange}
            />

            <div className={`form-group ${siteKey !== 'dk' && 'form-group--not-required'} ${ this.errorValidator('vat_id')}`}>
              <label className="sign-up-form__label" htmlFor="vat_id">
                <input
                  name="vat_id"
                  type="text"
                  value={values.vat_id}
                  id="vat_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  data-tip={errors.vat_id}
                  className={`sign-up-form__input ${values.vat_id && 'active'}`}
                />
                <span className={`sign-up-form__floating-label ${values.vat_id && 'active'}`}>
                  { I18n.t('signup.new_form.register.vat_id.placeholder') }
                </span>

                { isMobile || isIPad13
                  ?
                  <div className="sign-up-form__tooltip" ref={el => this.tooltipWrapper = el}>
                    <SvgIco
                      className="sign-up-form__tooltip-icon"
                      name="help_outline"
                      onClick={(e) => this.handleTooltipClick(e)}
                      size={22}
                    />

                    { tooltipVatIsOpen &&
                      <div className="sign-up-form__tooltip-content-wrapper" ref={el => this.tooltipContent = el}>
                        <div className="sign-up-form__tooltip-content">
                          { I18n.t('signup.new_form.register.vat_id.tooltip') }
                          <SvgIco
                            name="close"
                            onClick={(e) => this.handleTooltipClick(e)}
                            size={22}
                          />
                        </div>
                      </div>
                    }
                  </div>
                  :
                  <div className="sign-up-form__tooltip">
                    <SvgIco
                      className="sign-up-form__tooltip-icon"
                      name="help_outline"
                      size={22}
                    />
                    <div className="sign-up-form__tooltip-content-wrapper">
                      <div className="sign-up-form__tooltip-content">
                        { I18n.t('signup.new_form.register.vat_id.tooltip') }
                      </div>
                    </div>
                  </div>
                }
              </label>
              <div className="sign-up-form__input-tooltip-error">{ errors.vat_id }</div>
            </div>

            { siteKey === 'dk' &&
              <div className={`form-group form-group--not-required ${this.errorValidator('ean')}`}>
                <label className="sign-up-form__label" htmlFor="ean">
                  <input
                    name="ean"
                    type="text"
                    value={values.ean}
                    id="ean"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-tip={errors.ean}
                    className={`sign-up-form__input ${values.ean && 'active'}`}
                  />
                  <span className={`sign-up-form__floating-label ${values.ean && 'active'}`}>
                  { I18n.t('signup.new_form.register.ean.placeholder') }
                </span>
                </label>
                { !!this.errorValidator('ean')
                  ? <div className="sign-up-form__input-tooltip-error">{ errors.ean }</div>
                  : <div className="sign-up-form__input-tooltip bigger-margin-bottom">{ I18n.t('signup.new_form.ean_tooltip') }</div>
                }
              </div>
            }

            <div className={`form-group ${this.errorValidator('line_1')}`}>
              <label className="sign-up-form__label" htmlFor="line_1">
                <input
                  name="line_1"
                  type="text"
                  value={values.line_1}
                  id="line_1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  data-tip={errors.line_1}
                  className={`sign-up-form__input ${values.line_1 && 'active'}`}
                />
                <span className={`sign-up-form__floating-label ${values.line_1 && 'active'}`}>
                { I18n.t('signup.new_form.register.line_1.placeholder') }*
              </span>
              </label>
              <div className="sign-up-form__input-tooltip-error">{ errors.line_1 }</div>
            </div>

            <div className="sign-up-form__inline-group">
              <div className="sign-up-form__inline">
                <div className={`form-group form-group--short ${this.errorValidator('postal_code')}`}>
                  <label className="sign-up-form__label" htmlFor="postal_code">
                    <input
                      name="postal_code"
                      type="text"
                      value={values.postal_code}
                      id="postal_code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      data-tip={errors.postal_code}
                      className={`sign-up-form__input ${values.postal_code && 'active'}`}
                    />
                    <span className={`sign-up-form__floating-label ${values.postal_code && 'active'}`}>
                    { I18n.t('signup.new_form.register.postal_code.placeholder') }*
                  </span>
                  </label>
                  <div className="sign-up-form__input-tooltip-error sign-up-form__input-tooltip-postal-error">{ errors.postal_code }</div>
                </div>

                <div className={`form-group form-group--long ${this.errorValidator('city')}`}>
                  <label className="sign-up-form__label" htmlFor="city">
                    <input
                      name="city"
                      type="text"
                      value={values.city}
                      id="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      data-tip={errors.city}
                      className={`sign-up-form__input ${values.city && 'active'}`}
                    />
                    <span className={`sign-up-form__floating-label ${values.city && 'active'}`}>
                    { I18n.t('signup.new_form.register.city.placeholder') }*
                  </span>
                  </label>
                  <div className="sign-up-form__input-tooltip-error">{ errors.city }</div>
                </div>
              </div>
            </div>

            { siteKey === 'global' &&
              <DropdownSelectWithSearch
                options={countriesOptions}
                label={I18n.t('apps.lease.goto.types.country')}
                setFieldValue={setFieldValue}
                field={'country_alpha2'}
                initialValue={currentCountryCode}
                value={values.country_alpha2}
              />
            }

            <div className={`form-group form-group--password ${this.errorValidator('password')}`}>
              <label className="sign-up-form__label" htmlFor="password">
                <input
                  name="password"
                  type={passwordVisibility ? 'text' : 'password'}
                  value={values.password}
                  id="password"
                  onChange={(e) => setFieldValue('password', e.target.value.replace(/\s/g, ""))}
                  onBlur={handleBlur}
                  data-tip={errors.password}
                  className={`sign-up-form__input ${values.password && 'active'}`}
                />
                <span className={`sign-up-form__floating-label ${values.password && 'active'}`}>
                { I18n.t('signup.new_form.register.password.placeholder') }*
              </span>
              </label>

              { passwordVisibility
                ? <SvgIco className="password-visibility" name="visibility_filled" onClick={ this.togglePasswordVisibility } size={18} />
                : <SvgIco className="password-visibility" name="visibility_off_filled" onClick={ this.togglePasswordVisibility } size={18} />
              }

              { !!this.errorValidator('password')
                ? <div className="sign-up-form__input-tooltip-error">{ errors.password }</div>
                : <div className="sign-up-form__input-tooltip">{ I18n.t('signup.new_form.password_tooltip') }</div>
              }
            </div>

            <div className="form-group form-group--checkbox">
              <div className="sign-up-form__terms-conditions-label">
                <label htmlFor="terms_of_service">
                  <input
                    required={true}
                    name="terms_of_service"
                    type="checkbox"
                    value={false}
                    id="terms_of_service"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-tip={errors.terms_of_service}
                  />
                  <span className="checkmark" />
                  <div dangerouslySetInnerHTML={{__html: I18n.t('signup.new_form.register.terms_of_service.placeholder')}} />
                </label>
                <span className="sign-up-form__terms-conditions-toggle" onClick={toggleTermsModal}>
                { I18n.t('signup.new_form.register.terms_link') }
              </span>
              </div>
            </div>

            <div className="form-group form-group--not-required">
              <button type="submit" className="b-button">
                { I18n.t('signup.new_form.register.submit') }
              </button>
              <span className="sign-up-form__subheading">
                { I18n.t('signup.new_form.register.notice') }
              </span>
            </div>

          </form>
        </div>
      </Modal>
    );
  }
};


const { postal_codeI18n } = SignUp.getI18nAttrNames();

const {
  vat_id,
  ean,
  line_1,
  postal_code,
  country_alpha2,
  city,
  password,
  contract_id,
  fifth_element,
} = SignUp.getClientInfo();

const ContinueRegistrationForm = withFormik({
  mapPropsToValues: (bag) => {
    return {
      vat_id,
      ean,
      line_1,
      postal_code,
      city,
      password,
      fifth_element,
      contract_id: contract_id || bag.activeContract.contract.id,
      country_alpha2: country_alpha2 || bag.currentCountryCode,
    }},

  validationSchema: Yup.object().shape({
    vat_id: vatIdValidation,
    ean: eanValidation,
    line_1: addressValidation,
    city: cityValidation,
    password: passwordValidation,
    postal_code: Yup.string()
      .test('isPostalCode', postalCodeError, function(value) {
        const { path, createError } = this;
        const codeIsValid = postalCodes.validate(this.parent.country_alpha2, value);

        return codeIsValid === true || !value || createError({ path, message: postalCodeError });
      })
      .required(requiredFieldError(postal_codeI18n)),
  }),

  handleSubmit: (values, bag) => {
    SignUp.updateClientInfo({ ...values, contract_name: bag.props.contractName, active_contract_section: bag.props.activeContractSection});
    bag.props.submitHandler(values, bag.props.signUpFormUrl);
  },

  displayName: 'ContinueRegistrationForm',
})(ContinueRegistrationModal);

export default ContinueRegistrationForm;
