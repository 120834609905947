import React from 'react';
import { KeyboardArrowRightRounded, KeyboardArrowLeftRounded, SvgIco } from '../../SharedComponents/Icons';

import './index.sass';


class FixedNavigationBar extends React.PureComponent {

  state = {
    activeSection: null,
    initialization: true,
    isScrollable: false,
    navigationListLeftBorder: null,
    navigationListRightBorder: null,
    showLeftArrow: false,
    showRightArrow: false,
    topOffset: null,
  };


  componentDidMount() {
    this.handleNavigationTop();

    this.setState({
      activeSection: this.props.activeSection || null,
    }, () =>
      this.state.activeSection && this.handleActiveSectionChange(this.state.activeSection)
    );

    this.handleHorizontalScroll();
    window.addEventListener('resize', this.handleHorizontalScroll);
    window.addEventListener('orientationchange', this.handleNavigationTop);
  };


  componentWillUnmount() {
    this.navigationLinksList.removeEventListener('scroll', this.handleArrowAppearance);
    window.removeEventListener('resize', this.handleHorizontalScroll);
    window.removeEventListener('orientationchange', this.handleNavigationTop);
  };


  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.activeSection && this.props.activeSection !== prevProps.activeSection) {
      this.handleActiveSectionChange(this.props.activeSection);
    }
  };


  // We need to use setTimeout as header changes the layout after the recalculateNavigationTop fires
  handleNavigationTop = () => setTimeout(this.recalculateNavigationTop, 10);


  recalculateNavigationTop = () =>
    this.setState({ topOffset:  document.getElementById('top-menu').offsetHeight + 'px' });


  handleHorizontalScroll = () => {
    const navigationListFirstChild = this.navigationLinksList && this.navigationLinksList.firstChild;
    const navigationListLastChild = this.navigationLinksList && this.navigationLinksList.lastChild;

    const isScrollable = navigationListFirstChild
      && (Math.round(navigationListFirstChild.getBoundingClientRect().left) < this.navigationLinksList.getBoundingClientRect().left
      || (navigationListLastChild && Math.round(navigationListLastChild.getBoundingClientRect().right) > this.navigationLinksList.getBoundingClientRect().right));

    this.handleArrowAppearance();
    this.navigationLinksList.addEventListener('scroll', this.handleArrowAppearance);
    this.setState({ isScrollable: isScrollable });

    this.handleNavigationTop();
  };


  handleActiveSectionChange = (targetName) => {
    const { activeSection, isScrollable, initialization } = this.state;
    const targetSection = this[targetName];

    if (targetSection) {
      if (targetName !== activeSection || initialization) {
        if (isScrollable) {
          const targetRightCoodrs = targetSection.getBoundingClientRect().right,
                targetLeftCoords = targetSection.getBoundingClientRect().left,
                navigationListFirstChild = this.navigationLinksList.firstChild,
                navigationListLastChild = this.navigationLinksList.lastChild,
                navigationListRightCoords = targetSection === navigationListLastChild
                                            ? this.navigationLinksList.getBoundingClientRect().right
                                            : this.navigationLinksList.getBoundingClientRect().right - 24,
                navigationListLeftCoords = targetSection === navigationListFirstChild
                                           ? this.navigationLinksList.getBoundingClientRect().left
                                           : this.navigationLinksList.getBoundingClientRect().left + 24;

          targetRightCoodrs > navigationListRightCoords
          ? this.scrollToRightTarget(targetLeftCoords)
          : targetLeftCoords < navigationListLeftCoords
            ? this.scrollToLeftTarget(targetLeftCoords)
            : null;
        }
        activeSection && this[activeSection].classList.remove('active');
        targetSection.classList.add('active');
        this.setState({ activeSection: targetName });
        initialization && this.setState({ initialization: false });
      }
    } else {
      // At first render React needs time to initialize ref and we get undefined
      setTimeout(() => this.handleActiveSectionChange(targetName), 10);
    }
  };


  handleArrowAppearance = () => {
    const leftBoundary = this.navigationLinksList.firstChild.getBoundingClientRect().left;
    const rightBoundary = this.navigationLinksList.lastChild.getBoundingClientRect().right;
    const navigationListLeftBorder = this.navigationLinksList.getBoundingClientRect().left;
    const navigationListRightBorder = this.navigationLinksList.getBoundingClientRect().right;

    this.setState({
      showLeftArrow: Math.round(leftBoundary) < navigationListLeftBorder,
      showRightArrow: Math.round(rightBoundary) > navigationListRightBorder,
    });
  };

  scrollToTarget = (target) => {
    if (this.state.activeSection !== target) {
      this.handleActiveSectionChange(target);
      this.props.scrollToTarget(target);
    }
  };


  handleLeftScroll = () =>
    this.navigationLinksList.scrollLeft = this.navigationLinksList.scrollLeft - this.navigationLinksList.offsetWidth;


  handleRightScroll = () =>
    this.navigationLinksList.scrollLeft = this.navigationLinksList.offsetWidth + this.navigationLinksList.scrollLeft;


  scrollToRightTarget = (targetLeftCoords) => {
    this.navigationLinksList.scrollLeft = (targetLeftCoords - 44) + this.navigationLinksList.scrollLeft;
  };


  scrollToLeftTarget = (targetLeftCoords) => {
    const targetLeftScroll = targetLeftCoords >= 0 ? targetLeftCoords : targetLeftCoords * -1;
    this.navigationLinksList.scrollLeft = this.navigationLinksList.scrollLeft - (targetLeftScroll + 44);
  };


  render () {

    const { showMap, showOrderFormButton, showNavigationMenu, showSimilarLocations, similarLocationsHeadline, scrollToForm, showRichSnippets, checkEconomySectionPresent } = this.props;
    const { showLeftArrow, showRightArrow, topOffset } = this.state;
    const classes = `fixed-nav${ showNavigationMenu ? ' active' : '' } ${showOrderFormButton ? 'with-button' : ''}`;
    const wrapperTop = { 'top': topOffset };


    return (
      <div className={classes} id="dv-fixed-nav" style={wrapperTop}>
        <div className="container">

          <div className="fixed-nav__links-wrapper">
            <ul className="fixed-nav__links-list" ref={el => this.navigationLinksList = el}>

              <li className="fixed-nav__link" ref={el => this.description = el}>
                <span onClick={() => this.scrollToTarget('description')}>{ I18n.t('activerecord.attributes.property.description') }</span>
              </li>

              <li className="fixed-nav__link" ref={ el => this.facts = el }>
                <span onClick={() => this.scrollToTarget('facts')}>{ I18n.t('apps.lb_showroom.detailed.tabs.facts_and_facilities') }</span>
              </li>

              { checkEconomySectionPresent &&
                <li className="fixed-nav__link" ref={el => this.economy = el}>
                <span
                  onClick={() => this.scrollToTarget('economy')}>{I18n.t('apps.lb_showroom.detailed.tabs.economy')}</span>
                </li>
              }

              { showMap &&
                <li className="fixed-nav__link" ref={ el => this.map = el }>
                  <span onClick={() => this.scrollToTarget('map')}>{ I18n.t('detailed.location.headline') }</span>
                </li>
              }

              { showSimilarLocations &&
                <li className="fixed-nav__link" ref={ el => this.similarLocations = el }>
                  <span onClick={() => this.scrollToTarget('similarLocations')}>{ similarLocationsHeadline }</span>
                </li>
              }

              { showRichSnippets &&
                <li className="fixed-nav__link" ref={ el => this.faq = el }>
                  <span onClick={() => this.scrollToTarget('faq')}>{ I18n.t('apps.lb_showroom.detailed.tabs.faq_title') }</span>
                </li>
              }

            </ul>

            { showLeftArrow &&
              <div className="fixed-nav__arrow-left" onClick={this.handleLeftScroll}>
                <KeyboardArrowLeftRounded />
              </div>
            }
            { showRightArrow &&
              <div className="fixed-nav__arrow-right" onClick={this.handleRightScroll}>
                <KeyboardArrowRightRounded />
              </div>
            }
          </div>

          { showOrderFormButton &&
            <div className="order-form-scroll-btn" onClick={() => scrollToForm('order-form-target')}>
              <SvgIco name="contract" size={24} />
              { I18n.t('apps.lb_showroom.detailed.get_more_insights') }
            </div>
          }

        </div>
      </div>
    )
  }
}


export default FixedNavigationBar;
