import React, { useContext, useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import PropTypes from 'prop-types';

import { requestBuilder } from '../../../api/httpService';
import { apiProvider } from '../../../api/core';
import { Context } from '../index';
import MapWrapper from './MapWrapper';

import mapStyles from "../../../src/stylesheets/map_styles.json";
import './index.sass';


const containerStyle = {
  width: '100%',
  height: '100%'
};

let boundsTimer;


const MapSection = () => {

  const {
    getLocationsByCoords,
    googleApiKey,
    keywords,
    mapOptions,
    markersArray,
    requestParams,
    section,
    setComparableLocations,
    setFavouriteLocationsIds,
  } = useContext(Context);

  const { countryAlpha2 } = keywords;
  const { open: mapIsOpen, loaded: mapLoaded } = mapOptions

  const [boundsTrigger, setBoundsTrigger] = useState(false);
  const [currentMarkersArray, setCurrentMarkersArray] = useState([]);
  const [googleMap, setGoogleMap] = useState(null);
  const [mapDragged, setMapDragged] = useState(null);
  const [mapZoomed, setMapZoomed] = useState(null);
  const [mapBounds, setMapBounds] = useState(false);

  const mapMarkersUrl = '/lease/api/frontend/locations/map_markers?country_alpha2=' + countryAlpha2;
  const updateTrigger = mapDragged || mapZoomed;


  useEffect(() => {
    boundsTrigger && boundsMap();
  }, [boundsTrigger]);


  useEffect(() => {
    mapIsOpen && boundsMap();
  }, [mapIsOpen]);


  useEffect(() => {
    setCurrentMarkersArray(markersArray);
    mapIsOpen && boundsMap();
  }, [markersArray]);


  const onLoad = useCallback((map) => {
    setGoogleMap(map);

    apiProvider.post(mapMarkersUrl, requestBuilder(requestParams))
      .then((data) => {
        setCurrentMarkersArray(data.locations || []);
        boundsMap(data.locations, map)
      });
  },[]);


  const onDragEnd = (changeCoords, boundsTrigger) => {
    const googleMapBounds = googleMap && googleMap.getBounds();

    boundsTrigger && setBoundsTrigger(false);
    setMapDragged(false);
    setMapZoomed(false);

    if (googleMapBounds && mapLoaded && changeCoords && !boundsTrigger && !mapBounds) {
      const ne = googleMap.getBounds().getNorthEast();
      const sw  = googleMap.getBounds().getSouthWest();
      const viewport_string = `${sw.lat()},${sw.lng()},${ne.lat()},${ne.lng()}`;
      const searchData = { ...requestParams, viewport_string, postal_districts: [] };

      apiProvider.post(mapMarkersUrl, requestBuilder(searchData))
        .then((data) => {
          // setShowLoader(false);
          data.locations && data.locations.length > 0 && setCurrentMarkersArray(data.locations);
        });

      getLocationsByCoords(viewport_string);
    }

    setMapBounds(false);
  };


  const setMarkers = (markers) => {
    const newMarkers = markers && [ ...markers ];

    return newMarkers && newMarkers.map(marker => {
      const { id, lat, lon } = marker.location;
      let ids = [];

      newMarkers.map(marker => {
        if (marker.location.id !== id && marker.location.lat === lat && marker.location.lon === lon) {
          ids.length ? ids.push(marker.location.id) : ids = [id, marker.location.id];
        };
      });

      ids.length && (marker.location.ids = ids);

      return marker;
    });
  };


  const boundsMap = (currentMarkers, map) => {
    const currentMap = map || googleMap;
    const markers = currentMarkers ? currentMarkers : markersArray;

    clearTimeout(boundsTimer);

    if (currentMap && markers.length > 0 && window.google) {
      const bounds = new window.google.maps.LatLngBounds();

      setMapBounds(true);

      markers.map((elem) => {
        elem.location.lat && elem.location.lon && bounds.extend(new window.google.maps.LatLng(elem.location.lat, elem.location.lon));
        return elem;
      });

      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const extendPoint1 = new window.google.maps.LatLng(bounds.getNorthEast().lat() + 0.001, bounds.getNorthEast().lng() + 0.001);
        const extendPoint2 = new window.google.maps.LatLng(bounds.getNorthEast().lat() - 0.001, bounds.getNorthEast().lng() - 0.001);

        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }

      currentMap.fitBounds(bounds);
    } else {
      boundsTimer = setTimeout(() => boundsMap(currentMarkers, map), 10);
    }
  };


  const currentMarkers =useMemo(() => {
    return setMarkers(currentMarkersArray.length > 0 ? currentMarkersArray : markersArray);
  },[ currentMarkersArray, markersArray ]);


  return (
    <LoadScript googleMapsApiKey={googleApiKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        mapContainerClassName="main-map"
        id="main-map"
        zoom={10}
        // onBoundsChanged={() => !mapLoaded && setMapLoaded(true)}
        onLoad={onLoad}
        onDragStart={() => setMapDragged(true)}
        onIdle={() => onDragEnd(updateTrigger, boundsTrigger)}
        onZoomChanged={() => setMapZoomed(true)}
        options={{
          gestureHandling: "greedy",
          styles: mapStyles,
          scrollwheel: true,
          mapTypeControl: false,
          fullscreenControl: false,
          panControl: false,
          streetViewControl: false,
        }}
      >
        <MapWrapper
          boundsTrigger={boundsTrigger}
          closeInfoWindow={mapDragged || mapZoomed || boundsTrigger}
          markersArray={currentMarkers}
          section={section}
          countryAlpha2={countryAlpha2}
          updateFavouriteLocations={setFavouriteLocationsIds}
          updateComparableLocations={setComparableLocations}
        />
      </GoogleMap>
    </LoadScript>
  );
};


MapSection.propTypes = {
  markersArray: PropTypes.arrayOf(PropTypes.shape),
};

MapSection.defaultProps = {
  markersArray: [],
};

export default MapSection;
