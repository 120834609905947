import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { arrayIncludesObject } from '../../helpers/array';
import { SvgIco } from "../../SharedComponents/Icons";

import './index.sass';


class DropdownSelector extends React.PureComponent {
  state = {
    isOpened: false,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (event.target.tagName !== 'svg' && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpened: false})
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  itemIsSelected = (id) => {
    return arrayIncludesObject(this.props.selectedData, 'id', id);
  };

  handleClick = () => {
    this.setState({ isOpened: !this.state.isOpened });
  };

  renderPlaceholder = () => {
    const { selectedData, selectedCounterPhrase, showCount, showOnlyCountPlaceholder } = this.props;
    let placeholder;

    if (showOnlyCountPlaceholder && selectedData.length > 0) return `${selectedCounterPhrase}${showCount ? ` (${selectedData.length})` : ''}`;

    switch(selectedData.length) {
      case 0:
        placeholder = this.props.placeholder;
        break;
      case 1:
        placeholder = selectedData[0].name.charAt(0).toUpperCase() + selectedData[0].name.slice(1);
        break;
      default:
        placeholder = `${selectedCounterPhrase}${showCount ? ` (${selectedData.length})` : ''}`;
    }

    return placeholder;
  };

  generateDropdownBody = (itemsArray) => {

    return (
      <div className='dropdown-selector__column'>
        {itemsArray.map((item) => (
          <div key={item.id}
               onClick={e => this.props.selectAction(e, item)}
               className={`dropdown-selector__item ${ this.itemIsSelected(item.id) ? 'dropdown-selector__item--selected' : '' }`}>

            <div className='dropdown-selector__item-icon'>
              <div className={ this.itemIsSelected(item.id) ? 'dropdown-selector__item-icon-checked' : 'dropdown-selector__item-checkbox' }>
                <SvgIco name="check" size={18} />
              </div>
            </div>

            <div className='dropdown-selector__item-name' style={{textTransform: 'capitalize'}}>
              {item.name}
            </div>
          </div>
        ))}
      </div>
    )
  };

  render() {
    const { dynamicData, selectedData, selectedCounterPhrase, isMobileMenu } = this.props;
    const isOpened = !isMobileMenu || this.state.isOpened;
    const dropdownBody = this.generateDropdownBody(dynamicData);
    const triggerClasses = classNames('dropdown-selector__trigger', { 'dropdown-selector__trigger--active': isOpened });


    return (
      <div className='dropdown-selector' ref={ this.setWrapperRef }>
        <div className={triggerClasses} onClick={e => this.handleClick(e)}>
          <div className={`dropdown-selector__placeholder${isOpened ? ' active' : ''}`}>
            <div className="dropdown-selector__placeholder-text mobile-hidden">
              { this.renderPlaceholder() }
            </div>

            <div className="dropdown-selector__placeholder-text">
              { selectedCounterPhrase }
              { selectedData.length > 0 &&
              <div className="dropdown-selector__selected-tooltip">
                { selectedData.map(item => item.name).join(', ') }
              </div>
              }
            </div>

          </div>
          <div className="dropdown-selector__action-icon">
            { !isOpened ? <SvgIco name="expand-more" size={20} /> : <SvgIco name="expand-less" size={20} /> }
          </div>
        </div>

        { (isOpened || isMobileMenu) &&
          <div className={`dropdown-selector__body${isOpened ? ' dropdown-selector__body--open' : ''}`}>
            {(dynamicData.length > 0) && dropdownBody}
            {(dynamicData.length === 0) && (
              <div className='dropdown-selector__empty'>
                { I18n.t('search_agent.no_results') }
              </div>
            )}
            <div className="dropdown-selector__separator" />
          </div>
        }

      </div>
    );
  }
}

DropdownSelector.propTypes = {
  dynamicData: PropTypes.array,
};


DropdownSelector.defaultProps = {
  dynamicData: []
};

export default DropdownSelector;
