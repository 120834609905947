import React from 'react';
import AdditionalOption from '../SharedComponents/AdditionalOption';

import './EmptyPage.sass';

const EmptyPage = () => {

  const bannerClasses = 'inline col-12 col-md-12 col-lg-12 col-xl-12';

  return (
    <div className="favorites-empty-page container">
      <div className="row">
        <div className="col-12 col-md-7 col-lg-7 col-xl-6 favorites-empty-page__text-content">
          <p>{ I18n.t('favorite_locations.empty_page.first_paragraph') }</p>
          <p>{ I18n.t('favorite_locations.empty_page.second_paragraph') }</p>
        </div>
        <div className="col-6 offset-3 col-md-4 offset-md-1 col-lg-3 offset-lg-2 col-xl-3 offset-xl-3 favorites-empty-page__image-wrapper">
          <img src={require('../../src/images/icons/favorite_empty_page.svg')} alt="favorite_empty_page"/>
        </div>

        <AdditionalOption
          headline={I18n.t('search_agents.index.browse_header')}
          content={I18n.t('search_agents.index.browse_body')}
          linkContent={I18n.t('search_agents.index.browse_cta')}
          buttonColor="orange"
          gridClasses={bannerClasses}
        />
      </div>
    </div>
  )
}

export default EmptyPage;
