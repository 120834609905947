import React, { Component } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import parkingPin from '../../src/images/icons/p_marker.png';

import './ParkingMarker.sass';


class ParkingMarker extends Component {
  handleClick = (element) => {
    switch (element) {
        case 'marker':
            if(this.props.markerOpenedId == this.props.marker.id) {
                this.props.updateMarkerOpenedId(null);
            } else {
                this.props.updateMarkerOpenedId(this.props.marker.id);
            }
            break;
        case 'close_icon':
            this.props.updateMarkerOpenedId(null);
            break;
    }
  };
  renderPrice = (priceFrom, priceTo) => {
    if (priceFrom && priceTo) {
      return (
        <div className="parking-info-window__price">
          <div className="parking-info-window__price-label">
            {I18n.t(
              'parking.parking_lots.parking_interval_row.monthly_rent_per_space'
            ) + ': '}
            <div className="parking-info-window__price-value">
              {priceFrom + ' - ' + priceTo}
            </div>
          </div>
        </div>
      );
    } else if (priceFrom && !priceTo) {
      return (
        <div className="parking-info-window__price">
          <div className="parking-info-window__price-label">
            {I18n.t(
              'parking.parking_lots.parking_interval_row.monthly_rent_per_space'
            ) + ': '}
            <div className="parking-info-window__price-value">{priceFrom}</div>
          </div>
        </div>
      );
    }
    return null;
  };
  render() {
    const position = {
      lat: this.props.marker.latitude,
      lng: this.props.marker.longitude,
    };
    const {
      marker: {
        url,
        title,
        kind,
        monthly_rent_per_space_amount_from,
        monthly_rent_per_space_amount_to,
        primary_photo_url,
      },
    } = this.props;
    return (
      <Marker
        id={this.props.marker.id}
        icon={parkingPin}
        position={position}
        onClick={() => this.handleClick('marker')}
      >
        {this.props.markerOpenedId == this.props.marker.id && (
          <InfoWindow
            position={position}
            onCloseClick={() => this.handleClick('close_icon')}
            options={{ pixelOffset: new google.maps.Size(0, 0) }}
          >
            <div className="parking-info-window">
              <div className="parking-info-window__wrapper">
                <div className="parking-info-window__container">
                  <div className="parking-info-window__title">
                    <a href={url} className="parking-info-window__title-link">
                      {title}
                      <span className="parking-info-window__block-link" />
                    </a>
                  </div>
                  <div className="parking-info-window__details">
                    <div className="parking-info-window__kind">
                      {I18n.t(`parking.generic.${kind}`)}
                    </div>
                    {this.renderPrice(
                      monthly_rent_per_space_amount_from,
                      monthly_rent_per_space_amount_to
                    )}
                  </div>
                </div>

                <div className="parking-info-window__image-wrapper">
                  <img
                    className="parking-info-window__image"
                    src={primary_photo_url}
                  />
                </div>
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

export default ParkingMarker;
