import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';
import { isMobileOnly } from 'react-device-detect';
import { SvgIco } from "../../SharedComponents/Icons";
import TableContent from './TableContent';
import { tabDataBot, danishInfoFullPriceList, tabData } from '../praiceLinks'
import "./index.sass";


class ContractsTop extends Component {

  state = {
    activeTab: true,
    changeState: false,
    isDesktop: window.innerWidth > 960,
  };

  changeActiveTab = () =>
    this.setState({ activeTab: !this.state.activeTab });

  updateSliderHeight = () => {
    if (this.slider) {
      setTimeout(() => {
        this.slider.forceUpdate()
      }, isMobileOnly ? 0 : 350)
    }
  };

  fillPrices = (prices, strategy) => {
    if (this.props.siteKey === 'dk') {
      let priceTemplate = JSON.parse(JSON.stringify(danishInfoFullPriceList));
      let strategy_prices = prices['prices'][strategy];

      priceTemplate.forEach((obj) => {
        obj.price = strategy_prices[obj.key];
      });

      return priceTemplate;
    } else {
      return null;
    }
  };


  render() {

    const {
      advertisementStrategiesPrices,
      contactUsFormPath,
      contractDetails,
      contractDetailsBot,
      contractLink,
      formAuthToken,
      privacyPolicyPath,
      signupFormPath,
      siteKey,
    } = this.props;

    const { activeTab, isDesktop } = this.state;
    const priceDetails = contractDetails.payPerAdvertisementContracts;
    const premiumPlusId = contractDetails.payPerAdvertisementContracts ? contractDetails.payPerAdvertisementContracts.premiumPlus.id : '';
    const priceDetailsPremiumPlus = { heading: 'Fra  5250', id: premiumPlusId };
    const isDk = siteKey === 'dk';

    const settings = {
      adaptiveHeight: true,
      arrows: true,
      dots: true,
      infinite: true,
      nextArrow: <div className="testimonials__next-btn"><SvgIco name="keyboard-arrow-right-rounded-ios" size={32} /></div>,
      prevArrow: <div className="testimonials__prev-btn"><SvgIco name="keyboard-arrow-left-rounded-ios" size={32} /></div>,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const basicPayPerShowTable =
      <TableContent
        activeTab={activeTab}
        danishInfoFullPriceList={this.fillPrices(advertisementStrategiesPrices, 'basic')}
        dropDownPrices={true}
        payPerShow={true}
        priceSection={priceDetails ? priceDetails.basic : ''}
        rate={'basic'}
        siteKey={siteKey}
        signupFormPath={signupFormPath}
        tabInfo={tabData.basicNoCure}
        updateSliderHeight={this.updateSliderHeight}
      />;

    const basicPlusPayPerShowTable =
      <TableContent
        activeTab={activeTab}
        danishInfoFullPriceList={this.fillPrices(advertisementStrategiesPrices, 'basicPlus')}
        dropDownPrices={true}
        payPerShow={true}
        priceSection={priceDetails ? priceDetails.basicPlus : ''}
        rate={'plus'}
        signupFormPath={signupFormPath}
        siteKey={siteKey}
        tabInfo={tabData.basicNoCurePlus}
        updateSliderHeight={this.updateSliderHeight}
      />;

    const premiumPayPerShowTable =
      <TableContent
        activeTab={activeTab}
        danishInfoFullPriceList={this.fillPrices(advertisementStrategiesPrices, 'premium')}
        dropDownPrices={true}
        payPerShow={true}
        priceSection={priceDetails ? priceDetails.premium : ''}
        rate={'premium'}
        signupFormPath={signupFormPath}
        siteKey={siteKey}
        tabInfo={tabData.noCurePremium}
        updateSliderHeight={this.updateSliderHeight}
      />;

    const enterprisePayPerShowTable =
      <TableContent
        activeTab={activeTab}
        contactUsFormPath={contactUsFormPath}
        danishInfoFullPriceList={this.fillPrices(advertisementStrategiesPrices, 'premiumPlus')}
        dropDownPrices={true}
        formAuthToken={formAuthToken}
        payPerShow={true}
        priceSection={priceDetails ? priceDetailsPremiumPlus : ''}
        privacyPolicyPath={privacyPolicyPath}
        rate={'premium-plus'}
        signupFormPath={signupFormPath}
        siteKey={siteKey}
        tabInfo={tabData.premiumPlus}
        updateSliderHeight={this.updateSliderHeight}
      />;

    const basicLeaseTable =
      <TableContent
        priceSection={contractDetails.noCureNoPayContracts.noCureBasic}
        rate={'basic'}
        siteKey={siteKey}
        signupFormPath={signupFormPath}
        tabInfo={tabData.basicNoCure}
      />;

    const enterpriceLeaseTable =
      <TableContent
        contactUsFormPath={contactUsFormPath}
        formAuthToken={formAuthToken}
        privacyPolicyPath={privacyPolicyPath}
        rate={'enterprise'}
        signupFormPath={signupFormPath}
        siteKey={siteKey}
        tabInfo={tabData.noCureEnterprice}
      />;


    return (
      <div id="tabs" className="signup-tabs-wrapper contracts-top">

        { !isDk && isDesktop && <div id="coworking" className="desktop-target" /> }

        <div className="container">
          { !isDk && isDesktop
            ?
              <div className="global-title-wrapper">
                <div className="business-center-title">
                  <div className="signup-tabs-heading">
                    <h2>{ I18n.t("signup.new_form.plans.block1.title_new") }</h2>
                  </div>

                  <div className="signup-tabs-subheading">
                    <a href={`${signupFormPath}?contract_id=${contractDetails.noCureNoPayContracts.noCureBasic.id}`}>
                      { I18n.t('signup.teaser.tabs.tab.click-premise') }
                    </a>
                  </div>
                </div>

                <div className="meeting-rooms-title">
                  <div className="signup-tabs-heading">
                    <h2>{ I18n.t("apps.entry_page.entry_pages.show.lease.meeting_rooms") }</h2>
                  </div>

                  <div className="signup-tabs-subheading">
                    <a href={`${signupFormPath}?contract_id=${contractLink.annualRemoval.id}`}>
                      { I18n.t("signup.teaser.meeting_rooms_free_treal_link") }
                    </a>
                  </div>
                </div>
              </div>
            :
              <>
                <div className="signup-tabs-heading">
                  <h2>{ I18n.t("signup.new_form.plans.block1.title_new") }</h2>
                </div>

                { isDk
                  ?
                    <div className="signup-tabs-subheading">
                      <p>{ I18n.t('signup.teaser.tabs.heading_sub') }</p>
                    </div>
                  :
                    <div className="signup-tabs-subheading text-center">
                      <a href={`${signupFormPath}?contract_id=${contractDetails.noCureNoPayContracts.noCureBasic.id}`}>
                        { I18n.t('signup.teaser.tabs.tab.click-premise') }
                      </a>
                    </div>
                }
              </>
          }

          <div className="signup-tabs-tabs">

            { isDk &&
              <div className="tabs-wrapper">
                <div onClick={this.changeActiveTab} className={`${this.state.activeTab ? 'active': ''} tab tab-left`}>
                  <span>{ I18n.t('signup.teaser.tabs.tab.left') }</span>
                </div>

                <div onClick={this.changeActiveTab} className={`${!this.state.activeTab ? 'active': ''} tab tab-right`}>
                  <span>{ I18n.t('signup.teaser.tabs.tab.right') }</span>
                </div>
              </div>
            }


            <div className="tab-content">
              { isDk && activeTab
                ?
                  <div id="pay_per_showing">
                    <Slider ref={ref => { this.slider = ref }} {...settings}>
                      { basicPayPerShowTable }
                      { basicPlusPayPerShowTable }
                      { premiumPayPerShowTable }
                      { enterprisePayPerShowTable }
                    </Slider>
                    <div className="tabs__wrapper">
                      { basicPayPerShowTable }
                      { basicPlusPayPerShowTable }
                      { premiumPayPerShowTable }
                      { enterprisePayPerShowTable }
                    </div>
                  </div>
                :
                  <div id="lease">
                    { isDk &&
                      <Fragment>
                        <Slider ref={ref => { this.slider = ref }} {...settings}>
                          { basicLeaseTable }
                          { enterpriceLeaseTable }
                        </Slider>
                        <div className="tabs__wrapper two-columns-center">
                          { basicLeaseTable }
                          { enterpriceLeaseTable }
                        </div>
                      </Fragment>
                    }

                    { !isDk &&
                      <div className="tab__wrapper">
                        <div className="global-business-center-wrapper">
                          { basicLeaseTable }
                        </div>

                        { !isDk && isDesktop &&
                          <div className="meeting-rooms-wrapper">

                            <Slider ref={ref => { this.slider = ref }} {...settings}>
                              <TableContent
                                annual={true}
                                id={contractDetailsBot.meetingRoomContracts.annualRemoval.id}
                                price={(advertisementStrategiesPrices && advertisementStrategiesPrices.prices.meetingRoom.default) || null}
                                rate={'basic'}
                                signupFormPath={signupFormPath}
                                siteKey={siteKey}
                                tabInfo={tabDataBot.basic}
                              />
                              <TableContent
                                annual={true}
                                id={contractDetailsBot.meetingRoomContracts.annualRemovalPremium.id}
                                price={(advertisementStrategiesPrices && advertisementStrategiesPrices.prices.meetingRoomPremium.default) || null}
                                rate={'premium premium-bottom'}
                                signupFormPath={signupFormPath}
                                siteKey={siteKey}
                                tabInfo={tabDataBot.premium}
                              />
                            </Slider>

                            <div className="tabs__wrapper desktops">
                              <TableContent
                                annual={true}
                                id={contractDetailsBot.meetingRoomContracts.annualRemoval.id}
                                price={(advertisementStrategiesPrices && advertisementStrategiesPrices.prices.meetingRoom.default) || null}
                                rate={'basic'}
                                signupFormPath={signupFormPath}
                                siteKey={siteKey}
                                tabInfo={tabDataBot.basic}
                              />
                              <TableContent
                                annual={true}
                                id={contractDetailsBot.meetingRoomContracts.annualRemovalPremium.id}
                                price={(advertisementStrategiesPrices && advertisementStrategiesPrices.prices.meetingRoomPremium.default) || null}
                                rate={'premium premium-bottom'}
                                signupFormPath={signupFormPath}
                                siteKey={siteKey}
                                tabInfo={tabDataBot.premium}
                              />
                            </div>
                          </div>
                        }
                      </div>
                    }
                  </div>
              }
            </div>

            { !isDk && isDesktop
              ?
                <div className="global-bottom-wrapper">
                  <div className="business-enter-bottom">
                    { activeTab && isDk
                      ?
                        <div className="signup-tabs-bottom">
                          <p dangerouslySetInnerHTML={{__html: I18n.t("signup.teaser.prices.footer")}} />
                        </div>
                      :
                        <div className="signup-tabs-bottom">
                          <p dangerouslySetInnerHTML={{__html: I18n.t("signup.teaser.tabs.footer")}} />
                        </div>
                    }
                  </div>

                  <div className="meeting-room-bottom">
                    <div className="signup-tabs-bottom with-two-columns">
                      <p dangerouslySetInnerHTML={{__html: I18n.t('signup.teaser.prices.footer')}} />
                    </div>
                  </div>
                </div>
              :
                <>
                  { activeTab && isDk
                    ?
                      <div className="signup-tabs-bottom">
                        <p dangerouslySetInnerHTML={{__html: I18n.t("signup.teaser.prices.footer")}} />
                      </div>
                    :
                      <div className="signup-tabs-bottom text-center">
                        <p dangerouslySetInnerHTML={{__html: I18n.t("signup.teaser.tabs.footer")}} />
                      </div>
                  }
                </>
            }
          </div>
        </div>
      </div>
    );
  }
};

export default ContractsTop;
