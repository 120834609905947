import React from 'react';
// import { useTable } from 'react-table/src/hooks/useTable';
import { useTable } from 'react-table';
import TableBody from '@material-ui/core/TableBody';
import MaUTable from '@material-ui/core/Table';


const Table = ({ columns,
                 data,
                 name,
                 extraClass }) => {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data,
    },
  );

  const locationsLength = data && data.length;

  const isEmpty = (value) => !value || !Object.keys(value).length;


  return (
    <div className={`comparison-table__table ${extraClass}`} id={`${name || ''}TableWrapper`}>
      <MaUTable {...getTableProps()} className="table sticky">
        <TableBody {...getTableBodyProps()}
          // className={`body ${loading ? 'loading' : ''}`}
        >
          { headerGroups.map(headerGroup =>
             headerGroup.headers.map((column, headerIndex) => {
               let emptyCount = 0;

               return (
                 <tr key={headerIndex}>
                   <td style={{ width: `calc(100% / ${locationsLength + 1})` }}>
                     { column.render('Header') }
                   </td>
                   { data.map((item, index) => {
                     prepareRow(rows[index]);

                     const cell = rows[index].cells[headerIndex];
                     const cellIsEmpty = isEmpty(cell.value);

                     cellIsEmpty && (emptyCount = emptyCount + 1);

                     if (emptyCount === locationsLength) {
                       cell.column.toggleHidden();
                       emptyCount = 0;
                     }

                     index === locationsLength - 1 && (emptyCount = 0);

                     return (
                       <td {...cell.getCellProps()}
                           className={cellIsEmpty ? 'single' : ''}
                           style={{ width: `calc(100% / ${locationsLength + 1})`}}>
                         { cellIsEmpty ? '-' : cell.render('Cell') }
                       </td>
                     )
                   })}
                 </tr>)
             }
            ))
          }
        </TableBody>
      </MaUTable>
    </div>
  );
};

export default Table;
