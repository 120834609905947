import React, { Fragment } from 'react';

import './index.sass';


const PaymentTeasers = ({ siteKey }) => {
  return (
    <div className="payment-teasers container">
      <h2 className="payment-teasers__headline">
        { I18n.t('signup.teaser.intro.heading') }
      </h2>

      <p className="payment-teasers__content-text">
        { I18n.t('signup.teaser.intro.text') }
        { siteKey === 'dk' &&
          <Fragment>
            <br/> { I18n.t('signup.teaser.intro.text_2') }
          </Fragment>
         }
      </p>

      <div className="row">
        <div className="payment-teasers__teaser-block-wrapper col-12 col-md-6">
          <div className="payment-teasers__teaser-block payment-teasers__teaser-block--left">
            <div className="payment-teasers__teaser-content-background" />

            <div className="payment-teasers__teaser-headline title--2">
              { I18n.t('signup.new_form.plans.block1.title_new') }
            </div>

            <p className="payment-teasers__teaser-content">
              { I18n.t('signup.teaser.block.left.heading_sub') }
            </p>

            <a href="#tabs" className="payment-teasers__teaser-button">
              { I18n.t('signup.teaser.block.button_text') }
            </a>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="payment-teasers__splash">
            <div className="payment-teasers__splash-wrapper">
              <p className="payment-teasers__splash-content" dangerouslySetInnerHTML={{ __html: I18n.t('signup.teaser.meeting_rooms_splash_icon') }} />
            </div>
          </div>

          <div className="payment-teasers__teaser-block payment-teasers__teaser-block--right">
            <div className="payment-teasers__teaser-content-background" />

            <div className="payment-teasers__teaser-headline title--2">
              {siteKey === 'dk'
                ? I18n.t('apps.entry_page.entry_pages.show.lease.meeting_rooms_modelokaler')
                : I18n.t('apps.entry_page.entry_pages.show.lease.meeting_rooms')
              }
            </div>

            <p className="payment-teasers__teaser-content">
              { I18n.t('signup.teaser.block.right.heading_sub_new') }
            </p>

            <a href="#coworking" className="payment-teasers__teaser-button">
              { I18n.t('signup.teaser.block.button_text') }
            </a>
          </div>
        </div>
      </div>
    </div>
  )
};

export default React.memo(PaymentTeasers);
