import React, { Component } from 'react';

import TeaserImgLeft from '../../../src/images/list_office/teaser-img-left.svg';
import TeaserImgRight from '../../../src/images/list_office/teasr-img-right.svg';
import TeaserImgCenter from '../../../src/images/list_office/teaser-img-center.svg';
import GetOfferIcon from '../../../src/images/list_office/get-offer-icon.svg';
import SeePricesIcon from '../../../src/images/list_office/see-prices-icon.svg';
import ContactUsIcon from '../../../src/images/list_office/contact-us-icon.svg';
import LogoDk from '../../../src/images/list_office/logo-dk-new.svg';
import RealEstateProvidersLogos from '../../../src/images/list_office/real-estate-providers-logos.svg';
import { apiProvider } from "../../../api/core";
import { SvgIco } from "../../SharedComponents/Icons";
import FormSentModalThankyou from "../ContractsTop/FormSentModalThankyou";
import ContactForm from "./ContactForm";

import './index.sass';


class TeaserBlocks extends Component {

  state = {
    adviceFormIsOpen: false,
    realEstateAgentFormIsOpen: false,
    showServerErrorMessage: false,
    thankYouModalIsOpen: false,
  };

  openModalForm = (modalFormIsOpen) => {
    this.setState({
      [modalFormIsOpen]: true,
      thankYouModalIsOpen: false,
    });
  };

  closeModalForm = () => {
    this.setState({
      adviceFormIsOpen: false,
      realEstateAgentFormIsOpen: false,
      thankYouModalIsOpen: false,
      showServerErrorMessage: false,
    });
  };

  infoHandlerSubmit = (params) => {

    const { adviceFormIsOpen } = this.state;
    const { getHelpFormPath, findAgentFormPath } = this.props;

    const bodyFormData = new FormData();
    delete params.fifth_element;

    for (let key in params) {
      bodyFormData.append(key, params[key]);
    }

    apiProvider.post(adviceFormIsOpen ? getHelpFormPath : findAgentFormPath, bodyFormData, true)
      .then(response =>
        response.error
          ? this.setState({ showServerErrorMessage: true })
          : this.setState({
              thankYouModalIsOpen: true,
              adviceFormIsOpen: false,
              realEstateAgentFormIsOpen: false,
              showServerErrorMessage: false,
          })
      );
  };


  render() {

    const { privacyPolicyPath } = this.props;
    const { adviceFormIsOpen, realEstateAgentFormIsOpen, showServerErrorMessage, thankYouModalIsOpen } = this.state;


    return (
      <div className="top-info container">

        <FormSentModalThankyou
          handleClose={this.closeModalForm}
          openThankModal={thankYouModalIsOpen}
        />

        { (adviceFormIsOpen || realEstateAgentFormIsOpen) &&
          <div className="get-contact-form">
            <ContactForm
              isAdviceForm={adviceFormIsOpen}
              handleClose={this.closeModalForm}
              openContactModal={adviceFormIsOpen || realEstateAgentFormIsOpen}
              privacyPolicyPath={privacyPolicyPath}
              submitHandler={this.infoHandlerSubmit}
              showServerErrorMessage={showServerErrorMessage}
            />
          </div>
        }

        <div className="row">
          <div className="top-info__block-wrapper col-12 col-md-4">
            <div className="top-info__block">

              <div>
                <div
                  className="top-info__img-block lazyload"
                  data-bg={TeaserImgLeft}
                  data-sizes="auto"
                />

                <div className="top-info__block-headline">
                  { I18n.t('signup.teaser.tenant.headline') }
                </div>

                <div className="top-info__block-benefits-list">
                  <div className="top-info__block-benefit">
                    { I18n.t('signup.teaser.tenant.benefit_1') }
                  </div>

                  <div className="top-info__block-benefit">
                    { I18n.t('signup.teaser.tenant.benefit_2') }
                  </div>

                  <div className="top-info__block-benefit">
                    { I18n.t('signup.teaser.tenant.benefit_3') }
                  </div>

                  <div className="top-info__block-benefit">
                    { I18n.t('signup.teaser.tenant.benefit_4') }
                  </div>
                </div>
              </div>

              <div className="top-info__block-bottom-wrapper">
                <a href="#tabs" className="top-info__block-button btn-orange">
                  <img
                    alt="See Prices"
                    className="lazyload"
                    data-sizes="auto"
                    data-src={SeePricesIcon}
                    height={24}
                    width={24}
                  />
                  { I18n.t('signup.teaser.block.button_text') }
                </a>

                <div className="top-info__block-bottom-info">
                  <img
                    alt="Lokalebasen.dk"
                    className="lazyload"
                    data-sizes="auto"
                    data-src={LogoDk}
                    height={36}
                    width="auto"
                  />
                </div>
              </div>
            </div>
          </div>


          <div className="top-info__block-wrapper col-12 col-md-4">
            <div className="top-info__block">

              <div>
                <div className="top-info__img-block lazyload"
                     data-bg={TeaserImgCenter}
                     data-sizes="auto" />

                <div className="top-info__block-headline">
                  { I18n.t('signup.teaser.advice.headline') }
                </div>

                <div className="top-info__block-benefits-list">
                  <div className="top-info__block-benefit">
                    { I18n.t('signup.teaser.advice.benefit_1') }
                  </div>

                  <div className="top-info__block-benefit">
                    { I18n.t('signup.teaser.advice.benefit_2') }
                  </div>

                  <div className="top-info__block-benefit">
                    <div dangerouslySetInnerHTML={{ __html: I18n.t('signup.teaser.advice.benefit_3')}} />
                  </div>
                </div>
              </div>

              <div className="top-info__block-bottom-wrapper">
                <div className="top-info__block-button" onClick={() => this.openModalForm('adviceFormIsOpen')}>
                  <img data-src={GetOfferIcon} alt="See Prices" width={24} height={24} className="lazyload" data-sizes="auto" />
                  { I18n.t('signup.teaser.advice.button') }
                </div>
                <div className="top-info__block-bottom-info">
                  <div>{ I18n.t('signup.teaser.advice.hotline_text') }</div>
                  <div className="top-info__block-bottom-hotline">
                    <SvgIco name="phone" size={16} />
                    39 45 85 37
                  </div>
                </div>
              </div>

            </div>
          </div>


          <div className="top-info__block-wrapper col-12 col-md-4">
            <div className="top-info__block">

              <div>
                <div
                  className="top-info__img-block lazyload"
                  data-bg={TeaserImgRight}
                  data-sizes="auto"
                />

                <div className="top-info__block-headline">
                  { I18n.t('signup.teaser.agent.headline') }
                </div>

                <div className="top-info__block-benefits-list">
                  <div className="top-info__block-benefit">
                    { I18n.t('signup.teaser.agent.benefit_1') }
                  </div>

                  <div className="top-info__block-benefit">
                    { I18n.t('signup.teaser.agent.benefit_2') }
                  </div>

                  <div className="top-info__block-benefit">
                    { I18n.t('signup.teaser.agent.benefit_3') }
                  </div>
                </div>
              </div>

              <div className="top-info__block-bottom-wrapper">
                <div className="top-info__block-button" onClick={() => this.openModalForm('realEstateAgentFormIsOpen')}>
                  <img
                    alt="See Prices"
                    className="lazyload"
                    data-sizes="auto"
                    data-src={ContactUsIcon}
                    height={24}
                    width={24}
                  />
                  { I18n.t('signup.teaser.tabs.tab.block.contact_us.text') }
                </div>

                <div className="top-info__block-bottom-info">
                  <img
                    alt="Lokalebasen.dk"
                    className="lazyload providers-logos"
                    data-sizes="auto"
                    data-src={RealEstateProvidersLogos}
                    height={43}
                    width="auto"
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TeaserBlocks;
