import React, { useState, useEffect, Fragment } from 'react';
import queryString from "query-string";

import { apiProvider } from "../../api/core";
import httpService from "../../api/httpService";
import { handleComparableLocationsCounter } from "../../utils/topBarHelper";
import GetInfoFormModal from "../SharedComponents/GetInfoFormModal";
import SearchAgentBanner from "../SharedComponents/SearchAgentBanner";
import { checkOrderedLocation } from "../helpers/checkOrderedLocation";
import { loadTawkTo } from "../TawkTo/TawkTo";
import StickyBar from "../FavoritesPage/StickyBar";
import { SvgIco } from "../SharedComponents/Icons";
import { FileLoader } from "../Provider/Loaders";
import ModalWindow from "../SharedComponents/ModalWindow";
import { sendTrovitLead } from "../helpers/trovit_analitics";
import ComparisonTable from "./ComparisonTable";

import './index.sass';


let interval;


const LocationsComparison = ({ newSearchAgentPath, createComparisonPdfPath, siteKey }) => {

  const locationsKind = queryString.parse(window.location.search).kind;

  const [locations, setLocations] = useState([]);
  const [selectedLocationsIds, setSelectedLocationsIds] = useState([]);
  const [comparableLocations, setComparableLocations] = useState([]);
  const [orderedLocations, setOrderedLocations] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteButtonsOpen, setDeleteButtonsOpen] = useState(false);
  const [infoFormIsActive, setInfoFormIsActive] = useState(false);
  const [showServerErrorMessage, setShowServerErrorMessage] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [pdfModalIsOpen, setPdfModalIsOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfReady, setPdfReady] = useState(null);


  useEffect(() => {
    if (locationsKind) {
      const comparisonLeases = localStorage && JSON.parse(localStorage.getItem(`my.comparable_lease_${siteKey}`)) || {};
      const locationsIds = comparisonLeases[locationsKind];
      const locationsCount = locationsIds && locationsIds.length;

      let locationsParamsIds = '';
      locationsCount && locationsIds.map(id => locationsParamsIds = locationsParamsIds + `ids[]=${id}&`);
      setComparableLocations(comparisonLeases);

      if (locationsCount) {

        setLoading(true);

        apiProvider.getAll('/api/compartion_locations/get_by_ids?' + locationsParamsIds)
          .then(response => {
            setLocations(response);
            setLoading(false);

            if (response.length !== locationsIds.length) {
              const newComparableLocations = { ...comparisonLeases };

              response.length
                ? (newComparableLocations[locationsKind] = response.map(location => location.location_id))
                : (delete newComparableLocations[locationsKind]);

              localStorage && localStorage.setItem(`my.comparable_lease_${siteKey}`, JSON.stringify(newComparableLocations));
              setComparableLocations(comparisonLeases);
              handleComparableLocationsCounter(siteKey);
            }
          })
          .catch(error => {
            setLoading(false);
            console.log('Error', error);
          })
      }
    }
  }, []);

  useEffect(() => {
    loadTawkTo(siteKey);
  }, []);

  useEffect(() => {
    loadTawkTo(siteKey);
  }, [pdfModalIsOpen]);

  useEffect(() => {
    if(localStorage) {
      localStorage.getItem('my.ordered_locations')
        ? setOrderedLocations(checkOrderedLocation)
        : localStorage.setItem('my.ordered_locations', JSON.stringify({}));
    }
  }, []);


  const locationsAvailableForOrder = React.useMemo(
    () => {
      const selectedLocationsList = [];

      locations.map(location => {

        const { location_id: id } = location;

        const {
          address,
          // is_jeudan,
          shown_as_rented_out,
          temporarily_unavailable,
          url,
          uuid
        } = location.data;

        selectedLocationsIds.includes(`${id}`)
        && !orderedLocations.includes(uuid)
        && !shown_as_rented_out
        && !temporarily_unavailable
        && selectedLocationsList.push({
            address,
            id,
            url,
            uuid,
            // isJeudan: is_jeudan
          });
      });

      return selectedLocationsList;
    },
    [locations, orderedLocations, selectedLocationsIds],
  );


  const handleSelect = (id) => {
    const updatedSelectedLocation = [ ...selectedLocationsIds ];
    const selectedIndex = updatedSelectedLocation.indexOf(`${id}`);

    selectedIndex >= 0
      ? updatedSelectedLocation.splice(selectedIndex, 1)
      : updatedSelectedLocation.push(`${id}`);

    setSelectedLocationsIds(updatedSelectedLocation);
  };


  const deleteAll = () => {
    const newComparableLocations = { ...comparableLocations };

    delete newComparableLocations[locationsKind];
    setLocations([]);
    localStorage && localStorage.setItem(`my.comparable_lease_${siteKey}`, JSON.stringify(newComparableLocations));
    handleComparableLocationsCounter(siteKey);
  };


  const deleteSelected = () => {
    const newComparableLocations = { ...comparableLocations };
    const newLocations = [ ...locations ].filter(location => !selectedLocationsIds.includes(location.location_id));
    const comparableIds = [ ...newComparableLocations[locationsKind] ].filter(id => !selectedLocationsIds.includes(id));

    comparableIds.length
      ? (newComparableLocations[locationsKind] = comparableIds)
      : delete newComparableLocations[locationsKind];

    setLocations(newLocations);
    setSelectedLocationsIds([]);
    localStorage && localStorage.setItem(`my.comparable_lease_${siteKey}`, JSON.stringify(newComparableLocations));
    handleComparableLocationsCounter(siteKey);
  };


  const handleOpenButtonsDropdown = () => {
    const isOpen = !deleteButtonsOpen;

    setDeleteButtonsOpen(isOpen);
    isOpen
      ? window.addEventListener('click', (e) => handleClickOutside(e))
      : window.removeEventListener('click', (e) => handleClickOutside(e));
  };


  const handleClickOutside = (e) => {
    const buttonsWrapper = document.getElementById('deleteButtons');

    !buttonsWrapper.contains(e.target) && setDeleteButtonsOpen(false);
  };


  const handleCloseInfoForm = () => {
    setInfoFormIsActive(false);
    setShowServerErrorMessage(false);
    setSubmitSuccess(false);
  };


  // const showJeudanMessage = () =>
  //   !!locationsAvailableForOrder.find(location => location.isJeudan);


  const multipleOrderSubmit = (params, orderUrl) => {
    const uuids = locationsAvailableForOrder.map(location => location.uuid);

    setIsFormLoading(true);

    httpService.orderInfoListViewForm(orderUrl, params, uuids)
      .then(response => {
        if (response) {
          const orderedLocations = JSON.parse(localStorage.getItem('my.ordered_locations'));
          uuids.map(uuid => orderedLocations[uuid] = [ { timestamp: new Date().toISOString() } ]);

          const updateOrderedLocation = JSON.stringify(orderedLocations);
          localStorage && localStorage.setItem('my.ordered_locations', updateOrderedLocation);

          setSubmitSuccess(true);
          setShowServerErrorMessage(false);
          setIsFormLoading(false);
          setOrderedLocations(checkOrderedLocation);
          sendTrovitLead(siteKey);

        } else {
          setSubmitSuccess(false);
          setShowServerErrorMessage(true);
          setIsFormLoading(false);
        }
      });
  };

  const loadPdf = () => {
    const bodyFormData = new FormData();
    locations.map(location => bodyFormData.append(`location_ids[]`, location.location_id))

    apiProvider.post(createComparisonPdfPath, bodyFormData)
      .then(response => {
        const { pdf_url, status_url } = response;
        (interval = setInterval(() => getStatus(status_url, bodyFormData), 2000));
        setPdfUrl(pdf_url);
      })
      .catch(error => console.log('error', error));
  };

  const getStatus = (url) =>
    apiProvider.getAll(url)
      .then(response => {
        if (response && response.status === 'finished') {
          clearInterval(interval);
          setPdfReady(true);
        }
      });

  const openPdfModal = () => {
    setPdfModalIsOpen(true);
    loadPdf();
  };

  const closePdfModal = () => {
    setPdfModalIsOpen(false);
    setTimeout(() => {
      clearInterval(interval);
      setPdfReady(false);
      setPdfUrl(null);
    }, 1000);
  };


  return (
    <div className="locations-comparison">

      <div className="locations-comparison__header container">
        <h1>{ I18n.t('compare.page.headline') }</h1>
        <SvgIco name="reports" width={110} height={76} />
      </div>

      { loading &&
        <div className="spinner-wrapper">
          <div className="smoothie-spinner" />
        </div>
      }

      { (!locationsKind || (!locations.length && !loading)) &&
        <div className="locations-comparison__empty-message">
          <div className="locations-comparison__empty-message-border" />
          { I18n.t('compare.page.empty_results') }
        </div>
      }

      { !!locations.length &&

        <Fragment>
          <StickyBar>
            <div className="locations-comparison__action-bar container">
              <div className={`locations-comparison__get-info-button ${!locationsAvailableForOrder.length ? 'inactive' : ''}`}
                   onClick={() => setInfoFormIsActive(true)}>
                { I18n.t("apps.lb_showroom.detailed.order_form.get_information") + ` (${locationsAvailableForOrder.length})` }
              </div>
              <div className="locations-comparison__action-bar-right">
                <div id="deleteButtons"
                     className="locations-comparison__delete-group"
                     onClick={handleOpenButtonsDropdown}>
                  <SvgIco name="trash" size={20} />
                  <div className={`locations-comparison__action-buttons-wrapper locations-comparison__action-buttons-wrapper--red ${ deleteButtonsOpen ? 'open' : '' }`}>
                    <div className="locations-comparison__delete-selected-btn" onClick={deleteSelected}>
                      { I18n.t('generic.delete_selected') }
                    </div>
                    <div className="locations-comparison__delete-all-btn" onClick={deleteAll}>
                      { I18n.t('generic.delete_all') }
                    </div>
                  </div>
                </div>
                <div className="locations-comparison__download-btn-wrapper" onClick={openPdfModal}>
                  <div className="locations-comparison__download-btn">
                    <SvgIco name="download_pdf" size={29} />
                  </div>
                  <div className="locations-comparison__download-tooltip">
                    { I18n.t('compare.page.download_pdf_tooltip') }
                  </div>
                </div>

              </div>
            </div>
          </StickyBar>

          <ComparisonTable
            handleSelect={handleSelect}
            locations={locations}
            orderedLocations={orderedLocations}
            selectedLocationsIds={selectedLocationsIds}
            siteKey={siteKey} />

          <div id="getInfoFormComparableWrapperMulti">
            { infoFormIsActive &&
              <GetInfoFormModal
                gtmClass="getInfoFormComparable"
                handleClose={handleCloseInfoForm}
                // isJeudan={showJeudanMessage()}
                isLoading={isFormLoading}
                isMultiple={true}
                locations={locationsAvailableForOrder}
                orderUrl={'/lease/api/orders'}
                parkingCheckbox={false}
                showServerErrorMessage={showServerErrorMessage}
                siteKey={siteKey}
                submitHandler={multipleOrderSubmit}
                submitSuccess={submitSuccess}
              />
            }
          </div>

        </Fragment>
      }

      <div className="locations-comparison__search-agent-wrapper">
        <SearchAgentBanner
          newSearchAgentPath={newSearchAgentPath}
          searchAgentText={I18n.t('lb_showroom.detailed.search_agent.description')}
          searchAgentHeadline={I18n.t('lb_showroom.detailed.search_agent.headline')}
          siteKey={siteKey}
        />
      </div>

      <ModalWindow
        isOpen={pdfModalIsOpen}
        name="pdf-modal"
        onRequestClose={closePdfModal}>
        <div className="pdf-modal__wrapper">
          <SvgIco name="reports" width={299} height={207} />

          { !pdfReady &&
            <Fragment>
              <h3 className="pdf-modal__headline">
                { I18n.t('compare.pdf.modal_preparing_headline') }
              </h3>
              <p>{ I18n.t('views.locations.shared.cart.please_wait') }</p>
              <FileLoader />
            </Fragment>
          }

          { pdfReady && pdfUrl &&
            <Fragment>
              <h3 className="pdf-modal__headline">
                { I18n.t('compare.pdf.modal_ready_headline') }
              </h3>
              <a className="pdf-modal__button" href={pdfUrl} download>
                { I18n.t('compare.pdf.modal_button_text') }
              </a>
            </Fragment>
          }
        </div>
      </ModalWindow>

    </div>
  )
}

export default LocationsComparison;
