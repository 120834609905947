import React, { useContext, useEffect, useState } from 'react';

import ListViewItems from '../../ListView/ListViewItems/ListViewItems';
import GridViewItem from '../../ListView/GridViewItem/GridViewItem';
import { checkOrderedLocation } from '../../helpers/checkOrderedLocation';
import { Context } from "../index";

import './index.sass';


const SameAddressLocations = ({ locationsWithSameAddress }) => {

  const {
    comparableLocations,
    countryAlpha2,
    favouriteLocationsIds,
    section,
    siteKey,
    setComparableLocations,
    setFavouriteLocationsIds,
  } = useContext(Context);

  const [orderedLocations, setOrderedLocations] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  const handleWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };


  useEffect(() => {
    window.addEventListener('resize', handleWindowWidth);

    if (localStorage) {
      localStorage.getItem('my.ordered_locations')
        ? setOrderedLocations(checkOrderedLocation())
        : localStorage.setItem('my.ordered_locations', JSON.stringify({}));
    }

    return () => {
      window.removeEventListener('resize', handleWindowWidth);
    };
  }, []);


  return (
    <div className="same-address-locations">
      <div className="container">
        <h2 className="dv-info-section__title">
          { I18n.t('apps.lb_showroom.same_address_locations_headline') }
        </h2>
      </div>

      { locationsWithSameAddress.map((location) =>
        windowWidth >= 768 ? (
          <ListViewItems
            comparableLocations={comparableLocations}
            countryAlpha2={countryAlpha2}
            favouriteLocationsIds={favouriteLocationsIds}
            hideMap={true}
            key={location.id}
            locationData={location}
            orderedLocations={orderedLocations}
            section={section}
            setOrderedLocations={(orderedLocations) => setOrderedLocations(orderedLocations)}
            siteKey={siteKey}
            updateComparableLocations={setComparableLocations}
            updateFavouriteLocations={(ids) => setFavouriteLocationsIds(ids)}
          />
        ) : (
          <GridViewItem
            comparableLocations={comparableLocations}
            countryAlpha2={countryAlpha2}
            favouriteLocationsIds={favouriteLocationsIds}
            hideMap={true}
            key={location.id}
            locationData={location}
            orderedLocations={orderedLocations}
            section={section}
            setOrderedLocations={(orderedLocations) => setOrderedLocations(orderedLocations)}
            siteKey={siteKey}
            updateComparableLocations={setComparableLocations}
            updateFavouriteLocations={(ids) => setFavouriteLocationsIds(ids)}
          />
        )
      )}
    </div>
  );
}

export default SameAddressLocations;
