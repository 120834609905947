import React from 'react';

import './index.sass';


const ProvidersSection = ({ siteKey }) => {

  return (
    <div className="providers container">
      <h2 className="providers__headline">
        { I18n.t('signup.teaser.section.headline') }
      </h2>

      <img
        alt="Providers"
        className="providers__image lazyload"
        data-sizes="auto"
        data-src={require(`../../../src/images/list_office/${siteKey}_logos_all`)}
      />

      <img
        alt="Providers"
        className="providers__image providers__image--desktop lazyload"
        data-sizes="auto"
        data-src={require(`../../../src/images/list_office/${siteKey}_logos`)}
      />
    </div>
  )
};

export default React.memo(ProvidersSection);
