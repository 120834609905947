import React from 'react';
import searchAgentBanner from '../../../src/images/entry_pages/search-agent-banner.svg';
import { DescriptionLine, handleListItemClick } from '../SharedComponents/ItemsListComponents';

import './ListViewSearchAgent.sass';


const ListViewSearchAgent = ({ advertismentData: { title, description },
                               newSearchAgentPath,
                               signupTeaserPath,
                               siteLogoPath,
                               rootPath,
                               siteKey,
                             }) => {

  const checkDk = siteKey === 'dk';

  return (
    <div className="container max-width">
      <div
        className="search-banner"
        onClick={(e) => handleListItemClick(e, newSearchAgentPath, true)}
      >
        <div className="list-item__wrapper row">
          <div className="col-xl-3 col-lg-3 col-md-4 col-3 search-agent-banner">
            <img
              className="search-image lazyload"
              data-src={searchAgentBanner}
              data-sizes="auto"
              alt="search-agent-banner"
              height={132}
              width={168}
            />
          </div>

          <div className="list-item__description-wrapper col-xl-6 col-lg-6 col-md-4 col-sm-9 col-12">
            <div className="adver-description-container">
              <div className="list-item__description-title">
                <p>{title}</p>
              </div>

              <div className="description-line">
                <div className="adver-description-tablet">
                  <DescriptionLine substring={description} lines={3} />
                </div>
                <div className="adver-description-desktop">
                  <DescriptionLine substring={description} lines={2} />
                </div>
              </div>

              <div className="list-item__bottom">
                <div className="advert-images">
                  <a href={rootPath}>
                    <img
                      alt="provider logo"
                      className="lazyload"
                      data-expand="100"
                      data-sizes="auto"
                      data-src={`https://www.lokalebasen.dk${siteLogoPath}`}
                      height={44}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="search-banner__button-wrapper col-xl-3 col-lg-3 col-md-4">
            <a href={newSearchAgentPath} target="_blank" className={`list-item__get-info-button sa-button ${checkDk ? 'dk_align' : ''}`}>
              <span>{checkDk ? I18n.t('search_agents.new.standard_search_agent') : I18n.t('list-view.advertisement.search_agent_button_text')}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListViewSearchAgent;
