import React from 'react';

import './CircularChart.sass';


const CircularChart = ({ percents, color }) => {

  return (
    <div className="circular-chart-wrapper">
      <svg viewBox="0 0 36 36" className={`circular-chart ${color || 'green'}`}>
        <path className="circle-bg"
              d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path className="circle"
              stroke-dasharray={`${percents}, 100`}
              d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.35" className="percentage">{percents}</text>
      </svg>
    </div>
  )
}

export default CircularChart;
