import React, { useContext, useEffect, useState, Fragment } from 'react';
import Modal from 'react-modal';

import Storage from '../../../utils/localStorage';
import { contactProvider } from '../../../utils/api';
import { SvgIco } from '../../SharedComponents/Icons';
import InfoModal from '../../OrderForm/InfoModal';
import { Context } from "../index";
import GetInfoFormMobile from './ModalGetInfoFormMobile';


const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: '9990',
    padding: '0'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    pointerEvents: 'visible',
    height: '100%',
    border: 'none',
    borderRadius: '0px'
  },
};


const AskProviderModalForm = () => {

  const {
    contactOrdered,
    contactPath,
    // isJeudan,
    locationId,
    locationUuid,
    modalFormOpen,
    setContactOrdered,
    setModalFormOpen,
    setUpdateAskLandlord,
    switchSelectedOrderForm,
    visitorToken,
  } = useContext(Context);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const locationOrdered = Storage.isLocationOrdered(locationUuid);


  const contactFormHandler = params => {
    params.locationId = locationId;
    params.user_token = visitorToken;

    contactProvider(contactPath, params)
      .then(response => {
        setUpdateAskLandlord(true);
        setModalFormOpen(false);
        Storage.updateContactItem();
        setContactOrdered(true);
        window.location.href = response.data.confirmation_page_url;
        locationOrdered ? switchSelectedOrderForm('book_showcase') : switchSelectedOrderForm('order');
      })
      .catch(error => { console.error(error) });
  };


  return (
    <Fragment>

      <InfoModal
        handleClose={() => setModalIsOpen(false)}
        isShowingModal={modalIsOpen} />

      <Modal
        ariaHideApp={false}
        isOpen={modalFormOpen}
        onRequestClose={() => setModalFormOpen(false)}
        shouldCloseOnOverlayClick={false}
        style={customStyles}>

        <div className="get-info-form-modal-content">
          <div className="get-info-form-modal-content__title-section">
            <div className='modal-close' onClick={() => setModalFormOpen(false)}>
              <SvgIco name="arrow-back" size={24} />
            </div>

            <div className="get-info-form-modal-content__title">
              <p>{ I18n.t('apps.entry_page.generic.not_provided') }</p>
            </div>
            <div className='modal-close' onClick={() => setModalFormOpen(false)}>
              <SvgIco name="close" size={24} />
            </div>
          </div>

          <div className="get-info-form-modal-content__main-content">
            <div className="order-form__title form-header__title order-form__title--subtitle">
              { I18n.t('apps.lb_showroom.detailed.order_form.rental_question') }
            </div>

            <p className="get-info-form-modal-content__description">
              <Fragment>{ I18n.t('apps.lb_showroom.detailed.order_form.landlord_request') }</Fragment>
            </p>

            <GetInfoFormMobile
              contactOrdered={contactOrdered}
              // isJeudan={isJeudan}
              submitHandler={contactFormHandler}
              visitorToken={visitorToken}
            />

            <div className="order-form__info-wrapper info-wrapper">
              <SvgIco name="lock" size={24} />
              <span className="info-label" onClick={() => setModalIsOpen(prevState => !prevState)}>
                { I18n.t('apps.lb_showroom.detailed.order_form.data_protection') }
              </span>
            </div>

          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

export default AskProviderModalForm;
