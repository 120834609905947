import React from 'react';

import { SvgIco } from "../../SharedComponents/Icons";

import './index.sass'


const TopBanner = ({ locale, signupFormPath, siteKey }) => {
  return (
    <div className="signup-top-banner">
      <div className="container">
        <div className="signup-top-content">
          <div dangerouslySetInnerHTML={{__html: I18n.t('signup.signup_top_content')}} />
          <a href={signupFormPath} className="signup-cta-button main-button">
            { I18n.t('signup.signup_top.button') }
            <SvgIco name="keyboard-arrow-right-rounded" size={28} />
          </a>
          <a href="#tabs" className="signup-top-banner__prices-button">
            { I18n.t('signup.teaser.block.button_text') }
            <SvgIco name="keyboard-arrow-right-rounded" size={28} />
          </a>
        </div>

        <div className="signup-image-block">
          <img
            alt="computer_new"
            className="lazyload"
            data-sizes="auto"
            data-src={require(`../../../src/images/list_office/computer_new_${siteKey}_${locale}.png`)}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(TopBanner);
