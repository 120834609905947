import React, { Fragment } from 'react';
import { SvgIco } from '../../SharedComponents/Icons';

const NoCureInfoContent = ({ contractDetailsOnDeal, contractDetailsOnDealBasic, contractDetailsOnDealPremium, siteKey }) => {
  return (
    <Fragment>
      <div className="sign-up-modal__header">
        <h2 className="sign-up-modal__header-title">
          { I18n.t('signup.new_form.plans.block1.title_new') }
        </h2>
        <div className="sign-up-modal__close-btn" id="modalCloseBtn">
          <SvgIco name="close" size={32}/>
        </div>
      </div>
      <div className="sign-up-modal__body highlight-basic">
        <p className="sign-up-modal__pricing-description" dangerouslySetInnerHTML={{__html: I18n.t('signup.signup_form.on_deal.infobox.body_new')}} />
        <div className="sign-up-modal__pricing-table-wrapper">
          <h3>{ I18n.t('signup.signup_form.on_deal.infobox.price_model_business_center') }</h3>

          <table className='sign-up-modal__table  sign-up-modal__table-global'>
            <tbody>

            <tr className="sign-up-modal__table-header">
              <td>{ I18n.t('signup.signup_form.on_deal.infobox.prices') }</td>
              <td className="sign-up-modal__table-td">Basic</td>
            </tr>

            <tr>
              <td>{ I18n.t('signup.signup_form.on_deal.infobox.fee_per_rental') }</td>
              <td>{ contractDetailsOnDeal.table.persentage + I18n.t('signup.teaser.tabs.tab.block.fee') }</td>
            </tr>


            <tr className="sign-up-modal__table-header">
              <td>{ I18n.t('signup.signup_form.on_deal.infobox.you_get') }</td>
              <td/>
            </tr>

            { siteKey === 'dk' ?

              <Fragment>

                <tr>
                  <td>Annonce med kontaktformular</td>
                  <td><span className="check-icon" /></td>
                </tr>

                <tr>
                  <td>Få henvendelser via e-mail & SMS</td>
                  <td><span className="check-icon" /></td>
                </tr>

                <tr>
                  <td>Stor synlighed overfor potentielle lejere</td>
                  <td><span className="check-icon" /></td>
                </tr>

                <tr>
                  <td>Få gratis ”Til leje” skilte</td>
                  <td><span className="check-icon" /></td>
                </tr>

                <tr>
                  <td>Du betaler kun honorar, hvis du finder en lejer via os</td>
                  <td><span className="check-icon" /></td>
                </tr>

                <tr>
                  <td>Ingen binding. Du kan altid skifte afregningsmodel</td>
                  <td><span className="check-icon" /></td>
                </tr>

                <tr>
                  <td>Personlig hjælp og support via telefon, e-mail og chat</td>
                  <td><span className="check-icon" /></td>
                </tr>

              </Fragment>

              :

              <Fragment>
                <tr>
                  <td>{ I18n.t('signup.teaser.tabs.tab.block.no_cure_no_pay.list_2') }</td>
                  <td><span className="check-icon" /></td>
                </tr>

                <tr>
                  <td>{ I18n.t('signup.teaser.tabs.tab.block.great') }</td>
                  <td><span className="check-icon" /></td>
                </tr>

                <tr>
                  <td>{ I18n.t('signup.teaser.tabs.tab.block.general_2') }</td>
                  <td><span className="check-icon" /></td>
                </tr>

                <tr>
                  <td>{ I18n.t('signup.teaser.tabs.tab.block.no_cure_no_pay.list_1') }</td>
                  <td><span className="check-icon" /></td>
                </tr>


              </Fragment>
            }
            </tbody>
          </table>

        </div>
      </div>
    </Fragment>
  )
};

export default NoCureInfoContent;
