import React, { Component } from 'react';
import { SocialIcons } from '../SharedComponents/Icons';
import './index.sass';


class SocialMedia extends Component {
  sharePopUp = (url, w, h) => {
    let left, top;
    left = Math.max(screen.width / 2 - w / 2, 0);
    top = Math.max(screen.height / 2 - h / 2, 0);
    window.open(
      url,
      '_blank',
      'toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=' +
        w +
        ', height=' +
        h +
        ', top=' +
        top +
        ', left=' +
        left +
        ''
    );
    return false;
  };

  handleClick = e => {
    const shareUrl = e.target.getAttribute('url') + window.location.href;
    const w = e.target.getAttribute('w');
    const h = e.target.getAttribute('h');
    this.sharePopUp(shareUrl, w, h);
  };

  render() {
    return (
      <div className="social-media__wrapper">
        <span className="text"> {I18n.t('lb_showroom.detailed.social_share')}</span>
        <span
          w={600}
          h={500}
          url="http://www.facebook.com/sharer/sharer.php?u="
          className="facebook"
          onClick={this.handleClick}
        >
          {SocialIcons.facebook}
        </span>
        <span
          w={550}
          h={420}
          url="https://twitter.com/intent/tweet?url="
          className="twitter"
          onClick={this.handleClick}
        >
          {SocialIcons.twitter}
        </span>
        <span
          w={520}
          h={570}
          url="https://www.linkedin.com/shareArticle?url="
          className="linkedin"
          onClick={this.handleClick}
        >
          {SocialIcons.linkedin}
        </span>
      </div>
    );
  }
}

export default SocialMedia;
