import React, { useContext, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { CrawledBadge, RentedOutBadge, AnonymousBadge } from "../../SharedComponents/Badges";
import { mappedMonths } from '../../helpers/danishMonths';
import FavouriteIcon from '../../SharedComponents/FavouriteIcon';
import CompareIcon from "../../SharedComponents/CompareIcon";
import Tooltip from '../../SharedComponents/Tooltip';
import { SvgIco } from "../../SharedComponents/Icons";
import { Context } from "../index";

import './MarkerContent.sass';


const MarkerContent = ({ markerData, onInfoToggle, showFullAddress }) => {

  const {
    comparableLocations,
    favouriteLocationsIds,
    section,
    setFavouriteLocationsIds,
    setComparableLocations,
    siteKey,
  } = useContext(Context);

  const [showToolTip, setShowToolTip] = useState(false);

  const {
    address_line_1,
    anonymous,
    closed_month,
    href,
    id,
    is_internal,
    kind,
    postal_code,
    postal_name,
    presenter,
    primary_photo_2x: imageUrl,
    shown_as_rented_out,
    street_name,
    suitable_for_kinds,
    temporarily_unavailable,
  } = markerData;

  const {
    area,
    area_icon,
    area_label,
    label: priceLabel,
    price,
  } = presenter;

  const closedMonthName = mappedMonths(closed_month);
  const suitableForList = suitable_for_kinds.length ? (suitable_for_kinds.length > 1 ? suitable_for_kinds.slice(0, 1) : suitable_for_kinds) : null;
  const translated = suitable_for_kinds.map(elem => I18n.t(`lb_showroom.showroom.suitable.for.mapview.tooltip.${elem}`));
  const updatedTranslation = translated.join(', ');
  const parsedLocImageStyle = { backgroundImage: `url(${imageUrl})` };
  const imageStyle = {
    backgroundImage: `url(${imageUrl})`,
    filter: shown_as_rented_out || temporarily_unavailable ? 'grayscale(100%)' : 'none',
  };


  return (
    <div className="marker-info-window">
      <div>
        <div className="marker-info-window__close-btn" onClick={() => onInfoToggle(id)}>
          <SvgIco name="close" size={24} />
        </div>

        { section === 'lease' &&
          <CompareIcon
            comparableLocations={comparableLocations}
            kind={kind}
            locationId={id}
            siteKey={siteKey}
            tooltipPosition={'bottom'}
            updateComparableLocations={setComparableLocations}
          />
        }

        <FavouriteIcon
          favoriteLocations={favouriteLocationsIds}
          locationId={id}
          tooltipPosition={'bottom'}
          section={section}
          siteKey={siteKey}
          updateFavouriteLocations={setFavouriteLocationsIds}
        />

        <a href={href} target="_blank" className="marker-info-window__header">
          { is_internal
            ?
              <div className='parsed-location-placeholder' style={parsedLocImageStyle}>
                { is_internal && <CrawledBadge root='map'/> }
              </div>
            :
              <div className="marker-info-window__photo-block" style={imageStyle}>
                { (shown_as_rented_out || temporarily_unavailable) &&
                  <RentedOutBadge
                    label={temporarily_unavailable ? I18n.t('activerecord.attributes.location.states.temporarily_unavailable') : null}
                    month={siteKey === 'dk' ? closedMonthName : null}
                    root='grid'
                    sale={section !== 'lease'}
                  />
                }
                { anonymous && <AnonymousBadge root='grid' /> }
              </div>
          }
        </a>

        <a href={href} target="_blank" className="marker-info-window__description-wrapper">
          <div className="marker-info-window__header-wrapper">

            <div className="marker-info-window__address">
              <p className="marker-info-window__address-line">{ `${street_name || address_line_1 }, ${ showFullAddress ? postal_code + ' ' : '' }${ postal_name }` }</p>
            </div>

            <div className="marker-info-window__suitableFor">
              { suitableForList && suitableForList.map((elem, index) =>
                <div
                  onMouseOver={() => setShowToolTip(true)}
                  onMouseLeave={() => setShowToolTip(false)}
                  className='suitable-for__item' key={index}
                >
                  <SvgIco name={elem} width={16} height={16} className="suitable-for__icon" />

                  { index === suitableForList.length - 1 && suitable_for_kinds.length > 1 &&
                    <div className="suitable-for__more">+{suitable_for_kinds.length - 1}</div>
                  }
                </div>
              )}

              <div className={`tooltip-wrapper ${showToolTip ? 'tooltip-wrapper-active' : ''}`}>
                <Tooltip
                  tooltipContent={I18n.t(`generic.suitable_for`)}
                  tooltipSuitableForOnly={updatedTranslation}
                />
              </div>

            </div>
          </div>

          <div className="marker-info-window__marker-info">
            <div className="marker-info-window__info-line">
              { area &&
                <Fragment>
                  <span>
                    <SvgIco name={area_icon || 'area'} size={22} />
                    { area_label }
                  </span>
                  <span>{ area }</span>
                </Fragment>
              }
            </div>

            <div className="marker-info-window__info-line">
              <span>{ priceLabel }</span>
              <span>{ price }</span>
            </div>

          </div>
        </a>

      </div>
    </div>
  );

}

MarkerContent.propTypes = {
  address_line_1: PropTypes.string,
  href: PropTypes.string,
  id: PropTypes.number,
  kind: PropTypes.string,
  postal_code: PropTypes.string,
  postal_name: PropTypes.string,
  presenter: PropTypes.object,
  primary_photo_2x: PropTypes.string,
  shown_as_rented_out: PropTypes.bool,
  showFullAddress: PropTypes.bool,
  temporarily_unavailable: PropTypes.bool,
};

MarkerContent.defaultProps = {
  address_line_1: '',
  href: '',
  id: null,
  kind: '',
  postal_code: '',
  postal_name: '',
  presenter: {
    area: '',
    area_icon: '',
    area_label: '',
    label: '',
    price: '',
  },
  primary_photo_2x: '',
  shown_as_rented_out: false,
  suitable_for_kinds: [],
  secondary_kinds: [],
  showFullAddress: false,
  temporarily_unavailable: false,
};

export default React.memo(MarkerContent);
