import React from 'react';
import Modal from 'react-modal';
import ThankYouIcon from '../../../src/images/list_office/thank_you.svg';
import { SvgIco } from '../../SharedComponents/Icons';
import './FormSentModalThankyou.sass'


const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    top: '0',
    overflow: 'auto',
    zIndex: '9999'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    pointerEvents: 'visible',
    background: 'white',
    borderRadius: '4px',
    position: 'absolute',
  },
};

const FormSentModalThankyou = ({ handleClose, openThankModal }) => {
  return (
    <div>
      <Modal
        ariaHideApp={false}
        className="thank-you-modal"
        closeTimeoutMS={500}
        contentLabel="thank-you-modal"
        isOpen={openThankModal}
        onRequestClose={handleClose}
        style={customStyles}
      >

        <div className="formsent">
          <div className='modal-close' onClick={handleClose}>
            <SvgIco name="close" size={24} />
          </div>

          <div className="content-wrapper">
            <div className="main-header">
              <div className="main-title">
                { I18n.t("signup.teaser.modal.thank_you.thanks") }
              </div>

              <p>{ I18n.t("signup.teaser.modal.thank_you.we_will_return") }</p>

              <button onClick={handleClose} className="thank-close-button">
                { I18n.t('generic.close') }
              </button>
            </div>

            <div className="icon-wrapper">
              <img
                alt="thanks icon"
                className="lazyload"
                data-sizes="auto"
                data-src={ThankYouIcon}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FormSentModalThankyou;
