import React, { Component, Fragment, Suspense } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { isMobileOnly, isMobile } from 'react-device-detect';
import ReactTooltip from "react-tooltip";

import httpService from '../../../api/httpService';
import { SvgIco } from '../../SharedComponents/Icons';
import { mappedMonths } from '../../helpers/danishMonths';
import FavouriteIcon from '../../SharedComponents/FavouriteIcon';
import { DescriptionLine, handleListItemClick } from '../SharedComponents/ItemsListComponents';
import EmblaCarousel from "../../SharedComponents/EmblaCarousel";
import { sendTrovitLead } from "../../helpers/trovit_analitics";

const CompareIcon = React.lazy(() => import('../../SharedComponents/CompareIcon'));
const InfoBadges = React.lazy(() => import('../../SharedComponents/InfoBadges'));
const InfoNotice = React.lazy(() => import('../../SharedComponents/InfoNotice'));
const GetInfoFormModal = React.lazy(() => import('../../SharedComponents/GetInfoFormModal'));


import './ListViewItems.sass';


class ListViewItems extends Component {
  state = {
    isActiveInfoForm: false,
    orderedLocations: [],
    additionalFormPadding: null,
    offset: null,
    submitSuccess: false,
    infoSent: false,
    dataProtectionIsOpen: false,
    showServerErrorMessage: false,
    showMorePhotos: false,
    isLoading: false,
    showEnergyRatingTooltip: false,
    orderId: null,
  };

  static timestamp() {
    return { timestamp: new Date().toISOString() };
  }

  componentDidMount() {
    window.history.scrollRestoration = 'manual';
  }

  handleOpenInfoForm = () => {
    this.setState({ isActiveInfoForm: true });
  };

  handleCloseInfoForm = () => {
    this.setState({ isActiveInfoForm: false, showServerErrorMessage: false });
    window.removeEventListener('mousedown', this.handleClickOutside);
  };

  infoHandlerSubmit = (params, orderUrl, abTestParam) => {
    const { uuid } = this.props.locationData;

    // params.ab_code = abTestParam;

    this.setState({ isLoading: true });

    httpService
      .orderInfoListViewForm(orderUrl, params, uuid)
      .then((response) => {
        if (response) {
          const orderedLocations = JSON.parse(localStorage.getItem('my.ordered_locations'));
          const { order_uuid } = response;

          orderedLocations[uuid] = [this.constructor.timestamp()];

          const updateOrderedLocation = JSON.stringify(orderedLocations);

          localStorage.setItem('my.ordered_locations', updateOrderedLocation);

          sendTrovitLead(this.props.siteKey);

          this.setState({
            submitSuccess: true,
            showServerErrorMessage: false,
            isLoading: false,
            orderId: order_uuid,
          });

          this.props.setOrderedLocations(Object.keys(orderedLocations));

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'ga4-get-info-form-submit',
            eventCategory: 'submit-info-form-list-view',
            eventAction: 'submit-info-form-list-view',
          });
        } else {
          this.setState({
            submitSuccess: false,
            showServerErrorMessage: true,
            isLoading: false,
          });
        }
      });
  };

  handleSlideChange = () =>
    !this.state.showMorePhotos && this.setState({ showMorePhotos: true });

  promotedLink = (e) => {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();
      e.stopPropagation();

      const href =
        'https://www.lokalebasen.dk/serviceydelser-til-udlejere/fremhevet/annoncering';
      window.open(href, '_blank');
    }
  };

  handleTooltip = (key, tooltipRef) => {
    const tooltipShowState = this.state[key];

    this.setState({ [key]: !tooltipShowState }, () =>
      tooltipShowState ? ReactTooltip.hide(this[tooltipRef]) : ReactTooltip.show(this[tooltipRef])
    );
  };

  afterHide = (key) =>
    this.setState({ [key]: false });


  render() {
    const {
      currentSlide,
      infoSent,
      isActiveInfoForm,
      isLoading,
      showMorePhotos,
      showServerErrorMessage,
      submitSuccess,
      orderId,
    } = this.state;

    const {
      comparableLocations,
      countryAlpha2,
      currency,
      favouriteLocationsIds,
      hideMap,
      missingImagesUrl,
      locationData,
      orderedLocations,
      section,
      siteKey,
      updateComparableLocations,
      updateFavouriteLocations,
      userCountryPhoneCode,
    } = this.props;

    const {
      address_line1,
      address_title,
      anonymous,
      description,
      energy_rating,
      exempted_energy,
      href,
      id,
      indexing_prohibited,
      is_increased_in_listview,
      is_internal,
      kind,
      nearby_parking_lot,
      order_path,
      postal_code,
      postal_name,
      presenter,
      price_decrease_percentage,
      provider_logo,
      provider_name,
      secondary_kinds,
      show_full_address,
      show_new_badge,
      show_price_decrease_label,
      shown_as_rented_out,
      special_offer,
      street_name,
      suitable_for_kinds,
      temporarily_unavailable,
      uuid,
      provider_partner,
      monthly_rent_price,
    } = locationData;


    // TODO remove after deploy and locations props update
    const all_photos = locationData.all_photos || locationData.first_3_photos || [];
    const premium = is_increased_in_listview;
    const premiumOnlyDK = is_increased_in_listview && countryAlpha2 === 'dk';
    const suitableFor = suitable_for_kinds || [];
    const locationKinds = [kind, ...secondary_kinds];
    const descriptionLines = premium ? 5 : suitableFor.length && is_internal ? 2 : 3;
    // const isJeudan = provider_name && provider_name.toLowerCase() === 'jeudan';
    const suitableForList = suitableFor.length ? (suitableFor.length > 1 ? suitableFor.slice(0, 1) : suitableFor) : null;
    const kindsCountToShow = isMobile ? 1 : 2;
    const locationKindsLength = locationKinds.length > kindsCountToShow && locationKinds.length - kindsCountToShow;
    const reducedKinds = (locationKindsLength && locationKinds.slice(0, kindsCountToShow)) || locationKinds;
    const closedMonth = mappedMonths(locationData.closed_month);
    const streetName = street_name ? street_name + ',' : null;
    const addressLine = address_line1 ? address_line1 + ',' : null;
    const addressOrStreet =  streetName || addressLine;
    const energyRatingMark = energy_rating ? `${energy_rating}`.toLowerCase() : exempted_energy ? 'exempted' : null;
    const energyRatingLabel = energy_rating ? I18n.t('activerecord.attributes.property.energy_rating') : I18n.t('simple_form.options.location.energy_rating.X');
    const { persons, persons_label, persons_tooltip } = presenter;
    const { formatted_monthly_rent_price, monthly_rent_label } = monthly_rent_price || {};
    const showInfoBadges = anonymous || show_new_badge || shown_as_rented_out || temporarily_unavailable || is_internal || special_offer;

    const getInfoForm = classNames('form-block col-lg-9 col-sm-12', {
      'active-form': isActiveInfoForm,
    });

    const descriptionClasses = classNames('description-line mobile-hidden', {
      'reduced': suitableFor.length,
    });

    const locationClasses = classNames('container list-item max-width', {
      'list-item--premium': premium,
    });

    const sliderClasses = classNames('list-item__image-slider', {
      disabled: all_photos.length === 1 || shown_as_rented_out || temporarily_unavailable,
      anonymous,
      crawled: is_internal,
      'rented-out': shown_as_rented_out || temporarily_unavailable,
      'col-xl-3 col-lg-3 col-md-4 col-3': !premium,
    });

    const descriptionWrapperClasses = classNames('list-item__description-wrapper', {
      'col-xl-6 col-lg-6 col-md-4 col-sm-9 col-9': !premium,
      'rented-out': shown_as_rented_out || temporarily_unavailable,
    })

    const metricsWrapperClasses = classNames('list-item__metrics-wrapper mobile-hidden col-lg-3 col-md-4', {
      'rented-out': shown_as_rented_out || temporarily_unavailable,
    });

    const styles = {
      itemStyle: shown_as_rented_out || temporarily_unavailable ? { background: '#F4F5F5' } : null,
      carouselStyle: shown_as_rented_out || temporarily_unavailable ? { filter: 'grayscale(100%)' } : null,
    };

    const SLIDES = all_photos.map((elem, index) => (
      <div key={elem} className="list-item__image-wrapper">
        <img
          className={
            index <= 1 ||
            index === all_photos.length - 1 ||
            showMorePhotos
              ? 'lazyload blur-up'
              : ''
          }
          data-sizes="auto"
          alt={elem.alt}
          data-src={elem.url}
          data-expand="500"
          style={styles.carouselStyle}
          height="172"
          width="100%"
        />
      </div>
    ));

    const OPTIONS = { loop: true };
    const disableScroll = shown_as_rented_out || temporarily_unavailable;


    return (
      <div className={locationClasses}>
        <a
          href={href}
          className="list-item__wrapper row"
          onClick={(e) => handleListItemClick(e, href, id)}
          {...(indexing_prohibited ? { rel: 'noindex nofollow' } : {})}
          style={styles.itemStyle}
        >
          <div className={sliderClasses}>

            { showInfoBadges &&
              <Suspense fallback={''}>
                <InfoBadges
                  anonymous={anonymous}
                  closedMonth={siteKey === 'dk' && !isMobileOnly ? closedMonth : null}
                  isCrawled={is_internal}
                  isRentedOut={shown_as_rented_out || temporarily_unavailable}
                  isNew={show_new_badge}
                  hideCrawledBottom={true}
                  missingImagesUrl={missingImagesUrl}
                  rentedOutLabel={temporarily_unavailable ? I18n.t('activerecord.attributes.location.states.temporarily_unavailable') : null}
                  sale={section !== 'lease'}
                  specialOffer={special_offer}
                  wrapperClass="list-view"
                />
              </Suspense>
            }

            <EmblaCarousel
              carouselName="list-item-slider"
              disableScroll={disableScroll}
              handleChange={this.handleSlideChange}
              slides={SLIDES}
              options={OPTIONS}
              withDots
            />

            { section === 'lease' && !isMobileOnly &&
              <Suspense fallback={''}>
                <CompareIcon
                  comparableLocations={comparableLocations}
                  kind={kind}
                  locationId={id}
                  siteKey={siteKey}
                  tooltipPosition={'left'}
                  updateComparableLocations={updateComparableLocations}
                />
              </Suspense>
            }

            <FavouriteIcon
              favoriteLocations={favouriteLocationsIds}
              locationId={id}
              section={section}
              siteKey={siteKey}
              tooltipPosition={'left'}
              updateFavouriteLocations={updateFavouriteLocations}
            />
          </div>

          <div className={descriptionWrapperClasses}>
            <div className="list-item__kinds-line">
              <span className="list-item__kinds">
                { reducedKinds.map((elem, index) =>
                  <span className="list-item__kind" key={index}>{ I18n.t(`generic.${elem}`)} </span>
                )}

                { locationKindsLength
                  ? <span className="list-item__kind">
                      +{ locationKindsLength }
                    </span>
                  : null
                }
              </span>

              { energyRatingMark &&
                <div className={`list-item__energy-rating-wrapper ${energyRatingMark} list`}
                     role="button"
                     aria-labelledby="energy-rating">
                  <div className={`list-item__energy-rating ${energyRatingMark}`}
                       data-class="list-item-tooltip"
                       data-for={'energy-rating' + id}
                       data-tip={energyRatingLabel}
                       onClick={() => this.handleTooltip('showEnergyRatingTooltip', 'EnergyRatingRef')}
                       ref={ref => this.EnergyRatingRef = ref}
                       role="button">
                    <div className="list-item__energy-rating-label">
                      { energyRatingLabel }
                    </div>
                    <SvgIco
                      name={`energy-rating-${energyRatingMark}`}
                      width={premium ? 24 : 18}
                      height={premium ? 24 : 18}
                    />
                    <ReactTooltip
                      afterHide={() => this.afterHide('showEnergyRatingTooltip')}
                      effect="solid"r
                      id={'energy-rating' + id}
                      place="left"
                    />
                  </div>
                </div>
              }
            </div>

            <div className="list-item__description-title">
              <h4>{ address_line1 }, { show_full_address && postal_code } { postal_name }</h4>

              { (premium && suitableForList)
                ?
                  <p style={styles.descriptionStyle}>
                    { addressOrStreet } { show_full_address && postal_code }{' '}
                    { postal_name }
                  </p>
                :
                  <Fragment>
                    <div className="list-item__description-title-wrapper">
                      <p style={styles.descriptionStyle}>
                        { street_name || address_line1 }
                      </p>
                      <p>
                        { show_full_address && postal_code } { postal_name }
                      </p>
                    </div>
                    <div className="list-item__description-title-wrapper desktops">
                      <p style={styles.descriptionStyle}>
                        { addressOrStreet } { show_full_address && postal_code }{' '}
                        { postal_name }
                      </p>
                    </div>
                  </Fragment>
              }
            </div>

            { suitableForList &&
              <div className={`suitable-for ${is_internal ? 'top' : ''}`}>
                { suitableForList.map((elem, index) =>
                  <div className="suitable-for__item" key={index}>

                    <SvgIco name={elem} width={22} height={22} />

                    <span className="suitable-for__label">
                      { I18n.t(`lb_showroom.showroom.suitable.for.${elem}`) }
                    </span>

                    { index === suitableForList.length - 1 && suitableFor.length > 1 &&
                      <div className="suitable-for__more">
                        +{ suitableFor.length - 1 }
                      </div>
                    }
                  </div>
                )}
              </div>
            }

            <div className={descriptionClasses}>
              <p>
                {
                  <DescriptionLine
                    substring={description}
                    lines={descriptionLines}
                  />
                }
              </p>
            </div>

            <div className="list-item__bottom-wrapper">
              <div className="list-item__bottom">

                { is_internal &&
                  <Suspense fallback={''}>
                    <div role="button">
                      <div role="button">
                        <InfoNotice
                          id={id}
                          missingImagesUrl={missingImagesUrl}
                          noticeKind={'parsed'}
                          root="list"
                          showCopyright={true}
                        />
                      </div>
                    </div>
                  </Suspense>
                }
              </div>
            </div>

            { premium && <span className="list-item__premium-separator" /> }

          </div>

          <div className={metricsWrapperClasses}>
            <div className="list-item__metrics">
              { (persons || presenter.area) &&
                <div className="list-item__metrics-area">
                  <p className="list-item__metrics-description">
                    { persons ? persons_label : presenter.area_label}:
                  </p>
                  <p className="list-item__metrics-square">
                    { persons || presenter.area }
                    { persons && persons_tooltip &&
                      <span className="list-view__info-tooltip"
                            role="button"
                            data-for={'persons-list-tooltip-' + id}
                            data-tip={persons_tooltip}
                            onClick={() => this.handleTooltip('showPersonsTooltip', 'PersonsTooltipRef')}
                            ref={ref => this.PersonsTooltipRef = ref}>
                    <SvgIco name="info_outline" size={14} />
                    <ReactTooltip
                      afterHide={() => this.afterHide('showPersonsTooltip')}
                      effect="solid"
                      id={'persons-list-tooltip-' + id}
                      place="top"
                      className="persons-tooltip"
                    />
                  </span>
                    }
                  </p>
                </div>
              }

              <div className={`list-item__price-wrapper ${ premium ? 'promoted-location' : ''}`}>
                <div>
                  { formatted_monthly_rent_price &&
                    <Fragment>
                      <p className="list-item__metrics-description">
                        { monthly_rent_label }:
                      </p>
                      <p className="list-item__price month-price" style={{ display: 'flex' }}>
                        { formatted_monthly_rent_price }
                        { show_price_decrease_label &&
                          <div className="sale-image-badge">
                            <span className="percentage">-{price_decrease_percentage}%</span>
                          </div>
                        }
                      </p>
                    </Fragment>
                  }
                </div>
                <div>
                  <p className="list-item__metrics-description">{presenter.label}:</p>
                  <p className={`list-item__price ${!formatted_monthly_rent_price ? 'month-price' : '' }`} style={{ display: 'flex' }}>
                    { presenter.price }
                    { !formatted_monthly_rent_price && show_price_decrease_label &&
                      <div className="sale-image-badge">
                        <span className="percentage">-{price_decrease_percentage}%</span>
                      </div>
                    }
                  </p>
                </div>
              </div>
            </div>

            { !shown_as_rented_out && !temporarily_unavailable &&
              <div role="button" className={`list-item__info-button-wrapper ${orderedLocations.includes(uuid) ? 'list-item__info-sent' : ''}`}>
                { orderedLocations.includes(uuid)
                  ?
                    <div className="b-button list-item__get-info-button sent-info">
                      <SvgIco name="check" size={24}/>
                      { I18n.t('apps.lb_showroom.detailed.order_form.cta_fixed_info_sent') }
                    </div>
                  :
                    <div role="button">
                      <div className="b-button list-item__get-info-button"
                           id={'infoFormBtn' + id}
                           onClick={this.handleOpenInfoForm}>
                        { I18n.t('apps.lb_showroom.detailed.order_form.get_information') }
                      </div>
                    </div>
                }

                { premiumOnlyDK && (
                  <div
                    role="button"
                    className="list-item__premium-link"
                    onClick={(e) => this.promotedLink(e)}>
                    { I18n.t('generic.promoted_location_link') }
                  </div>
                )}

              </div>
            }


            { (shown_as_rented_out || temporarily_unavailable) && premiumOnlyDK &&
              <div
                role="button"
                className="list-item__premium-link"
                onClick={(e) => this.promotedLink(e)}>
                { I18n.t('generic.promoted_location_link') }
              </div>
            }
          </div>
        </a>

        { isActiveInfoForm &&
          <div className={getInfoForm} id={'listItemFormWrapper' + id}>
            <div
              className="list-item__close-form-button"
              onClick={this.handleCloseInfoForm}
            />
            <div className="list-item__form-wrapper">
              <div className="list-item__info-form-wrapper">
                <Suspense fallback={
                            <div className="spinner-wrapper order-form-spinner">
                              <div className="smoothie-spinner" />
                            </div>}>
                  <GetInfoFormModal
                    submitHandler={this.infoHandlerSubmit}
                    parkingCheckbox={nearby_parking_lot}
                    orderUrl={order_path}
                    id={id}
                    infoSent={infoSent}
                    submitSuccess={submitSuccess}
                    // isJeudan={isJeudan}
                    handleClose={this.handleCloseInfoForm}
                    gtmClass="getInfoForm"
                    address={address_title}
                    showServerErrorMessage={showServerErrorMessage}
                    isLoading={isLoading}
                    userCountryPhoneCode={userCountryPhoneCode}
                  />
                </Suspense>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

ListViewItems.propTypes = {
  address_title: PropTypes.string,
  address_line1: PropTypes.string,
  closed_month: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
  id: PropTypes.string,
  kind: PropTypes.string,
  locationData: PropTypes.object,
  missingImagesUrl: PropTypes.string,
  postal_name: PropTypes.string,
  presenter: PropTypes.object,
  provider_logo: PropTypes.string,
  provider_name: PropTypes.string,
  provider_url: PropTypes.string,
  secondary_kinds: PropTypes.array,
  show_new_badge: PropTypes.bool,
  shown_as_rented_out: PropTypes.bool,
  show_full_address: PropTypes.bool,
  temporarily_unavailable: PropTypes.bool,
};

ListViewItems.defaultProps = {
  address_title: '',
  address_line1: '',
  closed_month: '',
  description: '',
  href: '',
  id: '',
  kind: '',
  locationData: {},
  missingImagesUrl: '',
  postal_name: '',
  presenter: {},
  provider_logo: '',
  provider_name: '',
  provider_url: '',
  secondary_kinds: [],
  show_new_badge: false,
  shown_as_rented_out: false,
  uuid: '',
  show_full_address: false,
  temporarily_unavailable: false,
};


export default ListViewItems;
