export const numbersLandlords = (siteKey) => {
  let numbersMapped = {
    'global': 2000,
    'ch': 100,
    'de': 350,
    'dk': 1000,
    'es': 350,
    'fi': 100,
    'no': 100,
    'nl': 200,
    'pl': 100,
    'se': 350,
    'gb': 500,
    'ua': 250,
    'be': 250,
    'sg': 100,
    'it': 250,
    'fr': 250,
    'lu': 100,
    'in': 1000,
    'hk': 150,
  };

  return numbersMapped[siteKey] ? numbersMapped[siteKey] : numbersMapped['global']
}