import React from 'react';

import EmblaCarousel from '../../SharedComponents/EmblaCarousel';
import InteriorDesignBanner from './InteriorDesignBanner';
import Landscape from './Landscape';

import './MobileGallery.sass';


const MobileGallery = ({ activeSection, data, isDefault, latitude, longitude, onClick }) => {

  const OPTIONS = { loop: true };

  const SLIDES = data.map((image, index)=>
    image.itemType === 'image'
      ?
        <div className={`image-gallery__img-wrapper ${isDefault ? 'default' : ''}`}>
          <img
            alt={image.alt}
            className="lazyload"
            data-expand="400"
            data-sizes="auto"
            data-src={image.original}
            onClick={() => onClick(true, index)}
          />
        </div>
      :
    image.itemType === 'interior-design-banner'
      ?
        <InteriorDesignBanner isFullScreen={true} orientationHorizontalMobile={false} />
      :
        <Landscape latitude={latitude} longitude={longitude} />
  );


  return (
    <EmblaCarousel
      carouselName={activeSection}
      slides={SLIDES}
      options={OPTIONS} />
  )
}

export default MobileGallery;
