import React from 'react';

export const testimonials = [
  { authorName: I18n.t('signup.teaser.partners.regus.name'),
    authorInfo: I18n.t('signup.teaser.partners.regus.position'),
    authorImg: 'thomas_munk',
    content: I18n.t('signup.teaser.partners.regus.text'),
    companyLogo: {name: 'regus', height: 35, width: 68, alt: 'Regus' },
  },
  { authorName: I18n.t('signup.teaser.partners.deas.name'),
    authorInfo: I18n.t('signup.teaser.partners.deas.position'),
    authorImg: 'henriette_holm_stahlhut',
    content: I18n.t('signup.teaser.partners.second.text'),
    companyLogo: { name: 'Deas', height: 50, width: 'auto', alt: 'Deas Blue' },
  },
  { authorName: I18n.t('signup.teaser.partners.nrep.name'),
    authorInfo: I18n.t('signup.teaser.partners.nrep.position'),
    authorImg: 'nina_therkelsen',
    content: I18n.t('signup.teaser.partners.nrep.text'),
    companyLogo: {name: 'nrep', height: 'auto', width: 100, alt: 'NREP' },
  },
]
