import React, { Fragment, useContext, useState } from 'react';
import Truncate from 'react-truncate';
import { isMobile } from 'react-device-detect';

import ExemptedEnergyRatingIcon from '../../../src/images/icons/facts_and_facilities/ExemptedEnergyRating.svg';
import { SvgIco } from '../../SharedComponents/Icons';
import CollapsibleSection from '../../SharedComponents/CollapsibleSection';
import SmartTextTooltip from '../SmartTextTooltip';
import { Context } from "../index";
import FacilitiesListItem from './FacilitiesListItem';
import DistancesListItem from './DistancesListItem';
import ParkingListItem from './ParkingListItem';

import './index.sass';


const FactsAndFacilities = () => {

  const {
    anonymous,
    isRequestFromPrerender,
    location: { addressLine1 },
    suitableFor,
    tabs: {
      facility: { buildRoute, energyRating, energyCertificateLevels, exemptedEnergy, facilities },
      nearbyParkingLots,
      smartText: { factAndFacilities: smartTextFacilities },
      transport,
    },
  } = useContext(Context);

  const [facilityText, setFacilityText] = useState(false);
  const [showAllFacilities, setShowAllFacilities] = useState(false);
  const [showAllDistances, setShowAllDistances] = useState(false);
  const [showMoreParkings, setShowMoreParkings] = useState(false);

  const collapseDistances = !!transport.distances.length && transport.distances.length > 6;
  const visibleDistancesList = !!transport.distances.length && (collapseDistances ? transport.distances.slice(0, 6) : transport.distances);
  const collapsedDistancesList = collapseDistances && transport.distances.slice(6);
  const collapseFacilities = !!facilities.length && facilities.length > 1;
  const visibleFacilitiesList = !!facilities.length && facilities[0];
  const collapsedFacilitiesList = collapseFacilities && facilities.slice(1);
  const collapsedParkings = nearbyParkingLots && nearbyParkingLots.slice(3);
  const visibleParkingsList = nearbyParkingLots && nearbyParkingLots.slice(0, 3);
  const textLines = isMobile ? 4 : 2;


  const showMoreLinkText = (showMore) =>
    showMore
      ? I18n.t('views.locations.show.facilities.show_less_link')
      : I18n.t('views.locations.show.facilities.show_more_link');


  const showMoreLink = (showAll, handleShow) => (
    <div className="facts-and-facilities__facilities-toggle-link-wrapper" onClick={handleShow}>
      <span>{ showMoreLinkText(showAll) }</span>
      { showAll
        ? <SvgIco name="expand-less" size={24} />
        : <SvgIco name="expand-more" size={24} />
      }
    </div>
  );


  const showMoreParkingsLink = (showAllParkings) => (
    <div className="facts-and-facilities__facilities-toggle-link-wrapper parking-link"
         onClick={() => setShowMoreParkings(prevState => !prevState)}>
      <span>{ showMoreLinkText(showAllParkings) }</span>
      { showAllParkings
        ? <SvgIco name="expand-less" size={24} />
        : <SvgIco name="expand-more" size={24} />
      }
    </div>
  );


  const energyCertificateIconHandler = (id) => {
    try {
      return require(`../../../src/images/energy_certificates/${id}`);
    } catch (error) {
      return require(`../../../src/images/energy_certificates/8`);
    }
  };


  return (
    <div className="dv-info-section-wrapper facts-and-facilities" id="facts">
      <div className="dv-info-section__background-full-width" />

      <h2 className="dv-info-section__title">
        { I18n.t('apps.lb_showroom.detailed.tabs.facts_and_facilities') }
      </h2>

      <div>
        <div className="row">
          <div className="col-lg-12">
            { smartTextFacilities && <SmartTextTooltip id="facts" /> }

            { facilityText || isRequestFromPrerender
              ?
                <Fragment>
                  <p className="smart-text-extended">{smartTextFacilities}</p>
                  <div className="dv-info-section__read-more-smart-text">
                    <a onClick={() => setFacilityText(prevState => !prevState)}>
                      { I18n.t('apps.lb_showroom.detailed.description.hide_more_link') }
                      <SvgIco name="expand-less" size={16} />
                    </a>
                  </div>
                </Fragment>
              :
                <Fragment>
                  { smartTextFacilities &&
                    <div className="smart-text">
                      <Truncate
                        children={smartTextFacilities}
                        lines={textLines}
                        ellipsis={
                          <Fragment>
                            <span>{textLines !== 0 && '... '}</span>
                            <div className="dv-info-section__read-more-smart-text">
                              <a onClick={() => setFacilityText(prevState => !prevState)}>
                                {I18n.t('generic.read_more')}
                                <SvgIco name="expand-more" size={16} />
                              </a>
                            </div>
                          </Fragment>
                        }
                      />
                    </div>
                  }
                </Fragment>
            }
          </div>

          <div className="facts-and-facilities__column col-xl-6 col-lg-12">
            <div className="facts-and-facilities__address-line">
              <SvgIco name="map_detail" size={22} />
              <div>
                { !anonymous && <p>{ addressLine1 }</p> }
                <p>{ transport.postalDistrict.postalName }</p>
                <a href={transport.postalDistrict.districtUrl} className="facts-and-facilities__area-link">
                  <span>
                    { I18n.t('apps.lb_showroom.detailed.area.area_link', { kinds: I18n.t(`global.models.location.kinds.${transport.kind}.short`, { count: 2 })})}
                  </span>
                </a>
              </div>
            </div>

            { buildRoute && !anonymous &&
              <div className="facts-and-facilities__build-route-wrapper">
                <div className="facts-and-facilities__build-route-icon">
                  <SvgIco name="direction" size={20} />
                </div>
                <a target="_blank" href={buildRoute} className="facts-and-facilities__build-route-link">
                  <span>
                    { I18n.t('views.locations.show.build_route') }
                  </span>
                </a>
              </div>
            }

            { energyRating &&
              <div className="facts-and-facilities__energy-rating-wrapper">
                <SvgIco
                  className="facts-and-facilities__energy-rating-icon"
                  name={`energy-rating-${energyRating}`}
                  size={20}
                />
                { I18n.t('views.locations.show.energy_rating') } { energyRating.toUpperCase() }
              </div>
            }

            { exemptedEnergy &&
              <div className="facts-and-facilities__energy-rating-wrapper">
                <img
                  className="lazyload"
                  data-sizes="auto"
                  data-src={ExemptedEnergyRatingIcon}
                  alt="energy"
                  data-expand="100"
                />
                { I18n.t('views.locations.show.exempted_from_energy_rating') }
              </div>
            }
          </div>
        </div>

        { !!(energyCertificateLevels && energyCertificateLevels.length) &&
          <Fragment>
            <h3 className="facts-and-facilities__headline">
              { I18n.t('generic.certifications') }
            </h3>

            <div className="row">
              <div className="facts-and-facilities__facilities-wrapper col-md-8 col-lg-12 col-xl-12">
                { energyCertificateLevels.map(certificate =>
                  <div className="facts-and-facilities__energy-certificate-wrapper">
                    <img alt={`${certificate.certificateName} ${certificate.name}`}
                         src={energyCertificateIconHandler(certificate.id)}
                         height={24}
                         width={24}
                    />
                    { certificate.certificateName } { certificate.name }
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        }

        { visibleDistancesList.length &&
          <Fragment>
            <h3 className="facts-and-facilities__headline">
              { I18n.t('lb_showroom.detailed.transport_distances.transport_distances_headline')}
            </h3>

            <div className="row">
              <div className="facts-and-facilities__facilities-wrapper col-md-8 col-lg-12 col-xl-12 distances">
                <div className="row">
                  { visibleDistancesList.map((item, index) =>
                    <DistancesListItem
                      item={item}
                      key={index}
                      lazyload={true}
                    />
                  )}
                </div>

                { collapseDistances &&
                  <CollapsibleSection
                    uncollapse={showAllDistances}
                    isRequestFromPrerender={isRequestFromPrerender}
                    classes="row">
                    { collapsedDistancesList.map((item, index) => <DistancesListItem item={item} key={index + 6} />)}
                  </CollapsibleSection>
                }

                { collapseDistances && showMoreLink(showAllDistances, () => setShowAllDistances(prevState => !prevState)) }
              </div>
            </div>
          </Fragment>
        }

        { nearbyParkingLots &&
          <Fragment>
            <h3 className="facts-and-facilities__headline--parking">
              { I18n.t('parking.generic.parking_nearby_headline') }
            </h3>

            <div className="row">
              <div className="col-md-8 col-xs-12 facts-and-facilities__parking-block">

                { visibleParkingsList.map((item, index) =>
                  <ParkingListItem item={item} key={index} />
                )}

                { nearbyParkingLots.length > 3 &&
                  <CollapsibleSection
                    uncollapse={showMoreParkings}
                    isRequestFromPrerender={isRequestFromPrerender}>
                    { collapsedParkings.map((item, index) => <ParkingListItem item={item} key={index} />)}
                  </CollapsibleSection>
                }
                { nearbyParkingLots.length > 3 && showMoreParkingsLink(showMoreParkings) }
              </div>
            </div>
          </Fragment>
        }

        <h3 className="facts-and-facilities__headline--facilities">
          { I18n.t('apps.lb_showroom.detailed.tabs.facilities') }
        </h3>

        <div className="facts-and-facilities__facilities-wrapper">
          { suitableFor.length > 0 &&
            <div className="facts-and-facilities__facility-block-wrapper row">
              <p className="facts-and-facilities__facilities-type-headline col-xl-12 col-lg-12 col-md-12">
                { I18n.t('generic.suitable_for') }
              </p>
              { suitableFor.map((elem, index) => (
                <div className="facts-and-facilities__suitable-for-item col-xl-3 col-lg-4 col-md-4 col-6" key={index}>
                  <SvgIco name={elem.icon} width={20} height={20} className="facts-and-facilities__suitable-for-icon" />
                  <span className="facts-and-facilities__suitable-for-label">
                    { elem.shortLabel }
                  </span>
                </div>
              ))}
            </div>
          }

          { visibleFacilitiesList
            ?
              <Fragment>
                <FacilitiesListItem item={visibleFacilitiesList} key={0} />
                { collapseFacilities &&
                  <CollapsibleSection
                    uncollapse={showAllFacilities}
                    isRequestFromPrerender={isRequestFromPrerender}>
                    { collapsedFacilitiesList.map((item, index) =>
                      <FacilitiesListItem item={item} key={index + 1} />
                    )}
                  </CollapsibleSection>
                }
              </Fragment>
            :
              <div className={`facts-and-facilities__blank-title${suitableFor.length > 0 ? ' with-indentation' : ''}`}>
                { I18n.t('facilities.no_information_provided') }
              </div>
          }
        </div>

        { collapseFacilities && showMoreLink(showAllFacilities, () => setShowAllFacilities(prevState => !prevState)) }
      </div>
    </div>
  );
}


export default FactsAndFacilities;
