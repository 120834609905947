import React from 'react';

import Breadcrumb from '../index';

describe('Suite for <Breadcrumb />', () => {
  const props = {
    breadcrumbs: [
      { label: 'Hiem', path: '/' },
      { label: 'Leje', path: '/leje' },
      { label: 'Kontorlakler', path: '/kontorlakler' },
    ],
  };
  const wrapper = shallow(<Breadcrumb {...props} />);
  it('renders with 3 links', () => {
    expect(wrapper.find('a').length).toBe(3);
  });

  it('renders with link', () => {
    expect(
      wrapper
        .find('a')
        .at(1)
        .props().href
    ).toEqual('/leje');
    expect(
      wrapper
        .find('a')
        .at(1)
        .text()
    ).toEqual('Leje');
  });

  it('renders blank container', () => {
    let wrapper_blank = shallow(<Breadcrumb />);
    expect(wrapper_blank.find('a').length).toBe(0);
    expect(wrapper_blank.find('.container').length).toBe(1);
  });
});
