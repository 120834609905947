import React, { Component } from 'react';
import { getParkingLotsNear } from '../../utils/api';

import DetailMap from './DetailMap';
import ParkingMarker from '../Map/ParkingMarker';
import { isMobile, isTablet } from 'react-device-detect';

class ParkingMarkerMap extends Component {
  handleOrientationChange = () => {
    this.setState({ orientationHorizontalMobile: !this.state.orientationHorizontalMobile })
  };
  handleFullscreenMobile = () => this.setState({ isFullscreenMobile: !this.state.isFullscreenMobile });

  constructor(props) {
    super(props);
    this.state = {
      parkingLots: [],
      markerOpenedId: null,
      isGalleryMobileComponent: false,
      isFullscreenMobile: false,
      orientationHorizontalMobile: false,
    };
  }

  componentDidMount() {
    const { coordinates: { latitude, longitude } } = this.props;
    isMobile && this.setState({ isGalleryMobileComponent: true });
    if (isMobile && !isTablet) {
      window.innerHeight < window.innerWidth && this.setState({
        orientationHorizontalMobile: true
      });
      window.addEventListener('orientationchange', this.handleOrientationChange);
    }

    getParkingLotsNear(latitude, longitude)
      .then(res => {
        const parkingLots = res.data.parking_lots;
        this.setState({ parkingLots });
      })
      .catch(error => {
        console.error(error);
      });
  }


  updateMarkerOpenedId = (marker_id) => {
    this.setState({ markerOpenedId: marker_id });
  };


  markers = () => {
    return this.state.parkingLots.map(marker => (
      <ParkingMarker marker={marker} key={marker.id} updateMarkerOpenedId={this.updateMarkerOpenedId} markerOpenedId={this.state.markerOpenedId} />
    ));
  };

  render() {
    const { coordinates: { latitude, longitude }, googleApiKey } = this.props;
    const { isFullscreenMobile, isGalleryMobileComponent, orientationHorizontalMobile } = this.state;
    const coordinatesObj = { lat: latitude, lng: longitude };
    const containerClass = `map-container${isFullscreenMobile ? ' fullscreen-mobile' : ''}`;

    return (
      <DetailMap
        googleApiKey={googleApiKey}
        containerElement={<div className="bottom-map-container" />}
        mapElement={<div id="location-react-map" className="bottom-location-map" />}
        coordinates={coordinatesObj}
        markers={this.markers()}
        isGalleryMobileComponent={isGalleryMobileComponent}
        isMobile={isMobile && !isTablet}
        handleFullscreenMobile={this.handleFullscreenMobile}
        isFullscreenMobile={isFullscreenMobile}
        orientationHorizontalMobile={orientationHorizontalMobile}
        containerClass={containerClass}
      />
    );
  }
}

export default ParkingMarkerMap;
