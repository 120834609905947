import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { SvgIco } from "../../SharedComponents/Icons";
import { Context } from "../index";

import './index.sass';


const SuitableForFilter = ({ clearSuitableFor, isMobileMenu, toggleClearSuitableForFilter }) => {

  const {
    applySuitableForFilters,
    requestParams,
    selectedSuitableFor,
    setRequestParams,
    setSelectedSuitableFor,
    siteKey,
    suitableForList,
    suitableForStructuredTypes,
  } = useContext(Context);

  const { kinds } = requestParams;

  const filterWrapper = useRef();

  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [currentSelectedSuitableFor, setCurrentSelectedSuitableFor] = useState(selectedSuitableFor);
  const [showSuitableFor, setShowSuitableFor] = useState(false);
  const [currentSuitableForList, setCurrentSuitableForList] = useState([]);

  const suitableForFunctionalityCountries = ['dk', 'global', 'se', 'fi'];
  const showSuitableForFunctionality = suitableForFunctionalityCountries.indexOf(siteKey) >= 0


  useEffect(() => {
    if (isMobileMenu || suitableForList) {
      if (kinds && kinds.length) {
        handleSuitableForList();
      } else {
        setCurrentSuitableForList(suitableForList);
        setShowSuitableFor(!!suitableForList.length);
      }
    }
  }, []);


  useEffect(() => {
    setCurrentSelectedSuitableFor(selectedSuitableFor);
  }, [selectedSuitableFor]);


  useEffect(() => {
    if (clearSuitableFor) {
      handleApplyFilters([]);
      toggleClearSuitableForFilter();
    }
  }, [clearSuitableFor]);


  useEffect(() => {
    handleSuitableForList()
  }, [kinds, suitableForList]);


  const handleSuitableForList = () => {
    let newSuitableForList = [];
    let sortedSuitableForByKinds = [];

    if (kinds && kinds.length) {
      kinds
        .map(kind =>
          suitableForStructuredTypes[kind] && suitableForStructuredTypes[kind].map(suitableForKind =>
            !sortedSuitableForByKinds.includes(suitableForKind) && sortedSuitableForByKinds.push(suitableForKind))
        );

      suitableForList.map(elem => sortedSuitableForByKinds.includes(elem.id) && newSuitableForList.push(elem));
      newSuitableForList.sort((a, b) => a.id.localeCompare(b.id));
    } else {
      newSuitableForList = [...suitableForList];
    }

    setCurrentSuitableForList(newSuitableForList);
    setShowSuitableFor(!!newSuitableForList.length);
  };


  const toggleFilter = (currentState) => {
    const isOpen = !currentState;

    setFilterIsOpen(isOpen);
    isOpen
      ? window.addEventListener('click', handleFilterOpenClick)
      : window.removeEventListener('click', handleFilterOpenClick)
  };


  const handleFilterOpenClick = (e) => {
    !filterWrapper && !filterWrapper.current && window.removeEventListener('click', handleFilterOpenClick);

    const applyAllFiltersButtonMobile = document.getElementById('applyFiltersButtonMobile');

    filterWrapper
    && filterWrapper.current
    && !filterWrapper.current.contains(e.target)
    && (!applyAllFiltersButtonMobile || !applyAllFiltersButtonMobile.contains(e.target))
    && toggleFilter(true);
  };


  const handleFilterCheckboxClick = (e) => {
    const value = e.target.value;
    const updatedList = [ ...currentSelectedSuitableFor ];
    const valueIndex = updatedList.indexOf(value);

    valueIndex >= 0 ? updatedList.splice(valueIndex, 1) : updatedList.push(value);
    handleApplyFilters(updatedList);
  };


  const handleApplyFilters = (newData) => {
    setCurrentSelectedSuitableFor(newData);

    if (newData) {
      setRequestParams(prevParams => ({ ...prevParams, suitable_for: newData  }));
      setSelectedSuitableFor(newData);
    }

    !isMobileMenu && applySuitableForFilters(newData);
  };


  const isOpen = !isMobileMenu || filterIsOpen;
  const showFilter = showSuitableForFunctionality && currentSuitableForList && showSuitableFor && currentSuitableForList.length > 0;

  let selectedSuitableForLabels = [];
  currentSelectedSuitableFor.length && currentSuitableForList.map(listItem => currentSelectedSuitableFor.includes(listItem.id) && selectedSuitableForLabels.push(listItem.label));


  if (showFilter) {
    return (
      <div className="suitable-for-filter" ref={filterWrapper}>
        <div className={`suitable-for-filter__dropdown${ isOpen ? ' open' : '' }`} onClick={() => toggleFilter(filterIsOpen)}>
          <span>{ I18n.t('generic.suitable_for') }</span>
          <div className="dropdown-selector__trigger-right">
            { selectedSuitableForLabels.length > 0 &&
              <div className="dropdown-selector__selected-tooltip">
                { selectedSuitableForLabels.join(', ') }
              </div>
            }
            <div className="dropdown-selector__action-icon">
              { isOpen
                ? <SvgIco name="expand-less" size={20} />
                : <SvgIco name="expand-more" size={20} />
              }
            </div>
          </div>
        </div>

        { (isOpen || isMobileMenu) &&
          <div className={`suitable-for-filter__list-wrapper${isOpen ? ' open' : '' }`}>
            <div className={`suitable-for-filter__main-body${isOpen ? ' open' : '' }`}>
              <div className="suitable-for-filter__list">
                { currentSuitableForList.map((item, index) =>
                  <div className="suitable-for-filter__list-item" key={index}>
                    <label className={`${currentSelectedSuitableFor.includes(item.id) ? 'active' : ''}`}>
                      <SvgIco name="check" size={18} />
                      <input
                        checked={currentSelectedSuitableFor.includes(item.id)}
                        type="checkbox"
                        value={item.id}
                        onChange={(e) => handleFilterCheckboxClick(e)} />
                      { item.label }
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className="dropdown-selector__separator" />
          </div>
        }
      </div>
    )
  } else {
    return null;
  }
};


SuitableForFilter.propTypes = {
  selectedSuitableFor: PropTypes.arrayOf(PropTypes.string),
  suitableForList: PropTypes.arrayOf(PropTypes.object),
};

SuitableForFilter.defaultProps = {
  selectedSuitableFor: [],
  suitableForList: []
};


export default SuitableForFilter;
