import React, { Component, Fragment, Suspense } from 'react';

import FAQSection from '../SharedComponents/FAQSection';
import RequestCallModal from '../SharedComponents/RequestCallModal';
import { ToTheTopBtn } from "../SharedComponents/Buttons";
import TrustBoxContainer from '../SharedComponents/TrustPilot';
import TopBanner from './TopBanner';
import ProvidersSection from './ProvidersSection';
import Testimonials from './Testimonials';
import ContactsSection from './ContactsSection';
import BenefitsSection from './BenefitsSection';
import ContractsTop from './ContractsTop';
import { loadTawkTo } from '../TawkTo/TawkTo';

const AdvBannerDk = React.lazy(() => import('./AdvBannerDk'));
const CalendlyBlock = React.lazy(() => import('./Calendly'));
const ContractsBottom = React.lazy(() => import('./ContractsBottom'));
const PaymentTeasers = React.lazy(() => import('./PaymentTeasersSection'));
const TeaserBlocks = React.lazy(() => import('./TeaserBlocks'));

import './index.sass';


class ListOffice extends Component {
  state = {
    isDesktop: window.innerWidth > 960,
  };

  componentDidMount() {
    loadTawkTo(this.props.siteKey);
  }

  render() {
    const {
      advertisementStrategiesPrices,
      contactInfoEmail,
      contactInfoPhone,
      contactUsFormPath,
      contractDetails,
      findAgentFormPath,
      formAuthToken,
      getHelpFormPath,
      isRequestFromPrerender,
      locale,
      privacyPolicyPath,
      richSnippetQuestions,
      signupFormPath,
      siteKey,
      trustpilotWidget,
      virtualTourUrl,
    } = this.props;

    const trustPilotLocale = siteKey === 'dk' ? 'da-DK' : 'en-US';
    const checkTurkish = locale === 'tr';


    return (
      <div className="list-office">

        <ToTheTopBtn />

        <TopBanner
          locale={locale}
          signupFormPath={signupFormPath}
          siteKey={siteKey}
        />

        { siteKey !== 'dk' &&
          <Suspense fallback="">
            <PaymentTeasers siteKey={siteKey} />
          </Suspense>
        }

        <ProvidersSection siteKey={siteKey} />

        <Testimonials />

        <div className="list-office__trustpilot container">
          <TrustBoxContainer
            data={trustpilotWidget}
            locale={trustPilotLocale}
          />
        </div>

        <ContactsSection
          contactInfoEmail={contactInfoEmail}
          contactInfoPhone={contactInfoPhone}
          siteKey={siteKey}
        />

        <ContractsTop
          advertisementStrategiesPrices={advertisementStrategiesPrices}
          contactUsFormPath={contactUsFormPath}
          contractDetails={contractDetails.topContracts}
          contractDetailsBot={contractDetails.bottomContracts}
          contractLink={contractDetails.bottomContractForLink}
          formAuthToken={formAuthToken}
          privacyPolicyPath={privacyPolicyPath}
          signupFormPath={signupFormPath}
          siteKey={siteKey}
        />

        { siteKey === "dk" &&
          <Fragment>
            <Suspense fallback="">
              <AdvBannerDk />
            </Suspense>
            { richSnippetQuestions && richSnippetQuestions.length > 0 &&
              <FAQSection
                isRequestFromPrerender={isRequestFromPrerender}
                richSnippetQuestions={richSnippetQuestions}
                title={I18n.t('provider.teaser.faq_questions.title')}
              />
            }
          </Fragment>
        }


        { siteKey === "dk" &&
          <Suspense fallback="">
            <ContractsBottom
              advertisementStrategiesPrices={advertisementStrategiesPrices}
              contractDetails={contractDetails.bottomContracts}
              contractLink={contractDetails.bottomContractForLink}
              signupFormPath={signupFormPath}
              siteKey={siteKey}
            />
          </Suspense>
        }

        { siteKey !== "dk" && !this.state.isDesktop &&
          <Suspense fallback="">
            <ContractsBottom
              advertisementStrategiesPrices={advertisementStrategiesPrices}
              contractDetails={contractDetails.bottomContracts}
              contractLink={contractDetails.bottomContractForLink}
              signupFormPath={signupFormPath}
              siteKey={siteKey}
            />
          </Suspense>
        }


        { !checkTurkish && (siteKey === 'dk' || siteKey === 'global') &&
          <Suspense fallback="">
            <CalendlyBlock siteKey={siteKey} />
          </Suspense>
        }

        { siteKey === 'dk' &&
          <Suspense fallback="">
            <TeaserBlocks
              findAgentFormPath={findAgentFormPath}
              getHelpFormPath={getHelpFormPath}
              privacyPolicyPath={privacyPolicyPath}
            />
          </Suspense>
        }

        <BenefitsSection siteKey={siteKey} virtualTourUrl={virtualTourUrl} />

        <RequestCallModal />

        { siteKey !== "dk" &&
          <Fragment>
            { richSnippetQuestions && richSnippetQuestions.length > 0 &&
              <FAQSection
                isRequestFromPrerender={isRequestFromPrerender}
                richSnippetQuestions={richSnippetQuestions}
                title={I18n.t('provider.teaser.faq_questions.title')}
              />
            }
          </Fragment>
        }
      </div>
    );
  }
};

export default ListOffice;
