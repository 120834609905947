class NumberFormatter {
  static floatSeperator = '.';

  constructor(delimiter, seperator) {
    this.delimiter = delimiter;
    this.seperator = seperator;
  }

  numberWithDelimiter(number) {
    let seperatedNumber = String(number).split(NumberFormatter.floatSeperator);
    seperatedNumber[0] = seperatedNumber[0].replace(
      /(\d)(?=(\d\d\d)+(?!\d))/g,
      '$1' + this.delimiter
    );
    return seperatedNumber.join(this.seperator);
  }
}

export default NumberFormatter;
