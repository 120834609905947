import React, { useContext, useEffect, useState, Fragment } from 'react';
import { isMobileOnly } from "react-device-detect";
import Modal from 'react-modal';

import Storage from '../../../utils/localStorage';
import Client from '../../../utils/Client';
import { placeOrder } from '../../../utils/api';
import { SvgIco } from '../../SharedComponents/Icons';
import { Context } from "../index";
import Form from './Form.jsx';

import './index.sass';


const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: '9990',
    padding: '0'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'visible',
    border: 'none',
  },
};


const VirtualTour = () => {

  const {
    assets: { virtualTour },
    contactOrdered,
    // isJeudan,
    locationOrdered,
    locationUuid,
    orderPath,
    setUpdateOrderForm,
    showcaseOrdered,
    switchSelectedOrderForm,
    updateVirtualTourForm,
  } = useContext(Context);

  const [isOrdered, setIsOrdered] = useState(locationOrdered);
  const [isSubmittingOrder, setIsSubmittingOrder] = useState(false);
  const [formHasBeenSent, setFormHasBeenSent] = useState(Storage.isLocationOrdered(locationUuid));
  const [modalIsOpen, setModalIsOpen] = useState(false);


  useEffect(() => {
    if(locationOrdered && !contactOrdered) {
      switchSelectedOrderForm('provider_message')
    } else if (contactOrdered && locationOrdered && !showcaseOrdered) {
      switchSelectedOrderForm('book_showcase')
    } else {
      switchSelectedOrderForm('order')
    }
    isMobileOnly && !isOrdered && setModalIsOpen(true);
  }, []);


  useEffect(() => {
    updateVirtualTourForm && setFormHasBeenSent(true);
  }, [updateVirtualTourForm]);


  const submitFormHandler = params => {
    params.locationId = locationUuid;
    setIsSubmittingOrder(true);

    placeOrder(orderPath, params)
      .then(() => {
        Storage.updateOrderedItem();
        setFormHasBeenSent(true);
        setUpdateOrderForm(true);

        if (showcaseOrdered && contactOrdered) {
          switchSelectedOrderForm('order');
          return
        }
        if (contactOrdered) {
          switchSelectedOrderForm('book_showcase');
          return
        }
        switchSelectedOrderForm('provider_message');
        modalIsOpen && setModalIsOpen(false);
      })
      .catch(error => {
        console.error(error);
      });
  };


  const renderRequestLayout = () =>  {
    const { email } = Client.getClientInfo();

    return (
      <div className="virtual-tour">
        <div className={`virtual-tour__row ${formHasBeenSent ? 'form-sent-wrapper' : ''}`}>
          { formHasBeenSent
            ?
              <div className="virtual-tour__thanks-text-wrapper">
                <h2>
                  { I18n.t('apps.lb_showroom.detailed.virual_tour.thanks') }<br/>
                  { I18n.t('apps.lb_showroom.detailed.virual_tour.link_sent') }!
                </h2>

                <div className="icon-wrapper">
                  <SvgIco name="check" size={32} />
                  <p>{ I18n.t('apps.lb_showroom.detailed.virual_tour.thanks_text_updated', {'email': email}) }</p>
                </div>

                <button className='get-info-submit-button' onClick={() => setIsOrdered(true)}>
                  { I18n.t('apps.lb_showroom.detailed.virual_tour.open_button_text') }
                  <SvgIco name="keyboard-arrow-right-rounded" size={28} />
                </button>
              </div>
            :
              <div className="virtual-tour__description-wrapper">
                { isMobileOnly
                  ?
                    <Fragment>
                      <h2>{ I18n.t('apps.lb_showroom.detailed.virual_tour.main-title') }</h2>
                      <p>{ I18n.t('apps.lb_showroom.detailed.virual_tour.details_description') }</p>
                      <div className="virtual-tour__open-modal-link" onClick={() => setModalIsOpen(prevState => !prevState)}>
                        { I18n.t('apps.lb_showroom.detailed.virual_tour.request_link') }
                      </div>
                    </Fragment>
                  :
                    <Fragment>
                      <h2>{ I18n.t('apps.lb_showroom.detailed.virual_tour.main-title') }</h2>
                      <p>{ I18n.t('apps.lb_showroom.detailed.virual_tour.details_description') }</p>
                      <p>{ I18n.t('apps.lb_showroom.detailed.virual_tour.details_description_second') }</p>
                    </Fragment>
                }
              </div>
          }

          { !formHasBeenSent && !isMobileOnly &&
            <div className="virtual-tour__form-wrapper">
              <Form
                submitHandler={submitFormHandler}
                isSubmittingOrder={isSubmittingOrder}
                // isJeudan={isJeudan}
              />
            </div>
          }


          { modalIsOpen &&
            <Modal
              ariaHideApp={false}
              className="virtual-tour-modal"
              isOpen={modalIsOpen}
              onRequestClose={() => setModalIsOpen(prevState => !prevState)}
              style={customStyles}>
              <div className="virtual-tour-modal__close-btn" onClick={() => setModalIsOpen(prevState => !prevState)}>
                <SvgIco name="close" size={24} />
              </div>
              <h2>{ I18n.t('apps.lb_showroom.detailed.virual_tour.main-title') }</h2>
              <p>{ I18n.t('apps.lb_showroom.detailed.virual_tour.details_description') }</p>
              <p>{ I18n.t('apps.lb_showroom.detailed.virual_tour.details_description_second') }</p>
              <Form
                submitHandler={submitFormHandler}
                isSubmittingOrder={isSubmittingOrder}
                // isJeudan={isJeudan}
              />
            </Modal>
          }


          </div>
      </div>
    );
  };

  const renderVirtualFrame = () => {
    return (
      <div className="virtual-tour__frame-wrapper">
        <iframe src={virtualTour} />
      </div>
    );
  }


  return isOrdered ? renderVirtualFrame() : renderRequestLayout();
};


export default VirtualTour;
