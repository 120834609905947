import React from 'react';
import ReactTooltip from "react-tooltip";

import { SvgIco } from "../../SharedComponents/Icons";

import './index.sass';


const SmartTextTooltip = ({ id }) => {

  return (
    <div className="smart-text-tooltip">
      <i className="smart-text-tooltip__icon"
         data-tip={I18n.t('apps.lb_showroom.detailed.smart_text_tooltip')}
         data-for={`${id}-smart-text-tooltip`}>
         <SvgIco name="info_outline" size={20} />
      </i>

      <ReactTooltip
        place='right'
        effect='solid'
        id={`${id}-smart-text-tooltip`}
        type="light"
        border={true}
        borderColor="black" />
    </div>
  )
}

export default SmartTextTooltip;
