import React from 'react';
import './index.sass'


const AdvBannerDk = () => {
  return (
    <div className="advbanner-section">
      <div className="container">
        <div className="advbanner-wrapper">

          <div className="advbanner-text-block">
            <p className="advbanner-description-text">
              Mødelokaler eller parkeringspladser
            </p>
          </div>

          <div className="advbanner-button-block">
            <a href="#coworking" className="advbanner-button">Se priser her</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvBannerDk;
