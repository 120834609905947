import React, { useContext } from 'react';

import BaseSlider from "../../SharedComponents/BaseSlider";
import LocationTemplate from "../../SharedComponents/LocationTemplate";
import { Context } from "../index";

import './index.sass';


const ViewedLocations = () => {

  const {
    comparableLocations,
    favouriteLocationsIds,
    section,
    setComparableLocations,
    setFavouriteLocationsIds,
    siteKey,
    viewedLocations,
  } = useContext(Context);


  return (
    <div className="viewed-locations-wrapper">
      <div className="viewed-locations container">
        <h2 className="viewed-locations__headline container">
          { siteKey === 'dk' && section !== 'lease'
            ? I18n.t('apps.lb_showroom.viewed_sales')
            : I18n.t('apps.lb_showroom.viewed_leases')
          }
        </h2>
        <BaseSlider infinite withDots>
          { viewedLocations.map(location => {
            return (
              <LocationTemplate
                comparableLocations={comparableLocations}
                data={location}
                favoriteLocations={favouriteLocationsIds}
                key={location.uuid}
                section={section}
                siteKey={siteKey}
                updateFavouriteLocations={setFavouriteLocationsIds}
                updateComparableLocations={setComparableLocations}
              />
            )})}
        </BaseSlider>
      </div>
    </div>
  )
};


export default ViewedLocations;
