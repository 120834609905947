import React, { useEffect, useState, Fragment, Suspense } from 'react';
import { createContext } from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from "react-device-detect";

import { preventResizeObserverError } from "../../utils/resizeObzerver";
import Storage from '../../utils/localStorage';
import httpService from '../../api/httpService';
import { loadTawkTo } from '../TawkTo/TawkTo';
import ErrorBoundary from '../ErrorBoundary';
import { ToTheTopBtn } from '../SharedComponents/Buttons';
import { SvgIco } from "../SharedComponents/Icons";
import GallerySection from './Gallery';
import Header from './Header';
import InfoSections from './InfoSections';

import '../SharedComponents/TrustLogos.sass';
import './index.sass';

const AskProviderModalForm = React.lazy(() => import('./AskProviderModalForm'));
const ClosedLocationBanner = React.lazy(() => import('./ClosedLocationBanner'));
const LocationUnavailable = React.lazy(() => import('./LocationUnavailable'));
const OrderForm = React.lazy(() => import('../OrderForm'));


export const Context = createContext({});


const DetailedViewHeader = (props) => {

  const {
    apiCreateOrderPath,
    apiShowcasePath,
    location,
    locationAvailable,
    locationIsClosed,
    // locationContactDetails,
    rentedOut,
    section,
    siteKey,
    temporarilyUnavailable,
  } = props;

  const [activeGallerySection, setActiveGallerySection] = useState('photos');
  const [contactOrdered, setContactOrdered] = useState(Storage.isContactOrdered(location.uuid));
  const [comparableLocations, setComparableLocations] = useState(null);
  const [favouriteLocationsIds, setFavouriteLocationsIds] = useState(null);
  const [locationOrdered, setLocationOrdered] = useState(localStorage && Storage.isLocationOrdered(location.uuid));
  const [mapSectionOpen, setMapSectionOpen] = useState(false);
  const [modalFormOpen, setModalFormOpen] = useState(false);
  const [selectedFormOption, setSelectedFormOption] = useState('order');
  const [showcaseOrdered, setShowcaseOrdered] = useState(localStorage && Storage.isShowcaseOrdered(location.uuid));
  const [viewedLocations, setViewedLocations] = useState(null);
  const [virtualTourOpen, setVirtualTourOpen] = useState(false);
  const [updateOrderForm, setUpdateOrderForm] = useState(false);
  const [updateVirtualTourForm, setUpdateVirtualTourForm] = useState(false);
  const [updateAskLandlord, setUpdateAskLandlord] = useState(false);
  const [showSimilarLocations, setShowSimilarLocations] = useState(false);

  // const isJeudan = locationContactDetails.provider.name && locationContactDetails.provider.name.toLowerCase() === 'jeudan';
  const locationClosed = locationIsClosed && !rentedOut && !locationAvailable;
  const locationRented = !!(rentedOut || temporarilyUnavailable || locationClosed);
  const sectionType = section === 'lease' ? section : 'sale';


  useEffect(() => {
    localStorage && localStorage.setItem('locale', props.locale);
    !isMobileOnly && loadTawkTo(siteKey);
    preventResizeObserverError();
  }, []);


  useEffect(() => {
    updateAskLandlord && setContactOrdered(true);
  }, [updateAskLandlord]);


  const switchGallerySection = (option) => {
    setActiveGallerySection(option);
    setMapSectionOpen(option === 'map');
    setVirtualTourOpen(option === 'virtualTour');
  };


  const scrollToTarget = (target) => {
    const targetElem = document.getElementById(target);

    if (targetElem) {
      const targetCoords = Math.floor(targetElem.getBoundingClientRect().top + window.pageYOffset - (document.getElementById('top-menu').offsetHeight + 48));
      window.scroll(0, targetCoords);
    }
  };


  const updateViewedLocations = () => {
    const locationIdStr = location.id.toString();
    const retrievedViewed = localStorage && localStorage.getItem(`my.viewed_${sectionType}_${siteKey}`);
    let viewedLocations = JSON.parse(retrievedViewed) || [];

    if (viewedLocations.indexOf(locationIdStr) < 0) {
      // Limit viewed locations to last 10
      viewedLocations.length && viewedLocations.length === 10 && viewedLocations.pop();
      viewedLocations.unshift(locationIdStr);
    }

    localStorage.setItem(`my.viewed_${sectionType}_${siteKey}`, JSON.stringify(viewedLocations));
    viewedLocations && viewedLocations.length && getViewedLocations(viewedLocations);
  };


  const getViewedLocations = (viewedLocationsIds) => {
    httpService.getLocationsByIds(viewedLocationsIds)
      .then((response) => {
        if (response) {
          response.length
          && response.length !== viewedLocationsIds.length
          && localStorage
          && localStorage.setItem(`my.viewed_${sectionType}_${siteKey}`, JSON.stringify(response.map(location => `${location.id}`)));

          setViewedLocations(response);
        }
      });
  };


  const contextProps = {
    ...props,
    activeGallerySection,
    anonymous: location.anonymous,
    areaUnit: location.areaUnit,
    comparableLocations,
    contactOrdered,
    favouriteLocationsIds,
    // isJeudan,
    locationAddress: location.address,
    locationClosed,
    locationId: location.id,
    locationOrdered,
    locationRented,
    locationUuid: location.uuid,
    mapSectionOpen,
    modalFormOpen,
    orderPath: apiCreateOrderPath,
    scrollToForm: scrollToTarget,
    sectionType,
    selectedFormOption,
    setModalFormOpen,
    setComparableLocations,
    setContactOrdered,
    setFavouriteLocationsIds,
    setLocationOrdered,
    setShowcaseOrdered,
    setShowSimilarLocations,
    setUpdateAskLandlord,
    setUpdateOrderForm,
    setUpdateVirtualTourForm,
    showCasePath: apiShowcasePath,
    showcaseOrdered,
    showSimilarLocations,
    switchGallerySection,
    switchSelectedOrderForm: setSelectedFormOption,
    viewedLocations,
    virtualTourOpen,
    updateAskLandlord,
    updateOrderForm,
    updateViewedLocations,
    updateVirtualTourForm,
  };


  return (
    <Context.Provider value={contextProps}>
      <ErrorBoundary>

        { !locationRented && !locationClosed &&
          <div className="order-form-scroll-btn-mobile">
            <div className="order-form-scroll-btn" onClick={() => scrollToTarget('order-form-target')}>
              <SvgIco name="contract" size={24} />
              { I18n.t('apps.lb_showroom.detailed.get_more_insights') }
            </div>
          </div>
        }

        { !locationRented && !locationClosed &&
          <Suspense fallback={''}>
            <AskProviderModalForm />
          </Suspense>
        }

        <div className={`dv-wrapper${locationRented ? ' dv-wrapper--rented-out' : ''}`}>
          { (locationAvailable || locationClosed)
            ?
              <Fragment>
                <GallerySection />

                <div className="container">
                  <div className="row">
                    <div className="col-12 col-md-7 col-lg-8">
                      <Header />
                    </div>

                    { !locationRented && !locationClosed &&
                      <Suspense fallback={''}>
                        <div className="col-12 col-md-5 col-lg-4" id="order-form-target">
                          <div className="form-wrapper">
                            <OrderForm />
                          </div>
                        </div>
                      </Suspense>
                    }

                    { !isMobileOnly && locationClosed &&
                      <Suspense fallback={''}>
                        <div className="col-12 col-md-5 col-lg-4">
                          <ClosedLocationBanner />
                        </div>
                      </Suspense>
                    }
                  </div>
                </div>


                { !locationRented && !locationClosed &&
                  <Suspense fallback={''}>
                    <div className="col-lg-4 col-md-4 col-12 form-wrapper form-wrapper--mobile" id="order-form-target">
                      <OrderForm />
                    </div>
                  </Suspense>
                }

                { isMobileOnly && locationClosed &&
                  <Suspense fallback={''}>
                    <ClosedLocationBanner />
                  </Suspense>
                }

                <div className="dv-description-wrapper">
                  <InfoSections />
                </div>
              </Fragment>
            :
              <Suspense fallback={''}>
                <LocationUnavailable />
              </Suspense>
          }
          <ToTheTopBtn />
        </div>
      </ErrorBoundary>
    </Context.Provider>
  );
};


export default DetailedViewHeader;


DetailedViewHeader.propTypes = {
  title: PropTypes.string.isRequired,
  suitableFor: PropTypes.array,
  assets: PropTypes.shape({
    photos: PropTypes.array,
    videos: PropTypes.array,
    floorPlans: PropTypes.array,
    virtualTour: PropTypes.string,
  }),
  price: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  area: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  location: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  tabs: PropTypes.shape({
    facility: PropTypes.shape({
      facilities: PropTypes.array,
    }),
    economy: PropTypes.shape({
      disposalPrice: PropTypes.string,
    }),
    smartText: PropTypes.shape({
      factAndFacilities: PropTypes.string,
    }),
    transport: PropTypes.shape({
      distances: PropTypes.array,
      postalDistrict: PropTypes.shape({
        postalName: PropTypes.string,
      }),
    }),
  }),
  facts: PropTypes.shape({
    area: PropTypes.shape({
      icon: PropTypes.string,
    }),
    price: PropTypes.object,
  }),
};


DetailedViewHeader.defaultProps = {
  tabs: {
    facility: {
      facilities: [],
    },
    economy: {
      disposalPrice: '0 kr.',
    },
    smartText: {
      factAndFacilities: '',
    },
    transport: {
      distances: [],
      postalDistrict: {
        postalName: '',
      },
    },
  },
  facts: {
    area: {
      icon: '',
      label: '',
      value: '',
      unit: '',
    },
    price: {
      icon: '',
      label: '',
      value: '',
      unit: null,
    },
    economy: {},
  },
};
