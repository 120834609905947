import React, { Component } from 'react';
import { withFormik } from 'formik';
import Yup from 'yup';
import classNames from 'classnames';

import InfoIcon from '../../../src/images/icons/info.svg';
import { validationPatterns } from "../../../utils/validationPatterns";
import Client from '../../../utils/Client';
import Loader from '../../SharedComponents/Loader';
import { SvgIco } from "../../SharedComponents/Icons";

import './Form.sass';


class VirtualTourForm extends Component {
  state = {
    isSubmitting: false,
    // jeudanReadMore: false,
    // showJeudanValidationMessage: false,
  };

  // handleJeudanPopup = (e) =>
  //   (e.target === this.jeudanCloseButton || !this.jeudanPopup.contains(e.target)) && this.closeReadMoreJeudan();


  // closeReadMoreJeudan = () =>
  //   this.setState({ jeudanReadMore: false }, () => this.infoForm.removeEventListener('click', this.handleJeudanPopup));
  //
  // openReadMoreJeudan = () => {
  //   this.setState({ jeudanReadMore: true }, () => this.infoForm.addEventListener('click', this.handleJeudanPopup));
  // }

  // showJeudanValidationMessage = () =>
  //   this.setState({ showJeudanValidationMessage: true }, () => setTimeout(() => this.setState({ showJeudanValidationMessage: false }), 2000));

  handleFieldBlur = (e) => {
    this.props.handleBlur(e);
  };

  handleSendingData = () => {
    this.props.validateForm()
      .then(resolved => {
        if (Object.entries(this.props.errors).length === 0) {
          this.setState({ isSubmitting: true });
        }
      })
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // this.props.isJeudan && !this.props.values.jeudan_policy && this.showJeudanValidationMessage();
    this.props.handleSubmit();
  };

  render() {

    const {
      values,
      touched,
      errors,
      handleChange,
      isSubmittingOrder,
      // isJeudan,
      setFieldValue,
    } = this.props;

    const {
      isSubmitting,
      // jeudanReadMore,
      // showJeudanValidationMessage,
    } = this.state;

    const noErrors = Object.keys(errors).length === 0;
    const buttonIcon = isSubmitting && noErrors ? <Loader size={18} left={7} top={15}/> : null;
    const buttonText = I18n.t('apps.lb_showroom.detailed.virtual_tour.send_button_new_design');
    // const jeudanValidationMessageClasses = classNames('jeudan-policy-checkbox__validation-message-jeudan', { 'visible': showJeudanValidationMessage });
    // const checkboxClasses = classNames('jeudan-policy-checkbox__wrapper', {
    //   'checked': values.jeudan_policy,
    //   'error': touched.jeudan_policy && errors.jeudan_policy,
    // });

    return (
      <div ref={el => this.infoForm = el}>
        <form onSubmit={this.handleSubmit} noValidate>
          {/* here we check does this field was filled by bot */}
          <input
            id="fifth_element"
            type="text"
            name="fifth_element"
            className="invisible"
            value={values.fifth_element}
            onPaste={handleChange}
            onChange={handleChange}
            onBlur={this.handleFieldBlur}
          />

          <div className="order-form__collapsible-wrapper ">
             <div className="input-wrapper">
                <div className="form-group">
                  <input
                    id="name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={this.handleFieldBlur}
                    className={`form-control order-form__input
                          ${values.name !== '' ? 'not-empty' : 'empty'}
                          ${touched.name ? (errors.name ? 'error' : 'is-valid order-input-valid') : null}`}
                  />
                  <span
                    className="floating-label">{I18n.t('activemodel.attributes.provider_service_request.person_name')}*</span>
                  <div className="validation-wrapper"/>
                  <div className="validation-message">
                    {touched.name && errors.name ? errors.name : null}
                  </div>
                </div>

                <div className="form-group">
                  <input
                    id="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={this.handleFieldBlur}
                    className={`form-control order-form__input
                          ${values.email !== '' ? 'not-empty' : 'empty'}
                          ${touched.email ? (errors.email ? 'error' : 'is-valid order-input-valid') : null}`}
                  />
                  <span className="floating-label">
                        {I18n.t('activemodel.attributes.provider_service_request.email')}*
                      </span>
                  <div className="validation-wrapper"/>
                  <div className="validation-message">
                    {touched.email && errors.email ? errors.email : null}
                  </div>
                </div>

                <div className="form-sub">
                  <div className="form-group">
                    <input
                      id="company"
                      type="text"
                      value={values.company}
                      onChange={(e) => {
                        setFieldValue('company', e.target.value.trimStart().replace(/\s\s+/g, ' '))
                      }}
                      onBlur={this.handleFieldBlur}
                      className={`form-control order-form__input
                            ${values.company !== '' ? 'not-empty' : 'empty'}
                            ${touched.company ? (errors.company ? 'error' : 'is-valid order-input-valid') : null}`}
                    />
                    <span className="floating-label">
                          {I18n.t('activemodel.attributes.provider_service_request.company_name')}*
                        </span>
                    <div className="validation-wrapper"/>
                    <div className="validation-message">
                      {touched.company && errors.company ? errors.company : null}
                    </div>
                  </div>

                  <div className="form-group">
                    <input
                      id="phone"
                      type="tel"
                      value={values.phone}
                      onChange={(e) => {
                        setFieldValue('phone', e.target.value.trimStart().replace(/\s\s+/g, ' '))
                      }}
                      onBlur={this.handleFieldBlur}
                      className={`form-control order-form__input
                            ${values.phone !== '' ? 'not-empty' : 'empty'}
                            ${touched.phone ? (errors.phone ? 'error' : 'is-valid order-input-valid') : null}`}
                    />
                    <span className="floating-label">
                          {I18n.t('activemodel.attributes.provider_service_request.phone_number_order_form')}*
                        </span>
                    <div className="validation-wrapper"/>
                    <div className="validation-message">
                      {touched.phone && errors.phone ? errors.phone : null}
                    </div>
                  </div>


                </div>
            </div>
          </div>

          <button disabled={isSubmittingOrder} onClick={this.handleSendingData} className={`get-info-submit-button ${isSubmittingOrder ? 'sent' : ''} `} >
            { buttonIcon }
            { buttonText }
          </button>

          {/*{ isJeudan &&*/}
          {/*  <div className="form-group jeudan-policy-checkbox">*/}
          {/*    <div className={checkboxClasses}>*/}
          {/*      <SvgIco name="check" size={17} />*/}
          {/*      <input*/}
          {/*        name="jeudan_policy"*/}
          {/*        type="checkbox"*/}
          {/*        value={values.jeudan_policy}*/}
          {/*        id="jeudan_policy"*/}
          {/*        onChange={handleChange}*/}
          {/*        onBlur={this.handleFieldBlur}*/}
          {/*      />*/}
          {/*      <div className="jeudan-policy-checkbox__label">*/}
          {/*        <div>*/}
          {/*          { I18n.t('activemodel.attributes.provider_service_request.jeudan_privacy_policy_short') }*/}
          {/*          <img onClick={this.openReadMoreJeudan} src={InfoIcon} alt="info-icon" className="lazyload" data-sizes="auto" />*/}
          {/*          /!*<span  className="jeudan-policy-checkbox__label-read-more-link">*!/*/}
          {/*                      /!*{ I18n.t('generic.read_more') }.*!/*/}
          {/*                    /!*</span>*!/*/}
          {/*        </div>*/}

          {/*        { jeudanReadMore &&*/}
          {/*        <div className="jeudan-policy-checkbox__popup" ref={el => this.jeudanPopup = el}>*/}
          {/*          <div ref={el => this.jeudanCloseButton = el}*/}
          {/*               className="jeudan-policy-checkbox__popup-close-btn">*/}
          {/*            <SvgIco name="close" size={30} />*/}
          {/*          </div>*/}
          {/*          <div dangerouslySetInnerHTML={{ __html: I18n.t('activemodel.attributes.provider_service_request.jeudan_privacy_policy_full')}}*/}
          {/*               className="jeudan-policy-checkbox__label-content" />*/}
          {/*        </div>*/}
          {/*        }*/}
          {/*      </div>*/}

          {/*      { touched.jeudan_policy && errors.jeudan_policy &&*/}
          {/*      <div className={jeudanValidationMessageClasses}>*/}
          {/*        { touched.jeudan_policy && errors.jeudan_policy ? errors.jeudan_policy : null }*/}
          {/*      </div>*/}
          {/*      }*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*}*/}
        </form>
      </div>
    );
  }
};

const { fifth_element, name, phone, email, company } = Client.getClientInfo();
const { nameI18n, emailI18n, companyI18n, phoneI18n } = Client.getI18nAttrNames();
const i18nRequiredKey = 'apps.lb_showroom.detailed.order_form.validation.field_required';
const i18nMinNumberKey = 'apps.lb_showroom.detailed.order_form.validation.min_number';
const i18nSymbolsLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 254 });
const i18nSymbolsEmailLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 100 });
const i18nAtLeast8Digits = 'apps.lb_showroom.detailed.order_form.validation.at_least_8_digits';

const Form = withFormik({
  mapPropsToValues: (bag) => {
    return (
      ({
        name,
        email,
        phone,
        company,
        fifth_element,
        // jeudan_policy: false,
        // isJeudan: bag.isJeudan
      })
    )
  },
  validationSchema: Yup.object().shape({
    fifth_element: Yup.string(),
    name: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .matches(validationPatterns.name, I18n.t("apps.lb_showroom.misc.scoped_form.name.invalid"))
      .required(I18n.t(i18nRequiredKey, { field: nameI18n })),
    email: Yup.string()
      .matches(validationPatterns.symbolsEmailLimit, i18nSymbolsEmailLimitErrorMessage)
      .email(I18n.t('apps.lb_showroom.detailed.order_form.validation.wrong_email'))
      .required(I18n.t(i18nRequiredKey, { field: emailI18n })),
    company: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .matches(validationPatterns.company, I18n.t("apps.lb_showroom.misc.scoped_form.company.invalid"))
      .required(I18n.t(i18nRequiredKey, { field: companyI18n })),
    phone: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .min(8, I18n.t(i18nMinNumberKey, { field: phoneI18n }))
      .matches(validationPatterns.phone, I18n.t('apps.lb_showroom.misc.scoped_form.phone.invalid'))
      .test('minDigitsValidation', I18n.t(i18nAtLeast8Digits), function(value) {
        const { path, createError } = this;
        const phoneIsValid = value && value.replace(/[+\-\s()]/g, '').length >= 8;
        return phoneIsValid || !value || createError({ path, message: I18n.t(i18nAtLeast8Digits) });
      })
      .required(I18n.t(i18nRequiredKey, { field: phoneI18n })),
    isJeudan: Yup.boolean(),
    jeudan_policy: Yup.boolean()
      .when("isJeudan", {
        is: true,
        then: Yup.boolean()
          .required(I18n.t('activemodel.attributes.provider_service_request.jeudan_privacy_policy_error_message'))
          .oneOf([true], I18n.t('activemodel.attributes.provider_service_request.jeudan_privacy_policy_error_message')),
      })
  }),
  handleSubmit: (values, bag) => {
    Client.updateClientInfo(values);
    bag.props.submitHandler(values);
  },
  displayName: 'VirtualTourForm', // helps with React DevTools
})(VirtualTourForm);

export default Form;
