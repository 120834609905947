import React, { Component } from 'react';
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";

import { apiProvider } from '../../api/core';
import MarkerWrapper from './MarkerWrapper';
import MarkerContent from './MarkerContent';


class MapWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      markerInfoLocation: false,
      markerFullInfo: null
    };
  }

  onMarkerInfoToggle = (locationId, coords) => {

    if (locationId === this.state.markerInfoLocation) {
      return this.setState(() => ({
        markerInfoLocation: false,
        markerFullInfo: null,
      }));
    } else {
      this.setState({ markerFullInfo: null });

      apiProvider.getAll(`/lease/api/frontend/locations/${locationId}/map_markers_marker_info`)
                 .then(response => response && this.setState({ markerFullInfo: response }));

      return this.setState(() => ({
        markerInfoLocation: locationId,
      }));
    }
  };

  getMarkers() {
    const { markerInfoLocation, markerFullInfo } = this.state;
    return this.props.markersArray.filter(e => (e.location.lat && e.location.lon)).map((data) => {
      const openByClick = (markerInfoLocation === (data.location.id ? data.location.id : data.id));


      return (
        <MarkerWrapper
          key={ data.location.id ? data.location.id : data.id}
          onInfoToggle={ this.onMarkerInfoToggle }
          locationId={ data.location.id ? data.location.id : data.id }
          lat={ data.location.lat  }
          lng={ data.location.lon }
          isActive={ openByClick }
          map={this.props.map}
        >
          { markerFullInfo && (
            <MarkerContent
              address={ markerFullInfo.address_line_1 }
              areaLabel={ markerFullInfo.presenter.area_label }
              area={ markerFullInfo.presenter.area }
              priceLabel={ markerFullInfo.presenter.label }
              price={ markerFullInfo.presenter.price }
              id={ markerFullInfo.id }
              imageUrl={ markerFullInfo.primary_photo_2x }
              kind={ markerFullInfo.kind }
              link={ markerFullInfo.href }
              orderUrl={ markerFullInfo.order_path }
              postalCode={ markerFullInfo.postal_code }
              postalName={ markerFullInfo.postal_name }
              features={ markerFullInfo.features_presence }
              rentedOut={ markerFullInfo.shown_as_rented_out }
              parkingCheckbox={ markerFullInfo.nearby_parking_lot }
              onInfoToggle={ this.onMarkerInfoToggle }
              uuid={ markerFullInfo.uuid }
              isInternal={markerFullInfo.is_internal}
              anonymous={markerFullInfo.anonymous}
              provider={markerFullInfo.provider_name}
              closedMonth={markerFullInfo.closed_month}
              streetName={markerFullInfo.street_name}
              suitableFor={markerFullInfo.suitable_for_kinds}
              secondaryKinds={markerFullInfo.secondary_kinds}
              siteKey={this.props.siteKey}
              section={this.props.section}
              favouriteLocationsIds={this.props.favouriteLocationsIds}
              comparableLocations={this.props.comparableLocations}
              updateFavouriteLocations={this.props.updateFavouriteLocations}
              updateComparableLocations={this.props.updateComparableLocations}
              countryAlpha2={this.props.countryAlpha2}
              areaIcon={markerFullInfo.presenter.area_icon}
            />
          )}
        </MarkerWrapper>
      );
    });
  }

  render() {
    const markers = this.getMarkers();

    return (
      <MarkerClusterer
        averageCenter
        enableRetinaIcons
        gridSize={60}
        minimumClusterSize={7}
      >
        <div>{ markers }</div>
      </MarkerClusterer>
    );
  }
}

export default MapWrapper;
