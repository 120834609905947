import React from 'react';

import { SvgIco } from "../../SharedComponents/Icons";

import './MarkersList.sass';


const MarkersList = ({ markers, handleClose }) => {

  const { address_line_1, postal_name, street_name } = markers[0];


  return (
    <div className="markers-list">
      <div className="markers-list__close-btn" onClick={() => handleClose()}>
        <SvgIco name="close" size={18} />
      </div>

      <div className="markers-list__top-wrapper">
        <div className="markers-list__address">
          { `${street_name || address_line_1 }, ${postal_name}` }
        </div>

        <div className="markers-list__header">
          { I18n.t('apps.lb_showroom.showroom.list.same_address_locations_headline') }:
        </div>
      </div>

      <div className="markers-list__locations-list">
        { markers.map(marker =>
          <a href={marker.href} className="markers-list__card" target="_blank" key={`${marker.id}-markers-list`}>
            <div className="markers-list__card-img" style={{ backgroundImage: `url(${marker.primary_photo_2x})` }} />
            <div className="markers-list__card-info-wrapper">

              { marker.presenter.area &&
                <div className="markers-list__card-info area">
                  <div className="markers-list__card-info-label">
                    <SvgIco name="area_custom" size={18} />
                    { marker.presenter.area_label }:
                  </div>
                  <div className="markers-list__card-info-value">
                    { marker.presenter.area }
                  </div>
                </div>
              }

              <div className="markers-list__card-info price">
                <div className="markers-list__card-info-label">
                  { marker.presenter.label }:
                </div>
                <div className="markers-list__card-info-value">
                  { marker.presenter.price }
                </div>
              </div>

              <div className="markers-list__card-read-more">
                { I18n.t('apps.lb_showroom.showroom.list.details_tooltip') }
                <SvgIco name="keyboard-arrow-right-rounded-ios" size={14} />
              </div>
            </div>
          </a>
        )}
      </div>
    </div>
  )
}

export default MarkersList;
