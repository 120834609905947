import React, { Component } from 'react';

import { apiProvider } from '../../api/core';
import { loadTawkTo } from '../TawkTo/TawkTo';
import ForgotPasswordForm from './ForgotPasswordForm';

import './ForgotPassword.sass';


class ForgotPassword extends Component {
  state = {
    submitSuccessMessage: false,
    emailServerError: false,
  };

  componentDidMount() {
    loadTawkTo(this.props.siteKey);
  };

  handleFormSubmit = (values) => {
    const { forgotPasswordUrl } = this.props;
    const bodyFormData = new FormData();

    bodyFormData.append(`login`, values.email);
    bodyFormData.append('fifth_element', values.fifth_element);

    apiProvider.post(forgotPasswordUrl, bodyFormData, true)
               .then(response => {
                 response.success && this.setState({
                   submitSuccessMessage: response.success.message || 'Success',
                   emailServerError: false,
                 });
                 response.error &&
                 this.setState({
                   emailServerError: response.data.error.message || response.statusText,
                   submitSuccessMessage: false,
                 });
               });
  };

  handleError = () => this.setState({ emailServerError: false, submitSuccessMessage: false });


  render() {

    const { submitSuccessMessage, emailServerError } = this.state;

    return (
      <div className="forgot-password">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9 col-lg-9 col-xl-8">
              <h1>{ I18n.t('views.sessions.new.login.forgotten_password') }</h1>
              <p>{ I18n.t('providers.sessions.forgot_password.instruction') }</p>
            </div>
          </div>
          <ForgotPasswordForm emailServerError={emailServerError}
                              submitSuccessMessage={submitSuccessMessage}
                              handleSubmit={this.handleFormSubmit}
                              handleError={this.handleError}
          />
        </div>
      </div>
    )
  }
}

export default ForgotPassword;
