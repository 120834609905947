import React from 'react';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap } from 'react-google-maps';

import MainMapWrapper from "./MainMapWrapper";

import './MainMap.sass';

export const mapApi = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCB-C2df0QoK2pJ1TnRGKXTOexFZDeHY3U&libraries=geometry,drawing,places';

const MainMap = compose(
    withProps(props => ({
        googleMapURL: mapApi,
        loadingElement: <div style={{ height: '100%' }} />,
        containerElement: <div className="main-map" id="main-map"/>,
        mapElement: <div style={{ height: '100%' }} />,
    })),
    withScriptjs,
    withGoogleMap,
)((props) => (
    <MainMapWrapper
      toggleMap={props.toggleMap}
      onMarkerClustererClick={props.onMarkerClustererClick}
    />
));

export default MainMap;
