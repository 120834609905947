import React, { Fragment } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import { SvgIco } from '../../SharedComponents/Icons';


const SectionRow = ({ activeKey,
                      columnWrapperClass,
                      content,
                      handleClick,
                      isOpen,
                      sectionHeadline,
                      sectionClasses,
                    }) => {

  return (
    <div className={`dv-info-section__section ${sectionClasses}`}>
      <div className="dv-info-section__section-wrapper">
        { isMobile ?
          <Accordion defaultActiveKey={activeKey}>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Card.Header} onClick={handleClick} variant="link" eventKey={activeKey}>
                  <div className="card-header-wrapper">
                    <div>
                      <h3 className="dv-info-section__header">
                        <SvgIco name="price" size={22}/>
                        <span>{ sectionHeadline }</span>
                      </h3>
                    </div>
                    <div>
                      <h3 className="dv-info-section__header">
                        { isOpen
                          ? <span className="hide-show-mobile">{ I18n.t('generic.hide') }</span>
                          : <span className="hide-show-mobile">{ I18n.t('generic.show') }</span>
                        }
                        { isOpen
                          ? <SvgIco name="expand-less" size={18} />
                          : <SvgIco name="expand-more" size={18} />
                        }
                      </h3>
                    </div>
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={activeKey}>
                <div className="dv-info-section__section-wrapper">
                  { content }
                </div>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          :
          <Fragment>
            <h3 className="dv-info-section__header">
              <SvgIco name="price" size={22}/>
              <span>{ sectionHeadline }</span>
            </h3>
            <div className={columnWrapperClass}>
              { content }
            </div>
          </Fragment>
        }
      </div>
    </div>
  )
}

export default SectionRow;
