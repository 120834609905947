import React, { useState } from 'react';
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";

import RadioButtons from "../Forms/RadioButtons";
import Checkbox from "../Forms/CheckBox";
import { SvgIco } from "../Icons";

import './SpaceCalculatorModal.sass';


const SpaceCalculatorModal = ({ isOpen, handleClose }) => {

  const [workplaces, setWorkplaces] = useState(1);
  const [workplaceClass, setWorkplaceClass] = useState('workplace_class_comfort');
  const [additionalSpaces, setAdditionalSpaces] = useState({ small_lounge: 0, big_lounge: 0, small_meeting: 0, big_meeting: 0 });
  const [spacesConsiderations, setSpacesConsiderations] = useState([]);


  const metrics = {
    workplace_class_economy: 9 * workplaces,
    workplace_class_comfort: 14 * workplaces,
    workplace_class_business: 18 * workplaces,
    small_lounge: additionalSpaces.small_lounge ? 15 * additionalSpaces.small_lounge : 15,
    big_lounge: additionalSpaces.big_lounge ? 30 * additionalSpaces.big_lounge : 30,
    small_meeting: additionalSpaces.small_meeting ? 10 * additionalSpaces.small_meeting : 10,
    big_meeting: additionalSpaces.big_meeting ? 30 * additionalSpaces.big_meeting : 30,
    conference: 4 + 2 * workplaces,
    reception: 10,
    break: 7 + 2 * workplaces,
    mail: 10,
  };


  const workplaceClassOptions = [
    {
      checked: workplaceClass === 'workplace_class_economy',
      label: I18n.t('space_calculator.workplace_class_economy'),
      value: 'workplace_class_economy'
    },
    {
      checked: workplaceClass === 'workplace_class_comfort',
      label: I18n.t('space_calculator.workplace_class_comfort'),
      value: 'workplace_class_comfort'
    },
    {
      checked: workplaceClass === 'workplace_class_business',
      label: I18n.t('space_calculator.workplace_class_business'),
      value: 'workplace_class_business'
    },
  ];


  const spaceConsiderationsOptions = ['reception', 'break', 'mail'];


  const chosenCategoriesIds = React.useMemo(
    () => {
      const ids = [workplaceClass, ...spacesConsiderations];

      Object.keys(additionalSpaces).map(spaceKind => additionalSpaces[spaceKind] && ids.push(spaceKind));

      return ids;
    },
    [additionalSpaces, spacesConsiderations, workplaceClass],
  );

  const areaSummary = React.useMemo(
    () => {
      const result = { fullArea: 0 };

      chosenCategoriesIds.map(id => metrics[id] && (result.fullArea = result.fullArea + metrics[id]));

      if (chosenCategoriesIds.includes('halls')) {
        result.fullArea = Math.round(result.fullArea + (result.fullArea / 5));
        result.halls = Math.round(result.fullArea / 5);
      }

      return result;
    },
    [chosenCategoriesIds, workplaces],
  );


  const handleSpaceConsideration = (e, name) => {
    const newData = [ ...spacesConsiderations ];
    const valueIndex = newData.indexOf(name);

    valueIndex >= 0 ? newData.splice(valueIndex, 1) : newData.push(name);
    setSpacesConsiderations(newData);
  };


  return (
    <Modal
      ariaHideApp={false}
      className="space-calculator-modal"
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customStyles}
    >
      <div className="space-calculator-modal__wrapper">

        <div className="space-calculator-modal__headline">
          { I18n.t('space_calculator.title') }
        </div>

        <div className="space-calculator-modal__subheader">
          { I18n.t('space_calculator.subtitle') }
        </div>

        <div className="space-calculator-modal__close-btn" onClick={handleClose}>
          <SvgIco name="close" size={24} />
        </div>

        <div className="row">

          <div className="col-12 col-lg-8">
            <div className="space-calculator-modal__option-box">
              <div className="space-calculator-modal__option-box-headline">
                { I18n.t('space_calculator.workplace_count_label') }
              </div>

              <div className="space-calculator-modal__option-box-body">

                <div className="space-calculator-modal__input-wrapper workplaces">
                  <div className={`space-calculator-modal__number-dropdown__body-input-control decrement ${!workplaces ? 'inactive' : 0}`}
                       onClick={() => workplaces ? setWorkplaces(Number(workplaces) - 1) : null}>
                    -
                  </div>

                  <input
                    className="space-calculator-modal__number-dropdown"
                    id="workplaces"
                    name="workplaces"
                    onChange={(e) => (/^\d+$/.test(e.target.value) || !e.target.value) && setWorkplaces(Number(e.target.value))}
                    placeholder="0"
                    type="text"
                    value={workplaces}
                  />

                  <div className="space-calculator-modal__number-dropdown__body-input-control increment" onClick={() => setWorkplaces(Number(workplaces) + 1)}>
                    +
                  </div>
                </div>

              </div>
            </div>

            <div className="space-calculator-modal__option-box">
              <div className="space-calculator-modal__option-box-headline">
                { I18n.t('space_calculator.workplace_class_label') }
              </div>

              <div className="space-calculator-modal__option-box-body radio-btns">
                <RadioButtons
                  handleChange={setWorkplaceClass}
                  options={workplaceClassOptions}
                  wrapperClass={'space-calculator-modal__radio-btns'}
                />
              </div>
            </div>

            <div className="space-calculator-modal__option-box">
              <div className="space-calculator-modal__option-box-headline">
                { I18n.t('space_calculator.additional_spaces_label') }
              </div>

              <div className="space-calculator-modal__option-box-body">

                { Object.keys(additionalSpaces).map(spaceName =>
                  <div className="space-calculator-modal__input-wrapper" key={spaceName}>
                    <div className={`space-calculator-modal__number-dropdown__body-input-control decrement ${!additionalSpaces[spaceName] ? 'inactive' : 0}`}
                         onClick={() => additionalSpaces[spaceName] ? setAdditionalSpaces(prevState => ({ ...prevState, [spaceName]: prevState[spaceName] - 1 })) : null}>
                      -
                    </div>

                    <input
                      className="space-calculator-modal__number-dropdown"
                      name="additional_workplaces"
                      onChange={(e) => {
                        e.persist();
                        (/^\d+$/.test(e.target.value) || !e.target.value) && setAdditionalSpaces(prevState => ({ ...prevState, [spaceName]: Number(e.target.value) || 0 }));
                      }}
                      placeholder="0"
                      type="text"
                      value={additionalSpaces[spaceName]}
                    />

                    <div className="space-calculator-modal__number-dropdown__body-input-control increment" onClick={() => setAdditionalSpaces(prevState => ({ ...prevState, [spaceName]: prevState[spaceName] + 1 }))}>
                      +
                    </div>

                    <div className="space-calculator-modal__number-dropdown__label">
                      { I18n.t(`space_calculator.additional_spaces_${spaceName}`) }
                    </div>

                    <span
                      className="space-calculator-modal__tooltip-wrapper"
                      role="button"
                      data-tip={I18n.t(`space_calculator.additional_spaces_${spaceName}_tooltip`)}
                      data-class="space-calculator-modal__tooltip"
                      data-for={'additional_space_tooltip' + spaceName}
                    >
                      <SvgIco name="help_outline" size={20} />
                    </span>
                    <ReactTooltip place="left" effect="solid" id={'additional_space_tooltip' + spaceName} />
                  </div>
                )}

              </div>
            </div>

            <div className="space-calculator-modal__option-box">
              <div className="space-calculator-modal__option-box-headline">
                { I18n.t('space_calculator.space_consideration_label') }
              </div>

              <div className="space-calculator-modal__option-box-body">
                { spaceConsiderationsOptions.map(spaceType =>
                  <div className="space-calculator-modal__option-row" key={spaceType}>
                    <div className="space-calculator-modal__option-checkbox-wrapper">
                      <Checkbox
                        handleChange={handleSpaceConsideration}
                        name={spaceType}
                        label={I18n.t(`space_calculator.space_consideration_${spaceType}`)}
                        value={spacesConsiderations.includes(spaceType)}
                      />

                      <span
                        className="space-calculator-modal__tooltip-wrapper"
                        role="button"
                        data-tip={I18n.t(`space_calculator.space_consideration_${spaceType}_tooltip`)}
                        data-class="space-calculator-modal__tooltip"
                        data-for={'space_consideration' + spaceType}>
                        <SvgIco name="help_outline" size={20} />
                      </span>
                      <ReactTooltip place="bottom" effect="solid" id={'space_consideration' + spaceType} />
                    </div>
                  </div>
                )}
              </div>
            </div>

          </div>

          <div className="col-12 col-lg-4">
            <div className="space-calculator-modal__summary-wrapper">

              <div className="space-calculator-modal__summary-title">
                { I18n.t('space_calculator.summary') }:
              </div>

              { !!areaSummary.fullArea &&
                <div className="space-calculator-modal__summary-list">
                  { workplaces &&
                    <div className="space-calculator-modal__summary-list-item">
                      <div className="space-calculator-modal__summary-list-item-left">
                        <div className="space-calculator-modal__summary-list-item-count">
                          { workplaces }
                        </div>
                        <div className="space-calculator-modal__summary-list-item-multiplier">
                          x
                        </div>
                        <div className="space-calculator-modal__summary-list-item-label">
                          <div>{ I18n.t('space_calculator.workplaces') }</div>
                          <div>&nbsp;{ I18n.t(`space_calculator.${workplaceClass}`) }</div>
                        </div>
                      </div>
                      <div className="space-calculator-modal__summary-list-item-area">
                        { metrics[workplaceClass] } m²
                      </div>
                    </div>
                  }

                  { Object.keys(additionalSpaces).map(spaceType => {
                    if (additionalSpaces[spaceType]) {
                      return (
                        <div className="space-calculator-modal__summary-list-item" key={`summary-${spaceType}`}>
                          <div className="space-calculator-modal__summary-list-item-left">
                            <div className="space-calculator-modal__summary-list-item-count">
                              { additionalSpaces[spaceType] }
                            </div>
                            <div className="space-calculator-modal__summary-list-item-multiplier">
                              x
                            </div>
                            <div className="space-calculator-modal__summary-list-item-label">
                              { I18n.t(`space_calculator.additional_spaces_${spaceType}`) }
                            </div>
                          </div>

                          <div className="space-calculator-modal__summary-list-item-area">
                            { metrics[spaceType] } m²
                          </div>
                        </div>
                      )
                    }
                  })}

                  { spacesConsiderations.map(spaceType =>
                    <div className="space-calculator-modal__summary-list-item" key={`summary-${spaceType}`}>
                      <div className="space-calculator-modal__summary-list-item-left">
                        <div className="space-calculator-modal__summary-list-item-count">
                          1
                        </div>
                        <div className="space-calculator-modal__summary-list-item-multiplier">
                          x
                        </div>
                        <div className="space-calculator-modal__summary-list-item-label">
                          { I18n.t(`space_calculator.space_consideration_${spaceType}`) }
                        </div>
                      </div>

                      <div className="space-calculator-modal__summary-list-item-area">
                        { spaceType === 'halls'  ? areaSummary.halls || 0 : metrics[spaceType] } m²
                      </div>
                    </div>
                  )}
                </div>
              }

              <div className="space-calculator-modal__summary-total">
                <div>{ I18n.t('space_calculator.space_total_area') }</div>
                <div>{ areaSummary.fullArea } m²</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Modal>
  )
}

export default SpaceCalculatorModal;




const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    top: '0',
    overflow: 'auto',
    zIndex: '9999',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'visible',
    borderRadius: '8px',
    position: 'absolute',
  },
};
