import React from 'react';

const FacilitiesListItem = ({ item }) => {

  return (
    <div className="facts-and-facilities__facility-block-wrapper row">
      <p className="facts-and-facilities__facilities-type-headline col-xl-12 col-lg-12 col-md-12">
        { item.groupName }
      </p>
      { item.items.map(facility =>
        <div className="facts-and-facilities__list-item col-xl-3 col-lg-4 col-md-4 col-6" key={facility.key}>
          <div className="facts-and-facilities__list-item-icon lazyload"
               data-bg={facility.iconSvg || require(`../../../src/images/icons/facts_and_facilities/default.svg`)}
               data-sizes="auto"
          />
          <span>{ facility.name }</span>
        </div>
      )}
    </div>
  )
};


export default React.memo(FacilitiesListItem);
