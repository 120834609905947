import React from 'react';

import UnavailableLocationIcon from '../../../src/images/icons/unavaillable-location-icon.svg';
import SimilarLocations from '../../SimilarLocations';

import './index.sass';


const InformationUnavailable = ({ similarLocationsList, similarLocationsHeadline, headline, content, siteKey, section }) =>
  <div className="information-unavailable">
    <div className="container">
      <div className="information-unavailable__wrapper row">
        <div className="information-unavailable__img-block col-md-3 col-lg-3 col-xl-2">
          <img src={ UnavailableLocationIcon }
               alt="Location"
               className="information-unavailable__img lazyload"
               data-sizes="auto"/>
        </div>

        <div className="information-unavailable__content col-12 col-md-8 col-lg-7 col-xl-6 offset-xl-1">
          <h2 className="information-unavailable__title">
            { headline }
          </h2>
          <p className="information-unavailable__subtitle">
            { content }
          </p>
          <div className="information-unavailable__img-block information-unavailable__img-block--mobile col-6 offset-3">
            <img src={ UnavailableLocationIcon }
                 alt="Location"
                 className="information-unavailable__img lazyload"
                 data-sizes="auto"/>
          </div>
        </div>
      </div>
    </div>

    { similarLocationsList && similarLocationsList.length > 0 &&
      <div className="info-sections__block-wrapper">
        <SimilarLocations
          similarLocationsList={ similarLocationsList }
          similarLocationsHeadline={ similarLocationsHeadline }
          siteKey={siteKey}
          section={section}
        />
      </div>
    }
  </div>;


export default InformationUnavailable;
