import React, { Component } from "react";
import PropTypes from "prop-types";
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import { apiProvider } from '../../api/core';
import { loadTawkTo } from '../TawkTo/TawkTo';
import RequestCallModal from "../SharedComponents/RequestCallModal";
import Modal from "./Modals";
import SignUpForm from "./SignUpForm";
import ContinueRegistrationForm from "./Modals/ContinueRegistrationModal";
import MeetingRoomsInfoContent from "./Modals/MeetingRoomsInfoContent";
import TermsOfServiceContent from "./Modals/TermsOfServiceContent";
import BasicPriceContent from "./Modals/BasicPriceContent";
import NoCureInfoContent from "./Modals/NoCureInfoContent";
import BasicParkeringContent from './Modals/BasicParkeringContent';

import './index.sass';


class SignUp extends Component {

  state = {
    activeContractId: '',
    contractName: '',
    activeContractSection: '',
    emailAlreadyExist: false,
    internalErrorFirstStep: false,
    internalErrorSecondStep: false,
    registrationModalIsOpen: false,
    activeModal: {
      basicPriceInfo: false,
      noCureInfo: false,
      coworkingInfo: false,
      termOfService: false,
      basicParking: false,
    },
  };

  componentDidMount() {
    const { activeContract } = this.props;
    this.setState({
      activeContractId: activeContract && activeContract.contract.id,
      uuid: uuidv4(),
    });
    loadTawkTo(this.props.siteKey);
  }

  handleEmailAlreadyExistError = () => this.setState({ emailAlreadyExist: '' });

  preSubmitHandler = (params) => {

    const { company_name, email, name, phone_number } = params;

    delete params.fifth_element;

    const providerData = {
      provider:{
        company_name,
        name,
        email,
        phone_number,
        token: this.state.uuid,
      }
    };

    apiProvider.withOptions({ url: "/signup_new_first_step", method: 'post', data: providerData }, true)
      .then(response => {
        if (response && response.status === 'created') {
          this.setState({ registrationModalIsOpen: true, internalErrorFirstStep: false });
        } else if (response.error) {
          response.status >= 500
            ? this.setState({ internalErrorSecondStep: true })
            : this.setState({ emailAlreadyExist: params.email, internalErrorSecondStep: false });
        }
      });
  };

  submitHandler = (params, signUpFormUrl) => {
    let activeContractId = this.state.activeContractId;
    const strategy = activeContractId && this.props.contracts.filter(elem => elem.contract.id === activeContractId)[0].contract.invoicing_strategy;
    const { line_1, postal_code, city, country_alpha2, password } = params;

    queryString.parse(window.location.search).contract_name === 'parking' ? activeContractId = 9 : null;
    delete params.fifth_element;

    const providerData = {
      provider: {
        ...params,
        strategy,
        terms_of_service: '1',
        contract_id: activeContractId,
        password_confirmation: params.password,
        primary_contact_attributes: { password, password_confirmation: params.password },
        invoicing_policy_attributes: { contract_id: activeContractId, strategy },
        address_attributes: { country_alpha2, line_1, postal_code, city },
        token: this.state.uuid,
      },
    };

    apiProvider.withOptions({ url: signUpFormUrl, method: 'post', withCredentials: true, data: providerData }, true)
       .then(response => {
         // change path to welcome page
         // if (response.status === 'created') return window.location.href = '/new_providers/welcome';
         if (response.status === 'created') return window.location.href = response.redirect_path;

         if (response.error) {
           response.status >= 500
           ? this.setState({ internalErrorSecondStep: true })
           : this.setState({ emailAlreadyExist: params.email, internalErrorSecondStep: false });
         }
       });
  };

  toggleModal = (modal) => {
    this.setState({
      activeModal: {
        ...this.state.activeModal,
        [modal]: !this.state.activeModal[modal],
      }
    })
  };

  setActiveContractValues = (id, contractName, activeSection) => {
    this.setState({
      activeContractId: id,
      contractName: contractName,
      activeContractSection: activeSection,
    });
  };

  closeRegistrationModal = () =>
    this.setState({ registrationModalIsOpen: false });


  render() {

    const {
      activeContract,
      advertisementStrategiesPrices,
      contractDetailsOnDeal,
      contractDetailsOnDealBasic,
      contractDetailsOnDealPremium,
      contactInfoEmail,
      contactInfoPhone,
      contracts,
      countriesSelectOptions,
      currentCurrency,
      geocoderCountryCode,
      hashedContracts,
      siteKey,
      signUpFormUrl,
      userCountryPhoneCode,
    } = this.props;

    const { basicPriceInfo, noCureInfo, coworkingInfo, termOfService, basicParking } = this.state.activeModal;
    const activeContractData = this.state.activeContractId && contracts.filter(elem => elem.contract.id === this.state.activeContractId)[0].contract;
    const { activeContractSection, contractName, emailAlreadyExist, internalErrorFirstStep, internalErrorSecondStep, registrationModalIsOpen } = this.state;
    const advertisementStrategiesPricesPresent = advertisementStrategiesPrices && advertisementStrategiesPrices.prices;
    const parkingPrice =
      ( advertisementStrategiesPricesPresent &&
        advertisementStrategiesPrices.prices.parking &&
        advertisementStrategiesPrices.prices.parking.default)
      || 0;
    const meetingRoomPrice =
      ( advertisementStrategiesPricesPresent &&
        advertisementStrategiesPrices.prices.meetingRoom &&
        advertisementStrategiesPrices.prices.meetingRoom.default)
      || 0;
    const meetingRoomPremiumPrice =
      ( advertisementStrategiesPricesPresent &&
        advertisementStrategiesPrices.prices.meetingRoomPremium &&
        advertisementStrategiesPrices.prices.meetingRoomPremium.default)
      || 0;


    return (
      <div className="sign-up">
        <div className="container">

          <SignUpForm
            activeContract={activeContract}
            activeContractSection={activeContractSection}
            contactInfoEmail={contactInfoEmail}
            contactInfoPhone={contactInfoPhone}
            contractName={contractName}
            currentCurrency={currentCurrency}
            emailAlreadyExist={emailAlreadyExist}
            handleEmailAlreadyExistError={this.handleEmailAlreadyExistError}
            hashedContracts={hashedContracts}
            internalError={internalErrorFirstStep}
            preSubmitHandler={this.preSubmitHandler}
            setActiveContractValues={this.setActiveContractValues}
            siteKey={siteKey}
            toggleModal={this.toggleModal}
            userCountryPhoneCode={userCountryPhoneCode}
            advertisementStrategiesPrices={advertisementStrategiesPrices}
          />
        </div>

        { registrationModalIsOpen &&
          <ContinueRegistrationForm
            activeContract={activeContract}
            activeContractSection={activeContractSection}
            contractName={contractName}
            countriesSelectOptions={countriesSelectOptions}
            currentCountryCode={siteKey === 'global' ? geocoderCountryCode : siteKey}
            handleClose={this.closeRegistrationModal}
            internalError={internalErrorSecondStep}
            isOpen={registrationModalIsOpen}
            signUpFormUrl={signUpFormUrl}
            siteKey={siteKey}
            submitHandler={this.submitHandler}
            toggleTermsModal={() => this.toggleModal('termOfService')}
            userCountryPhoneCode={this.userCountryPhoneCode}
          />
        }

        { basicPriceInfo &&
          <Modal
            toggleModal={this.toggleModal}
            modalId='basicPriceInfo'
            content={<BasicPriceContent advertisementStrategiesPrices={advertisementStrategiesPrices} />}
          />
        }

        { noCureInfo &&
          <Modal
            toggleModal={this.toggleModal}
            modalId='noCureInfo'
            content={
              <NoCureInfoContent
                contractDetailsOnDeal={contractDetailsOnDeal}
                contractDetailsOnDealBasic={contractDetailsOnDealBasic}
                contractDetailsOnDealPremium={contractDetailsOnDealPremium}
                siteKey={siteKey}
              />
            }
          />
        }

        { coworkingInfo &&
          <Modal
            toggleModal={this.toggleModal}
            modalId='coworkingInfo'
            content={
              <MeetingRoomsInfoContent
                basicPrice={meetingRoomPrice}
                currentCurrency={currentCurrency}
                premiumPrice={meetingRoomPremiumPrice}
                siteKey={siteKey}
              />
            }
          />
        }

        { basicParking &&
          <Modal
            toggleModal={this.toggleModal}
            modalId='basicParking'
            content={
              <BasicParkeringContent price={parkingPrice} />
            }
          />
        }

        { termOfService &&
          <Modal
            toggleModal={this.toggleModal}
            modalId='termOfService'
            content={ <TermsOfServiceContent activeContract={activeContractData} /> }
          />
        }

        <RequestCallModal hideModal={registrationModalIsOpen} />

      </div>
    )
  }
}

export default SignUp;

SignUp.propTypes = {
  activeContract: PropTypes.shape({
    contract: PropTypes.object,
  }),
  siteKey: PropTypes.string,
  contracts: PropTypes.arrayOf(PropTypes.object),
  contractCoworkingYearly: PropTypes.shape({
    table: PropTypes.object,
  }),
  contractCoworkingYearlyPremium: PropTypes.shape({
    table: PropTypes.object,
  }),
  contractDetailsOnDeal: PropTypes.shape({
    table: PropTypes.object,
  }),
  contractDetailsOnDealBasic: PropTypes.shape({
    table: PropTypes.object,
  }),
  contractDetailsOnDealPremium: PropTypes.shape({
    table: PropTypes.object,
  }),
  countriesSelectOptions: PropTypes.array,
  hashedContracts: PropTypes.object,
  geocoderCountryCode: PropTypes.string,
  signUpFormUrl: PropTypes.string,
};


SignUpForm.defaultProps = {
  activeContract: {
    contract: {},
  },
  siteKey: '',
  contracts: [],
  contractCoworkingYearly: {
    table: {},
  },
  contractCoworkingYearlyPremium: {
    table: {},
  },
  contractDetailsOnDeal: {
    table: {},
  },
  contractDetailsOnDealBasic: {
    table: {},
  },
  contractDetailsOnDealPremium: {
    table: {},
  },
  countriesSelectOptions: [],
  hashedContracts: {},
  geocoderCountryCode: '',
  signUpFormUrl: PropTypes.string,
};
