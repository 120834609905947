import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { CrawledBadge, RentedOutBadge, AnonymousBadge } from "../SharedComponents/Badges";
import { mappedMonths } from '../helpers/danishMonths';
import FavouriteIcon from '../SharedComponents/FavouriteIcon';
import CompareIcon from "../SharedComponents/CompareIcon";
import Tooltip from '../SharedComponents/Tooltip';
import { SvgIco } from "../SharedComponents/Icons";

import './MarkerInfoWindow.sass';


class MarkerContent extends Component {

  state ={
    showToolTip: false
  };

  showTooltip = () => {
    this.setState({showToolTip: true})
  };

  hideTooltip = () => {
    this.setState({showToolTip: false})
  };

  render() {
    const {
      address,
      areaIcon,
      anonymous,
      area,
      areaLabel,
      closedMonth,
      comparableLocations,
      favouriteLocationsIds,
      id,
      imageUrl,
      isInternal,
      kind,
      link,
      onInfoToggle,
      postalCode,
      postalName,
      price,
      priceLabel,
      rentedOut,
      secondaryKinds,
      section,
      showFullAddress,
      siteKey,
      streetName,
      suitableFor,
      updateFavouriteLocations,
      updateComparableLocations,
    } = this.props;

    const parsedLocImageStyle = { backgroundImage: `url(${imageUrl})` };
    const imageStyle = {
      backgroundImage: `url(${imageUrl})`,
      filter: rentedOut ? 'grayscale(100%)' : 'none',
    };

    const closedMonthName = mappedMonths(closedMonth);
    const suitableForList = suitableFor.length ? (suitableFor.length > 1 ? suitableFor.slice(0, 1) : suitableFor) : null;
    const translated = suitableFor.map(elem => I18n.t(`lb_showroom.showroom.suitable.for.mapview.tooltip.${elem}`));
    const updatedTranslation = translated.join(', ');


    return (
      <div className="marker-info-window">
        <div>
          <button className="marker-info-window__close-btn" onClick={() => onInfoToggle(id)}>
            <SvgIco name="close" size={24} />
          </button>

          { section === 'lease' &&
            <CompareIcon
              comparableLocations={comparableLocations}
              kind={kind}
              locationId={id}
              siteKey={siteKey}
              tooltipPosition={'bottom'}
              updateComparableLocations={updateComparableLocations} />
          }

          <FavouriteIcon
            favoriteLocations={favouriteLocationsIds}
            locationId={id}
            section={section}
            siteKey={siteKey}
            tooltipPosition={'bottom'}
            updateFavouriteLocations={updateFavouriteLocations} />

          <a href={link} target="_blank" className="marker-info-window__header">
            {isInternal ? (
              <div className='parsed-location-placeholder' style={parsedLocImageStyle}>
                {isInternal && <CrawledBadge root='map'/>}
              </div>
            ) : (
              <div className="marker-info-window__photo-block" style={imageStyle}>
                { rentedOut &&
                  <RentedOutBadge
                    root='grid'
                    month={siteKey === 'dk' ? closedMonthName : null}
                    sale={section !== 'lease'} />
                }
                {anonymous && <AnonymousBadge root='grid'/>}
              </div>
            )}
          </a>
          <a href={link} target="_blank" className="marker-info-window__description-wrapper">
            <div className="marker-info-window__kinds-suitable-for-wrapper">
              <div className="marker-info-window__kinds">
                <span className="marker-info-window__kind">{I18n.t(`generic.${kind}`)} </span>
                { secondaryKinds.length !== 0 &&
                  <div className="secondary-kinds">+ {secondaryKinds.length}</div>
                }
              </div>
              <div className="marker-info-window__suitableFor">
                { suitableForList && suitableForList.map((elem, index) =>
                  <div
                    onMouseOver={this.showTooltip}
                    onMouseLeave={this.hideTooltip}
                    className='suitable-for__item' key={index}>
                    {/*<img className="suitable-for__icon"*/}
                    {/*     src={require(`../../src/images/icons/suitable_for/${elem}.svg`)}*/}
                    {/*     alt={elem}*/}
                    {/*     height={22}*/}
                    {/*     width={22}*/}
                    {/*/>*/}
                    { index === suitableForList.length - 1 && suitableFor.length > 1 &&
                    <div className="suitable-for__more">+{suitableFor.length - 1}</div>}

                  </div>
                )}
                <div className={`tooltip-wrapper ${this.state.showToolTip ? 'tooltip-wrapper-active' : ''}`}>
                  <Tooltip tooltipContent={I18n.t(`generic.suitable_for`)} tooltipSuitableForOnly={updatedTranslation}/>
                </div>
              </div>
            </div>

            <div className="marker-info-window__address">
              <p className="marker-info-window__address-line">{ `${streetName || address }, ${ showFullAddress ? postalCode + ' ' : '' }${postalName}` }</p>
            </div>

            <div className="marker-info-window__marker-info">
              <div className="marker-info-window__info-line">
                {area && (
                  <Fragment>
                    <span><img src={require(`../../src/images/icons/${areaIcon || 'area'}.svg`)} alt='area label' className="lazyload"
                               data-sizes="auto"/>{areaLabel}</span>
                    <span>{area}</span>
                  </Fragment>
                )}
              </div>
              <div className="marker-info-window__info-line">
                <span>{priceLabel}</span>
                <span>{price}</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  }
}

MarkerContent.propTypes = {
  address: PropTypes.string,
  area: PropTypes.string,
  areaLabel: PropTypes.string,
  features: PropTypes.object,
  id: PropTypes.number,
  imageUrl: PropTypes.string,
  kind: PropTypes.string,
  link: PropTypes.string,
  postalCode: PropTypes.string,
  postalName: PropTypes.string,
  price: PropTypes.string,
  priceLabel: PropTypes.string,
  rentedOut: PropTypes.bool,
  uuid: PropTypes.string,
  countryAlpha2: PropTypes.string,
  showFullAddress: PropTypes.bool,
};

MarkerContent.defaultProps = {
  address: '',
  area: '',
  areaLabel: '',
  features: {},
  id: null,
  imageUrl: '',
  kind: '',
  link: '',
  postalCode: '',
  postalName: '',
  price: '',
  priceLabel: '',
  rentedOut: false,
  uuid: '',
  countryAlpha2: '',
  suitableFor: [],
  secondaryKinds: [],
  showFullAddress: false,
};

export default React.memo(MarkerContent);
