import React from 'react';
import SslSecureLogo from '../../src/images/logos/ssl-secure-encryption.svg'

const FooterBottom = ({ contacts, footerBottom }) => {

  const { addressLine, postalCodeCity, email, phone, showCvr, cvr } = contacts;
  const { copyrightUrl, logoUrl, root, siteLinks, socialLinks, sslLink, supportUrl } = footerBottom;

  return (
    <div className="global-footer__bottom">
      <div className="row">
        <div className="col-md-12">
          <a href={root} className="global-footer__bottom-logo">
            <img src={logoUrl} alt="Logo" className="lazyload" id="logo-image" data-sizes="auto" width={150}/>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="global-footer__bottom-wrapper">

          <div className="global-footer__contacts-wrapper">
            <div className="global-footer__contacts">
              <p>{ addressLine }</p>
              <p>{ postalCodeCity }</p>
              <a href={"mailTo:" + email} >{ email }</a>
              <br />
              <a href={"tel://" + phone}>{ phone }</a>
              { showCvr && <p>{cvr}</p> }
            </div>
          </div>

          <div className="global-footer__bottom-sites">
            { siteLinks.map(link =>
              <div>
                <a href={link.footer_site_link.url}
                   className="global-footer__country-site-link"
                   key={link.footer_site_link.id}
                   target="_blank"
                >
                  {link.footer_site_link.name}
                </a>
              </div>
            )}
          </div>

          <div className="global-footer__social-links">
            <div className="global-footer__ssl-wrapper">
              <a href={sslLink}>
                <img src={SslSecureLogo} alt="SSL secured" className="lazyload global-footer__ssl-img" data-sizes="auto" width={130}/>
              </a>
            </div>
            <div className="global-footer__social-buttons-wrapper">
              <ul className="global-footer__social-buttons-list">
                { socialLinks.map(link =>
                  <li className="global-footer__social-link" key={link[0]}>
                    <a href={link[1]} className={`global-footer__social-link--${link[0]}`}/>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div className="global-footer__copyright-block">
            <div className="global-footer__copyright-wrapper">
              <div className="global-footer__copyright--help">
                <a href={supportUrl}>{ I18n.t('layouts.responsive.bottom.help_link') }</a>
              </div>
              <div className="global-footer__copyright--copyright">
                <a href={copyrightUrl} className="global-footer__copyright-link">
                  Copyright { new Date().getFullYear() }
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default FooterBottom;
