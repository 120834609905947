import React from 'react';

import './index.sass';


const InfoBadges = ({ anonymous,
                      closedMonth,
                      crawledBottomText,
                      hideCrawledBottom,
                      isCrawled,
                      isRentedOut,
                      isNew,
                      missingImagesUrl,
                      rentedOutLabel,
                      sale,
                      specialOffer,
                      wrapperClass
                    }) => {
  return (
    <div className="info-badges">
      { anonymous && <AnonymousBadge wrapperClass={wrapperClass} /> }
      { isCrawled && <CrawledBadge bottomText={crawledBottomText} hideBottomLine={hideCrawledBottom} missingImagesUrl={missingImagesUrl} wrapperClass={wrapperClass} /> }
      { isRentedOut && <RentedOutBadge label={rentedOutLabel} month={closedMonth} sale={sale} wrapperClass={wrapperClass} /> }
      { (isNew || specialOffer) &&
        <div className="info-badges__new-special-wrapper">
          { isNew && <NewBadge wrapperClass={wrapperClass} /> }
          { specialOffer && <SpecialOfferBadge wrapperClass={wrapperClass} /> }
        </div>
      }
    </div>
  )
};

export default InfoBadges;


export const SpecialOfferBadge = ({ wrapperClass }) =>
  <div className={`special-offer-badge ${wrapperClass || ''}`}>
    <span>{ I18n.t('generic.special_offer') }</span>
  </div>;


export const NewBadge = ({ wrapperClass }) =>
  <div className={`new-badge ${wrapperClass || ''}`}>
    <span>{ I18n.t("apps.lb_showroom.showroom.list.new_item_label") }</span>
  </div>;


export const RentedOutBadge = ({ label, month, sale, wrapperClass }) =>
  <div className={`rented-out-badge ${wrapperClass || ''}`}>
    { label ||
      ( sale
          ? I18n.t('activerecord.attributes.location.states.sold_out')
          : `${ I18n.t('activerecord.attributes.location.states.rented_out', {count: month ? 1 : 0}) } ${ month || '' }`
      )
    }
  </div>;


export const AnonymousBadge = ({ wrapperClass }) =>
  <div className={`anonymous-badge ${wrapperClass || ''}`}>
    { I18n.t('generic.anonymous_location.detailed.label') }
  </div>;


export const CrawledBadge = ({ bottomText, hideBottomLine, missingImagesUrl, wrapperClass }) =>
  <div className={`crawled-badge ${wrapperClass || ''}`}>
    <div className="crawled-badge__top-line">
      { I18n.t('generic.parsed_location') }
    </div>

    { !hideBottomLine &&
      <div className="crawled-badge__bottom-line">
        { bottomText || I18n.t('generic.photos_are_not_available') }&nbsp;
        { missingImagesUrl &&
          <span>
          <a href={missingImagesUrl} target="_blank">
          { I18n.t('generic.read_more_about_scraped') }
          </a>
        </span>
        }
      </div>
    }
  </div>;
