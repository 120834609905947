import React from 'react';

import './TestimonialBlock.sass';


const TestimonialBlock = ({ additionalClass, testimonial }) => {

  const { authorImg, authorInfo, authorName, companyLogo, content } = testimonial;

  return (
    <div className={ `testimonial${additionalClass ? ` ${additionalClass}` : ''}` }>
      <div className="testimonial__wrapper">
        <img
          alt={companyLogo.alt}
          className="lazyload testimonial__logo"
          data-expand="100"
          data-sizes="auto"
          data-src={require(`../../../src/images/list_office/${companyLogo.name}`)}
          height={companyLogo.height}
          width={companyLogo.width}
        />

        <div className="testimonial__author-info">
          <img
            alt={authorName}
            className="testimonial__img lazyload"
            data-expand="100"
            data-sizes="auto"
            data-src={require(`../../../src/images/list_office/${authorImg}`)}
            height={56}
            width={56}
          />
          <div className="testimonial__author-info-wrapper">
            <div className="testimonial__author-name">
              { authorName }
            </div>
            <p>{ authorInfo }</p>
          </div>
        </div>

        <p className="testimonial__content">{ content }</p>

      </div>
    </div>
  )
};

export default TestimonialBlock;
