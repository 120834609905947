import React, { useState, Fragment } from 'react';

import { SvgIco } from "../SharedComponents/Icons";
import Table from "./Table";
import { EconomyColumns, FacilitiesColumns, MainInfoColumns } from "./ComparisonColumns";

import './ComparisonTable.sass';


const ComparisonTable = ({ handleSelect, locations, orderedLocations, selectedLocationsIds, siteKey }) => {

  const [economyIsOpen, setEconomyIsOpen] = useState(true);
  const [facilitiesIsOpen, setFacilitiesIsOpen] = useState(true);

  const data = locations.map(location => ({ ...location.data, id: location.location_id }));
  const showFacilities = data.some(location => Object.keys(location.facilities).length);

  const mainInfoColumns = MainInfoColumns(data, siteKey, selectedLocationsIds, handleSelect, orderedLocations);
  const economyColumns = EconomyColumns();
  const facilitiesColumns = FacilitiesColumns();


  return (
    <div className="comparison-table">
      <div className="comparison-table__container container">
        <Table
          columns={mainInfoColumns}
          data={data}
          name="mainInfo" />

        <div className="comparison-table__section-trigger"
             onClick={() => setEconomyIsOpen(!economyIsOpen)}
             style={{ marginBottom: economyIsOpen ? '4px' : '0' }}>
          { I18n.t('providers.locations.form.economy') }
          { economyIsOpen
            ? <SvgIco name="expand-less" size={16} />
            : <SvgIco name="expand-more" size={16} />
          }
        </div>

        <div className={`comparison-table__dynamic-section-wrapper ${economyIsOpen ? 'open' : 'close'}`}>
          <Table
            columns={economyColumns}
            data={data}
            name="economy" />
        </div>

        { showFacilities &&
          <Fragment>
            <div className="comparison-table__section-trigger"
                 onClick={() => setFacilitiesIsOpen(!facilitiesIsOpen)}
                 style={{ marginBottom: facilitiesIsOpen ? '4px' : '0' }}>
              { I18n.t('apps.lb_showroom.detailed.tabs.facilities') }
              { facilitiesIsOpen
                ? <SvgIco name="expand-less" size={16} />
                : <SvgIco name="expand-more" size={16} />
              }
            </div>

            <div className={`comparison-table__dynamic-section-wrapper ${facilitiesIsOpen ? 'open' : 'close'}`}>
              <Table
                alignColumns="flex-start"
                columns={facilitiesColumns}
                data={data}
                name="facilities" />
            </div>
          </Fragment>
        }
      </div>
    </div>
  )
}

export default ComparisonTable;
