import React from 'react';
import classNames from 'classnames';
import Yup from 'yup';
import { withFormik } from 'formik';
import Modal from 'react-modal';

import Client from '../../../utils/Client';
import alertErrorIcon from '../../../src/images/icons/alert.svg';
import { validationPatterns } from '../../../utils/validationPatterns';
import { SvgIco } from '../../SharedComponents/Icons';

import './GetContactForm.sass';


const customStylesMobile = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: '9990',
    padding: '0'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    pointerEvents: 'visible',
    height: '100%',
    border: 'none',
    borderRadius: '0px'
  },
};


const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    overflow: 'auto',
    zIndex: '9990'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    pointerEvents: 'visible',
    background: 'white',
    borderRadius: '4px',
    position: 'absolute',
  },
};


const GetContactFormWrapper = ({ errors,
                                 handleBlur,
                                 handleChange,
                                 handleClose,
                                 handleSubmit,
                                 openContactModal,
                                 privacyPolicyPath,
                                 setFieldValue,
                                 showServerErrorMessage,
                                 submitSuccess,
                                 values,
                                 touched,
                               }) => {

  const mobileOrTablet = window.innerWidth <= 1024;

  const inputClassBuilder = (fieldName) =>
    classNames({
      'error': touched[fieldName] && errors[fieldName],
      'filled': values[fieldName],
    });

  return (
    <Modal
      isOpen={openContactModal}
      style={mobileOrTablet ? customStylesMobile : customStyles}
      ariaHideApp={false}
      onRequestClose={handleClose}
      closeTimeoutMS={500}
    >
      <div className="get-contact" id="get-contact-form">
        { mobileOrTablet
          ?
            <div className="get-contact__title-section">
              <div className='modal-close' onClick={handleClose}>
                <SvgIco name="arrow-back" size={24}/>
              </div>

              <div className="get-contact__title">
                <p>{ I18n.t('signup.teaser.tabs.tab.block.contact_us.link') }</p>
              </div>
              <div className='modal-close' onClick={handleClose}>
                <SvgIco name="close" size={24}/>
              </div>
            </div>
          :
            <div className="get-contact__header">
              <div>
                <div className={`server-error-message ${!showServerErrorMessage ? 'not-showing' : ''}`}>
                  <img
                    alt="alertErrorIcon"
                    className="lazyload"
                    data-sizes="auto"
                    data-src={alertErrorIcon}
                  />
                  <p>
                    { I18n.t('generic.server-error-listview-page') }&nbsp;<a href={window.location.href}>{ I18n.t('general.refresh-page') }</a>
                  </p>
                </div>
              </div>
              <h2>{ I18n.t('signup.teaser.modal.form.get_in_touch') }</h2>
            </div>
          }

        <SvgIco
          className="get-contact__close"
          name="close"
          onClick={handleClose}
          size={22}
        />

        <div className="get-contact__body">
          { mobileOrTablet &&
            <>
              <div>
                <div className={`server-error-message ${!showServerErrorMessage ? 'not-showing' : ''}`}>
                  <img
                    alt="alertErrorIcon"
                    className="lazyload"
                    data-sizes="auto"
                    data-src={alertErrorIcon}
                  />
                  <p>
                    { I18n.t('generic.server-error-listview-page')}&nbsp;<a href={window.location.href}>{ I18n.t('general.refresh-page') }</a>
                  </p>
                </div>
              </div>
              <h2 className="get-contact__mobile-header">
                { I18n.t('signup.teaser.modal.form.get_in_touch') }
              </h2>
            </>
          }

          <p className="get-contact__description">
            { I18n.t('signup.teaser.modal.form.send_us') }
          </p>

          <form
            name="getContactModalForm"
            className="get-contact__form"
            onSubmit={handleSubmit}
            noValidate>

            <input
              className="invisible"
              id="fifth_element"
              name="fifth_element"
              onPaste={handleChange}
              onChange={handleChange}
              type="text"
              value={values.fifth_element}
            />

            <div>
              <div className="form-group">
                <input
                  className={inputClassBuilder('name')}
                  id="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  value={values.name}
                />

                <span className="floating-label">
                  { I18n.t('generic.name') }*
                </span>

                <div className="error-tooltip">
                  { errors.name }
                </div>
              </div>

              <div className="form-group">
                <input
                  className={inputClassBuilder('email')}
                  id="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="email"
                  value={values.email}
                />

                <span className={`floating-label ${values.email && 'active'}`}>
                  { I18n.t('generic.email') }*
                </span>

                { touched.email && errors.email && <div className="error-tooltip">{ errors.email }</div> }
              </div>

              <div className="form-group">
                <input
                  className={inputClassBuilder('phone')}
                  id="phone"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={(e) => { setFieldValue('phone', e.target.value.trimStart().replace(/\s\s+/g, ' ')) }}
                  type="tel"
                  value={values.phone}
                />

                <span className={`floating-label ${values.phone && 'active'}`}>
                  { I18n.t('generic.phone') }*
                </span>

                { touched.phone && errors.phone && <div className="error-tooltip">{ errors.phone }</div> }
              </div>
            </div>

            <div>
              <div className="form-group">
                <input
                  className={inputClassBuilder('address')}
                  id="address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.address}
                />

                <span className="floating-label">
                  { I18n.t('signup.teaser.modal.form.placeholder_address') }
                </span>
              </div>

              <div className="form-group">
                <textarea
                  className={values.comment ? 'filled' : ''}
                  id="comment"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  resize="none"
                  type="text"
                  value={values.comment}
                />

                <span className="floating-label">
                  { I18n.t('signup.teaser.modal.form.other_comments')}
                </span>
              </div>

              <div className="form-submit-section">
                <div className="order-button">
                  <button
                    className={`form-set-info-submit b-button ${ (!submitSuccess) && 'loading'} ${(submitSuccess) && 'sent-info'}`}
                    type="submit">
                    { submitSuccess
                      ? I18n.t('apps.lb_showroom.detailed.order_form.information_was_sent')
                      : I18n.t('signup.teaser.modal.form.contact_me')
                    }
                  </button>
                </div>

                <p>{ I18n.t('apps.lb_showroom.detailed.order_form.modal.policy_link_preline') }&nbsp;
                  <a href={privacyPolicyPath} className="private-policy-link">
                    { I18n.t('apps.lb_showroom.detailed.order_form.modal.privacy_policy') }
                  </a>
                </p>

              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

const { fifth_element, name, phone, email } = Client.getClientInfo();
const { nameI18n, emailI18n, phoneI18n } = Client.getI18nAttrNames();
const i18nRequiredKey = 'apps.lb_showroom.detailed.order_form.validation.field_required';
const i18nMinNumberKey = 'apps.lb_showroom.detailed.order_form.validation.min_number';
const i18nSymbolsLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 254 });
const i18nSymbolsEmailLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 100 });
const i18nAtLeast8Digits = 'apps.lb_showroom.detailed.order_form.validation.at_least_8_digits';

const GetContactModalForm = withFormik({
  mapPropsToValues: () => ({ name, email, phone, fifth_element, }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .matches(validationPatterns.name, I18n.t("apps.lb_showroom.misc.scoped_form.name.invalid"))
      .required(I18n.t(i18nRequiredKey, { field: nameI18n })),
    email: Yup.string()
      .matches(validationPatterns.symbolsEmailLimit, i18nSymbolsEmailLimitErrorMessage)
      .email(I18n.t('apps.lb_showroom.detailed.order_form.validation.wrong_email'))
      .required(I18n.t(i18nRequiredKey, { field: emailI18n })),
    phone: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .min(8, I18n.t(i18nMinNumberKey, { field: phoneI18n }))
      .matches(validationPatterns.phone, I18n.t('apps.lb_showroom.misc.scoped_form.phone.invalid'))
      .test('minDigitsValidation', I18n.t(i18nAtLeast8Digits), function(value) {
        const { path, createError } = this;
        const phoneIsValid = value && value.replace(/[+\-\s()]/g, '').length >= 8;
        return phoneIsValid || !value || createError({ path, message: I18n.t(i18nAtLeast8Digits) });
      })
      .required(I18n.t(i18nRequiredKey, { field: phoneI18n })),
    address: Yup.string(),
    comment: Yup.string()
  }),

  handleSubmit: (values, bag) => {
    bag.props.submitHandler(values);
  },

  displayName: 'GetContact',
})(GetContactFormWrapper);

export default GetContactModalForm;
