import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap } from 'react-google-maps';

import { requestBuilder } from '../../api/httpService';
import { apiProvider } from '../../api/core';
import { SvgIco } from "../SharedComponents/Icons";
import MapWrapper from './MapWrapper';

import mapStyles from './style.json';


const url  = '/lease/api/frontend/locations/map_markers';


class MainMapWrapper extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isMapReady: false,
      markersArray: [],
      turnOnDrag: false,
      showLoader: false
    };
  };


  componentDidMount() {
    if (this.props.mapOptions.root.length === 0) {
      apiProvider.post(this.mapMarkersUrl(), requestBuilder(this.props.requestParams))
        .then((data) => {
          this.setState({ markersArray: data.locations || [] });
          this.boundsMap(data.locations)
        })
    } else {
      this.boundsMap();
      this.setState({markersArray: []})
    }

    google.maps.event.addListener(this.map,'idle',function(){
      if(!this.get('dragging') && this.get('oldCenter') && this.get('oldCenter')!== this.getCenter()) {
      }
      if(!this.get('dragging')){
        this.set('oldCenter',this.getCenter())
      }
    });

    google.maps.event.addListener(this.map,'dragstart',function(){
      this.set('dragging',true);
    });

    google.maps.event.addListener(this.map,'dragend',function(){
      this.set('dragging',false);
      google.maps.event.trigger(this,'idle',{});
    });
  };


  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.markersArray !== prevProps.markersArray) {
      this.setState({markersArray: this.props.markersArray});
      this.boundsMap(this.props.markersArray)
    }
  };


  onDragEnd = () => {
    if (this.props.mapOptions.root.length === 0) {
      this.setState({showLoader: true})
      const { requestParams } = this.props;
      let ne = this.map.getBounds().getNorthEast();
      let sw  = this.map.getBounds().getSouthWest();
      let viewportString = `${sw.lat()},${sw.lng()},${ne.lat()},${ne.lng()}`;
      const searchData = {...requestParams, viewport_string: viewportString, postal_districts: []};

      apiProvider.post(this.mapMarkersUrl(), requestBuilder(searchData))
                 .then((data) => {
                   this.setState({ showLoader: false });
                   data.locations && data.locations.length > 0 && this.setState({ markersArray: data.locations });
                 });
    }
  };


  boundsMap(markersArray) {
    const markers = markersArray ? markersArray : this.props.markersArray;
    if (markers.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();

      markers.map((elem) => {
        bounds.extend(new window.google.maps.LatLng(
          elem.location.lat,
          elem.location.lon,
        ));
        return elem;
      });
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const extendPoint1 = new window.google.maps.LatLng(bounds.getNorthEast().lat() + 0.001,
          bounds.getNorthEast().lng() + 0.001);
        const extendPoint2 = new window.google.maps.LatLng(bounds.getNorthEast().lat() - 0.001,
          bounds.getNorthEast().lng() - 0.001);

        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }
      this.map.fitBounds(bounds);
    }
  };


  mapMarkersUrl = () => {
    const { countryAlpha2 } = this.props.keywords;

    return url + '?country_alpha2=' + countryAlpha2;
  };


  changeDragAndDropFunction = () => {
    this.setState({turnOnDrag: !this.state.turnOnDrag})
  };


  render() {
    const markersArray = this.state.markersArray.length > 0 ? this.state.markersArray : this.props.markersArray;
    const defaultCenter = { lat: 0, lng: 0 };
    const onlyAnonymusocation = markersArray.length === 0;

    const {
      comparableLocations,
      favouriteLocationsIds,
      section,
      showSearchButton,
      toggleMap,
      updateComparableLocations,
      updateFavouriteLocations,
    } = this.props;


    return (
      <GoogleMap
        ref={(map) => { this.map = map; }}
        zoom={ !onlyAnonymusocation ? 8 : 2 }
        maxZoom={ 15 }
        defaultOptions={{ mapTypeControl: false }}
        options={{ scrollwheel: true, mapTypeControl: false, gestureHandling: 'greedy', styles: mapStyles }}
        defaultCenter={ defaultCenter }
        onDragEnd={ this.state.turnOnDrag ? this.onDragEnd : () => null}
        onZoomChanged={this.state.turnOnDrag ? this.onDragEnd : () => null}
      >
        <MapWrapper
          markersArray={ markersArray }
          orderUrl={ this.props.keywords.apiCreateOrderPath }
          siteKey={ this.props.keywords.siteKey}
          favouriteLocationsIds={favouriteLocationsIds}
          section={section}
          updateFavouriteLocations={updateFavouriteLocations}
          updateComparableLocations={updateComparableLocations}
          map={this.map}
          comparableLocations={comparableLocations}
          countryAlpha2={this.props.keywords.countryAlpha2}
        />

        { !onlyAnonymusocation
          ?
            <div>
              <button className="main-map__close-button" onClick={ toggleMap }>
                <SvgIco name="arrow-back" size={24} />
                { I18n.t("generic.back") }
              </button>

              { showSearchButton &&
                <div className={`main-map__search-display-button ${this.state.turnOnDrag ? 'main-map__search-display-button--selected' : ''}`}
                     onClick={this.changeDragAndDropFunction}>
                  <p>{ I18n.t('generic.search-displayed-area') }</p>
                  <SvgIco name="check" size={16} />
                </div>
              }

              { this.state.showLoader &&
                <div className="spinner-wrapper">
                  <div className="smoothie-spinner" />
                </div>
              }
            </div>
         :
            <button className="main-map__close-button" onClick={ toggleMap }>
              <SvgIco name="arrow-back" size={24} />
              { I18n.t('generic.no-coordinates') }
            </button>
        }
      </GoogleMap>
    );
  }
}

MainMapWrapper.propTypes = {
  markersArray: PropTypes.arrayOf(PropTypes.shape),
};

MainMapWrapper.defaultProps = {
  markersArray: [],
};

export default MainMapWrapper;
