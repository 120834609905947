import React, { Component } from 'react';
import { withFormik } from 'formik';
import Yup from 'yup';
import classNames from 'classnames';

import Client from '../../../utils/Client';
import { validationPatterns } from '../../../utils/validationPatterns';
import Loader from '../../SharedComponents/Loader';
import { SvgIco } from "../../SharedComponents/Icons";

import './ModalGetInfoFormMobile.sass';


// import GoogleAnalytics from '../../utils/GoogleAnalytics';
// import { storeValidationFails } from '../../utils/FormHandler';


class ModalGetInfoFormMobile extends Component {
  state = {
    isShowingModal: false,
    isSubmitting: false,
    // jeudanReadMore: false,
  };


  handleSendingData = () => {
    this.props.validateForm()
      .then(resolved => {
        if (Object.entries(this.props.errors).length === 0) {
          this.setState({ isSubmitting: true });
        }
    })
  };

  handleFieldBlur = (e) => {
    this.props.handleBlur(e);
    // storeValidationFails(this, 'order');
  };

  // openReadMoreJeudan = () => this.setState({ jeudanReadMore: true });


  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleSubmit,
      contactOrdered,
      // isJeudan,
    } = this.props;

    const {
      isSubmitting,
      // jeudanReadMore
    } = this.state;

    const noErrors = Object.keys(errors).length === 0;

    const askLandLordButton = {
      mainText: I18n.t('apps.lb_showroom.detailed.order_form.contact_button_one_ab_test'),
      infoSentText: I18n.t('apps.lb_showroom.detailed.order_form.contact_button_sent'),
    };
    const buttonText = contactOrdered ? askLandLordButton.infoSentText : askLandLordButton.mainText;
    const buttonIcon = isSubmitting && noErrors ? <Loader size={18} left={7} top={15}/> : null;
    // const checkboxClasses = classNames('jeudan-policy-checkbox__wrapper', {'checked': values.jeudan_policy });



    return (
      <div className="form-section ab-test-form">
        <form onSubmit={handleSubmit} noValidate>
          {/* here we check does this field was filled by bot */}
          <input
            id="fifth_element"
            type="text"
            name="fifth_element"
            className="invisible"
            value={values.fifth_element}
            onPaste={handleChange}
            onChange={handleChange}
            onBlur={this.handleFieldBlur}
            error={errors.name && touched.name}
          />

          <div className="order-form__collapsible-wrapper">
            <div className='form-collapsible'>
              <div className="input-wrapper">

                <div className="form-group">
                  <input
                    id="name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={this.handleFieldBlur}
                    className={`form-control order-form__input
                        ${values.name !== '' ? 'not-empty' : 'empty'}
                        ${touched.name ? (errors.name ? 'error' : 'is-valid order-input-valid') : null}`}
                    error={errors.name && touched.name}
                  />
                  <span className="floating-label">{I18n.t('activemodel.attributes.provider_service_request.person_full_name')}*</span>
                  <div className="validation-wrapper" />
                  <div className="validation-message">
                    {touched.name && errors.name ? errors.name : null}
                  </div>
                </div>

                <div className="form-group">
                  <input
                    id="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={this.handleFieldBlur}
                    className={`form-control order-form__input
                        ${values.email !== '' ? 'not-empty' : 'empty'}
                        ${touched.email ? (errors.email ? 'error' : 'is-valid order-input-valid') : null}`}
                  />
                  <span className="floating-label">{I18n.t('activemodel.attributes.provider_service_request.email')}*</span>
                  <div className="validation-wrapper" />
                  <div className="validation-message">
                    {touched.email && errors.email ? errors.email : null}
                  </div>
                </div>

                <div className="form-group">
                  <input
                    id="phone"
                    type="tel"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={this.handleFieldBlur}
                    className={`form-control order-form__input
                        ${values.phone !== '' ? 'not-empty' : 'empty'}
                        ${touched.phone ? (errors.phone ? 'error' : 'is-valid order-input-valid') : null}`}
                  />
                  <span className="floating-label">{I18n.t('activemodel.attributes.provider_service_request.phone_number_order_form')}*</span>
                  <div className="validation-wrapper" />
                  <div className="validation-message">
                    {touched.phone && errors.phone ? errors.phone : null}
                  </div>
                </div>

                <div className="form-group">
                  <input
                    id="company"
                    type="text"
                    value={values.company}
                    onChange={handleChange}
                    onBlur={this.handleFieldBlur}
                    className={`form-control order-form__input
                        ${values.company !== '' ? 'not-empty' : 'empty'}
                        ${touched.company ? (errors.company ? 'error' : 'is-valid order-input-valid') : null}`}
                  />
                  <span className="floating-label">{I18n.t('generic.company_name')}*</span>
                  <div className="validation-wrapper" />
                  <div className="validation-message">
                    {touched.company && errors.company ? errors.company : null}
                  </div>
                </div>

                <div className="form-group">
                  <textarea
                    id="comment"
                    type="text"
                    resize="none"
                    value={values.comment}
                    onChange={handleChange}
                    onBlur={this.handleFieldBlur}
                    className={`form-control order-form__textarea
                      ${values.comment !== '' ? 'not-empty' : 'empty'}
                      ${touched.comment ? (errors.comment ? 'error' : 'is-valid order-input-valid') : null}`}
                  />
                  <span className="floating-label disappear-label">{I18n.t('activemodel.attributes.provider_service_request.your_question')}*</span>
                  <div className="validation-wrapper"/>
                  <div className="validation-message">
                    {touched.comment && errors.comment ? errors.comment : null}
                  </div>
                </div>

                {/*{ isJeudan &&*/}
                {/*  <div className="form-group jeudan-policy-checkbox">*/}
                {/*    <div className={checkboxClasses}>*/}
                {/*      <input*/}
                {/*        name="jeudan_policy"*/}
                {/*        type="checkbox"*/}
                {/*        value={values.jeudan_policy}*/}
                {/*        id="jeudan_policy"*/}
                {/*        onChange={handleChange}*/}
                {/*        onBlur={this.handleFieldBlur}*/}
                {/*      />*/}
                {/*      <SvgIco name="check" size={15} />*/}
                {/*      <div className="jeudan-policy-checkbox__label">*/}
                {/*        { jeudanReadMore*/}
                {/*          ?*/}
                {/*          <div dangerouslySetInnerHTML={{ __html: I18n.t('activemodel.attributes.provider_service_request.jeudan_privacy_policy_full') }}*/}
                {/*               className="jeudan-policy-checkbox__label-content"/>*/}
                {/*          :*/}
                {/*          <div>*/}
                {/*            { I18n.t('activemodel.attributes.provider_service_request.jeudan_privacy_policy_short') }*/}
                {/*            <span onClick={this.openReadMoreJeudan} className="jeudan-policy-checkbox__label-read-more-link">*/}
                {/*              { I18n.t('generic.read_more') }.*/}
                {/*            </span>*/}
                {/*          </div>*/}
                {/*        }*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*    <div className="validation-message validation-message--jeudan">*/}
                {/*      { touched.jeudan_policy && errors.jeudan_policy ? errors.jeudan_policy : null }*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*}*/}

              </div>
            </div>
          </div>

          <button onClick={this.handleSendingData} className="get-info-submit-button" >
            { buttonIcon }
            { buttonText }
          </button>

        </form>
      </div>
    );
  }
};

const { fifth_element, name, phone, email, company, option_parking_lots } = Client.getClientInfo();
const {
  emailI18n,
} = Client.getI18nAttrNames();
const i18nMinNumberKey =
  'apps.lb_showroom.detailed.order_form.validation.min_number';


const {
  fullName,
  phoneNumber,
  companyName,
  messageI18n,
} = Client.getI18nAttrNamesMobileModalForm();

const i18nRequiredStartKey = 'apps.lb_showroom.detailed.order_form.validation.field_required_modal_form';
const i18nSymbolsLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 254 });
const i18nSymbolsEmailLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 100 });
const i18nAtLeast8Digits = 'apps.lb_showroom.detailed.order_form.validation.at_least_8_digits';

const GetInfoFormMobile = withFormik({
  mapPropsToValues: (bag) => ({
    name, email, phone, company,
    option_parking_lots,
    comment: '', fifth_element,
    // jeudan_policy: false,
    // isJeudan: bag.isJeudan
  }),
  validationSchema: Yup.object().shape({
    fifth_element: Yup.string(),
    name: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .matches(validationPatterns.name, I18n.t("apps.lb_showroom.misc.scoped_form.name.invalid"))
      .required(I18n.t(i18nRequiredStartKey, { field: fullName })),
    email: Yup.string()
      .matches(validationPatterns.symbolsEmailLimit, i18nSymbolsEmailLimitErrorMessage)
      .email(I18n.t('apps.lb_showroom.detailed.order_form.validation.wrong_email'))
      .required(I18n.t(i18nRequiredStartKey, { field: emailI18n })),
    company: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .matches(validationPatterns.company, I18n.t("apps.lb_showroom.misc.scoped_form.company.invalid"))
      .required(I18n.t(i18nRequiredStartKey, { field: companyName })),
    phone: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .min(8, I18n.t(i18nMinNumberKey, { field: phoneNumber }))
      .matches(validationPatterns.phone, I18n.t('apps.lb_showroom.misc.scoped_form.phone.invalid'))
      .test('minDigitsValidation', I18n.t(i18nAtLeast8Digits), function(value) {
        const { path, createError } = this;
        const phoneIsValid = value && value.replace(/[+\-\s()]/g, '').length >= 8;
        return phoneIsValid || !value || createError({ path, message: I18n.t(i18nAtLeast8Digits) });
      })
      .required(I18n.t(i18nRequiredStartKey, { field: phoneNumber })),
    comment: Yup.string()
      .required(I18n.t(i18nRequiredStartKey, { field: messageI18n })),
    // isJeudan: Yup.boolean(),
    // jeudan_policy: Yup.boolean()
    //   .when("isJeudan", {
    //     is: true,
    //     then: Yup.boolean()
    //       .required(I18n.t('activemodel.attributes.provider_service_request.jeudan_privacy_policy_error_message'))
    //       .oneOf([true], I18n.t('activemodel.attributes.provider_service_request.jeudan_privacy_policy_error_message')),
    //   })
  }),
  handleSubmit: (values, bag) => {
    Client.updateClientInfo(values);
    bag.props.submitHandler(values);
  },
  displayName: 'InfoForm', // helps with React DevTools
})(ModalGetInfoFormMobile);

export default GetInfoFormMobile;
