import React, { useState, useEffect } from 'react';
import { useJsApiLoader } from "@react-google-maps/api";

import { SvgIco } from "../Icons";

import './index.sass';


const GoogleReviewsWidget = ({ apiKey, siteKey }) => {

  const [currentMap, setCurrentMap] = useState(null);
  const [rating, setRating] = useState(0);
  const [reviewsCount, setReviewsCount] = useState(0);
  const [showWidget, setShowWidget] = useState(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries: ['places']
  });


  useEffect(() => {
    isLoaded && !loadError && initMap();
  }, [isLoaded, loadError]);


  useEffect(() => {
    currentMap && getRating();
  }, [currentMap]);


  const initMap = () =>
    setCurrentMap(new google.maps.Map(document.getElementById('map')));


  const getRating = () => {
    const request = {
      placeId: siteKey === 'dk' ? 'ChIJb_2a015SUkYRUzw4AsPyuCM' : 'ChIJyWGa015SUkYRtpvXlkhWusk',
      fields: ['rating', 'user_ratings_total'],
    };

    let service = new google.maps.places.PlacesService(currentMap);
    service.getDetails(request, callback);

    function callback(place, status) {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        setRating(place.rating);
        setReviewsCount(place.user_ratings_total);
        setShowWidget(true);
      }
    }
  };


  const renderStarIcons = () => {

    const starsList = [];

    for (let i = 0; i < 5; i++) {
      const starType = (rating > i && i + 1 > rating) ? 'half' : i >= rating ? 'empty' : 'full';

      starsList.push(<SvgIco name={`google_star_${starType}`} size={14} />);
    }

    return starsList.map(star => star);
  };


  return (
    <div className="reviews-widget">
      <div id="map" style={{width: 0, height: 0, overflow: 'hidden'}} />
      { showWidget &&
        <div className="reviews-widget__wrapper">
          <div className="reviews-widget__info-wrapper">
            <div className="reviews-widget__logo-wrapper">
              <SvgIco name="google" width={63} height={32} />
              <div className="reviews-widget__logo-headline">
                { I18n.t('generic.reviews') }
              </div>
            </div>
            <div className="reviews-widget__rating-wrapper">
              <div className="reviews-widget__rating">
                { rating }
              </div>
              <div className="reviews-widget__rating-stars">
                { renderStarIcons() }
              </div>
              <div className="reviews-widget__reviews-count">
                { reviewsCount } { I18n.t('generic.reviews') }
              </div>
            </div>
          </div>
          <a className="reviews-widget__button-wrapper"
             href={siteKey === 'dk' ? 'https://g.page/r/CVM8OALD8rgjEBM/review' : 'https://g.page/r/Cbab15ZIVrrJEB0/review'}
             target="_blank">
            <div className="reviews-widget__button">
              { I18n.t('google_reviews.button_text') }
            </div>
          </a>
        </div>
      }
    </div>
  )
}

export default GoogleReviewsWidget;
