import React, { Component } from 'react';
import { withFormik } from 'formik';
import Yup from 'yup';
import { passwordValidation } from '../SharedComponents/Forms/ValidationSchemaPattern';
import { SvgIco } from "../SharedComponents/Icons";



class RecoveryPasswordFormWrapper extends Component {
  state = {
    passwordVisibility: false,
  };

  togglePasswordVisibility = () => {
    this.setState({
      passwordVisibility: !this.state.passwordVisibility,
    });
  };

  errorValidator = (field) => {
    const { errors, touched } = this.props;
    const fieldError = errors[field] && touched[field] && 'recovery-password-form__form-group-error';

    return fieldError;
  };

  render() {

    const { values, handleChange, handleBlur, errors, handleSubmit, submitSuccessMessage, handleError, setFieldValue } = this.props;
    const { passwordVisibility } = this.state;

    return (
      <div className="row">
        <div className="col-12 col-md-6 col-lg-5 col-xl-4">
          <div className="recovery-password-form">
            <form name="forgotPasswordForm" className="recovery-password-form__form" onSubmit={ handleSubmit } noValidate>

              { submitSuccessMessage &&
              <div className="recovery-password-form__input-tooltip-success">
                { submitSuccessMessage }
              </div>
              }

              <div className={`form-group ${ this.errorValidator('password')}`}>
                <label className="recovery-password-form__label" htmlFor="password">
                  <input
                    name="password"
                    type={ passwordVisibility ? 'text' : 'password' }
                    value={values.password}
                    id="password"
                    onChange={ e => {
                      setFieldValue('password', e.target.value.replace(/\s/g, ""))

                    }}
                    onBlur={handleBlur}
                    data-tip={errors.password}
                    className={`recovery-password-form__input ${values.password  && 'active'}`}
                    required
                  />
                  <span className={`floating-label ${values.password && 'active'}`}>
                    { I18n.t('generic.password') }*
                  </span>
                </label>

                { passwordVisibility
                  ? <SvgIco className="password-visibility" name="visibility_filled" onClick={this.togglePasswordVisibility} size={18} />
                  : <SvgIco className="password-visibility" name="visibility_off_filled" onClick={this.togglePasswordVisibility} size={18} />
                }
                <div className="recovery-password-form__input-tooltip-error">
                  { errors.password }
                </div>
              </div>

              <div className="form-group form-group--submit">
                <button type="submit" className="b-button">
                  Change password
                  {/*{ I18n.t('providers.sessions.forgot_password.send') }*/}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const RecoveryPasswordForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    password:  '',
  }),
  validationSchema: Yup.object().shape({
    password: passwordValidation
  }),

  handleSubmit: (values, bag) => {
    bag.resetForm();
    bag.props.handleSubmit(values);
  },

  displayName: 'LoginForm',
})(RecoveryPasswordFormWrapper);

export default RecoveryPasswordForm;
