import React from 'react';

const TypeformButton = ({ className, href, title }) => {


  return (
    <div>
      <a className={`${className} typeform-share`}
         data-mode="drawer_right"
         data-submit-close-delay="5"
         target="_blank"
         href={href}>
        { title }
      </a>
      {(function() {
        var qs,
          js,
          q,
          s,
          d=document,
          gi=d.getElementById,
          ce=d.createElement,
          gt=d.getElementsByTagName,
          id="typef_orm_share",
          b="https://embed.typeform.com/";

        if(!gi.call(d,id)) {
          js=ce.call(d,"script");
          js.id=id;
          js.src=b+"embed.js";
          q=gt.call(d,"script")[0];
          q.parentNode.insertBefore(js,q)
        }
      })()}
    </div>
  )
};

export default React.memo(TypeformButton);
