import React, { Component } from 'react';

import './index.sass';


class CollapsibleSection extends Component {
  state = {
    uncollapsed: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.uncollapse !== prevProps.uncollapse) {
      if (this.props.uncollapse) {
        this.setState({ uncollapsed: true }, () =>
          this.collapsibleSection.classList.toggle('uncollapsed'),
        );
      } else {
        this.collapsibleSection.classList.toggle('uncollapsed');
        setTimeout(() => this.setState({ uncollapsed: false }), 400);
      }
    }
  }

  render() {

    const delimiter = this.props.isRequestFromPrerender ? ' uncollapsed ' : ' ';
    const defaultDelimiter = this.props.isRequestFromPrerender ? ' uncollapsed' : '';

    return (
      <div className={`collapsible-section${this.props.classes ? delimiter + this.props.classes : defaultDelimiter}`}
           ref={(el) => (this.collapsibleSection = el)}>
        { (this.state.uncollapsed || this.props.isRequestFromPrerender) && this.props.children }
      </div>
    );
  }
}

export default CollapsibleSection;
