import React, { useContext, useState, useEffect, Fragment, Suspense } from 'react';
import Truncate from 'react-truncate';
import { isMobile } from "react-device-detect";

import { SvgIco } from '../../SharedComponents/Icons';
import SmartTextTooltip from "../SmartTextTooltip";
import { Context}  from "../index";

const ParkingMarkerMap = React.lazy(() => import('../../NewMainMap/ParkingMarkerMap'));


const MapSection = () => {

  const {
    anonymous,
    googleApiKey,
    isRequestFromPrerender,
    location: { addressLine1, latitude, longitude },
    staticMapUrl,
    tabs: { smartText: { geography: smartTextLocation }, transport },
  } = useContext(Context);

  const [isMapHovering, setIsMapHovering] = useState(false);
  const [locationText, setLocationText] = useState(false);
  const [staticImageAvailable, setStaticImageAvailable] = useState(true);

  const textLines = isMobile ? 4 : 2;

  const mapContainerStyle = {
    position: 'absolute',
    objectFit: 'cover',
    width: '100%'
  };

  useEffect(() => {
    const img = new Image();

    img.src = staticMapUrl;
    img.error = () => setStaticImageAvailable(false);
  }, []);


  const handleMapMouseHover = ({ target }) => {
    if (!isMapHovering) {
      target.style.background = 'white';
      setIsMapHovering(true);
    }
  };


  return (
    <div className="dv-info-section-wrapper location-details-section dv-info-section-wrapper--location">
      <div className="row">
        <div className="col-md-12">
          <h2 className='dv-map-section location-title'>{ I18n.t('detailed.location.headline') }</h2>
        </div>
        <div className="dv-map-section-location-address-wrapper col-12">
          <SvgIco name="map_detail" size={22}/>
          <div className="dv-map-section-location-address">
            <h2>{ !anonymous && `${ addressLine1 }, `}{ transport.postalDistrict.postalName }</h2>
          </div>
        </div>
      </div>

      { smartTextLocation && <SmartTextTooltip id="map" /> }

      { locationText || isRequestFromPrerender
        ?
          <Fragment>
            <p className="smart-text-extended">
              {smartTextLocation}
            </p>
            <div className="dv-info-section__read-more-smart-text">
              <a onClick={() => setLocationText(prevState => !prevState)}>
                { I18n.t('apps.lb_showroom.detailed.description.hide_more_link') }
                <SvgIco name="expand-less" size={15} />
              </a>
            </div>
          </Fragment>
        :
          <Fragment>
            { smartTextLocation &&
              <div className="smart-text">
                <Truncate
                  lines={ textLines }
                  ellipsis={
                    <Fragment><span>{textLines !== 0 && '... '}</span>
                      <div className="dv-info-section__read-more-smart-text">
                        <a onClick={() => setLocationText(prevState => !prevState)}>
                          { I18n.t('generic.read_more') }
                          <SvgIco name="expand-more" size={15} />
                        </a>
                      </div>
                    </Fragment>
                  }
                  children={smartTextLocation}
                />
              </div>
            }
          </Fragment>
      }

      <div className="bottom-location-map wrapper-map-location">
        { staticImageAvailable &&
        <img
          alt={!anonymous && `${ addressLine1 }, ${transport.postalDistrict.postalName}`}
          className='bottom-location-map lazyload'
          data-src={staticMapUrl}
          data-sizes="auto"
          onMouseEnter={handleMapMouseHover}
          onTouchStart={handleMapMouseHover}
          style={mapContainerStyle}
        />
        }
        { (isMapHovering || !staticImageAvailable) &&
          <Suspense fallback={<div className="skeleton-box" />}>
            <ParkingMarkerMap googleApiKey={googleApiKey} coordinates={{ latitude: latitude, longitude: longitude }} />
          </Suspense>
        }
      </div>
    </div>
  )
}

export default MapSection;
