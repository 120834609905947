import React, { Component } from "react";
import { withFormik } from 'formik';
import Yup from 'yup';

import { companyValidation, emailValidation, nameValidation, phoneValidation } from '../SharedComponents/Forms/ValidationSchemaPattern';
import SignUp from '../../utils/SignUp';
import PaymentModel from "./PaymentModel";

import './SignUpForm.sass';


class SignUpFormWrapper extends Component {

  state = {
    emailValueOnError: '',
    errorsOnSubmit: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.emailAlreadyExist !== prevProps.emailAlreadyExist && this.props.emailAlreadyExist) {
      this.setState({ emailValueOnError: this.props.emailAlreadyExist });
      const target = document.getElementById('emailErrorTarget');
      const targetCoords = Math.floor(target.getBoundingClientRect().top + window.pageYOffset - (document.getElementById('top-menu').offsetHeight + 48));

      window.scroll(0, targetCoords);
    }
    if (this.props.internalError !== prevProps.internalError && this.props.internalError) {
      const target = document.getElementById('internalErrorTarget');
      const targetCoords = Math.floor(target.getBoundingClientRect().top + window.pageYOffset - (document.getElementById('top-menu').offsetHeight + 48));

      window.scroll(0, targetCoords);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if(Object.keys(this.props.errors).length) {
      this.setState({ errorsOnSubmit: true }, () => {
        const target = document.querySelector('.sign-up-form__form-group-error');
        const targetCoords = Math.floor(target.getBoundingClientRect().top + window.pageYOffset - (document.getElementById('top-menu').offsetHeight + 48));

        window.scroll(0, targetCoords);
      });
    } else {
      this.props.handleSubmit();
    }
  };

  handleContractSelect = (id, activeSection) => {
    const { hashedContracts, setFieldValue, setActiveContractValues } = this.props;

    setActiveContractValues(hashedContracts[id], id, activeSection);
    setFieldValue('contract_id', hashedContracts[id]);
  };

  errorValidator = (field) => {
    const { errors, touched } = this.props;
    const { errorsOnSubmit } = this.state;
    const fieldError = errors[field] && (touched[field] || errorsOnSubmit) && 'sign-up-form__form-group-error';

    return field === 'email'
      ? (this.props.emailAlreadyExist && 'sign-up-form__form-group-error') || fieldError
      : fieldError;
  };

  handleEmailError = (e) => {
    this.setState({ emailValueOnError: e.target.value }, () => {
      this.props.setFieldValue('email', this.state.emailValueOnError);
      this.props.handleEmailAlreadyExistError()
    });
  };


  render() {

    const {
      activeContract,
      contactInfoEmail,
      contactInfoPhone,
      currentCurrency,
      emailAlreadyExist,
      errors,
      handleBlur,
      handleChange,
      hashedContracts,
      internalError,
      setFieldValue,
      siteKey,
      toggleModal,
      values,
      advertisementStrategiesPrices,
    } = this.props;

    const { emailValueOnError } = this.state;
    const internalErrorStyle = { display: 'block', marginTop: '-15px' };

    return (
      <div>
        <div className="sign-up-form-wrapper">
          <form name="signUpForm" className="sign-up-form" onSubmit={ this.handleSubmit }>

            <input
              id="fifth_element"
              type="text"
              name="fifth_element"
              className="invisible"
              value={values.fifth_element}
              onPaste={handleChange}
              onChange={handleChange}
            />

            <div className="row">

              <div className="sign-up-form__payment-model col-md-6">
                <h1>{ I18n.t('signup.new_form.title') }</h1>
                <p className="sign-up-form__payment-model description">{ I18n.t('signup.new_form.subheading') }</p>

                <div className="sign-up-form__payment-model-header">
                  <h2>{ I18n.t('signup.new_form.plans.title') }</h2>
                </div>

                <PaymentModel
                  activeContract={activeContract}
                  contactInfoEmail={contactInfoEmail}
                  contactInfoPhone={contactInfoPhone}
                  cookiesActiveGroup={active_contract_section}
                  cookiesContract={contract_name}
                  currentCurrency={currentCurrency}
                  hashedContracts={hashedContracts}
                  handleContractSelect={this.handleContractSelect}
                  setFieldValue={setFieldValue}
                  siteKey={siteKey}
                  toggleModal={toggleModal}
                  advertisementStrategiesPrices={advertisementStrategiesPrices}
                />
              </div>

              <div className="sign-up-form__contact-data col-md-6 col-lg-5 offset-lg-1">
                <div className="sign-up-form__contact-form-wrapper">

                  <div className="sign-up-form__contact-data-header">
                    <h2>{ I18n.t('apps.entry_page.entry_pages.become_provider_text.become_provider_now') }</h2>
                  </div>

                  <div id="internalErrorTarget" />

                  { internalError &&
                    <div className="sign-up-form__input-tooltip-error" style={internalErrorStyle}>
                      { I18n.t('apps.lb_showroom.detailed.order_form.error.message') }
                    </div>
                  }

                  <div className="form-inputs">
                    <div className={`form-group ${ this.errorValidator('company_name')}`}>
                      <label className="sign-up-form__label" htmlFor="company_name">
                        <input
                          name="company_name"
                          type="text"
                          value={values.company_name}
                          id="company_name"
                          onChange={(e) => {
                            setFieldValue('company_name', e.target.value.trimStart().replace(/\s\s+/g, ' '))
                          }}
                          onBlur={handleBlur}
                          data-tip={errors.company_name}
                          className={`sign-up-form__input ${values.company_name && 'active'}`}
                        />
                        <span className={`sign-up-form__floating-label ${values.company_name && 'active'}`}>
                          { I18n.t('signup.new_form.register.company_name.placeholder') }*
                        </span>
                      </label>
                      <div className="sign-up-form__input-tooltip-error">{errors.company_name}</div>
                    </div>

                    <div className={`form-group ${ this.errorValidator('name')}`}>
                      <label className="sign-up-form__label" htmlFor="name">
                        <input
                          name="name"
                          type="text"
                          value={values.name}
                          id="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          data-tip={ errors.name }
                          className={`sign-up-form__input ${values.name && 'active'}`}
                        />
                        <span className={`sign-up-form__floating-label ${values.name && 'active'}`}>
                          { I18n.t('signup.new_form.register.name.placeholder') }*
                        </span>
                      </label>
                      <div className="sign-up-form__input-tooltip-error">{ errors.name }</div>
                    </div>

                    <div id="emailErrorTarget" />
                    <div className={`form-group ${ this.errorValidator('email')}`}>
                      <label className="sign-up-form__label" htmlFor="email">
                        <input
                          name="email"
                          type="email"
                          value={emailAlreadyExist ? emailValueOnError : values.email}
                          id="email"
                          onChange={emailAlreadyExist ? this.handleEmailError : handleChange}
                          onBlur={handleBlur}
                          data-tip={emailAlreadyExist ? I18n.t('apps.lb_showroom.detailed.order_form.validation.email_already_exists') : errors.email}
                          className={`sign-up-form__input ${values.email  && 'active'}`}
                        />
                        <span className={`sign-up-form__floating-label ${values.email && 'active'}`}>
                          { I18n.t('signup.new_form.register.email.placeholder') }*
                        </span>
                      </label>
                      <div className="sign-up-form__input-tooltip-error">
                        { emailAlreadyExist ? I18n.t('apps.lb_showroom.detailed.order_form.validation.email_already_exists') : errors.email }
                      </div>
                    </div>

                    <div className={`form-group ${ this.errorValidator('phone_number')}`}>
                      <label className="sign-up-form__label" htmlFor="phone_number">
                        <input
                          name="phone_number"
                          type="tel"
                          value={values.phone_number}
                          id="phone_number"
                          onChange={(e) => {
                            setFieldValue('phone_number', e.target.value.trimStart().replace(/\s\s+/g, ' '))
                          }}
                          onBlur={handleBlur}
                          data-tip={ errors.phone_number }
                          className={`sign-up-form__input ${values.phone_number && 'active'}`}
                        />
                        <span className={`sign-up-form__floating-label ${values.phone_number && 'active'}`}>
                          { I18n.t('signup.new_form.register.phone_number.placeholder') }*
                        </span>
                      </label>
                      <div className="sign-up-form__input-tooltip-error">{ errors.phone_number }</div>
                    </div>

                    <div className="form-group form-group--not-required">
                      <button type="submit" className="b-button">
                        { I18n.t('apps.entry_page.entry_pages.become_provider_text.become_provider_now') }
                      </button>
                    </div>
                    <span className="sign-up-form__subheading">
                      { I18n.t('signup.new_form.register.notice') }
                    </span>
                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    )
  }
}

const {
  company_name,
  name,
  email,
  phone_number,
  fifth_element,
  contract_name,
  active_contract_section,
} = SignUp.getClientInfo();

const SignUpForm = withFormik({
  mapPropsToValues: (bag) => {
    return {
      company_name,
      name,
      email,
      phone_number: phone_number ? phone_number :  bag.userCountryPhoneCode,
      fifth_element,
    }},

  validationSchema: Yup.object().shape({
    company_name: companyValidation,
    name: nameValidation,
    email: emailValidation,
    phone_number: phoneValidation,
  }),

  handleSubmit: (values, bag) => {
    SignUp.updateClientInfo({ ...values, contract_name: bag.props.contractName, active_contract_section: bag.props.activeContractSection});
    bag.props.preSubmitHandler(values);
  },

  displayName: 'SignUpForm',
})(SignUpFormWrapper);

export default SignUpForm;
