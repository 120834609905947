import React from 'react';

import Phone from '../../../src/images/icons/phone_icon.svg';
import Email from '../../../src/images/list_office/Mail_icon.svg';

import './ContactBlock.sass'


const ContactBlock = ({ siteKey }) => {
  return (
    <div className="body-contact">
      <strong>{ I18n.t('signup.teaser.tabs.tab.block.contact_us.text') }</strong>
      <div className="info-wrapper">
        <ul>
          <li className="tel">
            <img alt="Phone" className="lazyload" data-sizes="auto" data-src={Phone} />
            <a href={`tel://(+45) 39 45 85 57`}>(+45) 39 45 85 57</a>
          </li>
          <li className="email">
            <img alt="Email" data-src={Email} className="lazyload" data-sizes="auto" />
            { siteKey === 'dk' &&
              <a href={`mailto:info@lokalebasen.dk`}>info@lokalebasen.dk</a>
            }
            { siteKey === 'se' &&
              <a href={`mailto:info@matchoffice.se`}>info@matchoffice.se</a>
            }

            { siteKey !== 'se' && siteKey !== 'dk' &&
              <a href={`mailto:info@matchoffice.com`}>info@matchoffice.com</a>
            }
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ContactBlock;
