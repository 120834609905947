import React, { Component } from 'react';

import TrustLogos from "../SharedComponents/TrustLogos";
import FooterLinks from "./FooterLinks";
import FooterBottom from "./FooterBottom";
import PropTypes from "prop-types";

import './index.sass';


class Footer extends Component {
  render() {
    const {
      contacts,
      footerBottom,
      footerBoxes,
      trustedLogos,
      trustedLogosUrl,
    } = this.props;

    return (
      <div className="global-footer">
        <TrustLogos trustedLogos={trustedLogos} trustedLogosUrl={trustedLogosUrl}/>
        <FooterLinks footerBoxes={footerBoxes}/>
        <FooterBottom contacts={contacts} footerBottom={footerBottom}/>
      </div>
    )
  }
}

export default Footer;

Footer.propTypes = {
  contacts: PropTypes.shape({
    addressLine: PropTypes.string,
    cvr: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    postalCodeCity: PropTypes.string,
    showCvr: PropTypes.bool,
  }),
  footerBottom: PropTypes.shape({
    copyrightUrl: PropTypes.string,
    logoUrl: PropTypes.string,
    siteLinks: PropTypes.arrayOf(PropTypes.object),
    socialLinks: PropTypes.arrayOf(PropTypes.array),
    sslLink: PropTypes.string,
    supportUrl: PropTypes.string,
  }),
  footerBoxes: PropTypes.arrayOf(PropTypes.object),
  siteKey: PropTypes.string,
  trustedLogos: PropTypes.arrayOf(PropTypes.object),
};

Footer.defaultProps = {
  contacts: {
    addressLine: '',
    cvr: '',
    email: '',
    phone: '',
    postalCodeCity: '',
    showCvr: false,
  },
  footerBottom: {
    copyrightUrl: '',
    logoUrl: '',
    siteLinks: [],
    socialLinks: [],
    sslLink: '',
    supportUrl: '',
  },
  footerBoxes: [],
  siteKey: '',
  trustedLogosL: [],
};
