import React from 'react';
import parkingIcon from '../../../src/images/icons/parking.svg';

const ParkingListItem = ({ item, index }) => {

  return (
    <div className="facts-and-facilities__parking-wrapper" key={index}>
      <div className="facts-and-facilities__parking-icon lazyload" data-bg={parkingIcon} data-sizes="auto" />
      <div className="facts-and-facilities__parking-text-wrapper">
        <a target="_blank" href={item.url} className="facts-and-facilities__parking-links">
          {item.address}
        </a>
        <div className="facts-and-facilities__parking-distance">
          {item.distance}
        </div>
      </div>
    </div>
  )
};

export default React.memo(ParkingListItem);