import React, { Fragment } from 'react';

import { localizedNumberFormatter } from "../../../utils/helpers";
import { SvgIco } from '../../SharedComponents/Icons';


const MeetingRoomsInfoContent = ({ basicPrice, currentCurrency, premiumPrice, siteKey }) => {

  return (
    <Fragment>
      <div className="sign-up-modal__header">
        <h2 className="sign-up-modal__header-title">
          { I18n.t('apps.entry_page.entry_pages.show.lease.meeting_rooms') }
        </h2>
        <div className="sign-up-modal__close-btn" id="modalCloseBtn">
          <SvgIco name="close" size={32}/>
        </div>
      </div>
      <div className="sign-up-modal__body">
        <p className="sign-up-modal__pricing-description" dangerouslySetInnerHTML={{__html: I18n.t('signup.signup_form.meeting_room.infobox.body')}} />
        <div className="sign-up-modal__pricing-table-wrapper">
          <h3>{ I18n.t('signup.signup_form.on_deal.infobox.price_model_meeting_rooms') }</h3>

          <table className="sign-up-modal__table sign-up-modal__meeting-rooms">
            <tbody>

            <tr className="sign-up-modal__table-header">
              <td>{ I18n.t('signup.signup_form.on_deal.infobox.prices') }</td>
              <td className="sign-up-modal__table-td">{ I18n.t('signup.teaser.prices.block.heading.removal_3') }</td>
              <td className="sign-up-modal__table-td">{ I18n.t('signup.teaser.prices.block.heading.removal_4') }</td>
            </tr>

            <tr>
              <td>{ I18n.t('signup.signup_form.meeting_room.infobox.monthly_price') }</td>
              <td>{ I18n.t('signup.teaser.tabs.tab.block.meeteng_room.monthly_fee_new', { price: localizedNumberFormatter(Number(basicPrice)) }) }</td>
              <td>{ I18n.t('signup.teaser.tabs.tab.block.meeteng_room.monthly_fee_premium_new', { price: localizedNumberFormatter(Number(premiumPrice)) }) }</td>
            </tr>

            <tr>
              <td>{ I18n.t('signup.signup_form.meeting_room.infobox.billing_period.period') }</td>
              <td>{ I18n.t('signup.signup_form.meeting_room.infobox.billing_period.yearly') }</td>
              <td>{ I18n.t('signup.signup_form.meeting_room.infobox.billing_period.yearly') }</td>
            </tr>

            <tr>
              <td>{ I18n.t('signup.signup_form.meeting_room.infobox.period_amount.period') }</td>
              <td>{ `${localizedNumberFormatter(Number(basicPrice) * 12)} ${siteKey === 'dk' ? 'kr.' : currentCurrency}` }</td>
              <td>{ `${localizedNumberFormatter(Number(premiumPrice) * 12)} ${siteKey === 'dk' ? 'kr.' : currentCurrency}` }</td>
            </tr>

            <tr>
              <td>{ I18n.t('signup.signup_form.meeting_room.infobox.term.term') }</td>
              <td>{ I18n.t('signup.signup_form.meeting.infobox.term.months_basic') }</td>
              <td>{ I18n.t('signup.signup_form.meeting.infobox.term.months_premium') }</td>
            </tr>

            <tr className="sign-up-modal__table-header">
              <td>{ I18n.t('signup.signup_form.on_deal.infobox.you_get') }</td>
              <td/>
              <td/>
            </tr>

            <tr>
              <td>{I18n.t('signup.teaser.tabs.tab.block.greater')}</td>
              <td><span className="check-icon" /></td>
              <td><span className="check-icon" /></td>
            </tr>

            <tr>
              <td>{I18n.t('signup.teaser.tabs.tab.block.general_2')}</td>
              <td><span className="check-icon" /></td>
              <td><span className="check-icon" /></td>
            </tr>

            <tr>
              <td>{I18n.t('signup.teaser.tabs.tab.block.basic.list_1')}</td>
              <td><span className="check-icon" /></td>
              <td><span className="check-icon" /></td>
            </tr>

            <tr>
              <td>{I18n.t('signup.teaser.prices.block.list_1')}</td>
              <td><span className="check-icon" /></td>
              <td><span className="check-icon" /></td>
            </tr>


            <tr>
              <td>{ I18n.t('signup.teaser.tabs.tab.block.no_cure_no_pay_plus.list_3') }</td>
              <td><span className="check-icon" /></td>
              <td><span className="check-icon" /></td>
            </tr>

            <tr>
              <td>{ I18n.t('signup.teaser.tabs.tab.block.no_cure_no_pay_plus.list_4') }</td>
              <td><span className="check-icon" /></td>
              <td><span className="check-icon" /></td>
            </tr>

            <tr>
              <td>{I18n.t('signup.teaser.tabs.tab.block.general_website-link')}</td>
              <td><span className="check-icon" /></td>
              <td><span className="check-icon" /></td>
            </tr>


            <tr>
              <td>{I18n.t('signup.teaser.tabs.tab.block.general_contact-person-photo')}</td>
              <td><span className="check-icon" /></td>
              <td><span className="check-icon" /></td>
            </tr>

            { siteKey === 'dk' &&
              <tr>
                <td>{I18n.t('signup.teaser.tabs.tab.block.linklist_14')}</td>
                <td><span className="check-icon" /></td>
                <td><span className="check-icon" /></td>
              </tr>
            }

            <tr>
              <td>{ I18n.t('signup.teaser.tabs.tab.block.no_cure_no_pay_premium.list_5') }</td>
              <td/>
              <td><span className="check-icon" /></td>
            </tr>

            <tr>
              <td>{ I18n.t('signup.teaser.tabs.tab.block.no_cure_no_pay_premium.list_6') }</td>
              <td/>
              <td><span className="check-icon" /></td>
            </tr>

            <tr>
              <td>{I18n.t('signup.teaser.prices.block.list_2_3_new')}</td>
              <td/>
              <td><span className="check-icon" /></td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  )
};

export default MeetingRoomsInfoContent;
