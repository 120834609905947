import React from 'react';

import './index.sass';

const ContactsSection = ({ contactInfoEmail, contactInfoPhone, siteKey }) => {

  return (
    <div className="contacts container">
      <div className="contacts__wrapper">
        <div className="contacts__CEO-block">

          <div className="contacts__CEO-block-top">

            <div className="contacts__CEO-img-wrapper">
              <img
                alt="Jakob Dalhoff"
                className="lazyload"
                data-expand="100"
                data-sizes="auto"
                data-src={require(`../../../src/images/list_office/JakobDalhoff.jpg`)}
              />
            </div>

            <div className="contacts__CEO-headline">

              <div className="contacts__CEO-title">Jakob Dalhoff</div>

              <div className="contacts__CEO-subtitle">
                <p>{ I18n.t('signup.teaser.contacts.block.center.jakob_subtitle') }</p>
                <a className="contacts__CEO-email" href={`mailto:${ siteKey === 'dk' ? 'jd@lokalebasen.dk' : 'jd@matchoffice.com' }`}>
                  { siteKey === 'dk' ? 'jd@lokalebasen.dk' : 'jd@matchoffice.com' }
                </a>
              </div>

            </div>
          </div>

          <div className="contacts__CEO-body">

            <p>{ I18n.t('signup.teaser.contacts.block.center.text') }</p>

            { siteKey === 'dk'
              ?
                <img
                  alt="Logo"
                  className="lazyload"
                  data-expand="100"
                  data-sizes="auto"
                  data-src={require(`../../../src/images/logos/lokalebasen_dk.svg`)}
                  height={34}
                  width={116}
                />

              :
                <img
                  alt="Logo"
                  className="lazyload"
                  data-expand="100"
                  data-sizes="auto"
                  data-src={require(`../../../src/images/logos/matchoffice_generic.svg`)}
                  height={41}
                  width={116}
                />
            }
          </div>
        </div>

        <div className="contacts__info-block">

          <div className="contacts__info-title">
            { I18n.t('signup.teaser.contacts.heading') }
          </div>

          <div className="contacts__info-links">
            { contactInfoPhone && (
              <div className="contacts__info-phone">
                <img
                  alt="Phone"
                  className="lazyload"
                  data-expand="100"
                  data-sizes="auto"
                  data-src={require(`../../../src/images/list_office/Phone_icon.svg`)}
                  height={21}
                  width={21}
                />
                <a href={`tel://${contactInfoPhone}`}>
                  { contactInfoPhone }
                </a>
              </div>
            )}
            <div className="contacts__info-email">
              <img
                alt="Phone"
                className="lazyload"
                data-expand="100"
                data-sizes="auto"
                data-src={require(`../../../src/images/list_office/Mail_icon.svg`)}
                height={17}
                width={21}
              />

              <a href={`mailto:${contactInfoEmail}`}>
                { contactInfoEmail }
              </a>
            </div>
          </div>

          <div className="contacts__working-hours-wrapper">
            <div className="contacts__info-title-hours">
              { I18n.t('simple_form.labels.parking_lot.opening_hours') + ':' }
            </div>

            <p className="contacts__working-hours">
              { I18n.t('views.sessions.new.opening_hours_weekdays') } { siteKey === 'dk' ? '09:00-16:30' : '09:00-17:00' }
            </p>
          </div>

        </div>
      </div>
    </div>
  )
};

export default React.memo(ContactsSection);
