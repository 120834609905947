import React from 'react';
import { openPopupWidget } from "react-calendly";

import "./index.sass"

const Calendly = ({ siteKey }) => {

  const clickBookMeeting = () => {
    const url = siteKey === "dk"
      ? 'https://calendly.com/lokalebasen/infomoede-lokalebasen'
      : 'https://calendly.com/infomatchofficecom/info-with-matchoffice';

    openPopupWidget({ url });
  };

  return (
    <div className="calendly-button-section">
      <div className="container">
        <div className="calendly-button-wrapper">
          <div className="calendly-button-block">
            <div onClick={clickBookMeeting} className="calendly-button">
              { I18n.t("signup.teaser.book-meeting-button") }
            </div>
          </div>
          <div className="calendly-text-block">
            <p className="calendly-description-text">
              { I18n.t("signup.teaser.book-meeting-description") }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Calendly;
