import React, { useContext } from 'react';

import UnavailableLocationIcon from '../../../src/images/icons/unavaillable-location-icon.svg';
import SimilarLocations from '../../SimilarLocations';
import { Context } from "../index";

import './index.sass';


const LocationUnavailable = () => {

  const {
    locationAddress,
    section,
    similarLocationsEndpoint,
    similarLocationsHeadline,
    siteKey,
  } = useContext(Context);

  return (
    <div className="location-unavailable">
      <div className="container">
        <div className="location-unavailable__wrapper row">
          <div className="location-unavailable__img-block col-md-3 col-lg-3 col-xl-2">
            <img data-src={ UnavailableLocationIcon }
                 alt="Location"
                 className="location-unavailable__img lazyload"
                 data-sizes="auto"
            />
            <h3 className="location-unavailable__address location-unavailable__address--mobile">
              { locationAddress }
            </h3>
          </div>
          <div className="location-unavailable__content col-12 col-md-8 col-lg-7 col-xl-6 offset-xl-1">
            <h2 className="location-unavailable__address">
              { locationAddress }
            </h2>
            <h2 className="location-unavailable__title">
              { I18n.t('location_unavailable.title') }
            </h2>
            <p className="location-unavailable__subtitle">
              { I18n.t('location_unavailable.subtitle') }
              <a href={ `https://${ window.location.host }` } className="location-unavailable__view-more-link">
                { I18n.t('orders.show.here') }.
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="info-sections__block-wrapper">
        <SimilarLocations
          section={section}
          similarLocationsEndpoint={ similarLocationsEndpoint }
          similarLocationsHeadline={ similarLocationsHeadline }
          siteKey={siteKey}
        />
      </div>
    </div>
  );
};


export default LocationUnavailable;
